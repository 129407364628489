import { Switch } from '@headlessui/react';
import classnames from 'classnames';

interface IProps {
    checked: boolean;
    disabled?: boolean;
    onChange: () => void;
}

const Toggle = ({ checked, disabled = false, onChange }: IProps): JSX.Element => (
    <Switch
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={classnames(
            { 'bg-primary': checked },
            { 'bg-gray-700': !checked },
            `
            border-2
            border-transparent
            cursor-pointer
            disabled:cursor-not-allowed
            disabled:opacity-50
            duration-200
            ease-in-out
            flex-shrink-0
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-offset-gray-900
            focus:ring-primary-500
            h-6
            inline-flex
            relative
            rounded-full
            transition-colors
            w-11
            `
        )}
    >
        <span className="sr-only">Use setting</span>
        <span
            className={classnames(
                { 'translate-x-5': checked },
                { 'translate-x-0': !checked },
                `
                bg-white
                duration-200
                ease-in-out
                h-5
                inline-block
                pointer-events-none
                ring-0
                rounded-full
                shadow
                transform
                transition
                w-5
                `
            )}
            aria-hidden="true"
        />
    </Switch>
);

export { Toggle };
