import * as signalR from '@microsoft/signalr';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from '~/hooks';
import { getInnerZoneBusinessKey } from '~/hooks/innerZone';
import config from '../config';

interface IProps {
    businessId: string;
    children: React.ReactNode;
}

const SignalR = ({ businessId, children }: IProps): JSX.Element => {
    const { getAccessTokenSilently } = useAuth();
    const queryClient = useQueryClient();

    useEffect(() => {
        const c = new signalR.HubConnectionBuilder()
            .withUrl(`${config.api.baseUrl}/Hubs/InnerZone`, {
                accessTokenFactory: () =>
                    getAccessTokenSilently().then(token => {
                        return token;
                    }),
            })
            .build();

        c.on('BusinessInvalidated', businessId => {
            queryClient.invalidateQueries(getInnerZoneBusinessKey(businessId));
        });

        c.start().then(() => {
            c.invoke('SubscribeToBusiness', businessId);
        });
    }, [businessId, getAccessTokenSilently, queryClient]);

    return <>{children}</>;
};

export default SignalR;
