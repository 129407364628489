import { INote, IUserEntitlementType } from '@api';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Button, Can } from '~/components';

interface IProps {
    note: INote;
    onEditClick: () => void;
}

const Note = ({ note, onEditClick }: IProps): JSX.Element => {
    return (
        <div className="p-4 mb-4 rounded bg-gray-800">
            <div className="truncated">{note.text}</div>
            <div className="flex justify-between text-sm mt-3">
                <div>{moment(note.updatedAt).format('L')}</div>
                <Can hasEntitlement={IUserEntitlementType.NoteCreate}>
                    <Button color="tertiary" size="sm" onClick={onEditClick}>
                        <FontAwesomeIcon icon={faEdit} className="mr-2" />
                        Edit
                    </Button>
                </Can>
            </div>
        </div>
    );
};

export default Note;
