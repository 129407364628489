import { EsopsApi, IEsop, IEsopCreateRequest, IEsopUpdateRequest, IProblemDetails } from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const esopsApi = new EsopsApi(undefined, config.api.baseUrl);

export const getEsopQueryKey = (businessId: string): QueryKey => getBusinessQueryKey(businessId, 'esop');

export const useAddEsop = (businessId: string): UseMutationResult<IEsop, IProblemDetails, IEsopCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IEsop, IProblemDetails, IEsopCreateRequest>(
        request => esopsApi.postEsop(undefined, request).then(response => response.data),
        {
            onSuccess: () => invalidateEsop(queryClient, businessId),
        }
    );

    return mutation;
};

export const useDeleteEsop = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        esopId => esopsApi.deleteEsop(esopId, undefined).then(response => response.data),
        {
            onSuccess: () => invalidateEsop(queryClient, businessId),
        }
    );

    return mutation;
};

interface IEsopUpdateParams {
    esopId: string;
    request: IEsopUpdateRequest;
}

export const useUpdateEsop = (businessId: string): UseMutationResult<void, IProblemDetails, IEsopUpdateParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IEsopUpdateParams>(
        ({ esopId, request }) => esopsApi.putEsop(esopId, undefined, request).then(response => response.data),
        {
            onSuccess: () => invalidateEsop(queryClient, businessId),
        }
    );

    return mutation;
};

export const useEsopForBusiness = (businessId: string): UseQueryResult<IEsop, IProblemDetails> =>
    useQuery(getEsopQueryKey(businessId), () => esopsApi.getForBusiness(businessId).then(response => response.data), {
        enabled: !!businessId,
    });

function invalidateEsop(queryClient: QueryClient, businessId: string) {
    // reset so that we can display an empty state with a 404
    queryClient.resetQueries(getEsopQueryKey(businessId));
}
