import { IBusiness, IEmployee } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInputElement, TextInputError, TextInputLabel } from '~/components';

const getInputShares = (value: string | undefined): number => {
    try {
        return Number.parseInt(value ?? '', 10) || 0;
    } catch {
        return 0;
    }
};

const getSharesAllocated = (employees: IEmployee[], selectedEmployeeId: string, inputShares: number) =>
    employees.filter(e => e.id !== selectedEmployeeId).reduce((a, e) => a + (e.shares ?? 0), 0) + inputShares;

const getTotalShares = (business: IBusiness) => business.sharesOutstanding ?? 0;

interface IShareAllocationTextProps {
    business?: IBusiness;
    employees: IEmployee[];
    selectedEmployeeId?: string;
    value?: string;
}

const ShareAllocationText = ({
    business,
    employees,
    selectedEmployeeId,
    value,
}: IShareAllocationTextProps): JSX.Element => {
    if (!business || !selectedEmployeeId) return <span className="text-sm" />;

    const totalShares = getTotalShares(business);
    if (totalShares === 0) {
        return <span className="text-sm" />;
    }

    const sharesAllocated = getSharesAllocated(employees, selectedEmployeeId, getInputShares(value));

    return (
        <span className="text-sm">{`Shares Allocated: ${sharesAllocated.toLocaleString()} / ${totalShares.toLocaleString()}`}</span>
    );
};

interface IWarningTextProps {
    business?: IBusiness;
    employees: IEmployee[];
    selectedEmployeeId?: string;
    value?: string;
}

const WarningText = ({ business, employees, selectedEmployeeId, value }: IWarningTextProps): JSX.Element => {
    if (!business || !selectedEmployeeId) return <span className="text-sm" />;

    const totalShares = getTotalShares(business);
    const sharesAllocated = getSharesAllocated(employees, selectedEmployeeId, getInputShares(value));

    if (totalShares === 0 || sharesAllocated <= totalShares) {
        return <span className="text-sm" />;
    }

    return (
        <div className="mt-1 pl-1 text-sm">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-yellow-300" />
            Share allocation is over 100%
        </div>
    );
};

interface IProps {
    business?: IBusiness;
    employees: IEmployee[];
    selectedEmployee?: Partial<IEmployee>;
    error: string;
    onChange: (v: string) => void;
    value: string;
}

const SharesInput = ({ business, employees, selectedEmployee, error, onChange, value }: IProps): JSX.Element => {
    return (
        <div>
            <div className="flex justify-between">
                <TextInputLabel label="Shares" />
                <ShareAllocationText
                    business={business}
                    employees={employees}
                    selectedEmployeeId={selectedEmployee?.id}
                    value={value}
                />
            </div>
            <TextInputElement error={error} label="Shares" type="number" value={value} onChange={onChange} />
            <WarningText
                business={business}
                employees={employees}
                selectedEmployeeId={selectedEmployee?.id}
                value={value}
            />
            <TextInputError error={error} />
        </div>
    );
};

export default SharesInput;
