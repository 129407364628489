import {
    FamilyLimitedPartnershipsApi,
    IFamilyLimitedPartnership,
    IFamilyLimitedPartnershipCreateRequest,
    IFamilyLimitedPartnershipUpdateRequest,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getEmployeeQueryKey } from './employees';

const familyLimitedPartnershipsApi = new FamilyLimitedPartnershipsApi(undefined, config.api.baseUrl);
const getFlpKey = (businessId: string, ...rest: string[]): QueryKey =>
    getEmployeeQueryKey(businessId, 'familyLimitedPartnership', ...(rest || []));

export const useAddFamilyLimitedPartnership = (
    businessId: string
): UseMutationResult<IFamilyLimitedPartnership, IProblemDetails, IFamilyLimitedPartnershipCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IFamilyLimitedPartnership, IProblemDetails, IFamilyLimitedPartnershipCreateRequest>(
        request =>
            familyLimitedPartnershipsApi
                .postFamilyLimitedPartnership(undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => invalidateFlp(queryClient, businessId),
        }
    );

    return mutation;
};

export const useDeleteFamilyLimitedPartnership = (
    businessId: string
): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        familyLimitedPartnershipId =>
            familyLimitedPartnershipsApi
                .deleteFamilyLimitedPartnership(familyLimitedPartnershipId, undefined)
                .then(response => response.data),
        {
            onSuccess: () => invalidateFlp(queryClient, businessId),
        }
    );

    return mutation;
};

export const useFamilyLimitedPartnershipForEmployee = (
    businessId: string,
    employeeId: string
): UseQueryResult<IFamilyLimitedPartnership, IProblemDetails> =>
    useQuery(
        getFlpKey(businessId, employeeId),
        () => familyLimitedPartnershipsApi.getForEmployee(employeeId).then(response => response.data),
        { enabled: !!businessId && !!employeeId }
    );

export const useUpdateFamilyLimitedPartnership = (
    businessId: string,
    familyLimitedPartnershipId: string
): UseMutationResult<void, IProblemDetails, IFamilyLimitedPartnershipUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IFamilyLimitedPartnershipUpdateRequest>(
        request =>
            familyLimitedPartnershipsApi
                .putFamilyLimitedPartnership(familyLimitedPartnershipId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => invalidateFlp(queryClient, businessId),
        }
    );

    return mutation;
};

function invalidateFlp(queryClient: QueryClient, businessId: string) {
    // reset so that we can display an empty state with a 404
    queryClient.resetQueries(getFlpKey(businessId));
}
