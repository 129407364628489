import { IBusiness, IBusinessValuation, IFamilyLimitedPartnership } from '@api';
import { getEquityAmount, getLimitedInterestAmount } from '~/utils/businessUtils';
import { formatPercentage } from '~/utils/percentageFormatter';
import { generalPartnerColor, limitedPartnerColor } from './constants';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    partnership: IFamilyLimitedPartnership;
}

const RemainingOwnerInterest = ({ business, businessValuation, partnership }: IProps): JSX.Element => {
    const partnerTransferSum = partnership?.partners.reduce((sum, p) => sum + p.dollarAmount, 0);
    const partnershipLimitedInterestAmount = getLimitedInterestAmount(
        business,
        businessValuation.value ?? 0,
        partnership
    );
    const partnershipValue = getEquityAmount(business, businessValuation.value ?? 0, partnership);
    const remainingLimitedInterestAmount = partnershipLimitedInterestAmount - partnerTransferSum;
    const remainingLimitedInterest = partnershipValue > 0 ? remainingLimitedInterestAmount / partnershipValue : 0;
    const formatOptions = remainingLimitedInterest > 0.01 ? { maximumFractionDigits: 0 } : undefined;

    return (
        <div className="text-lg font-bold pl-1">
            <span className={`${generalPartnerColor}`}>{partnership?.generalPartnerInterest}%</span>
            <span className={`${limitedPartnerColor} ml-2`}>
                {formatPercentage(remainingLimitedInterest, formatOptions)}
            </span>
        </div>
    );
};

export default RemainingOwnerInterest;
