import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import deepEqual from 'deep-equal';
import { PropsWithChildren } from 'react';
import { components, ControlProps, default as ReactSelect, NamedProps, OptionProps } from 'react-select';
import { default as CreatableSelect } from 'react-select/creatable';

export interface ISelectOption {
    label: string;
    value: string;
    __isNew__?: true; // based on react-select API (not in their type defs)
}

export interface ISelectProps<T extends ISelectOption> extends Omit<NamedProps<T>, 'value'> {
    error?: string;
    isCreatable?: boolean;
    label?: string;
    onCreateOption?: (inputValue: string) => void;
    optionComponent?: React.ComponentType<OptionProps<T, boolean>>;
    options?: Array<T>;
    showCreateWhenEmpty?: boolean;
    showSearchIcon?: boolean;
    value?: string | T;
}

const Control = <T extends ISelectOption>({ children, ...props }: PropsWithChildren<ControlProps<T, false>>) => {
    return (
        <components.Control {...props}>
            {props.selectProps.showSearchIcon && <FontAwesomeIcon icon={faSearch} className="ml-3 text-gray-500" />}
            {children}
        </components.Control>
    );
};

const isISelectOption = (toBeDetermined: string | ISelectOption | undefined): toBeDetermined is ISelectOption => {
    if ((toBeDetermined as ISelectOption).label) {
        return true;
    }
    return false;
};

const creatableOption: ISelectOption = {
    // included so "new" option is still shown when search is empty
    label: '',
    value: '',
    __isNew__: true,
};

const getOptions = <T extends ISelectOption>(options: T[], showCreateOption: boolean): T[] => [
    ...options,
    ...(showCreateOption ? [creatableOption as T] : []),
];

const Select = <T extends ISelectOption>({
    error,
    isCreatable = false,
    isDisabled = false,
    label,
    optionComponent,
    options,
    placeholder,
    showCreateWhenEmpty = false,
    value,
    ...rest
}: ISelectProps<T>): JSX.Element => {
    const valueIsSet = typeof value !== 'undefined' && value !== null;
    const foundValue =
        (valueIsSet &&
            options?.find(option =>
                isISelectOption(value)
                    ? deepEqual(option.value, value?.value) || deepEqual(option.value, value?.label)
                    : deepEqual(option.value, value)
            )) ||
        null;
    let showCreateOption = false;
    if (isCreatable) {
        if (!foundValue && !!value) {
            showCreateOption = true;
        } else if (showCreateWhenEmpty && !value) {
            showCreateOption = true;
        }
    }
    const optionComponentOverride = optionComponent ? optionComponent : components.Option;
    const props: NamedProps<T> = {
        className: 'sm:text-sm',
        components: { Control, Option: optionComponentOverride as never },
        isDisabled,
        isSearchable: true,
        maxMenuHeight: 450,
        menuPlacement: 'auto',
        options: getOptions(options || [], showCreateOption),
        placeholder: placeholder || 'Select One',
        styles: {
            control: styles => ({
                ...styles,
                '&:hover': 'var(--color-input-border)',
            }),
            menuList: styles => ({
                ...styles,
                '::-webkit-scrollbar': {
                    height: '0.5rem',
                    width: '0.5rem',
                },
                '::-webkit-scrollbar-track': {
                    background: 'var(--color-bg-700)',
                },
                '::-webkit-scrollbar-thumb': {
                    background: 'var(--color-bg-500)',
                },
                '::-webkit-scrollbar-thumb:hover': {
                    background: 'var(--color-primary-500)',
                },
            }),
            option: (styles, { isSelected }) => ({
                ...styles,
                color: isSelected ? 'white' : styles.color,
                backgroundColor: isSelected ? 'var(--color-input-border)' : styles.backgroundColor,
                minHeight: '2rem',
            }),
        },
        theme: theme => ({
            ...theme,
            borderRadius: 6,
            colors: {
                ...theme.colors,
                primary: 'var(--color-primary-500)',
                primary75: 'var(--color-input-border)',
                primary50: 'var(--color-input-border)',
                primary25: 'var(--color-input-border)',
                danger: 'var(--color-danger-400)',
                dangerLight: 'var(--color-danger-500)',
                neutral0: `var(--color-input-bg)`,
                neutral5: `var(--color-input-bg)`,
                neutral10: 'var(--color-input-border)',
                neutral20: 'var(--color-input-border)',
                neutral30: 'var(--color-bg-500)',
                neutral40: 'var(--color-bg-500)',
                neutral50: 'var(--color-bg-500)',
                neutral60: 'var(--color-bg-300)',
                neutral70: 'var(--color-bg-300)',
                neutral80: 'white',
                neutral90: 'white',
            },
        }),
        value: foundValue,
        ...rest,
    };

    return (
        <>
            {label && <label className="block text-sm font-medium mb-1">{label}</label>}
            <div
                className={classNames({
                    'cursor-not-allowed opacity-50': isDisabled,
                })}
            >
                {isCreatable ? <CreatableSelect {...props} /> : <ReactSelect {...props} />}
            </div>
            {error && <p className="mt-2 text-sm text-danger-600">{error}</p>}
        </>
    );
};

export { Select };
