import { IDocument } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteConfirmationModal, IModalProps } from '~/components';
import { useDeleteDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { getIconFromExtension } from '../icons/iconMap';
import { getColorFromIcon } from '../utils/colorMap';

interface IProps extends Pick<IModalProps, 'open'> {
    entries: IDocument[];
    onClose: () => void;
    onSaveSuccess: () => void;
}

enum Status {
    None,
    Error,
    IsSaving,
}

const BulkDeleteModal = ({ entries, onClose, onSaveSuccess, open }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const deleteDocument = useDeleteDocument(businessId);
    const [status, setStatus] = useState(Status.None);

    const handleConfirm = async () => {
        if (status === Status.IsSaving) {
            return;
        }
        try {
            setStatus(Status.IsSaving);
            await Promise.all(entries.map(entry => deleteDocument.mutateAsync(entry.id)));
            onSaveSuccess();
        } catch {
            setStatus(Status.Error);
        }
    };

    return (
        <DeleteConfirmationModal
            isOpen={open}
            isSaving={status === Status.IsSaving}
            onClose={onClose}
            onConfirm={handleConfirm}
            title="Delete Items"
        >
            <p className="text-sm text-gray-400 mb-4">
                Are you sure you want to delete these documents? Documents will be permanently removed. This action
                cannot be undone.
            </p>
            {entries.map((entry, index) => {
                const icon = getIconFromExtension(entry.name);
                const color = getColorFromIcon(icon.iconName);
                return (
                    <div key={index} className="flex items-center my-2">
                        <span className="fa-stack fa-sm flex items-center justify-center mr-2">
                            <FontAwesomeIcon icon={icon} className={color} aria-hidden />
                        </span>
                        <span className="truncate" title={entry.name}>
                            {entry.name}
                        </span>
                    </div>
                );
            })}
            {status === Status.Error && (
                <p className="text-danger mt-4">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" aria-hidden />
                    {defaultErrorMessage}
                </p>
            )}
        </DeleteConfirmationModal>
    );
};

export default BulkDeleteModal;
