import { IAdvisorFirm } from '@api';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Button,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { useAdvisorsByFirmId } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import { AdminPageType } from '../admin/AdminPageType';
import { ILocationState } from '../ILocationState';
import AddAdvisor from './AddAdvisor';

interface IProps {
    firm: IAdvisorFirm;
}

const AdvisorList = ({ firm }: IProps): JSX.Element | null => {
    const [isAddOpen, setIsAddOpen] = useState(false);
    const { isLoading: isLoadingAdvisors, error: errorAdvisors, data: advisors } = useAdvisorsByFirmId(firm.id);
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Advisor List',
    };

    if (errorAdvisors) return <ErrorPage />;
    if (isLoadingAdvisors) return <LoadingIndicator />;

    return (
        <>
            <div className="flex justify-between mb-4">
                <h3 className="text-lg leading-6 font-medium">Advisor List</h3>
                <div className="self-center space-x-3">
                    <Button color="primary" onClick={() => setIsAddOpen(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add New
                    </Button>
                </div>
            </div>
            {advisors && advisors.length === 0 && <p className="text-lg">No advisors added yet.</p>}
            {advisors && advisors.length > 0 && (
                <Table className="w-full">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Advisor Name</TableHeaderCell>
                            <TableHeaderCell className="hidden sm:table-cell">Email</TableHeaderCell>
                            <TableHeaderCell>
                                <span className="sr-only">Actions</span>
                            </TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {advisors.map(advisor => {
                            return (
                                <TableRow key={advisor.id}>
                                    <TableCell>{NameFormatter.getLastNameFirst(advisor)}</TableCell>
                                    <TableCell className="hidden sm:table-cell">
                                        {advisor.emailAddress || '--'}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <Link
                                            className="text-primary hover:text-primary-300"
                                            to={{
                                                pathname: `/${AdminPageType.Advisors}/${advisor.id}`,
                                                state: locationState,
                                            }}
                                        >
                                            Edit
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
            <AddAdvisor advisorFirmId={firm.id} isOpen={isAddOpen} onClose={() => setIsAddOpen(false)} />
        </>
    );
};

export default AdvisorList;
