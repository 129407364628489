import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';
import { useEffect, useRef } from 'react';

interface IProps {
    plotHeight: number;
    width: number;
    xAxis: d3Scale.ScaleLinear<number, number, never>;
}

const StackedAreaChartXAxis = ({ plotHeight, width, xAxis }: IProps): JSX.Element => {
    const xAxisRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!xAxisRef.current || width === 0) return;
        const axisSelection = d3Selection.select(xAxisRef.current).attr('class', 'xAxis');
        axisSelection.call(
            d3Axis
                .axisBottom(xAxis)
                .ticks(5)
                .tickSize(-plotHeight)
                .tickFormat(d => d.toString())
        );
        d3Selection.selectAll('.xAxis .tick line').style('stroke', 'gray');
        d3Selection.selectAll('.xAxis .tick text').attr('font-size', '1rem').attr('dy', '1rem');
        d3Selection.selectAll('.xAxis .domain').style('stroke-width', 0);
    }, [plotHeight, width, xAxis, xAxisRef]);

    return <g ref={xAxisRef} transform={`translate(0,${plotHeight})`} />;
};

export default StackedAreaChartXAxis;
