import { IInnerZoneEmployeeLinkType, IInnerZoneEmployeeProfileType } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faLightbulb, faNetworkWired, faUserCheck } from '@fortawesome/pro-light-svg-icons';

export const linkTypeColor: Record<IInnerZoneEmployeeLinkType, string> = {
    Moderate: 'yellow-500',
    Strongest: 'green-500',
    Weakest: 'red-500',
};

export const profileTypeColor: Record<IInnerZoneEmployeeProfileType, string> = {
    BottomLiner: 'purple-400',
    BridgeBuilder: 'orange-500',
    Energizer: 'green-400',
    Thinker: 'cyan-500',
};

export const profileTypeDisplay: Record<IInnerZoneEmployeeProfileType, string> = {
    BottomLiner: 'Bottom Liner',
    BridgeBuilder: 'Bridge Builder',
    Energizer: 'Energizer',
    Thinker: 'Thinker',
};

export const profileTypeIcon: Record<IInnerZoneEmployeeProfileType, IconProp> = {
    BottomLiner: faUserCheck,
    BridgeBuilder: faNetworkWired,
    Energizer: faBolt,
    Thinker: faLightbulb,
};
