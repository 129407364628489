import * as d3Axis from 'd3-axis';
import { ScaleBand } from 'd3-scale';
import { select as d3Select } from 'd3-selection';
import { ComponentPropsWithRef, ForwardedRef, forwardRef, useEffect } from 'react';
import useForwardedRef from '~/hooks/useForwardedRef';

interface IProps extends ComponentPropsWithRef<'g'> {
    scaleBand: ScaleBand<string>;
}

const BarChartYAxisComponent = ({ scaleBand, ...rest }: IProps, ref: ForwardedRef<SVGSVGElement>) => {
    const safeRef = useForwardedRef(ref);

    useEffect(() => {
        if (!safeRef.current) {
            return;
        }

        const axisSelection = d3Select(safeRef.current);
        const axis = d3Axis.axisLeft(scaleBand);

        axisSelection.call(axis);
        axisSelection.select('.domain').remove();
        axisSelection
            .selectAll('.tick text')
            .attr('font-size', '0.875rem')
            .attr('x', safeRef.current.getBoundingClientRect().width);
    }, [safeRef, scaleBand]);

    return <g ref={safeRef} {...rest} />;
};

const BarChartYAxis = forwardRef<SVGSVGElement, IProps>(BarChartYAxisComponent);

export default BarChartYAxis;
