import { IInnerZoneSurveyQuestion } from '@api';
import { Button } from '~/components';

interface IProps {
    progress: number;
    question: IInnerZoneSurveyQuestion;
    onAnswerOptionClick: (answerOption: string) => void;
}

const SurveyQuestion = ({ progress, question, onAnswerOptionClick }: IProps): JSX.Element => (
    <div className="h-full flex flex-col justify-between my-auto space-y-8 w-full lg:w-144" style={{ maxHeight: 600 }}>
        <div className="space-y-8">
            <div className="bg-gray-700 h-3">
                <div className="bg-primary-500 h-full" style={{ width: `${progress}%` }} />
            </div>
            <div className="h-14 text-xl leading-8">{question.question}</div>
        </div>
        <div>
            {question.answerOptions.map(o => (
                <div key={o} className="mb-2">
                    <Button
                        fullWidth
                        className="capitalize"
                        size="xxl"
                        onClick={e => {
                            e.currentTarget.blur();
                            onAnswerOptionClick(o);
                        }}
                    >
                        {o}
                    </Button>
                </div>
            ))}
        </div>
        <div />
    </div>
);

export default SurveyQuestion;
