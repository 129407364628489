import * as d3Array from 'd3-array';
import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';
import * as d3Shape from 'd3-shape';
import { useEffect, useRef } from 'react';
import StackedAreaChartGradients from './StackedAreaChartGradients';
import StackedAreaChartXAxis from './StackedAreaChartXAxis';
import StackedAreaChartYAxis from './StackedAreaChartYAxis';

const margin = {
    top: 10,
    right: 30,
    bottom: 30,
    left: 80,
};

interface IDataForYear {
    year: number;
    [key: string]: number;
}

interface IProps {
    data: IDataForYear[];
    height: number;
    width: number;
}

const getMaxYValue = (data: IDataForYear[]): number => {
    const values = data.map(d => {
        let value = 0;
        for (const [k, v] of Object.entries(d)) {
            if (k !== 'year') value += v;
        }
        return value * 1.1;
    });
    return Math.max(...values);
};

const StackedAreaChart = ({ data, height, width }: IProps): JSX.Element => {
    const plotHeight = height - margin.top - margin.bottom;
    const plotWidth = width - margin.left - margin.right;

    const svgRef = useRef<SVGSVGElement>(null);
    const keys = Object.keys(data[0]).slice(1);
    const stackedData = d3Shape.stack().keys(keys)(data);

    const extent = d3Array.extent(data, d => d.year) as [number, number];
    const xAxis = d3Scale.scaleLinear().domain(extent).range([0, plotWidth]);

    const maxYValue = getMaxYValue(data);
    const yAxis = d3Scale.scaleLinear().domain([0, maxYValue]).range([plotHeight, 0]);

    useEffect(() => {
        if (!svgRef.current || width === 0) return;
        const svg = d3Selection.select(svgRef.current);

        const areaGenerator = d3Shape
            .area()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .x((d, i) => xAxis((d as any).data.year))
            .y0(d => yAxis(d[0]))
            .y1(d => yAxis(d[1]));

        svg.selectAll('mylayers')
            .data(stackedData)
            .enter()
            .append('path')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .style('fill', (d, i) => `url(#Gradient${i})`)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .attr('d', areaGenerator as any);
    }, [keys, stackedData, svgRef, width, xAxis, yAxis]);

    return (
        <svg ref={svgRef} height={height} width={width}>
            <StackedAreaChartGradients />
            <g transform={`translate(${margin.left},${margin.top})`}>
                <StackedAreaChartXAxis plotHeight={plotHeight} width={width} xAxis={xAxis} />
                <StackedAreaChartYAxis width={width} yAxis={yAxis} />
            </g>
        </svg>
    );
};

export default StackedAreaChart;
