import { IAdvisorFirm, IBusiness, IBusinessPageType, IBusinessValuation } from '@api';
import { faCheckCircle, faCog, faGripVertical, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link, useLocation } from 'react-router-dom';
import {
    Button,
    DeleteConfirmationModal,
    ErrorModal,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Toggle,
} from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useAdvisorFirm, useUpdateBusiness } from '~/hooks';
import { EditAdvisorPageType } from '~/pages/advisorEdit';
import { ILocationState } from '~/pages/ILocationState';
import { businessPageDisplay, businessPageIcon, businessPageIconColor, getArrayOfEnum } from '~/utils/enumUtils';
import PageSettingsSlideOver from './pageSettings';

export interface IPageSetting {
    enabled: boolean;
    index: number;
    pageType: IBusinessPageType;
}

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const settingsMap: Record<IBusinessPageType, boolean> = {
    BonusRight: false,
    BusinessEvaluation: false,
    BusinessForecast: false,
    Community: false,
    CorporateStrategies: false,
    DocumentCenter: false,
    Education: true,
    ExecutivePrograms: false,
    InnerZone: false,
    Observations: false,
    Purpose: false,
    SuccessionPlan: false,
    TeamValues: true,
};

export const getInitialBusinessPageSettings = (pages: IBusinessPageType[]): IPageSetting[] => {
    let disabledCount = 0;

    return getArrayOfEnum(IBusinessPageType)
        .map(pageType => {
            const enabled = pages.includes(pageType);
            const index = enabled ? pages.indexOf(pageType) : pages.length + disabledCount;

            if (!enabled) {
                disabledCount++;
            }

            return {
                enabled,
                index,
                pageType,
            };
        })
        .sort((a, b) => a.index - b.index);
};

export const togglePage = (pageSettings: IPageSetting[], pageType: IBusinessPageType): IPageSetting[] =>
    pageSettings.map(pageSetting => {
        if (pageSetting.pageType === pageType) {
            return {
                ...pageSetting,
                enabled: !pageSetting.enabled,
            };
        }

        return pageSetting;
    });

export const reorder = (pageSettings: IPageSetting[], sourceIndex: number, destinationIndex: number): IPageSetting[] =>
    pageSettings
        .map(ps => {
            if (ps.index === sourceIndex) {
                return {
                    ...ps,
                    index: destinationIndex,
                };
            }
            if (ps.index < sourceIndex) {
                if (ps.index >= destinationIndex) {
                    return {
                        ...ps,
                        index: ps.index + 1,
                    };
                }
            }
            if (ps.index > sourceIndex) {
                if (ps.index <= destinationIndex) {
                    return {
                        ...ps,
                        index: ps.index - 1,
                    };
                }
            }

            return ps;
        })
        .sort((a, b) => a.index - b.index);

const getIsEqualToFirmDefault = (
    firmPages: IBusinessPageType[] | null | undefined,
    businessPages: IBusinessPageType[] | null | undefined
): boolean => {
    if (!firmPages || !businessPages) {
        return false;
    }
    if (businessPages.length !== firmPages.length) {
        return false;
    }
    return businessPages.every((page, i) => firmPages[i] === page);
};

const PresentationSettings = ({ business, businessValuation }: IProps): JSX.Element => {
    const [isDisabled, setIsDisabled] = useState(false);
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [pageSettings, setPageSettings] = useState<IPageSetting[]>(
        getInitialBusinessPageSettings(business.pages || [])
    );
    const {
        isError: isAdvisorFirmError,
        isLoading: isAdvisorFirmLoading,
        data: advisorFirm,
    } = useAdvisorFirm(business.advisorFirmId);
    const isEqualToFirmDefault = (advisorFirm: IAdvisorFirm) =>
        getIsEqualToFirmDefault(advisorFirm.pages, business.pages);
    const [pageSettingsSlideOver, setPageSettingsSlideOver] = useState<{
        isOpen: boolean;
        pageType?: IBusinessPageType;
    }>({ isOpen: false });
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
        label: `${business.name} Presentation Settings`,
    };
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [resetModal, setResetModal] = useState<{
        isOpen: boolean;
        isSaving?: boolean;
    }>({ isOpen: false });
    const updateBusiness = useUpdateBusiness(business.id);
    const onChange = (pageType: IBusinessPageType) => setPageSettings(togglePage(pageSettings, pageType));
    const onReorder = (sourceIndex: number, destinationIndex: number) =>
        setPageSettings(reorder(pageSettings, sourceIndex, destinationIndex));
    const onClickReset = () => setResetModal({ isOpen: true });
    const onConfirmReset = async () => {
        const firmDefault = getInitialBusinessPageSettings(advisorFirm?.pages || []);
        setResetModal({ ...resetModal, isSaving: true });
        try {
            await handleSave(firmDefault);
            setPageSettings(firmDefault);
        } finally {
            setResetModal({ isOpen: false });
        }
    };
    const onClickSave = () => handleSave(pageSettings);
    const handleSave = async (pages: IPageSetting[]) => {
        try {
            setIsDisabled(true);
            await updateBusiness.mutateAsync({
                ...business,
                pages: pages.filter(ps => ps.enabled).map(ps => ps.pageType),
                value: businessValuation.value,
            });
            showSuccessNotification();
        } catch {
            setShowErrorModal(true);
        } finally {
            setIsDisabled(false);
        }
    };

    if (isAdvisorFirmError) return <ErrorPage />;
    if (isAdvisorFirmLoading || !advisorFirm) return <LoadingIndicator />;

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Presentation Settings</h3>
            <p className="mt-1 text-sm leading-5 font-normal text-gray-300">
                Turn pages on or off to show in your presentation. Click and grab to change the order of your
                presentation.
            </p>
            <div className="mt-4 mb-2">
                <div className="text-sm flex items-center">
                    {isEqualToFirmDefault(advisorFirm) ? (
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
                    ) : (
                        <FontAwesomeIcon icon={faInfoCircle} className="text-primary-300 mr-2" />
                    )}
                    <span className="text-gray-400">
                        {isEqualToFirmDefault(advisorFirm)
                            ? `These settings are the same as your firm's default.`
                            : `These settings differ from your firm's default.`}
                    </span>
                    <Button
                        className="ml-2"
                        disabled={isEqualToFirmDefault(advisorFirm)}
                        onClick={onClickReset}
                        size="sm"
                    >
                        Reset to Default
                    </Button>
                    <Link
                        className="ml-2"
                        to={{
                            pathname: `/settings/Me/${EditAdvisorPageType.FirmPresentationSettings}`,
                            state: locationState,
                        }}
                    >
                        <Button size="sm">Edit Default</Button>
                    </Link>
                </div>
            </div>
            <DragDropContext
                onDragEnd={result => result.destination && onReorder(result.source.index, result.destination.index)}
            >
                <Droppable droppableId="droppable">
                    {provided => (
                        <Table ref={provided.innerRef}>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell>Presentation</TableHeaderCell>
                                    <TableHeaderCell>On/Off</TableHeaderCell>
                                    <TableHeaderCell></TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageSettings.map((pageSetting, index) => (
                                    <Draggable
                                        key={pageSetting.pageType}
                                        draggableId={pageSetting.pageType}
                                        index={index}
                                        isDragDisabled={isDisabled}
                                    >
                                        {provided => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <TableCell>
                                                    <FontAwesomeIcon className="text-gray-500" icon={faGripVertical} />
                                                </TableCell>
                                                <TableCell className="w-full text-sm leading-5">
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        className={`mr-4 text-${
                                                            businessPageIconColor[pageSetting.pageType]
                                                        }`}
                                                        icon={businessPageIcon[pageSetting.pageType]}
                                                    />
                                                    {businessPageDisplay[pageSetting.pageType]}
                                                </TableCell>
                                                <TableCell>
                                                    <Toggle
                                                        checked={pageSetting.enabled}
                                                        disabled={isDisabled}
                                                        onChange={() => onChange(pageSetting.pageType)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {settingsMap[pageSetting.pageType] && (
                                                        <Button
                                                            color="primaryLink"
                                                            disabled={isDisabled}
                                                            onClick={() =>
                                                                setPageSettingsSlideOver({
                                                                    isOpen: true,
                                                                    pageType: pageSetting.pageType,
                                                                })
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faCog} className="mr-2" />
                                                            Settings
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        </Table>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="py-3 flex flex-row-reverse">
                <Button color="primary" disabled={isDisabled} onClick={onClickSave}>
                    Save
                </Button>
                <Button className="mr-3" disabled={isEqualToFirmDefault(advisorFirm)} onClick={onClickReset}>
                    Reset to Default
                </Button>
            </div>
            <DeleteConfirmationModal
                confirmButtonText="Reset"
                onClose={() => setResetModal({ ...resetModal, isOpen: false })}
                onConfirm={onConfirmReset}
                title="Reset Presentation Settings"
                {...resetModal}
            >
                Are you sure you want to reset presentation settings? This will take effect immediately. Individual page
                settings such as education resource selections will remain unchanged.
            </DeleteConfirmationModal>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
            <PageSettingsSlideOver
                {...pageSettingsSlideOver}
                business={business}
                onClose={() => setPageSettingsSlideOver({ ...pageSettingsSlideOver, isOpen: false })}
            />
        </>
    );
};

export default PresentationSettings;
