import { faCaretRight, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, IModalProps, LoadingIndicator, Modal } from '~/components';
import { useDocumentList, useDocumentPath, useUpdateDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { documentCenterStore } from '../../DocumentCenterContext';
import FolderIconTableView from '../../icons/FolderIconTableView';
import SelectionIcon from '../../icons/SelectionIcon';
import ModalBreadcrumbs from './ModalBreadcrumbs';
import NoSubfoldersMessage from './NoSubfoldersMessage';

enum Status {
    None,
    IsSaving,
    SubmitError,
}

interface IProps extends Pick<IModalProps, 'open'> {
    onClose: () => void;
    onSaveSuccess: () => void;
}

const MoveItemsModal = ({ onClose, onSaveSuccess, open }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const updateDocument = useUpdateDocument(businessId);
    const { selectedItems, currentFolderId } = useContext(documentCenterStore);
    const [activeFolderId, setActiveFolderId] = useState(currentFolderId);
    const [status, setStatus] = useState(Status.None);
    const [targetFolderId, setTargetFolderId] = useState(currentFolderId);
    const showProgress = status === Status.IsSaving;
    const folder = useDocumentList(businessId, activeFolderId);
    const folderPath = useDocumentPath(businessId, activeFolderId);
    const activeFolder = folder.data;
    const subFolders = activeFolder?.documents.filter(d => d.isFolder);
    const isLoading = folder.isFetching || folderPath.isFetching;
    const hasError = folder.isError || folderPath.isError || status === Status.SubmitError;

    const onChangeActiveFolder = (itemId: string) => {
        if (showProgress) {
            return;
        }

        setActiveFolderId(itemId);
        setTargetFolderId(itemId);
    };

    const onSubmit = async () => {
        if (!currentFolderId || currentFolderId === targetFolderId) {
            onClose();
            return;
        }

        setStatus(Status.IsSaving);

        try {
            await Promise.all(
                Array.from(selectedItems.values()).map(item =>
                    updateDocument.mutateAsync({
                        documentId: item.id,
                        request: { ...item, parentFolderId: targetFolderId },
                    })
                )
            );
            setStatus(Status.None);
            setTargetFolderId(currentFolderId);
            onSaveSuccess();
        } catch (e) {
            setStatus(Status.SubmitError);
            throw e;
        }
    };

    const handleCloseWithErrors = () => {
        if (status === Status.SubmitError) {
            onSaveSuccess();
        } else {
            onClose();
        }
    };

    return (
        <Modal
            maxWidth="xl"
            open={open}
            setOpen={handleCloseWithErrors}
            title={`Move ${selectedItems.size} Selected Items`}
        >
            <div className="p-6">
                <div className="flex flex-col">
                    {isLoading && (
                        <div className="h-40">
                            <LoadingIndicator />
                        </div>
                    )}
                    {hasError && (
                        <p className="text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" aria-hidden />
                            {defaultErrorMessage}
                        </p>
                    )}
                    {!isLoading && !hasError && activeFolder && folderPath.data && (
                        <>
                            <ModalBreadcrumbs
                                folderSegments={folderPath.data}
                                onChangeActiveFolder={onChangeActiveFolder}
                            />
                            <hr className="m-0 border-t border-gray-700" />
                            {!subFolders?.length ? (
                                <NoSubfoldersMessage />
                            ) : (
                                <div className="overflow-y-scroll scrollbar-themed" style={{ maxHeight: '50vh' }}>
                                    {subFolders.map(item => {
                                        const isSelected = targetFolderId === item.id;
                                        return (
                                            <div
                                                key={item.id}
                                                className={classNames(
                                                    'hover:bg-gray-700 hover:bg-opacity-100 flex items-center w-full justify-between py-2 px-3',
                                                    { 'bg-gray-700': isSelected },
                                                    { 'odd:bg-gray-900 odd:bg-opacity-50': !isSelected }
                                                )}
                                                onClick={() => setTargetFolderId(item.id)}
                                            >
                                                <div className="flex">
                                                    <FolderIconTableView color={item.color} icon={item.icon} />
                                                    <Button
                                                        color="link"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            onChangeActiveFolder(item.id);
                                                        }}
                                                    >
                                                        {item.name}
                                                        <FontAwesomeIcon
                                                            icon={faCaretRight}
                                                            size="sm"
                                                            className="ml-2"
                                                        />
                                                    </Button>
                                                </div>
                                                <div className="text-right">
                                                    <SelectionIcon isSelected={isSelected} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="space-x-3 flex justify-end">
                    {hasError && (
                        <Button color="primary" onClick={handleCloseWithErrors}>
                            OK
                        </Button>
                    )}
                    {!hasError && (
                        <>
                            <Button className="mr-2" color="secondary" disabled={showProgress} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={onSubmit} disabled={showProgress}>
                                Move
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default MoveItemsModal;
