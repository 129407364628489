import { IBusiness, IBusinessPageType } from '@api';
import { Link } from 'react-router-dom';
import { Button } from '~/components';
import visionLinkLogo from '../VisionLinkLogo.png';

interface ITestNotTakenYetProps {
    business: IBusiness;
    businessPageType: IBusinessPageType;
    setServicesModalOpen: (isOpen: boolean) => void;
}

const TestNotTaKenYet = ({ business, businessPageType, setServicesModalOpen }: ITestNotTakenYetProps): JSX.Element => {
    return (
        <>
            <img className="h-10 sm:h-auto mx-auto" alt="VisionLink Logo" src={visionLinkLogo} />
            <div className="mb-8">
                Design comprehensive incentive plans to inspire employees. Individualized reporting allows for in-depth
                discussions around customized employee incentive plans and benefits.
            </div>
            <Button color="tertiary" className="w-full justify-center" onClick={() => setServicesModalOpen(true)}>
                Learn More
            </Button>
            <div className="mt-6 mb-8">
                We have made a survey test to better serve you with your needs, Please take the test to connect with a
                VisionLink Advisor.
            </div>
            <Link to={`/${business.id}/Present/${businessPageType}/Assessment`}>
                <Button color="primary" className="w-full justify-center">
                    Take Test
                </Button>
            </Link>
        </>
    );
};

export default TestNotTaKenYet;
