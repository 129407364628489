import { Button } from '~/components';
import innerZoneLogo from '../../images/innerzone-logo.png';

interface IProps {
    onStartClick: () => void;
}
const SurveyIntro = ({ onStartClick }: IProps): JSX.Element => (
    <div className="my-auto space-y-6">
        <img alt="InnerZone Logo" className="mx-auto max-w-xs" src={innerZoneLogo} />
        <p>
            InnerZone delivers productivity within work groups. The fixed communication traits relied upon by InnerZone
            are valuable across a wide range of use cases, including HR and team building.
        </p>
        <p>
            Our survey take only 2 to 4 minutes to complete and effectively power a range of productivity enhancing
            applications.
        </p>
        <Button fullWidth color="primary" size="xxl" onClick={onStartClick}>
            {`Let's Start`}
        </Button>
    </div>
);

export default SurveyIntro;
