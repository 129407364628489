import { IBusinessPageType, ITodoCreateRequest, ITodoPriorityType, ITodoSystemActivityType } from '@api';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import {
    useAddBusiness,
    useAddEmployee,
    useAddTodo,
    useAdvisorFirm,
    useBusinessAvatar,
    useCurrentUser,
    useEmployeeAvatar,
    useInviteEmployeeUploadDelegate,
    useUpdateBusiness,
    useUpdateEmployee,
    useUpdateTodo,
} from '~/hooks';
import {
    mapFormDataToApi,
    schemaValidation as businessInfoFormSchemaValidation,
} from '~/pages/business/edit/businessInfo/BusinessInfoForm';
import { getArrayOfEnum } from '~/utils/enumUtils';
import { yupToFormErrors } from '~/utils/yupUtils';
import {
    mapFormDataToApi as mapEmployeeFormToApi,
    schemaValidation as primaryEmployeeSchemaValidation,
} from '../../business/edit/primaryContact/PrimaryEmployeeForm';
import { EmployeeInputMethod, schemaValidation as employeeTaskSchemaValidation } from './EmployeeInputTaskForm';
import { IPageSetting } from './PresentationSettingsForm';
import { WizardState } from './useWizardState';

const getInitialPresentationSettings = (pages: IBusinessPageType[]): IPageSetting[] => {
    const defaultEnabled = pages.map(pageType => ({
        enabled: true,
        pageType,
    }));
    const defaultDisabled = getArrayOfEnum(IBusinessPageType)
        .filter(p => !defaultEnabled.some(e => e.pageType === p))
        .map(pageType => ({
            enabled: false,
            pageType,
        }));

    return defaultEnabled.concat(defaultDisabled);
};

export const useSaveBusiness = (wizardState: WizardState): (() => Promise<void>) => {
    const { businessAvatar, businessFormData, businessId: savedBusinessId, setWizardState } = wizardState;
    const { data: currentUser } = useCurrentUser();
    const advisorFirmId = currentUser?.advisorFirmId || '';
    const advisorFirm = useAdvisorFirm(advisorFirmId);
    const addBusiness = useAddBusiness();
    const updateBusiness = useUpdateBusiness(savedBusinessId || '');
    const updateBusinessAvatar = useBusinessAvatar();

    return async () => {
        let businessId = savedBusinessId;
        if (businessId) {
            await updateBusiness.mutateAsync(mapFormDataToApi(businessFormData));
        } else {
            const { id, pages } = await addBusiness.mutateAsync({
                ...mapFormDataToApi(businessFormData),
                advisorFirmId: currentUser?.advisorFirmId || '',
                pages: advisorFirm?.data?.pages,
                primaryAdvisorId: currentUser?.id || '',
            });
            businessId = id;
            setWizardState({
                businessId,
                pageSettings: getInitialPresentationSettings(pages || []),
            });
        }
        if (businessAvatar?.file) {
            await updateBusinessAvatar.mutateAsync({ avatar: businessAvatar.file, businessId });
        }
    };
};

export const validateBusiness = (wizardState: WizardState): boolean => {
    const { businessFormData, setWizardState } = wizardState;

    try {
        businessInfoFormSchemaValidation.validateSync(businessFormData, { abortEarly: false });
    } catch (err: unknown) {
        if (ValidationError.isError(err)) {
            setWizardState({ validationErrors: yupToFormErrors(err) });
        }
        return false;
    }
    return true;
};

export const useSavePrimaryContact = (wizardState: WizardState): (() => Promise<void>) => {
    const {
        businessFormData,
        businessId,
        primaryEmployeeAvatar,
        primaryEmployeeFormData,
        primaryEmployeeId: savedPrimaryEmployeeId,
        setWizardState,
    } = wizardState;
    const updateBusiness = useUpdateBusiness(businessId || '');
    const addEmployee = useAddEmployee(businessId || '');
    const updateEmployee = useUpdateEmployee(businessId || '');
    const updateEmployeeAvatar = useEmployeeAvatar(businessId || '');

    return async () => {
        let employeeId = savedPrimaryEmployeeId;
        const apiData = mapEmployeeFormToApi(primaryEmployeeFormData);
        if (employeeId) {
            await updateEmployee.mutateAsync({ ...apiData, employeeId });
        } else {
            const { id } = await addEmployee.mutateAsync({
                ...apiData,
                businessId: businessId || '',
            });
            employeeId = id;
            setWizardState({ primaryEmployeeId: employeeId });
            await updateBusiness.mutateAsync({
                ...mapFormDataToApi(businessFormData),
                primaryEmployeeId: employeeId,
            });
        }
        if (primaryEmployeeAvatar?.file) {
            await updateEmployeeAvatar.mutateAsync({
                avatar: primaryEmployeeAvatar.file,
                employeeId,
            });
        }
    };
};

export const validatePrimaryContact = (wizardState: WizardState): boolean => {
    const { primaryEmployeeFormData, setWizardState } = wizardState;

    try {
        primaryEmployeeSchemaValidation.validateSync(primaryEmployeeFormData, { abortEarly: false });
    } catch (err: unknown) {
        if (ValidationError.isError(err)) {
            setWizardState({ validationErrors: yupToFormErrors(err) });
        }
        return false;
    }
    return true;
};

export const validateEmployeeTask = (wizardState: WizardState): boolean => {
    const { employeeInputMethod, setWizardState } = wizardState;

    if (employeeInputMethod === EmployeeInputMethod.Manual) {
        return true;
    }

    try {
        employeeTaskSchemaValidation.validateSync(wizardState, { abortEarly: false });
    } catch (err: unknown) {
        if (ValidationError.isError(err)) {
            setWizardState({ validationErrors: yupToFormErrors(err) });
        }
        return false;
    }
    return true;
};

export const useSaveEmployeeTask = (wizardState: WizardState): (() => Promise<void>) => {
    const {
        businessId,
        delegateEmployeeEmail,
        employeeInputMethod,
        importEmployeeTodoId: savedImportEmployeeTodoId,
        setWizardState,
    } = wizardState;
    const { data: currentUser } = useCurrentUser();
    const inviteEmployeeUploadDelegate = useInviteEmployeeUploadDelegate();
    const addTodo = useAddTodo(businessId || '');
    const updateTodo = useUpdateTodo(businessId || '', savedImportEmployeeTodoId || '');

    return async () => {
        if (employeeInputMethod === EmployeeInputMethod.Delegate) {
            await inviteEmployeeUploadDelegate.mutateAsync({
                businessId: businessId || '',
                emailAddress: delegateEmployeeEmail,
            });
            return;
        }

        // TODO: consider moving this to backend like the delegate path
        const importEmployeeTodoId = savedImportEmployeeTodoId;
        const assigneeId = currentUser?.id || '';
        const apiData: ITodoCreateRequest = {
            assigneeId,
            businessId: businessId || '',
            description: 'Put in your employee information',
            dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
            priority: ITodoPriorityType.High,
            systemActivity: ITodoSystemActivityType.EmployeeUpload,
        };
        if (importEmployeeTodoId) {
            await updateTodo.mutateAsync({
                ...apiData,
                assigneeId,
                completed: false,
            });
        } else {
            const { id } = await addTodo.mutateAsync(apiData);
            setWizardState({ importEmployeeTodoId: id });
        }
    };
};

export const useSavePresentationSettings = (wizardState: WizardState): (() => Promise<void>) => {
    const { businessFormData, pageSettings, primaryEmployeeId, businessId: savedBusinessId } = wizardState;
    const { push } = useHistory();
    const updateBusiness = useUpdateBusiness(savedBusinessId || '');

    return async () => {
        await updateBusiness.mutateAsync({
            ...mapFormDataToApi(businessFormData),
            pages: pageSettings.filter(ps => ps.enabled).map(ps => ps.pageType),
            primaryEmployeeId,
        });
        push(`/${savedBusinessId}/Present`);
    };
};
