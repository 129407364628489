import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingIndicator = (): JSX.Element => (
    <div className="absolute inset-0 flex h-full">
        <div className="flex flex-col items-center m-auto bg-gray-800 border-4 border-gray-900 px-8 py-6 rounded-xl space-y-2">
            <p className="text-primary">
                <FontAwesomeIcon size="3x" icon={faCircleNotch} spin={true} />
            </p>
            <p className="text-white">Loading</p>
        </div>
    </div>
);

export { LoadingIndicator };
