import { IDocument } from '@api';
import classnames from 'classnames';
import moment from 'moment';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { documentCenterStore } from './DocumentCenterContext';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';
import FolderIconTableView from './icons/FolderIconTableView';
import SelectionIcon from './icons/SelectionIcon';
import FolderDropdown from './ItemDropdown';
import { getFolderRoute } from './utils/routes';

interface IProps {
    item: IDocument;
}

const ItemFolderTableView = ({ item }: IProps): JSX.Element => {
    const { selectedItems, toggleSelectItem } = useContext(documentCenterStore);
    const isSelected = selectedItems.has(item.id);

    return (
        <tr
            className={classnames(
                'hover:bg-gray-700 hover:bg-opacity-100',
                { 'bg-gray-700': isSelected },
                { 'odd:bg-gray-800 odd:bg-opacity-50': !isSelected }
            )}
            onClick={() => toggleSelectItem(item)}
        >
            <td className="text-center">
                <SelectionIcon isSelected={isSelected} />
            </td>
            <td className="py-2 px-6">
                <DragAndDropUploadHandler folderId={item.id} className="flex items-center">
                    <Link
                        className="flex"
                        draggable={false}
                        onClick={e => e.stopPropagation()}
                        to={getFolderRoute(item.id)}
                    >
                        <FolderIconTableView color={item.color} icon={item.icon} />
                    </Link>
                    <Link draggable={false} onClick={e => e.stopPropagation()} to={getFolderRoute(item.id)}>
                        {item.name}
                    </Link>
                </DragAndDropUploadHandler>
            </td>
            <td className="align-middle py-2 px-6">
                {item.fileCount} {item.fileCount === 1 ? 'File' : 'Files'}
            </td>
            <td className="align-middle py-2 px-6">{moment(item.hierarchyUpdatedAt).fromNow()}</td>
            <td className="text-right p-2">
                <FolderDropdown item={item} />
            </td>
        </tr>
    );
};

export default ItemFolderTableView;
