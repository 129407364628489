import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBusinessEvaluation } from '~/hooks';
import BusinessEvaluationWizard from './BusinessEvaluationWizard';
import Results from './Results';

interface IProps {
    business: IBusiness;
}

const BusinessEvaluation = ({ business }: IProps): JSX.Element => {
    const { data, error, isError, isLoading } = useBusinessEvaluation(business.id);

    if (isLoading) return <LoadingIndicator />;
    if (isError && error?.response?.status !== 404) return <ErrorPage />;
    if (data) return <Results business={business} data={data} />;
    return <BusinessEvaluationWizard business={business} />;
};

export default BusinessEvaluation;
