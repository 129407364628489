import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RadarChart } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { useBoundingClientRect } from '~/hooks';
import { IDepartment } from './panels/Team';

interface IProps {
    departments: IDepartment[];
    maxRating: number;
    minRating: number;
}

const TeamPanelChart = ({ departments, maxRating, minRating }: IProps): JSX.Element => {
    const [containerRect, containerRef] = useBoundingClientRect();
    const chartSeries = [
        {
            color: 'primary-600',
            gradients: ['rgba(129, 179, 228, 0.5)', 'rgba(14, 110, 203, 0.35)'],
            name: '',
            points: departments.map<IChartPoint>(d => ({
                name: d.name,
                value: d.ratingAverage,
            })),
        },
    ];

    return departments.length > 2 ? (
        <div ref={containerRef} style={{ height: '80vh' }}>
            {containerRect && (
                <RadarChart
                    className="mx-auto"
                    data={chartSeries}
                    dataMax={maxRating}
                    dataMin={0}
                    height={containerRect.height}
                    levels={5}
                    width={containerRect.width}
                />
            )}
        </div>
    ) : (
        <div className="flex flex-col items-center justify-between" style={{ height: '80vh' }}>
            {/* Empty divs used with justify-between to put the icon a bit above center.  */}
            <div />
            <div className="flex flex-col items-center center space-y-8">
                <FontAwesomeIcon className="text-9xl text-gray-600" icon={faExclamationCircle} />
                <p>You need at least three teams to view a radar chart.</p>
            </div>
            <div />
            <div />
        </div>
    );
};

export default TeamPanelChart;
