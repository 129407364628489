import { faBuilding, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Avatar,
    Button,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { useAdvisorFirms } from '~/hooks';
import { getAddress } from '~/utils/advisorFirmUtils';
import { businessSizeDisplay } from '~/utils/enumUtils';
import { ILocationState } from '../ILocationState';
import AddAdvisorFirm from './AddAdvisorFirm';

const AdvisorFirmList = (): JSX.Element | null => {
    const [isAddOpen, setIsAddOpen] = useState(false);
    const { isLoading, error, data: firms } = useAdvisorFirms();
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Advisor Firm List',
    };

    if (error) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    return (
        <div className="p-10">
            <div className="top-0 flex justify-between mb-12 pb-6 bg-gray-900 border-b border-gray-700">
                <h2 className="text-2xl leading-none self-center">Advisor Firm List</h2>
                <div className="self-center space-x-3">
                    <Button color="primary" onClick={() => setIsAddOpen(true)}>
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add New
                    </Button>
                </div>
            </div>
            <Table className="w-full">
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Firm Name</TableHeaderCell>
                        <TableHeaderCell className="hidden sm:table-cell">Location</TableHeaderCell>
                        <TableHeaderCell className="hidden md:table-cell">Size</TableHeaderCell>
                        <TableHeaderCell>
                            <span className="sr-only">Actions</span>
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {firms &&
                        firms.map(firm => (
                            <TableRow key={firm.id}>
                                <TableCell className="flex items-center">
                                    <Avatar
                                        src={firm.avatar || ''}
                                        placeholderIcon={faBuilding}
                                        className="mr-2"
                                        size={8}
                                    />
                                    {firm.name}
                                </TableCell>
                                <TableCell className="hidden sm:table-cell">{getAddress(firm) || '--'}</TableCell>
                                <TableCell className="hidden md:table-cell">
                                    {firm.size ? businessSizeDisplay[firm.size] : '--'}
                                </TableCell>
                                <TableCell className="text-right">
                                    <Link
                                        className="text-primary hover:text-primary-300"
                                        to={{
                                            pathname: `/${firm.id}`,
                                            state: locationState,
                                        }}
                                    >
                                        Edit
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <AddAdvisorFirm isOpen={isAddOpen} onClose={() => setIsAddOpen(false)} />
        </div>
    );
};

export default AdvisorFirmList;
