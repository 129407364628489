import classNames from 'classnames';

interface IProps {
    max: number;
    value: number;
}

const DiscreteProgressBar = ({ max, value }: IProps): JSX.Element => {
    return (
        <div className="flex flex-col space-y-2 items-end">
            <div>
                {value} / {max}
            </div>
            <div
                className="w-full flex h-1 place-content-around rounded-xl overflow-hidden"
                style={{ columnGap: '2px' }}
            >
                {[...Array(max).keys()].map(i => (
                    <div
                        key={i}
                        className={classNames(
                            'flex-grow',
                            { 'bg-primary-500': i < value },
                            { 'bg-gray-900': i >= value }
                        )}
                    />
                ))}
            </div>
        </div>
    );
};

export default DiscreteProgressBar;
