import { IAdvisorFirm } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';
import ProcessInfo from './ProcessInfo';

const TabId = 'BuildingPayGradeStructure';

const BuildingPayGradeStructure = (advisorFirm: IAdvisorFirm | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Building a Pay Grade Structure</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <h2 className="font-bold">Building a Pay Grade Structure</h2>
                <p>
                    To avoid compensation chaos, businesses need an effective structure for managing their compensation
                    offering. That structure should facilitate decision-making about pay levels for new employees and
                    simplify the management of salary changes throughout the company. It also establishes eligibility
                    and opportunties associated with incentive programs and other benefit plans. A good structure will
                    ensure smooth execution of the entire compensation budget.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group when our clients
                    are in need of pay grade services. Visionlink is a national compensation design firm that has
                    provided pay strategy solutions for businesses since 1996. The professionals at VisionLink can help
                    you build a structure that facilitates your team&apos;s ability to smoothly manage your
                    company&apos;s compensation offering.
                </p>
                <ProcessInfo
                    outcome="A fair and systematic way of budgeting and governing Company pay decisions so they are in line with both the Company's pay philosophy as well as current market economic conditions."
                    steps={[
                        'Organize all employee positions with their corresponding market benchmarks (See Validating Market Competitiveness).',
                        'Organize and sort market data to determine relative parity between internal positions.',
                        'Create groupings (Bands,Grades,Tiers) of positions all of which contain similar external market value (Market Pay) and internal equity.',
                        'Using the market data, determine pay minimums, maximums, and midpoints for all groupings (Bands, Grades, Tiers)',
                        'Validate the structure against current employee pay levels.  Adjust as needed',
                        "Correlate all Company Reward's program eligibility, targets, and ranges to the struture.",
                    ]}
                />
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { BuildingPayGradeStructure };
