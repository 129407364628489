import { faFileAlt as faLightFileAlt, faFolderOpen as faLightFolderOpen } from '@fortawesome/pro-light-svg-icons';
import {
    faFileAlt as faSolidFileAlt,
    faFolderOpen as faSolidFolderOpen,
    faPlusCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { Button, DropdownMenu } from '~/components';
import { documentCenterStore } from './DocumentCenterContext';
import { rootSharedFolderId } from './utils/rootFolder';

const AddButton = (): JSX.Element => {
    const { currentFolderId, openAddFilesModal, openAddFolderModal } = useContext(documentCenterStore);
    const canAddToCurrentLocation = currentFolderId !== rootSharedFolderId;
    return (
        <DropdownMenu position="right">
            <DropdownMenu.Trigger
                as={Button}
                color="primary"
                className="ml-3"
                disabled={!canAddToCurrentLocation}
                title={canAddToCurrentLocation ? undefined : 'Cannot add here. Please select a folder first.'}
            >
                <FontAwesomeIcon icon={faPlusCircle} size="lg" aria-hidden className="mr-2" />
                Add New
            </DropdownMenu.Trigger>
            <DropdownMenu.Item onClick={() => openAddFolderModal()}>
                {({ active }) => (
                    <>
                        <FontAwesomeIcon
                            aria-hidden="true"
                            className="mr-2"
                            fixedWidth
                            icon={active ? faSolidFolderOpen : faLightFolderOpen}
                            size="lg"
                        />
                        Folder
                    </>
                )}
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => openAddFilesModal()}>
                {({ active }) => (
                    <>
                        <FontAwesomeIcon
                            aria-hidden="true"
                            className="mr-2"
                            fixedWidth
                            icon={active ? faSolidFileAlt : faLightFileAlt}
                            size="lg"
                        />
                        File
                    </>
                )}
            </DropdownMenu.Item>
        </DropdownMenu>
    );
};

export default AddButton;
