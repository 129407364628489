import { faPaperPlane, faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { Button, IRadioGroupListOption, RadioGroupList, TextInput } from '~/components';

export enum EmployeeInputMethod {
    Delegate = 'Delegate',
    Manual = 'Manual',
}

interface IProps {
    delegateEmployeeEmail: string | undefined;
    employeeInputMethod: EmployeeInputMethod;
    errors: { [key: string]: string };
    onChangeEmail: (email: string) => void;
    onChangeInputMethod: (method: EmployeeInputMethod) => void;
    onClickSend: () => void;
}

const taskOptions: IRadioGroupListOption[] = [
    {
        description: 'Get someone to upload employee data.',
        label: 'Delegate',
        value: EmployeeInputMethod.Delegate,
    },
    {
        description: 'Add Employee data manually.',
        label: 'Manual',
        value: EmployeeInputMethod.Manual,
    },
];

export const schemaValidation = Yup.object().shape({
    delegateEmployeeEmail: Yup.mixed().when('employeeInputMethod', {
        is: EmployeeInputMethod.Delegate,
        then: Yup.string().email().required().label('Email'),
    }),
    employeeInputMethod: Yup.string().label('Input Method'),
});

const EmployeeInputTaskForm = ({
    delegateEmployeeEmail,
    employeeInputMethod,
    errors,
    onChangeEmail,
    onChangeInputMethod,
    onClickSend,
}: IProps): JSX.Element => {
    return (
        <>
            <div className="text-center h-80 xl:h-72">
                {employeeInputMethod === EmployeeInputMethod.Delegate ? (
                    <div className="flex items-center flex-col">
                        <div className="rounded-full p-8 bg-gray-700">
                            <FontAwesomeIcon icon={faPaperPlane} className="text-gray-900" size="4x" />
                        </div>
                        <p className="xl:w-2/3 m-6 text-sm leading-5">
                            Clicking send or next will invite recipient to upload or manually update employee data.
                            Recipient will have 7 days to complete.
                        </p>
                        <div className="flex items-start justify-center">
                            <TextInput
                                error={errors.delegateEmployeeEmail}
                                label="Email"
                                labelClassName="sr-only"
                                onChange={onChangeEmail}
                                placeholder="example@example.com"
                                value={delegateEmployeeEmail || ''}
                            />
                            <Button className="mt-1" onClick={onClickSend}>
                                Send
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center flex-col">
                        <div className="rounded-full p-8 bg-gray-700">
                            <FontAwesomeIcon icon={faPencilAlt} className="text-gray-900" size="4x" />
                        </div>
                        <p className="xl:w-2/3 m-6 text-sm leading-5">
                            You can visit the employee list later to enter data. We will assign a task to you to act as
                            a reminder.
                        </p>
                    </div>
                )}
            </div>
            <RadioGroupList
                ariaLabel="Employee Input Method"
                onChange={method => onChangeInputMethod(method as EmployeeInputMethod)}
                options={taskOptions}
                value={employeeInputMethod}
            />
        </>
    );
};

export default EmployeeInputTaskForm;
