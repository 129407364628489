import { IBusinessTeamMember, IBusinessTeamMemberType } from '@api';
import { DeleteConfirmationModal } from '~/components';
import { useDeleteAdvisorTeamMember, useDeleteEmployeeTeamMember, useDeleteExternalTeamMember } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    businessId: string;
    isOpen: boolean;
    teamMember?: IBusinessTeamMember;
    onClose: () => void;
    onConfirm: () => void;
}

const TeamMemberDeleteModal = ({ businessId, isOpen, teamMember, onClose, onConfirm }: IProps): JSX.Element => {
    const deleteAdvisorTeamMember = useDeleteAdvisorTeamMember(businessId);
    const deleteEmployeeTeamMember = useDeleteEmployeeTeamMember(businessId);
    const deleteExternalTeamMember = useDeleteExternalTeamMember(businessId);

    return (
        <DeleteConfirmationModal
            title="Confirm Delete Team Member"
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={async () => {
                switch (teamMember?.type) {
                    case IBusinessTeamMemberType.Advisor:
                        await deleteAdvisorTeamMember.mutateAsync(teamMember?.id ?? '');
                        break;
                    case IBusinessTeamMemberType.Employee:
                        await deleteEmployeeTeamMember.mutateAsync(teamMember?.id ?? '');
                        break;
                    case IBusinessTeamMemberType.External:
                        await deleteExternalTeamMember.mutateAsync(teamMember?.id ?? '');
                        break;
                    default:
                        break;
                }
                onConfirm();
            }}
        >
            {teamMember?.type === IBusinessTeamMemberType.External
                ? `Are you sure you want to delete ${NameFormatter.getName(teamMember)}?
                The data for this team member will be permanently removed. This action cannot be undone.`
                : `Are you sure you want to remove ${NameFormatter.getName(teamMember)} from the team?`}
        </DeleteConfirmationModal>
    );
};

export default TeamMemberDeleteModal;
