import { IDocument, IUserEntitlementType } from '@api';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext } from 'react';
import { Button, Can, DropdownMenu } from '~/components';
import { documentCenterStore } from './DocumentCenterContext';

interface IProps {
    item: IDocument;
}

const ItemDropdown = ({ item }: IProps): JSX.Element => {
    const { openDeleteModal, openMoveModal, openRenameModal, openShareModal, selectedItems } =
        useContext(documentCenterStore);
    const isSelected = selectedItems.has(item.id);
    const invisible = selectedItems.size > 1 || (selectedItems.size === 1 && !isSelected);

    return (
        <DropdownMenu position="right">
            <DropdownMenu.Trigger
                as={Button}
                className={classNames('px-3 py-1 mr-1', { invisible })}
                color="link"
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                title={item.name}
            >
                <FontAwesomeIcon icon={faEllipsisV} size="lg" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Item onClick={() => openDeleteModal(item)}>Delete</DropdownMenu.Item>
            <Can hasEntitlement={IUserEntitlementType.DocumentUpdate}>
                <DropdownMenu.Item onClick={() => openMoveModal(item)}>Move</DropdownMenu.Item>
                <DropdownMenu.Item onClick={() => openRenameModal(item)}>Rename</DropdownMenu.Item>
            </Can>
            <Can hasEntitlement={IUserEntitlementType.DocumentShare} condition={item.isFolder}>
                <DropdownMenu.Item onClick={() => openShareModal(item)}>Share</DropdownMenu.Item>
            </Can>
        </DropdownMenu>
    );
};

export default ItemDropdown;
