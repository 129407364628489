import numbro from 'numbro';

const isSpecified = (value: unknown) => typeof value !== 'undefined' && value !== null;

export default class NumberFormatter {
    static formatNumber = (value: number | string | undefined, defaultValue = ''): string => {
        if (!isSpecified(value) || (typeof value === 'string' && value.trim() === '') || isNaN(value as number)) {
            return defaultValue;
        }

        const numValue = Number(value);
        if (isNaN(numValue)) {
            return defaultValue;
        }

        const numberFormat = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        // Some browsers will format negative values with '-' sign, others with parentheses.
        // Until the Intl.NumberFormat behavior is unified, we must manually check.
        // https://github.com/tc39/proposal-unified-intl-numberformat
        const formattedNumber = numberFormat.format(numValue);
        if (numValue >= 0) {
            return formattedNumber;
        } else if (formattedNumber.indexOf('(') > -1) {
            return formattedNumber.replace(/\((.+)\)/, '-$1');
        } else {
            return formattedNumber;
        }
    };

    static unformatNumber = (value: string): number | null => {
        if (!isSpecified(value) || value.trim() === '') {
            return null;
        }

        if (value.trim() === '.') {
            return 0;
        }

        const numValue = numbro.unformat(value);
        return numValue;
    };
}
