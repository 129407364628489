import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { IconButton } from './IconButton';

type width = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';

export interface IModalProps {
    children: ReactNode;
    hideHeader?: boolean;
    hideOverflow?: boolean;
    maxWidth: width;
    open: boolean;
    setOpen: (open: boolean) => void;
    title?: string;
}

const getMaxWidth = (width: width) => {
    return {
        sm: 'sm:max-w-sm',
        md: 'sm:max-w-md',
        lg: 'sm:max-w-lg',
        xl: 'sm:max-w-xl',
        '2xl': 'sm:max-w-2xl',
        '3xl': 'sm:max-w-3xl',
        '4xl': 'sm:max-w-4xl',
        '5xl': 'sm:max-w-5xl',
        '6xl': 'sm:max-w-6xl',
        '7xl': 'sm:max-w-7xl',
    }[width];
};

const Modal = ({
    children,
    hideHeader = false,
    hideOverflow = false,
    maxWidth,
    open,
    setOpen,
    title,
}: IModalProps): JSX.Element => {
    return (
        <Transition.Root show={open} as={Fragment} appear>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0"
                        enterTo="opacity-100 translate-y-0"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0"
                    >
                        <div
                            className={classNames(
                                'bg-gray-900 inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full',
                                getMaxWidth(maxWidth),
                                { 'overflow-hidden': hideOverflow }
                            )}
                        >
                            {!hideHeader && (
                                <div className="flex items-center justify-between px-6 py-4 bg-gray-800 rounded-t-lg">
                                    <div className="text-xl font-bold">{title}</div>
                                    <div>
                                        <IconButton onClick={() => setOpen(false)}>
                                            <span className="sr-only">Close</span>
                                            <FontAwesomeIcon icon={faTimes} size="lg" aria-hidden="true" />
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export { Modal };
