import * as d3Axis from 'd3-axis';
import { ScaleLinear } from 'd3-scale';
import { select as d3Select } from 'd3-selection';
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import { IXAxisSettings } from './chartModels';

interface IProps extends ComponentPropsWithoutRef<'g'> {
    plotHeight: number;
    scaleLinear: ScaleLinear<number, number, never>;
    settings?: IXAxisSettings;
}

const BarChartXAxis = ({ plotHeight, scaleLinear, settings, ...rest }: IProps): JSX.Element => {
    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const axisSelection = d3Select(ref.current);
        const axis = d3Axis.axisBottom(scaleLinear).ticks(5).tickSize(-plotHeight);

        axisSelection.call(axis);
        axisSelection.select('.domain').remove();
        if (settings?.hideLabels) {
            axisSelection.selectAll('.tick text').remove();
        } else {
            axisSelection.selectAll('.tick text').attr('font-size', '0.875rem').attr('y', '10');
        }
        axisSelection.selectAll('.tick line').attr('class', 'stroke-gray-700');
    }, [plotHeight, ref, scaleLinear, settings]);

    return <g ref={ref} {...rest} />;
};

export default BarChartXAxis;
