import { IDocument } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { SyntheticEvent, useContext } from 'react';
import { useDocumentDownload } from '~/hooks';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { documentCenterStore } from './DocumentCenterContext';
import { getIconFromExtension } from './icons/iconMap';
import SelectionIcon from './icons/SelectionIcon';
import ItemDropdown from './ItemDropdown';
import { getColorFromIcon } from './utils/colorMap';

interface IProps {
    item: IDocument;
}

const ItemFileGridView = ({ item }: IProps): JSX.Element => {
    const icon = getIconFromExtension(item.name);
    const color = getColorFromIcon(icon.iconName);
    const getDocumentDownload = useDocumentDownload();
    const { openErrorModal, selectedItems, toggleSelectItem } = useContext(documentCenterStore);
    const isSelected = selectedItems.has(item.id);

    const handleDownload = async (e: SyntheticEvent) => {
        try {
            e.stopPropagation();
            const documentDownload = await getDocumentDownload(item.id);
            saveAs(documentDownload.url, item.name);
        } catch (err: unknown) {
            openErrorModal(`Failed to download ${item.name}. ${defaultErrorMessage}`);
        }
    };

    return (
        <div
            className={classnames(
                'h-60 w-60 hover:bg-gray-700 hover:bg-opacity-100 bg-opacity-50 rounded-lg mb-4 mr-4 flex flex-col justify-between items-center',
                isSelected ? 'bg-gray-700' : 'bg-gray-800'
            )}
            onClick={() => toggleSelectItem(item)}
        >
            <div className="w-full pl-3 mt-3 flex flex-row justify-between">
                <SelectionIcon isSelected={isSelected} />
                <ItemDropdown item={item} />
            </div>

            <div className="text-center w-full">
                <div className="w-24 mx-auto cursor-pointer" onClick={handleDownload}>
                    <FontAwesomeIcon icon={icon} size="4x" className={color} />
                </div>
                <div className="mt-3 px-3 truncate" title={item.name}>
                    {item.name}
                </div>
            </div>

            <div className={`text-sm w-full px-3 mb-3 flex flex-row justify-end`}>
                <div>{moment(item.hierarchyUpdatedAt).fromNow()}</div>
            </div>
        </div>
    );
};

export default ItemFileGridView;
