import { IBonusRightEmployeeValueStatementYear, IBusiness } from '@api';
import { useEffect, useState } from 'react';
import {
    LoadingIndicator,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import StackedAreaChart from '~/components/charts/StackedAreaChart';
import { isTrueForAnyQuery, useBoundingClientRect, useEmployees } from '~/hooks';
import { useBonusRightEmployeeValueStatement } from '~/hooks/bonusRightEmployeeRewards';
import CurrencyFormatter from '~/utils/currencyFormatter';
import BonusRightErrorPage from '../../BonusRightErrorPage';
import CurrencyTableCell from '../../CurrencyTableCell';

interface IProps {
    bonusRightEmployeeId: number;
    business: IBusiness;
    onClose: () => void;
}

const EmployeeValueStatementModal = ({ bonusRightEmployeeId, business, onClose }: IProps): JSX.Element | null => {
    const employees = useEmployees(business.id);
    const evs = useBonusRightEmployeeValueStatement(business, bonusRightEmployeeId, employees.data ?? []);
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    const [chartWidth, setChartWidth] = useState<number>(0);
    useEffect(() => {
        setChartWidth(chartContainerRect?.width || 0);
    }, [chartContainerRect]);
    const isError = isTrueForAnyQuery('isError', employees, evs);
    const isLoading = isTrueForAnyQuery('isLoading', employees, evs);
    if (isError) return <BonusRightErrorPage business={business} reportName="Employee Value Statement" />;
    if (isLoading) return <LoadingIndicator />;
    if (!evs.data || !evs.data.years) {
        return null;
    }
    const { years } = evs.data;

    return (
        <Modal
            maxWidth="7xl"
            open={bonusRightEmployeeId !== undefined}
            setOpen={onClose}
            title="Employee Value Statement"
        >
            <div className="flex flex-col items-center p-6 space-y-4">
                <div className="flex max-w-6xl space-x-5 w-full">
                    {renderTopLevelItem('Salary', years.map(y => y.salary).reduce(reducer))}
                    {renderTopLevelItem('Short-Term Incentives', years.map(y => y.stipPayment).reduce(reducer))}
                    {renderTopLevelItem('Long-Term Incentives', years.map(y => y.ltipRedemption).reduce(reducer))}
                </div>
                <div className="max-w-6xl w-full" ref={chartContainerRef}>
                    <StackedAreaChart
                        data={years.map(y => ({
                            year: parseInt(y.year ?? '0', 10),
                            salary: number(y.salary),
                            stip: number(y.stipPayment),
                            ltip: number(y.ltipRedemption),
                        }))}
                        height={260}
                        width={chartWidth}
                    />
                </div>
                <div className="max-w-6xl w-full">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell />
                                {years?.map(y => (
                                    <TableHeaderCell key={y.year}>{y.year}</TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderRow('Salary', years, y => y.salary)}
                            {renderRow('STIP Payment', years, y => y.stipPayment)}
                            {renderRow('LTIP Redemption', years, y => y.ltipRedemption)}
                            {renderRow('Vested Value', years, y => y.vestedValue)}
                            {renderRow('Unvested Value', years, y => y.unvestedValue)}
                            {renderRow(
                                'Total Plan Value',
                                years,
                                y =>
                                    number(y.salary) +
                                    number(y.stipPayment) +
                                    number(y.ltipRedemption) +
                                    number(y.vestedValue) +
                                    number(y.unvestedValue),
                                'bg-gray-800'
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Modal>
    );
};

const number = (n: number | undefined) => n ?? 0;

const reducer = (a: number | undefined, i: number | undefined) => number(a) + number(i);

const renderTopLevelItem = (label: string, value: number | undefined) => (
    <div className="bg-gray-800 flex-grow px-4 py-5 rounded-lg">
        <h3>Total {label}</h3>
        <p className="font-semibold text-3xl">{CurrencyFormatter.format(value)}</p>
    </div>
);

const renderRow = (
    label: string,
    years: IBonusRightEmployeeValueStatementYear[],
    accessor: (year: IBonusRightEmployeeValueStatementYear) => number | undefined,
    background = 'bg-gray-900'
) => (
    <TableRow>
        <TableCell background={background}>{label}</TableCell>
        {years?.map(y => (
            <CurrencyTableCell background={background} key={y.year} value={accessor(y)} />
        ))}
    </TableRow>
);

export default EmployeeValueStatementModal;
