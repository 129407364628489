import { IBusiness, IBusinessValuation } from '@api';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { Avatar } from '~/components';
import BusinessValuationNumber from '~/components/BusinessValuationNumber';
import { getHeadquarters } from '~/utils/businessUtils';
import { businessSizeDisplay, industryDisplay } from '~/utils/enumUtils';
import BusinessInfoGridItem from './BusinessInfoGridItem';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const BusinessInfo = ({ business, businessValuation }: IProps): JSX.Element => {
    return (
        <div className="mx-auto max-w-4xl p-6">
            <div className="text-center text-3xl md:text-5xl font-extrabold pb-3">{business.name}</div>
            <div className="text-center text-xl md:text-3xl font-extrabold">
                <BusinessValuationNumber businessValuation={businessValuation} />
            </div>
            <div className="text-center text-lg md:text-xl mb-3">Fair Market Value</div>
            <div className="text-center">
                <Avatar placeholderIcon={faBuilding} size={48} src={business.avatar || ''} />
            </div>
            <div className="my-8 border-t border-gray-600">
                <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-16">
                    <div>
                        <h4 className="text-xl font-bold">About</h4>
                        {business.description}
                    </div>
                    <div>
                        <div className="grid grid-cols-3 gap-1">
                            <BusinessInfoGridItem label="Website" value={business.website || '--'} />
                            <BusinessInfoGridItem
                                label="Industry"
                                value={(business.industry && industryDisplay[business.industry]) || '--'}
                            />
                            <BusinessInfoGridItem
                                label="Business Size"
                                value={(business.size && businessSizeDisplay[business.size]) || '--'}
                            />
                            <BusinessInfoGridItem label="Headquarters" value={getHeadquarters(business) || '--'} />
                            <BusinessInfoGridItem label="Founded" value={business.yearFounded?.toString() || '--'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessInfo;
