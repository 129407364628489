import { RadarChart } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { useBoundingClientRect } from '~/hooks';
import { IBusinessValuationMetric, IBusinessValuationUtils } from '../overview/Overview';
import DriverScoreCard from './MetricScoreCard';

interface IProps {
    metrics: IBusinessValuationMetric[];
    title: string;
    utils: IBusinessValuationUtils;
}

const Metrics = ({ metrics, title, utils }: IProps): JSX.Element => {
    const [containerRect, containerRef] = useBoundingClientRect();
    const chartSeries = [
        {
            color: 'primary-600',
            gradients: ['rgba(129, 179, 228, 0.5)', 'rgba(14, 110, 203, 0.35)'],
            name: '',
            points: metrics.map<IChartPoint>(d => ({
                name: utils.textMap[d.type] ?? d.type,
                value: d.score,
            })),
        },
    ];
    const total = metrics.reduce((total, metric) => {
        total += metric.score;
        return total;
    }, 0);

    return (
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4 h-full" ref={containerRef}>
            <div>
                <h4 className="flex justify-between text-2xl leading-8 font-medium mb-10">
                    <div>{title}</div>
                    <div className="font-bold">Total: {total}</div>
                </h4>
                <div className="grid grid-cols-2 auto-rows-min gap-4">
                    {metrics.map(m => (
                        <DriverScoreCard key={m.type} metric={m} utils={utils} />
                    ))}
                </div>
            </div>
            <div className="mt-4 2xl:mt-0">
                {containerRect && (
                    <RadarChart
                        className="mx-auto"
                        data={chartSeries}
                        dataMax={10}
                        dataMin={0}
                        height={containerRect.height}
                        levels={5}
                        width={containerRect.width}
                    />
                )}
            </div>
        </div>
    );
};

export default Metrics;
