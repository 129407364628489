import { faExclamationTriangle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { ComponentPropsWithoutRef, Fragment, useRef } from 'react';
import { Button } from './Button';
import { IconButton } from './IconButton';

interface IProps {
    confirmButtonText?: string;
    isOpen: boolean;
    isSaving?: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const DeleteConfirmationModal = ({
    children,
    confirmButtonText = 'Delete',
    isOpen,
    isSaving = false,
    onClose,
    onConfirm,
    title,
}: IProps & ComponentPropsWithoutRef<'dialog'>): JSX.Element => {
    const closeIconButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto scrollbar-themed"
                initialFocus={closeIconButtonRef}
                onClose={onClose}
                open={true}
                static
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <div className="h-7 flex items-center">
                                    <IconButton onClick={onClose} ref={closeIconButtonRef}>
                                        <span className="sr-only">Close</span>
                                        <FontAwesomeIcon icon={faTimes} size="lg" aria-hidden="true" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-danger sm:mx-0 sm:h-10 sm:w-10">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-white">
                                        {title}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {typeof children === 'string' ? (
                                            <p className="text-sm text-gray-400">{children}</p>
                                        ) : (
                                            children
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <Button
                                    color="danger"
                                    className="w-full inline-flex justify-center sm:ml-3 sm:w-auto sm:text-sm"
                                    disabled={isSaving}
                                    onClick={onConfirm}
                                >
                                    {isSaving ? (
                                        <FontAwesomeIcon icon={faCircleNotch} spin size="lg" />
                                    ) : (
                                        confirmButtonText
                                    )}
                                </Button>
                                <Button
                                    color="tertiary"
                                    className="mt-3 w-full inline-flex justify-center sm:mt-0 sm:w-auto"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export { DeleteConfirmationModal };
