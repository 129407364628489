import { IBusiness } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CurrencyFormatter from '~/utils/currencyFormatter';
import ValueGapCard from './GapCard';
import MetricCard from './MetricCard';
import MetricScoresCard from './MetricScoresCard';
import PotentialValueCard from './PotentialValueCard';

interface IProps {
    business: IBusiness;
    businessDetail: IBusinessValuationDetail;
    utils: IBusinessValuationUtils;
}

export interface IBusinessValuationUtils {
    isOperationsDriver: (metric: IBusinessValuationMetric) => boolean;
    isSalesDriver: (metric: IBusinessValuationMetric) => boolean;
    colorMap: Record<string, string>;
    textMap: Record<string, string>;
    iconMap: Record<string, IconProp>;
}

export interface IBusinessValuationDetail {
    gap: number;
    revenue: number;
    profit: number;
    metrics: IBusinessValuationMetric[];
    potentialValue: number;
    value: number;
}

export interface IBusinessValuationMetric {
    gap: number;
    score: number;
    type: string;
}

const Overview = ({ business, businessDetail, utils }: IProps): JSX.Element => {
    return (
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
            <div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
                    <MetricCard title="Annual Revenue">{CurrencyFormatter.format(businessDetail.revenue)}</MetricCard>
                    <MetricCard title="Annual Profit">{CurrencyFormatter.format(businessDetail.profit)}</MetricCard>
                </div>
                <PotentialValueCard businessDetail={businessDetail} />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <MetricScoresCard
                        metrics={businessDetail.metrics.filter(utils.isSalesDriver)}
                        title="Market Driver"
                        utils={utils}
                    />
                    <MetricScoresCard
                        metrics={businessDetail.metrics.filter(utils.isOperationsDriver)}
                        title="Operational Driver"
                        utils={utils}
                    />
                </div>
            </div>
            <ValueGapCard business={business} businessDetail={businessDetail} />
        </div>
    );
};

export default Overview;
