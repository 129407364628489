import { BreakdownChart } from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { IBusinessValuationDetail } from './Overview';

interface IProps {
    businessDetail: IBusinessValuationDetail;
}

const PotentialValueCard = ({ businessDetail }: IProps): JSX.Element => {
    return (
        <div className="flex flex-col bg-gray-800 rounded-lg px-4 py-5 mb-4">
            <div className="mb-1 text-sm leading-5 font-medium">Potential Value</div>
            <div className="text-2xl 3xl:text-3xl leading-9 font-semibold mb-6">
                {CurrencyFormatter.format(businessDetail.potentialValue)}
            </div>
            <BreakdownChart
                point1={{ color: 'green-400', name: 'Current Estimate', value: businessDetail.value }}
                point2={{ color: 'cyan-500', name: 'Value Gap', value: businessDetail.gap }}
                valueFormatter={CurrencyFormatter.format}
            />
        </div>
    );
};

export default PotentialValueCard;
