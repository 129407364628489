import { faCheck, faPlus, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { components, OptionProps, OptionTypeBase } from 'react-select';
import { Avatar } from './Avatar';
import { ISelectOption, Select } from './Select';

export interface IEntitySelectOption extends ISelectOption {
    avatar?: string;
    description?: string;
    disabled?: boolean;
    name: string;
    showAddButton?: boolean;
    showLoading?: boolean;
}

const atRestBg = 'bg-gray-800';
const focusedBg = 'bg-gray-700';
const hoverBg = 'hover:bg-gray-700';
const EntitySelectOptionComponent = (props: React.PropsWithChildren<OptionProps<IEntitySelectOption, false>>) => {
    if ((props.data as ISelectOption).__isNew__) {
        return (
            <components.Option
                {...props}
                getStyles={() => ({})}
                className={classNames(
                    hoverBg,
                    props.isFocused ? focusedBg : atRestBg,
                    'border-t border-gray-700 flex select-none cursor-pointer items-center p-3 first:border-t-0'
                )}
            >
                <Avatar />
                <div className="text-base ml-2">Add New</div>
            </components.Option>
        );
    }
    const entitySelectOption = props.data as IEntitySelectOption;

    return (
        <components.Option
            {...props}
            getStyles={() => ({})}
            className={classNames(
                { hoverBg: !entitySelectOption.disabled },
                props.isFocused ? focusedBg : atRestBg,
                'flex items-center p-3 group select-none',
                { 'cursor-pointer': !entitySelectOption.disabled }
            )}
        >
            <Avatar src={entitySelectOption.avatar || undefined} />
            <div className="truncate flex-1 ml-2">
                <div className="truncate text-base">{entitySelectOption.name}</div>
                <div className="text-gray-400 truncate text-sm">{entitySelectOption.description}</div>
            </div>
            {entitySelectOption.showAddButton && (
                <div
                    className={classNames(
                        props.isFocused ? 'bg-primary-500' : 'bg-primary-600',
                        'flex flex-shrink-0 justify-center items-center rounded-full h-8 w-8 group-hover:bg-primary-500'
                    )}
                >
                    <FontAwesomeIcon icon={faPlus} aria-hidden />
                </div>
            )}
            {entitySelectOption.showLoading && (
                <div className="flex flex-shrink-0 justify-center items-center rounded-full h-8 w-8">
                    <FontAwesomeIcon icon={faSpinner} size="lg" spin aria-hidden />
                </div>
            )}
            {!entitySelectOption.showAddButton && !entitySelectOption.showLoading && props.isSelected && (
                <FontAwesomeIcon icon={faCheck} aria-hidden className="text-primary-600" />
            )}
        </components.Option>
    );
};

interface IProps {
    error?: string;
    isCreatable?: boolean;
    isDisabled?: boolean;
    label?: string;
    menuIsOpen?: boolean;
    options: IEntitySelectOption[];
    placeholder?: string;
    value?: string;
    onEntityOptionSelected?: (option: IEntitySelectOption) => void;
    onNewSelected?: () => void;
}

const EntitySelect = ({
    error,
    isCreatable = false,
    isDisabled = false,
    label,
    menuIsOpen,
    options,
    placeholder,
    value,
    onEntityOptionSelected,
    onNewSelected,
    ...rest
}: IProps): JSX.Element => {
    const onChange = (option: OptionTypeBase | null | undefined) => {
        if ((option as ISelectOption).__isNew__) {
            return onNewSelected && onNewSelected();
        }

        if (onEntityOptionSelected) {
            const entityOption = option as IEntitySelectOption;

            if (entityOption && !entityOption.disabled) {
                return onEntityOptionSelected(entityOption);
            }
        }
    };

    return (
        <Select
            error={error}
            isCreatable={isCreatable}
            isDisabled={isDisabled}
            label={label}
            menuIsOpen={menuIsOpen}
            onChange={onChange}
            optionComponent={EntitySelectOptionComponent}
            options={options}
            placeholder={placeholder}
            showCreateWhenEmpty
            showSearchIcon
            value={value}
            {...rest}
        />
    );
};

export { EntitySelect };
