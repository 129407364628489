import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export interface IRadioGroupListOption {
    description: string;
    label: string;
    value: string;
}

interface IProps<T> {
    ariaLabel: string;
    onChange: (value: string) => void;
    options: T[];
    value: string;
}

const RadioGroupList = <T extends IRadioGroupListOption>({
    ariaLabel,
    onChange,
    options,
    value,
}: IProps<T>): JSX.Element => (
    <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="sr-only">{ariaLabel}</RadioGroup.Label>
        <div className="rounded-md -space-y-px">
            {options.map((option, index) => (
                <RadioGroup.Option
                    key={option.value}
                    value={option.value}
                    className={({ checked }) =>
                        classNames(
                            index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                            index === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                            checked ? 'bg-gray-700 z-10' : 'bg-gray-800 hover:bg-gray-700',
                            'relative border border-gray-700 p-4 flex cursor-pointer focus:outline-none focus:border-primary-500'
                        )
                    }
                >
                    {({ active, checked }) => (
                        <>
                            <span
                                className={classNames(
                                    checked ? 'bg-primary-600 border-transparent' : 'border-gray-600 bg-gray-900',
                                    active ? 'ring-2 ring-primary-500 ring-offset-2 ring-offset-gray-800' : '',
                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex flex-shrink-0 items-center justify-center'
                                )}
                                aria-hidden="true"
                            >
                                <span
                                    className={classNames(
                                        checked ? 'bg-white' : 'bg-transparent',
                                        'rounded-full w-1.5 h-1.5'
                                    )}
                                />
                            </span>
                            <div className="ml-3 flex flex-col">
                                <RadioGroup.Label as="span" className="block text-sm font-medium text-white">
                                    {option.label}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                        checked ? 'text-white' : 'text-gray-400',
                                        'block text-sm text-white leading-5'
                                    )}
                                >
                                    {option.description}
                                </RadioGroup.Description>
                            </div>
                        </>
                    )}
                </RadioGroup.Option>
            ))}
        </div>
    </RadioGroup>
);

export { RadioGroupList };
