import { IBusiness, IBusinessTeamMember, IBusinessTeamMemberType } from '@api';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { Avatar, Button } from '~/components';
import { useContactVisionLink } from '~/hooks/visionLinkContact';
import PostAssessmentContactSearch from './PostAssessmentContactSearch';

interface IProps {
    answers: Record<string, number>;
    business: IBusiness;
    businessTeam: IBusinessTeamMember[];
    unsetSubmitRequestAgain: () => void;
}

const ContactVisionLinkForm = ({ answers, business, businessTeam, unsetSubmitRequestAgain }: IProps): JSX.Element => {
    const [selectedTeamMembers, setSelectedTeamMembers] = useState<IBusinessTeamMember[]>([]);
    const contactVisionLink = useContactVisionLink(business.id).mutateAsync;

    return (
        <div className="space-y-2 sm:space-y-4">
            <h2 className="text-xl font-medium">Improve your Compensation Plan</h2>
            <p className="text-sm">Please let us know who you&apos;d like VisionLink to contact to get started.</p>
            <PostAssessmentContactSearch
                onChange={v => {
                    const selectedTeamMember = businessTeam.find(m => m.id === v?.value);
                    if (selectedTeamMember) setSelectedTeamMembers([...selectedTeamMembers, selectedTeamMember]);
                }}
                potentialMembers={businessTeam}
                teamMembers={selectedTeamMembers}
            />
            <div
                className={classNames('flex flex-col h-40 py-4 text-gray-500', {
                    'items-center justify-center': selectedTeamMembers.length === 0,
                    'space-y-4': selectedTeamMembers.length > 0,
                })}
            >
                {selectedTeamMembers.length === 0 ? (
                    <p>Please select at least one contact above.</p>
                ) : (
                    selectedTeamMembers.map(m => (
                        <div key={m.id} className="flex justify-center space-x-4">
                            <div className="flex items-center">
                                <Avatar src={m.avatar || ''} />
                            </div>
                            <div className="flex-grow">
                                <div className="font-medium text-white">
                                    {m.firstName} {m.lastName}
                                </div>
                                <div className="text-gray-300">{m.emailAddress}</div>
                            </div>
                            <div className="flex items-center">
                                <Button
                                    color="link"
                                    className="text-lg"
                                    onClick={() => {
                                        setSelectedTeamMembers(selectedTeamMembers.filter(s => s.id !== m.id));
                                    }}
                                >
                                    <FontAwesomeIcon className="text-danger-500" icon={faTrash} />
                                </Button>
                            </div>
                        </div>
                    ))
                )}
            </div>
            <Button
                color="primary"
                disabled={selectedTeamMembers.length === 0}
                onClick={() =>
                    contactVisionLink({
                        businessId: business.id,
                        advisorsToContact: selectedTeamMembers
                            .filter(s => s.type === IBusinessTeamMemberType.Advisor)
                            .map(s => s.id),
                        employeesToContact: selectedTeamMembers
                            .filter(s => s.type === IBusinessTeamMemberType.Employee)
                            .map(s => s.id),
                        answerMap: answers,
                    }).then(() => unsetSubmitRequestAgain())
                }
            >
                Connect With Expert
            </Button>
        </div>
    );
};

export default ContactVisionLinkForm;
