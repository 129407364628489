import { faCircle, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '~/components';

interface IProps {
    onShowResultsClick: () => void;
}

const SurveyComplete = ({ onShowResultsClick }: IProps): JSX.Element => (
    <div className="flex flex-col h-full my-auto space-y-4" style={{ maxHeight: 600 }}>
        <div className="bg-gray-700 h-3">
            <div className="bg-primary-500 h-full w-full" />
        </div>
        <div className="flex flex-grow">
            <div className="w-full text-center my-auto">
                <span className="fa-layers fa-fw fa-10x mb-5">
                    <FontAwesomeIcon className="text-gray-800" icon={faCircle} />
                    <FontAwesomeIcon className="text-primary-500" icon={faThumbsUp} transform="shrink-8" />
                </span>
                <p>Thank you for completing the survey!</p>
                <p>Click below to show your results</p>
            </div>
        </div>
        <Button fullWidth color="primary" size="xxl" onClick={onShowResultsClick}>
            Show Results
        </Button>
    </div>
);

export default SurveyComplete;
