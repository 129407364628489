import { IBusiness, IBusinessPageType } from '@api';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Avatar, Button } from '~/components';
import { ILocationState } from '~/pages/ILocationState';
import { businessPageDisplay, businessPageIcon, businessPageIconColor } from '~/utils/enumUtils';

interface IProps {
    className: string;
    business: IBusiness;
}

const getNavLink = (pageType: IBusinessPageType, businessId: string) => (
    <NavLink
        activeClassName="bg-sidebar-link-active text-white"
        className="group flex items-center px-2 py-2 rounded-md"
        key={`navlink-${pageType}`}
        to={`/${businessId}/Present/${pageType}${getTrailingPath(pageType)}`}
    >
        <FontAwesomeIcon
            fixedWidth
            className={`mr-4 text-${businessPageIconColor[pageType]}`}
            icon={businessPageIcon[pageType]}
        />
        {businessPageDisplay[pageType]}
    </NavLink>
);

const getTrailingPath = (businessPageType: IBusinessPageType): string => {
    switch (businessPageType) {
        case IBusinessPageType.Observations:
            return '/1';
        case IBusinessPageType.Purpose:
            return '/1';
        case IBusinessPageType.TeamValues:
            return '/Values';
        default:
            return '';
    }
};

const BusinessPageSideBar = ({ className, business }: IProps): JSX.Element => {
    const navLinks = business.pages?.map(p => getNavLink(p, business.id));
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
    };

    return (
        <div className={`${className || ''} flex flex-col pt-5 pb-4 font-medium bg-sidebar`}>
            <div className="flex justify-center flex-shrink-0 px-4">
                <Avatar placeholderIcon={faBuilding} rounded="sm" size={20} src={business.avatar || undefined} />
            </div>
            <div className="text-center mt-3 text-xl font-bold">{business.name}</div>
            <nav className="mt-5 flex-1 px-2 space-y-1 text-gray-300">{navLinks}</nav>
            <div className="text-center mt-3">
                <Link
                    to={{
                        pathname: `/${business.id}/Edit`,
                        state: locationState,
                    }}
                >
                    <Button color="primaryLink">Settings</Button>
                </Link>
            </div>
        </div>
    );
};

export default BusinessPageSideBar;
