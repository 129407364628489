import { IInnerZoneEmployeeUser } from '@api';
import classnames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';
import { Avatar } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    employeeUser: IInnerZoneEmployeeUser;
}

const EmployeeLabel = ({ className, employeeUser, ...rest }: IProps & ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const name = NameFormatter.getName(employeeUser);

    return (
        <div className={classnames('rounded bg-gray-800 overflow-hidden', className)} {...rest}>
            <div className="flex">
                <Avatar className="mr-1" rounded="none" size={16} src={employeeUser.avatar || undefined} />
                <div className="flex-1 p-2 truncate">
                    <div className="text-xl leading-7 font-semibold truncate">{name}</div>
                    <p className="text-gray-400 text-sm truncate">{employeeUser.title}</p>
                </div>
            </div>
        </div>
    );
};

export default EmployeeLabel;
