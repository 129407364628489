import { IUserEntitlementType } from '@api';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Can, EmptyStateIcon, ErrorModal, ErrorPage, LoadingIndicator } from '~/components';
import { useBusinessEducation } from '~/hooks';
import { IBusinessEducationLocationState } from '~/pages/advisorSettings/education';
import { EducationItem } from '~/pages/advisorSettings/education/EducationItem';
import { sort } from '~/utils/educationUtils';
import { BusinessParams } from '../..';

const Education = (): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const { data: educationList, error, isLoading } = useBusinessEducation(businessId);
    const { push } = useHistory();
    const [errorState, setErrorState] = useState<{ open: boolean; message?: string }>({
        open: false,
    });
    const locationState: IBusinessEducationLocationState = { backUrl: useLocation().pathname };

    if (error) return <ErrorPage />;
    if (isLoading || !educationList) return <LoadingIndicator />;

    return (
        <div className="p-10">
            <div className="flex justify-between content-center items-center mb-5">
                <h2 className="text-xl">Education</h2>
                <Can hasEntitlement={IUserEntitlementType.EducationUpdate}>
                    <Button onClick={() => push(`/settings/Education`, locationState)} className="mr-3">
                        <FontAwesomeIcon icon={faEdit} className="mr-2" />
                        Edit
                    </Button>
                </Can>
            </div>
            {educationList.length ? (
                <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-5">
                    {sort(educationList).map(education => (
                        <EducationItem
                            {...education}
                            key={education.id}
                            onError={message => setErrorState({ open: true, message })}
                        />
                    ))}
                </div>
            ) : (
                <div className="p-3 p-md-5 flex flex-col items-center text-center">
                    <EmptyStateIcon icon={faFileAlt} />
                    <p className="my-4 text-lg">No education resources added yet.</p>
                    <Can hasEntitlement={IUserEntitlementType.EducationCreate}>
                        <Button onClick={() => push(`/settings/Education`, locationState)} color="primary">
                            Add Education Resource
                        </Button>
                    </Can>
                </div>
            )}
            <ErrorModal {...errorState} setIsOpen={() => setErrorState({ ...errorState, open: false })} />
        </div>
    );
};

export default Education;
