import { BusinessOwnersApi, IBusinessOwnerCreateRequest, IProblemDetails, IUser } from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const businessOwnersApi = new BusinessOwnersApi(undefined, config.api.baseUrl);

export const getBusinessOwnersQueryKey = (businessId: string, ...rest: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'businessOwners', ...(rest || []));

export const useBusinessOwners = (businessId: string): UseQueryResult<IUser[], IProblemDetails> =>
    useQuery(getBusinessOwnersQueryKey(businessId), () =>
        businessOwnersApi.getBusinessOwners(businessId).then(response => response.data)
    );

export const useAddBusinessOwner = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBusinessOwnerCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBusinessOwnerCreateRequest>(
        request => businessOwnersApi.createBusinessOwner(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBusinessOwnersQueryKey(businessId));
            },
        }
    );

    return mutation;
};

export const useDeleteBusinessOwner = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        userId => businessOwnersApi.deleteBusinessOwner(userId).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBusinessOwnersQueryKey(businessId));
            },
        }
    );

    return mutation;
};

export const useResetPasswordBusinessOwner = (): UseMutationResult<void, IProblemDetails, string> =>
    useMutation<void, IProblemDetails, string>(userId =>
        businessOwnersApi.resetPassword(userId).then(response => response.data)
    );
