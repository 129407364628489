import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import * as d3Selection from 'd3-selection';
import { useEffect, useRef } from 'react';

interface IProps {
    width: number;
    yAxis: d3Scale.ScaleLinear<number, number, never>;
}

const StackedAreaChartYAxis = ({ width, yAxis }: IProps): JSX.Element => {
    const yAxisRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!yAxisRef.current || width === 0) return;
        const axisSelection = d3Selection.select(yAxisRef.current).attr('class', 'yAxis');
        axisSelection.call(
            d3Axis
                .axisLeft(yAxis)
                .tickSizeOuter(0)
                .ticks(3)
                .tickFormat(d => d.valueOf() / 1000 + 'K')
        );

        d3Selection.selectAll('.yAxis .tick line').style('stroke-width', 0);
        d3Selection.selectAll('.yAxis .tick text').attr('font-size', '1rem');
        d3Selection.selectAll('.yAxis .domain').style('stroke-width', 0);
        d3Selection.select('.yAxis .tick').remove(); // Remove 0
    }, [width, yAxis, yAxisRef]);

    return <g ref={yAxisRef} />;
};

export default StackedAreaChartYAxis;
