const StackedAreaChartGradients = (): JSX.Element => (
    <defs>
        <linearGradient id="Gradient0" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stop-color="#34D399" />
            <stop offset="100%" stop-color="#059669" />
        </linearGradient>
        <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stop-color="#FBBF24" />
            <stop offset="100%" stop-color="#D97706" />
        </linearGradient>
        <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stop-color="#60A5FA" />
            <stop offset="100%" stop-color="#2563EB" />
        </linearGradient>
    </defs>
);

export default StackedAreaChartGradients;
