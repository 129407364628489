import { IBusiness } from '@api';
import { useState } from 'react';
import { LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { useBonusRightExecutiveBenefitAnalysis } from '~/hooks/bonusRightEmployeeRewards';
import BonusRightErrorPage from '../BonusRightErrorPage';
import PoweredBy from '../PoweredBy';
import GroupLifeTable from './GroupLifeTable';
import LongTermDisabilityTable from './LongTermDisabilityTable';
import TableSelector from './TableSelector';

interface IProps {
    business: IBusiness;
}

export enum Tab {
    LossOfLife,
    IllnessOrInjury,
}

const ExecutiveBenefits = ({ business }: IProps): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.LossOfLife);
    const employees = useEmployees(business.id);
    const eba = useBonusRightExecutiveBenefitAnalysis(business, employees.data ?? []);
    const isError = isTrueForAnyQuery('isError', employees, eba);
    const isLoading = isTrueForAnyQuery('isLoading', employees, eba);
    if (isError) return <BonusRightErrorPage business={business} reportName="Executive Benefit Analysis" />;
    if (isLoading || !eba.data) return <LoadingIndicator />;

    return (
        <div className="flex flex-col p-10 space-y-4">
            <div className="flex justify-between">
                <div className="space-y-8">
                    <h3 className="text-lg leading-6 font-medium mr-2">Executive Programs</h3>
                    <TableSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </div>
                <PoweredBy business={business} showServicesOnClick={true} />
            </div>
            {selectedTab === Tab.LossOfLife && (
                <div>
                    <GroupLifeTable employees={eba.data.employees} />
                </div>
            )}
            {selectedTab === Tab.IllnessOrInjury && (
                <div>
                    <LongTermDisabilityTable employees={eba.data.employees} />
                </div>
            )}
        </div>
    );
};

export default ExecutiveBenefits;
