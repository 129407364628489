import { IInnerZoneSurvey, IInnerZoneSurveyAnswer } from '@api';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { NavBar } from '~/components';
import { useInnerZoneSurveySetResults, useInvalidateInnerZoneSurvey } from '~/hooks';
import CurrentUserProfile from './CurrentUserProfile';
import SurveyComplete from './SurveyComplete';
import SurveyIntro from './SurveyIntro';
import SurveyQuestion from './SurveyQuestion';

interface IProps {
    survey: IInnerZoneSurvey;
}

const InnerZoneSurvey = ({ survey }: IProps): JSX.Element => {
    const [answers, setAnswers] = useState<IInnerZoneSurveyAnswer[]>([]);
    const [isComplete, setIsComplete] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [questionIndex, setQuestionIndex] = useState(-1);
    const surveySetResults = useInnerZoneSurveySetResults();
    const invalidateInnerZoneSurvey = useInvalidateInnerZoneSurvey();
    const { questions } = survey;
    const currentQuestion = questions[questionIndex];

    if (showResults) return <CurrentUserProfile />;

    const showIntro = questionIndex === -1;
    const onAnswerOptionClick = (answerOption: string) => {
        const updatedAnswers = [
            ...answers.slice(0, questionIndex),
            { answer: answerOption, questionId: questions[questionIndex].id },
            ...answers.slice(questionIndex + 1),
        ];

        setAnswers(updatedAnswers);
        if (questionIndex === questions.length - 1) {
            setIsComplete(true);
            surveySetResults.mutateAsync({ answers: updatedAnswers });
        } else {
            setQuestionIndex(questionIndex + 1);
        }
    };

    return (
        <>
            <NavBar>
                {!showIntro && (
                    <>
                        <button
                            className={classNames('absolute inset-y-0 left-0 w-16', {
                                invisible: questionIndex === 0,
                            })}
                            onClick={() => {
                                if (isComplete) {
                                    setIsComplete(false);
                                } else {
                                    setQuestionIndex(questionIndex - 1);
                                }
                            }}
                        >
                            <FontAwesomeIcon className="text-2xl" icon={faChevronLeft} />
                        </button>
                        <div className="flex items-center justify-center w-full">
                            <div className="font-bold">
                                {questionIndex + 1}/{questions.length}
                            </div>
                        </div>
                    </>
                )}
            </NavBar>
            <div className="h-full max-w-2xl mx-auto w-full">
                <div className="p-5 pt-6 flex flex-col justify-between h-full">
                    {isComplete ? (
                        <SurveyComplete
                            onShowResultsClick={() => {
                                invalidateInnerZoneSurvey.invalidate();
                                setShowResults(true);
                            }}
                        />
                    ) : showIntro ? (
                        <SurveyIntro onStartClick={() => setQuestionIndex(0)} />
                    ) : (
                        <SurveyQuestion
                            progress={(questionIndex / questions.length) * 100}
                            question={currentQuestion}
                            onAnswerOptionClick={onAnswerOptionClick}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default InnerZoneSurvey;
