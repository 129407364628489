import { IEmployee } from '@api';
import {
    Avatar,
    IModalProps,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { getAgeAsString } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';
import { formatRating } from './employeeRatingUtils';

interface IProps extends Pick<IModalProps, 'open'> {
    employees: IEmployee[];
    onClose: () => void;
    title: string;
}

const DepartmentModal = ({ employees, onClose, open, title }: IProps): JSX.Element => {
    return (
        <Modal maxWidth="4xl" open={open} setOpen={onClose} title={title}>
            <div className="p-6 overflow-y-auto scrollbar-themed" style={{ maxHeight: '60vh' }}>
                <Table className="w-full ">
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Title</TableHeaderCell>
                            <TableHeaderCell className="text-right">Salary</TableHeaderCell>
                            <TableHeaderCell className="text-right">Rating</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.map(employee => (
                            <TableRow key={employee.id}>
                                <TableCell>
                                    <Avatar size={8} className="mr-4" src={employee.avatar || ''} />
                                    <span>
                                        {NameFormatter.getLastNameFirst(employee)}&nbsp;
                                        {employee.dateOfBirth && `(${getAgeAsString(employee.dateOfBirth)})`}
                                    </span>
                                </TableCell>
                                <TableCell>{employee.title}</TableCell>
                                <TableCell className="text-right">
                                    {CurrencyFormatter.format(employee.salary)}
                                </TableCell>
                                <TableCell className="text-right">{formatRating(employee.rating)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Modal>
    );
};

export default DepartmentModal;
