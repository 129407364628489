import { ReactNodeArray, useState } from 'react';
import { History, Navigation, Pagination, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Carousel.scss';
import { CarouselNavButton } from './CarouselNavButton';

interface IProps {
    children: ReactNodeArray;
    historyKey: string;
    slideNavs?: string[];
}

SwiperCore.use([Navigation, Pagination, History]);

const paginationSettings = (slideNavs?: string[]) => {
    if (slideNavs) {
        return {
            clickable: true,
            el: '.swiper-pager',
            bulletActiveClass: 'text-primary border-primary hover:text-primary-300 hover:border-primary-300',
            renderBullet: (index: number, className: string) => {
                return `<div class="${className} text-gray-200 border-transparent hover:text-gray-300 hover:border-gray-300 group inline-flex items-center p-4 border-t-2 font-medium text-sm cursor-pointer">${slideNavs[index]}</div>`;
            },
        };
    }
    return false;
};

const Carousel = ({ children, historyKey, slideNavs }: IProps): JSX.Element => {
    const [slideIndex, setSlideIndex] = useState(0);

    return (
        <>
            <div className="h-full overflow-y-auto p-10 scrollbar-themed">
                <Swiper
                    autoHeight={true}
                    className="flex flex-col min-h-full"
                    defaultValue={slideIndex}
                    history={{
                        key: historyKey,
                    }}
                    navigation={{
                        nextEl: '.swiper-next-btn',
                        prevEl: '.swiper-previous-btn',
                    }}
                    pagination={paginationSettings(slideNavs)}
                    onSlideChange={swiper => setSlideIndex(swiper.activeIndex)}
                    spaceBetween={250}
                >
                    {children.map((c, i) => (
                        <SwiperSlide data-history={`${i + 1}`} key={i}>
                            {c}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {slideNavs && (
                <div className="flex justify-between bottom-0 left-0 right-0 bg-gray-900 z-10">
                    <CarouselNavButton className="swiper-previous-btn" invisible={slideIndex === 0} type="previous" />
                    <div className="flex-1 border-t border-gray-700" />
                    <div className="border-t border-gray-700">
                        <nav className="swiper-pager flex" style={{ marginTop: '-2px' }}></nav>
                    </div>
                    <div className="flex-1 border-t border-gray-700" />
                    <CarouselNavButton
                        className="swiper-next-btn float-right"
                        invisible={slideIndex === children.length - 1}
                        type="next"
                    />
                </div>
            )}
        </>
    );
};

export { Carousel };
