import { IBusiness, IBusinessPageType } from '@api';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBonusRightAssessmentResponse } from '~/hooks/bonusRightOnboardingAssessment';
import { useVisionLinkContactRecord } from '~/hooks/visionLinkContact';
import ConnectToBonusRightModal from '../teaser/ConnectToBonusRightModal';
import ServicesModal from '../teaser/panels/ServicesModal';
import Assessment from './assessment/Assessment';
import background from './background.png';
import TestNotTakenYet from './TestNotTakenYet';
import TestTakenHasContacted from './TestTakenHasContacted';
import TestTakenNoContact from './TestTakenNoContact';

type IVisiblePage = 'Error' | 'Loading' | 'TestNotTakenYet' | 'TestTakenHasContacted' | 'TestTakenNoContact';

interface IProps {
    business: IBusiness;
    businessPageType: IBusinessPageType;
}

const UnconnectedPage = ({ business, businessPageType }: IProps): JSX.Element => {
    const {
        data: assessmentResponseData,
        isError: assessmentResponseIsError,
        isLoading: assessmentResponseIsLoading,
    } = useBonusRightAssessmentResponse(business.id);

    const {
        data: contactReccordData,
        isError: contactRecordIsError,
        isLoading: contactRecordIsLoading,
    } = useVisionLinkContactRecord(business.id);

    const [servicesModalOpen, setServicesModalOpen] = useState<boolean>(false);
    const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false);
    const [visiblePage, setVisiblePage] = useState<IVisiblePage>('Loading');
    const [submitContactRequestAgain, setSubmitContactRequestAgain] = useState<boolean>(false);

    useEffect(() => {
        if (assessmentResponseIsError || contactRecordIsError) {
            setVisiblePage('Error');
            return;
        }
        if (assessmentResponseIsLoading || contactRecordIsLoading) {
            setVisiblePage('Loading');
            return;
        }
        if (Object.keys(assessmentResponseData?.answerMap ?? {}).length === 0) {
            setVisiblePage('TestNotTakenYet');
            return;
        }
        if (contactReccordData?.recordExists && !submitContactRequestAgain) {
            setVisiblePage('TestTakenHasContacted');
            return;
        }
        setVisiblePage('TestTakenNoContact');
    }, [
        assessmentResponseData?.answerMap,
        assessmentResponseIsError,
        assessmentResponseIsLoading,
        contactReccordData?.recordExists,
        contactRecordIsError,
        contactRecordIsLoading,
        submitContactRequestAgain,
    ]);

    return (
        <>
            <Switch>
                <Route path={`/:businessId/Present/${businessPageType}/Assessment`}>
                    <Assessment business={business} redirectLink={`/${business.id}/Present/${businessPageType}`} />
                </Route>
                <Route path={`/:businessId/Present/${businessPageType}`}>
                    <div className="grid xl:grid-cols-2 h-full">
                        <div className="flex flex-col items-center justify-between py-4">
                            <div className="max-w-md my-auto">
                                {
                                    {
                                        Error: <ErrorPage />,
                                        Loading: <LoadingIndicator />,
                                        TestNotTakenYet: (
                                            <TestNotTakenYet
                                                business={business}
                                                businessPageType={businessPageType}
                                                setServicesModalOpen={setServicesModalOpen}
                                            />
                                        ),
                                        TestTakenHasContacted: (
                                            <TestTakenHasContacted
                                                business={business}
                                                setSubmitRequestAgain={() => {
                                                    setSubmitContactRequestAgain(true);
                                                    setVisiblePage('Loading');
                                                }}
                                            />
                                        ),
                                        TestTakenNoContact: (
                                            <TestTakenNoContact
                                                business={business}
                                                unsetSubmitRequestAgain={() => {
                                                    setSubmitContactRequestAgain(false);
                                                    setVisiblePage('Loading');
                                                }}
                                            />
                                        ),
                                    }[visiblePage]
                                }
                            </div>
                            <div>
                                <p className="text-center text-sm">
                                    If you already have a VisionLink account, please&nbsp;
                                    <div
                                        className="inline text-primary hover:text-primary-500 cursor-pointer"
                                        onClick={() => setConnectModalOpen(true)}
                                    >
                                        click&nbsp;here
                                    </div>
                                    .
                                </p>
                            </div>
                        </div>
                        <div
                            className="bg-center bg-cover bg-no-repeat hidden xl:block"
                            style={{ backgroundImage: `url(${background})` }}
                        />
                    </div>
                </Route>
            </Switch>
            <ServicesModal
                business={business}
                isConnected={false}
                open={servicesModalOpen}
                setOpen={setServicesModalOpen}
            />
            <ConnectToBonusRightModal businessId={business.id} open={connectModalOpen} setOpen={setConnectModalOpen} />
        </>
    );
};

export default UnconnectedPage;
