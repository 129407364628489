import { IBusiness, IBusinessPageType } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBonusRightConnectionStatus } from '~/hooks/bonusRightConnection';
import EmployeeRewards from './employeeRewards';
import UnconnectedPage from './unconnected';

interface IProps {
    business: IBusiness;
}

const EmployeeRewardsPage = ({ business }: IProps): JSX.Element => {
    const { data, error, isLoading } = useBonusRightConnectionStatus(business);

    if (isLoading) return <LoadingIndicator />;
    if (error) return <ErrorPage />;

    return data?.isConnected ? (
        <EmployeeRewards business={business} />
    ) : (
        <UnconnectedPage business={business} businessPageType={IBusinessPageType.BonusRight} />
    );
};

export default EmployeeRewardsPage;
