import { IBusiness } from '@api';
import { ErrorPage } from '~/components';
import PoweredBy from './PoweredBy';

interface IProps {
    business: IBusiness;
    reportName: string;
}

const BonusRightErrorPage = ({ business, reportName }: IProps): JSX.Element => (
    <div className="flex flex-col h-full justify-between items-center p-10">
        <PoweredBy business={business} className="self-end" showServicesOnClick={true} />
        <ErrorPage title={`Error loading report! Please ensure you're able to view the ${reportName} in BonusRight.`} />
    </div>
);

export default BonusRightErrorPage;
