import { IEducationCreateRequest, useAddEducation } from '~/hooks';
import EducationForm from './EducationForm';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const AddEducationForm = (props: IProps): JSX.Element => {
    const { onClose } = props;
    const addEducation = useAddEducation();
    const onSave = async (request: IEducationCreateRequest) => {
        await addEducation.mutateAsync(request);
        onClose();
    };

    return <EducationForm onSave={onSave} title="Add Education Resource" {...props} />;
};

export default AddEducationForm;
