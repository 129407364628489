import { IBusinessEvaluationMetricType } from '@api';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faBullseyeArrow,
    faDollarSign,
    faLightbulbOn,
    faLockAlt,
    faPeopleArrows,
    faPiggyBank,
    faSeedling,
    faStar,
    faTag,
    faUsers,
    faUsersCog,
} from '@fortawesome/pro-light-svg-icons';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { IBusinessValuationMetric } from '~/components/evaluations/overview/Overview';

const salesDrivers: IBusinessEvaluationMetricType[] = [
    IBusinessEvaluationMetricType.BarriersToEntry,
    IBusinessEvaluationMetricType.Brand,
    IBusinessEvaluationMetricType.CustomerDiversification,
    IBusinessEvaluationMetricType.Growth,
    IBusinessEvaluationMetricType.RecurringRevenue,
    IBusinessEvaluationMetricType.Sales,
];
const operationsDrivers: IBusinessEvaluationMetricType[] = [
    IBusinessEvaluationMetricType.Alignment,
    IBusinessEvaluationMetricType.Finances,
    IBusinessEvaluationMetricType.Innovation,
    IBusinessEvaluationMetricType.Management,
    IBusinessEvaluationMetricType.Operations,
    IBusinessEvaluationMetricType.CustomerSatisfaction,
];

export const isSalesDriver = (driver: IBusinessValuationMetric): boolean =>
    (salesDrivers as string[]).indexOf(driver.type) > -1;

export const isOperationsDriver = (driver: IBusinessValuationMetric): boolean =>
    (operationsDrivers as string[]).indexOf(driver.type) > -1;

const typedMetricTypeColor: Record<IBusinessEvaluationMetricType, string> = {
    Alignment: 'primary-500',
    BarriersToEntry: 'teal-500',
    Brand: 'pink-500',
    CustomerDiversification: 'orange-500',
    Finances: 'purple-500',
    Growth: 'primary-500',
    Innovation: 'orange-500',
    Management: 'cyan-500',
    Sales: 'rose-500',
    Operations: 'green-500',
    RecurringRevenue: 'green-500',
    CustomerSatisfaction: 'teal-500',
};

export const metricTypeColor = typedMetricTypeColor as Record<string, string>;

const typedMetricTypeDisplay: Record<IBusinessEvaluationMetricType, string> = {
    Alignment: 'Alignment',
    BarriersToEntry: 'Barriers to Entry',
    Brand: 'Brand',
    CustomerDiversification: 'Customer Diversification',
    CustomerSatisfaction: 'Customer Satisfaction',
    Finances: 'Financial',
    Growth: 'Growth',
    Innovation: 'Innovation',
    Management: 'Senior Management',
    Operations: 'Operations',
    RecurringRevenue: 'Recurring Revenue',
    Sales: 'Sales & Makreting',
};

export const metricTypeDisplay = typedMetricTypeDisplay as Record<string, string>;

const typedMetricTypeIcon: Record<IBusinessEvaluationMetricType, IconProp> = {
    Alignment: faBuilding,
    BarriersToEntry: faLockAlt,
    Brand: faTag,
    CustomerDiversification: faPeopleArrows,
    Finances: faDollarSign,
    Growth: faSeedling,
    Innovation: faLightbulbOn,
    Management: faUsers,
    Sales: faBullseyeArrow,
    Operations: faUsersCog,
    RecurringRevenue: faPiggyBank,
    CustomerSatisfaction: faStar,
};

export const metricTypeIcon = typedMetricTypeIcon as Record<string, IconProp>;

const utils = {
    isOperationsDriver,
    isSalesDriver,
    colorMap: metricTypeColor,
    textMap: metricTypeDisplay,
    iconMap: metricTypeIcon,
};

export default utils;
