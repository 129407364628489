import { IMe } from '@api';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import BusinessSwitch from './business';

interface IProps {
    currentUser: IMe;
}

const BusinessOwnerLayout = ({ currentUser }: IProps): JSX.Element => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/:businessId">
                    <BusinessSwitch />
                </Route>
                <Redirect to={`/${currentUser.businessId}`} />
            </Switch>
        </BrowserRouter>
    );
};

export default BusinessOwnerLayout;
