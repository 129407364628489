import { Avatar } from '~/components';
import { useCurrentUser, useInnerZoneCurrentUserEmployeeProfile } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import EmployeeProfile from '../business/present/innerZone/EmployeeProfile';

const CurrentUserProfile = (): JSX.Element | null => {
    const { data: currentUser } = useCurrentUser();
    const { data: profile } = useInnerZoneCurrentUserEmployeeProfile();

    if (!profile || !currentUser) return null;
    return (
        <div className="flex h-full overflow-y-auto scrollbar-themed">
            <div className="max-w-3xl mx-auto p-6">
                <div className="flex mb-6">
                    {currentUser.avatar && <Avatar className="mr-3" rounded="sm" size={16} src={currentUser.avatar} />}
                    <div className="text-2xl leading-8 my-auto">{NameFormatter.getName(currentUser)}</div>
                </div>
                <EmployeeProfile profile={profile} />
            </div>
        </div>
    );
};

export default CurrentUserProfile;
