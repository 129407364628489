import {
    EmployeesApi,
    IAvatar,
    IEmployee,
    IEmployeeCreateRequest,
    IEmployeeFamily,
    IEmployeeUpdateRequest,
    IEmployeeUploadDelegateInvitationRequest,
    IProblemDetails,
} from '@api';
import { saveAs } from 'file-saver';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const employeesApi = new EmployeesApi(undefined, config.api.baseUrl);

type key = string | number;
const getEmployeesQueryKey = (businessId: string, ...rest: key[]): QueryKey =>
    getBusinessQueryKey(businessId, 'employees', ...(rest || []));

export const getEmployeeQueryKey = (businessId: string, employeeId: string, ...rest: key[]): QueryKey =>
    getEmployeesQueryKey(businessId, employeeId, ...(rest || []));

export const useAddEmployee = (
    businessId: string
): UseMutationResult<IEmployee, IProblemDetails, IEmployeeCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IEmployee, IProblemDetails, IEmployeeCreateRequest>(
        request => employeesApi.postEmployee(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDependencies(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteEmployee = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        employeeId => employeesApi.deleteEmployee(employeeId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDependencies(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useEmployees = (businessId: string, enabled = true): UseQueryResult<IEmployee[], IProblemDetails> =>
    useQuery(
        getEmployeesQueryKey(businessId),
        () => employeesApi.getEmployeesForBusiness(businessId).then(response => response.data),
        { enabled: !!businessId && enabled }
    );

export const useEmployeeFamilies = (businessId: string): UseQueryResult<IEmployeeFamily[], IProblemDetails> =>
    useQuery(
        getEmployeesQueryKey(businessId, 'families'),
        () => employeesApi.getEmployeeFamiliesForBusiness(businessId).then(response => response.data),
        { enabled: !!businessId }
    );

export const useExportEmployeesForBusiness = (): ((businessId: string) => Promise<void>) => {
    const queryClient = useQueryClient();
    return (businessId: string) =>
        queryClient
            .fetchQuery(
                getBusinessQueryKey(businessId, 'employeesExport'),
                () => employeesApi.exportEmployeesForBusiness(businessId),
                {
                    staleTime: 0,
                }
            )
            .then(response => saveAs(new Blob([response.data], { type: 'text/csv' }), 'employees.csv'));
};

interface IEmployeeUpdateParams extends IEmployeeUpdateRequest {
    employeeId: string;
}

export const useUpdateEmployee = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IEmployeeUpdateParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IEmployeeUpdateParams>(
        request => employeesApi.putEmployee(request.employeeId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDependencies(queryClient, businessId);
            },
        }
    );

    return mutation;
};

interface IEmployeeAvatarParams {
    avatar: File;
    employeeId: string;
}

export const useEmployeeAvatar = (
    businessId: string
): UseMutationResult<IAvatar, IProblemDetails, IEmployeeAvatarParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAvatar, IProblemDetails, IEmployeeAvatarParams>(
        ({ avatar, employeeId }: IEmployeeAvatarParams) =>
            employeesApi.setEmployeeAvatar(employeeId, avatar).then(response => response.data),
        {
            onSuccess: () => {
                // we cancelInFlightQueries to fix a timing bug with updating the employee and the avatar at the same time
                invalidateDependencies(queryClient, businessId, true);
            },
        }
    );

    return mutation;
};

export const useInviteEmployeeUploadDelegate = (): UseMutationResult<
    void,
    IProblemDetails,
    IEmployeeUploadDelegateInvitationRequest
> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IEmployeeUploadDelegateInvitationRequest>(
        request =>
            employeesApi.createInvitationForEmployeeUploadDelegate(undefined, request).then(response => response.data),
        {
            onSuccess: (_, { businessId }) => {
                invalidateDependencies(queryClient, businessId);
            },
        }
    );

    return mutation;
};

function invalidateDependencies(queryClient: QueryClient, businessId: string, cancelInFlightQueries?: boolean) {
    const queryKey = getBusinessQueryKey(businessId);
    if (cancelInFlightQueries) {
        queryClient.cancelQueries(queryKey);
    }
    queryClient.invalidateQueries(queryKey);
}
