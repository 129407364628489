import { ReactNode } from 'react';

interface IProps {
    children?: ReactNode;
}

const NavBar = ({ children }: IProps): JSX.Element => (
    <div className="flex flex-row justify-between relative z-10 flex-shrink-0 h-16 bg-navbar border-navbar-700 border-b">
        {children}
    </div>
);

export { NavBar };
