import {
    DocumentPermissionsApi,
    IDocumentEmployeeInvitationRequest,
    IDocumentExternalTeamMemberInvitationRequest,
    IDocumentPermission,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';
import { getDocumentsQueryKey } from './documents';

const documentPermissionsApi = new DocumentPermissionsApi(undefined, config.api.baseUrl);
export const getDocumentPermissionsQueryKey = (businessId: string, documentId?: string): QueryKey => {
    const keys = ['permissions'];
    if (documentId) {
        keys.push(documentId);
    }
    return getDocumentsQueryKey(businessId, ...keys);
};

export const useDeleteDocumentPermission = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        permissionId =>
            documentPermissionsApi.deleteDocumentPermission(permissionId, undefined).then(response => response.data),
        {
            onSuccess: () => invalidateDocumentPermissions(queryClient, businessId),
        }
    );

    return mutation;
};

export const useShareDocumentWithEmployee = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IDocumentEmployeeInvitationRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IDocumentEmployeeInvitationRequest>(
        request =>
            documentPermissionsApi
                .createDocumentInvitationForEmployee(undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
                invalidateDocumentPermissions(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useShareDocumentWithExternalTeamMember = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IDocumentExternalTeamMemberInvitationRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IDocumentExternalTeamMemberInvitationRequest>(
        request =>
            documentPermissionsApi
                .createDocumentInvitationForExternalTeamMember(undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
                invalidateDocumentPermissions(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDocumentPermissions = (
    businessId: string,
    documentId: string
): UseQueryResult<IDocumentPermission[], IProblemDetails> =>
    useQuery(getDocumentPermissionsQueryKey(businessId, documentId), () =>
        documentPermissionsApi.getDocumentPermissionsForDocument(documentId).then(response => response.data)
    );

function invalidateBusiness(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getBusinessQueryKey(businessId));
}

function invalidateDocumentPermissions(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getDocumentPermissionsQueryKey(businessId));
}
