import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useInnerZoneBusinessDetail } from '~/hooks';
import LoginPage from './LoginPage';
import TeamCommunication from './TeamCommunication';

interface IProps {
    business: IBusiness;
}

const InnerZone = ({ business }: IProps): JSX.Element | null => {
    const { data: businessDetail, error } = useInnerZoneBusinessDetail(business.id);
    const isUnauthorized = error?.response?.status === 401;

    if (isUnauthorized) return <LoginPage />;
    if (error) return <ErrorPage />;
    if (businessDetail) return <TeamCommunication business={business} businessDetail={businessDetail} />;

    return <LoadingIndicator />;
};

export default InnerZone;
