import { IBusinessPageType } from '@api';
import { useState } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useBusiness, useBusinessValuation } from '~/hooks';
import SignalR from '~/sockets/SignalR';
import { BusinessParams } from '..';
import BusinessPageHeader from '../header/BusinessPageHeader';
import BusinessForecast from './bonusRight/BusinessForecastPage';
import EmployeeRewards from './bonusRight/EmployeeRewardsPage';
import ExecutivePrograms from './bonusRight/ExecutiveProgramsPage';
import BusinessEvaluation from './businessEvaluation';
import BusinessPageSideBar from './BusinessPageSideBar';
import Community from './community/Community';
import CorporateStrategies from './corporateStrategies';
import DocumentCenter from './documentCenter';
import Education from './education';
import InnerZone from './innerZone';
import Observations from './observations';
import Purpose from './purpose';
import SuccessionPlan from './successionPlan';
import TeamValues from './teamValues';

const BusinessPresent = (): JSX.Element => {
    const [showNav, setShowNav] = useState(false);
    const { businessId } = useParams<BusinessParams>();
    const businessQuery = useBusiness(businessId);
    const businessValuationQuery = useBusinessValuation(businessId);
    const isError = isTrueForAnyQuery('isError', businessQuery, businessValuationQuery);
    const isLoading = isTrueForAnyQuery('isLoading', businessQuery, businessValuationQuery);
    if (isLoading) return <LoadingIndicator />;
    if (isError || !businessQuery.data || !businessValuationQuery.data) return <ErrorPage />;

    const { data: business } = businessQuery;
    const { data: businessValuation } = businessValuationQuery;

    return (
        <SignalR businessId={businessId}>
            <div className={`lg:hidden ${showNav ? '' : 'hidden'}`} onClick={() => setShowNav(false)}>
                <div className="fixed inset-0 flex z-40">
                    <div className="fixed inset-0" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-600 opacity-75" />
                    </div>
                    <BusinessPageSideBar className="relative flex-1 max-w-s w-full" business={business} />
                </div>
            </div>
            <div className="hidden lg:flex lg:flex-shrink-0 z-10">
                <div className="flex flex-col w-64">
                    <BusinessPageSideBar
                        className="flex-grow overflow-y-auto scrollbar-themed border-r border-gray-700"
                        business={business}
                    />
                </div>
            </div>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <BusinessPageHeader business={business} onOpenNav={() => setShowNav(true)} />
                <main
                    className="flex flex-col justify-between flex-1 h-full max-h-full overflow-y-auto scrollbar-themed focus:outline-none"
                    tabIndex={0}
                >
                    <Switch>
                        <Route path={`/:businessId/Present/${IBusinessPageType.BonusRight}`}>
                            <EmployeeRewards business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.BusinessEvaluation}`}>
                            <BusinessEvaluation business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.BusinessForecast}`}>
                            <BusinessForecast business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.Community}`}>
                            <Community business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.ExecutivePrograms}`}>
                            <ExecutivePrograms business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.CorporateStrategies}`}>
                            <CorporateStrategies business={business} businessValuation={businessValuation} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.DocumentCenter}`}>
                            <DocumentCenter />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.Education}`}>
                            <Education />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.InnerZone}`}>
                            <InnerZone business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.Observations}/:slideId?`}>
                            <Observations business={business} businessValuation={businessValuation} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.Purpose}/:slideId?`}>
                            <Purpose business={business} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.SuccessionPlan}`}>
                            <SuccessionPlan business={business} businessValuation={businessValuation} />
                        </Route>
                        <Route path={`/:businessId/Present/${IBusinessPageType.TeamValues}`}>
                            <TeamValues business={business} />
                        </Route>
                        <Redirect to={`/:businessId/Present/${business.pages?.[0] || IBusinessPageType.Purpose}/1`} />
                    </Switch>
                </main>
            </div>
        </SignalR>
    );
};

export default BusinessPresent;
