import {
    BonusRightAssessmentApi,
    IBonusRightAssessmentResult,
    IBonusRightAssessmentSubmission,
    IProblemDetails,
} from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBonusRightQueryKey } from './bonusRightEmployeeRewards';

const bonusRightAssessmentApi = new BonusRightAssessmentApi(undefined, config.api.baseUrl);

const getBonusRightAssessmentQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'assessmentResult');

export const useBonusRightAssessmentResponse = (
    businessId: string
): UseQueryResult<IBonusRightAssessmentResult, IProblemDetails> =>
    useQuery(getBonusRightAssessmentQueryKey(businessId), () =>
        bonusRightAssessmentApi.getBonusRightAssessmentResult(businessId).then(response => response.data)
    );

export const useSubmitBonusRightAssessment = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBonusRightAssessmentSubmission> => {
    const queryClient = useQueryClient();
    return useMutation<void, IProblemDetails, IBonusRightAssessmentSubmission>(
        submission =>
            bonusRightAssessmentApi.submitBonusRightAssessment(undefined, submission).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBonusRightAssessmentQueryKey(businessId));
            },
        }
    );
};
