import { IUserEntitlementType } from '@api';
import { faListUl, faTh, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { ComponentPropsWithoutRef, useContext } from 'react';
import { Button, Can, DropdownMenu } from '~/components';
import AddButton from './AddButton';
import { DisplayType, documentCenterStore } from './DocumentCenterContext';

interface IDisplayTypeButtonProps {
    icon: IconDefinition;
    displayType: DisplayType;
}

const DisplayTypeButton: React.FC<IDisplayTypeButtonProps> = ({ icon, displayType }: IDisplayTypeButtonProps) => {
    const { displayType: currentDisplayType, setDisplayType } = useContext(documentCenterStore);

    return (
        <Button
            color="link"
            className={classnames(
                'p-2',
                { 'text-primary-500': currentDisplayType === displayType },
                { 'text-gray-600': currentDisplayType !== displayType }
            )}
            onClick={() => setDisplayType(displayType)}
        >
            <FontAwesomeIcon icon={icon} size="lg" />
        </Button>
    );
};

const MoreActionsButton = () => {
    const { openDeleteModal, openMoveModal, openRenameModal, openShareModal, selectedItems } =
        useContext(documentCenterStore);
    const canRename = selectedItems.size === 1;
    const canShare = selectedItems.size === 1 && Array.from(selectedItems.values())[0].isFolder;

    return (
        <DropdownMenu position="right">
            <DropdownMenu.Trigger as={Button} size="sm" color="secondary" className="mr-3">
                More
            </DropdownMenu.Trigger>
            <DropdownMenu.Item onClick={() => openDeleteModal(undefined)}>Delete</DropdownMenu.Item>
            <Can hasEntitlement={IUserEntitlementType.DocumentUpdate}>
                <DropdownMenu.Item onClick={() => openMoveModal(undefined)}>Move</DropdownMenu.Item>
                <DropdownMenu.Item
                    disabled={!canRename}
                    title={canRename ? '' : 'Must have only 1 item selected to rename.'}
                    onClick={() => openRenameModal(undefined)}
                >
                    Rename
                </DropdownMenu.Item>
            </Can>
            <Can hasEntitlement={IUserEntitlementType.DocumentShare}>
                <DropdownMenu.Item
                    disabled={!canShare}
                    title={canShare ? '' : 'Must have only 1 folder selected to share.'}
                    onClick={() => openShareModal(undefined)}
                >
                    Share
                </DropdownMenu.Item>
            </Can>
        </DropdownMenu>
    );
};

const Header = ({ children }: ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const { selectedItems } = useContext(documentCenterStore);

    return (
        <>
            <div className="flex justify-between items-center flex-wrap" style={{ minHeight: '2.1rem' }}>
                {children}
                <div className="flex items-center">
                    {selectedItems.size > 0 && <MoreActionsButton />}
                    <DisplayTypeButton displayType={DisplayType.Grid} icon={faTh} />
                    <DisplayTypeButton displayType={DisplayType.Table} icon={faListUl} />
                    <AddButton />
                </div>
            </div>
            <hr className="my-3 w-full border-t border-gray-700" />
        </>
    );
};

export default Header;
