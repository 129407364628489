import { IBusiness, IBusinessValuation } from '@api';
import { useContext, useState } from 'react';
import { ValidationError } from 'yup';
import { Button, ErrorModal, ErrorPage, LoadingIndicator, useAvatarInput } from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useAddEmployee, useEmployeeAvatar, useEmployees, useUpdateBusiness } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';
import EmployeeSearch from './EmployeeSearch';
import PrimaryEmployeeForm, {
    IPrimaryEmployeeFormData,
    mapFormDataToApi,
    schemaValidation,
} from './PrimaryEmployeeForm';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const getInitialFormData = (): IPrimaryEmployeeFormData => {
    return {
        emailAddress: '',
        firstName: '',
        lastName: '',
        title: '',
    };
};

const PrimaryEmployee = ({ business, businessValuation }: IProps): JSX.Element => {
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [formData, setFormData] = useState<IPrimaryEmployeeFormData>(getInitialFormData());
    const [primaryEmployeeAvatar, setPrimaryEmployeeAvatar] = useAvatarInput(business);
    const [primaryEmployeeId, setPrimaryEmployeeId] = useState<string | null | undefined>(business.primaryEmployeeId);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const addEmployee = useAddEmployee(business.id);
    const updateBusiness = useUpdateBusiness(business.id);
    const updateEmployeeAvatar = useEmployeeAvatar(business.id);
    const {
        isError: isEmployeesError,
        isLoading: isEmployeesLoading,
        data: employees = [],
    } = useEmployees(business.id);
    const onAddPrimaryEmployee = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        const apiData = mapFormDataToApi(formData);
        const { id: primaryEmployeeId } = await addEmployee.mutateAsync({
            ...apiData,
            businessId: business.id,
        });
        if (primaryEmployeeAvatar?.file) {
            await updateEmployeeAvatar.mutateAsync({
                avatar: primaryEmployeeAvatar.file,
                employeeId: primaryEmployeeId,
            });
        }
        setPrimaryEmployeeId(primaryEmployeeId);
        showSuccessNotification();
    };
    const onUpdatePrimaryEmployee = async (primaryEmployeeId: string) => {
        await updateBusiness.mutateAsync({
            ...business,
            primaryEmployeeId,
            value: businessValuation.value,
        });
        showSuccessNotification();
    };
    const handleSave = async () => {
        try {
            setIsDisabled(true);
            if (!primaryEmployeeId) {
                await onAddPrimaryEmployee();
            } else {
                await onUpdatePrimaryEmployee(primaryEmployeeId);
            }
        } catch {
            setShowErrorModal(true);
        } finally {
            setIsDisabled(false);
        }
    };

    if (isEmployeesError) return <ErrorPage />;
    if (isEmployeesLoading) return <LoadingIndicator />;

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Primary Contact Info</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-gray-300">
                Enter information or choose an employee as the primary contact for the business.
            </p>
            <div className="space-y-3">
                <EmployeeSearch
                    disabled={isDisabled}
                    employees={employees}
                    onChange={employeeId => setPrimaryEmployeeId(employeeId)}
                    onClickNew={() => setPrimaryEmployeeId(undefined)}
                    value={primaryEmployeeId}
                />
                {!primaryEmployeeId && (
                    <div className="mt-5">
                        <PrimaryEmployeeForm
                            autoFocus
                            avatar={primaryEmployeeAvatar?.url}
                            disabled={isDisabled}
                            errors={validationErrors}
                            formData={formData}
                            onChange={primaryEmployeeFormData => setFormData({ ...primaryEmployeeFormData })}
                            onChangeAvatar={setPrimaryEmployeeAvatar}
                        />
                    </div>
                )}
                <div className="py-3 border-t border-gray-700 text-right sm:px-0">
                    <Button color="primary" disabled={isDisabled} onClick={handleSave}>
                        Save
                    </Button>
                </div>
            </div>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
        </>
    );
};

export default PrimaryEmployee;
