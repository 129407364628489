import { IBusiness, IEmployee, IEmployeeRatingConfig } from '@api';
import EmployeeRating from '../EmployeeRating';
import { GridView } from '../GridView';
import RatingLegend from '../RatingLegend';
import { IEditState } from '../TeamValuesTabs';

interface IProps {
    business: IBusiness;
    employeeRatingConfig: IEmployeeRatingConfig;
    employees: IEmployee[];
    setEditState: (editState: IEditState) => void;
}

const Values = ({ business, employeeRatingConfig, employees, setEditState }: IProps): JSX.Element => {
    const employeesSortedByRating = [...employees];
    employeesSortedByRating.sort((a, b) => {
        const aRating = a.rating ?? 0;
        const bRating = b.rating ?? 0;
        return employeeRatingConfig.sortHighToLow ? bRating - aRating : aRating - bRating;
    });

    return (
        <>
            <div className="border-t border-gray-700 pt-3"></div>
            <RatingLegend {...employeeRatingConfig} />
            <GridView
                business={business}
                employees={employeesSortedByRating}
                onClickEmployee={(employee: IEmployee) => setEditState({ isOpen: true, employee })}
                renderValue={(employee: IEmployee) => (
                    <EmployeeRating
                        className="w-10 h-10"
                        rating={employee.rating}
                        ratingConfig={employeeRatingConfig}
                    />
                )}
            />
        </>
    );
};

export default Values;
