import { IBusiness } from '@api';
import CurrencyFormatter from '~/utils/currencyFormatter';
import DownArrow from './DownArrow';
import EntityCard from './EntityCard';
import LeftArrow from './LeftArrow';
import { SuccessionPlanType } from './models';
import PlanIllustrationCrossPurchaseArrow from './PlanIllustrationCrossPurchaseArrow';

interface IProps {
    business: IBusiness;
    deceasedContainerRect: DOMRect;
    deceasedEquityAmount: number;
    estateContainerRect: DOMRect;
    illustrationContainerRect: DOMRect;
    otherOwnersContainerRect: DOMRect;
    successionPlanType: SuccessionPlanType;
}

const PlanIllustrationEquityTransfer = ({
    business,
    deceasedContainerRect,
    deceasedEquityAmount,
    estateContainerRect,
    illustrationContainerRect,
    otherOwnersContainerRect,
    successionPlanType,
}: IProps): JSX.Element => {
    const yOffset = illustrationContainerRect?.top || 0;
    const xOffset = illustrationContainerRect?.left || 0;
    switch (successionPlanType) {
        case SuccessionPlanType.CrossPurchase:
            return (
                <div
                    className="absolute"
                    style={{
                        left: estateContainerRect?.right - xOffset,
                        top: otherOwnersContainerRect?.bottom - yOffset,
                    }}
                >
                    <PlanIllustrationCrossPurchaseArrow
                        estateContainerRect={estateContainerRect}
                        otherOwnersContainerRect={otherOwnersContainerRect}
                    />
                </div>
            );
        case SuccessionPlanType.NoPlan:
            return (
                <div className="absolute" style={{ top: deceasedContainerRect?.bottom - yOffset }}>
                    <DownArrow
                        containerHeight={(estateContainerRect?.top || 0) - (deceasedContainerRect?.bottom || 0)}
                        containerWidth={deceasedContainerRect?.width || 0}
                    />
                </div>
            );
        case SuccessionPlanType.StockRedemption:
            return (
                <div
                    className="absolute w-max"
                    style={{ left: estateContainerRect?.right - xOffset, top: estateContainerRect?.top - yOffset }}
                >
                    <div className="inline-block">
                        <LeftArrow
                            containerHeight={estateContainerRect?.height || 0}
                            containerWidth={(estateContainerRect?.width || 0) / 2}
                        />
                    </div>
                    <EntityCard
                        avatar={business.avatar || undefined}
                        className="col-span-2 inline-block"
                        description={CurrencyFormatter.format(deceasedEquityAmount)}
                        name={business.name}
                    />
                </div>
            );
    }
};

export default PlanIllustrationEquityTransfer;
