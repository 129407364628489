import {
    BonusRightConnectionApi,
    IBonusRightConnectionStatus,
    IBusiness,
    ICreateBonusRightConnectionRequest,
    IProblemDetails,
} from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBonusRightQueryKey } from './bonusRightEmployeeRewards';

const bonusRightConnectionApi = new BonusRightConnectionApi(undefined, config.api.baseUrl);

const getBonusRightConnectionStatusQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'connectionStatus');

export const useBonusRightConnectionStatus = (
    business: IBusiness
): UseQueryResult<IBonusRightConnectionStatus, IProblemDetails> =>
    useQuery(getBonusRightConnectionStatusQueryKey(business.id), () => {
        if (business.isSampleCase) {
            return { isConnected: true };
        }
        return bonusRightConnectionApi.getBonusRightConnectionStatus(business.id).then(response => response.data);
    });

export const useCreateBonusRightConnection = (
    businessId: string
): UseMutationResult<IBonusRightConnectionStatus, IProblemDetails, ICreateBonusRightConnectionRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IBonusRightConnectionStatus, IProblemDetails, ICreateBonusRightConnectionRequest>(
        request =>
            bonusRightConnectionApi
                .createBonusRightConnection(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBonusRightConnectionStatusQueryKey(businessId));
            },
        }
    );

    return mutation;
};

export const useDeleteBonusRightConnection = (
    businessId: string
): UseMutationResult<IBonusRightConnectionStatus, IProblemDetails, void> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IBonusRightConnectionStatus, IProblemDetails>(
        request =>
            bonusRightConnectionApi.deleteBonusRightConnection(businessId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                queryClient
                    .resetQueries(getBonusRightConnectionStatusQueryKey(businessId))
                    .then(() => queryClient.resetQueries(getBonusRightQueryKey(businessId)));
            },
        }
    );

    return mutation;
};
