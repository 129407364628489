import { IBusinessTeamMember } from '@api';
import { OptionTypeBase } from 'react-select';
import { EntitySelect } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    onChange: (id: string) => void;
    onClickNew: () => void;
    teamMembers: IBusinessTeamMember[];
    value: string | null | undefined;
}

const TeamMemberSearch = ({ onChange, onClickNew, teamMembers, value }: IProps): JSX.Element => {
    const options = [
        ...teamMembers.map(a => ({
            avatar: a.avatar || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            value: a.id,
        })),
    ];

    const handleChange = (option: OptionTypeBase | null | undefined) => {
        if (!option?.value) {
            return;
        }
        onChange(option.value);
    };

    return (
        <EntitySelect
            isCreatable
            options={options}
            placeholder="Search for team member"
            value={value || ''}
            onEntityOptionSelected={handleChange}
            onNewSelected={onClickNew}
        />
    );
};

export default TeamMemberSearch;
