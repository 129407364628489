import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

interface IProps {
    error?: string;
    inputClassName?: string;
    label: string;
    labelClassName?: string;
    name: string;
}

const Checkbox = ({
    className,
    error,
    id,
    inputClassName,
    label,
    labelClassName,
    name,
    ...rest
}: IProps & ComponentPropsWithoutRef<'input'>): JSX.Element => {
    return (
        <div className={className}>
            <div className="flex items-center">
                <input
                    id={id ?? name}
                    name={name}
                    type="checkbox"
                    className={classNames(
                        inputClassName,
                        `
                        bg-input-bg
                        border-gray-300
                        focus:outline-none
                        focus:ring-2
                        focus:ring-primary-500
                        h-4
                        rounded
                        text-primary-600
                        w-4
                        `
                    )}
                    {...rest}
                />
                <label htmlFor={name} className={classNames('ml-2 block', labelClassName)}>
                    {label}
                </label>
            </div>
            {error && <p className="mt-2 text-sm text-danger-600">{error}</p>}
        </div>
    );
};

export { Checkbox };
