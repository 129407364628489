import { IBusinessTeamMember } from '@api';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { components, OptionProps, OptionTypeBase } from 'react-select';
import { Avatar, ISelectOption, Select } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

type ITeamMemberOption = ISelectOption & IBusinessTeamMember;

const OptionComponent = (props: React.PropsWithChildren<OptionProps<ITeamMemberOption, false>>) => {
    if ((props.data as ISelectOption).__isNew__) {
        return (
            <components.Option
                {...props}
                getStyles={() => ({})}
                className={classNames(
                    props.isFocused ? 'bg-gray-700' : 'bg-gray-800',
                    'border-t border-gray-700 flex select-none cursor-pointer items-center p-3 first:border-t-0'
                )}
            >
                <Avatar />
                <div className="text-base ml-2">Add New</div>
            </components.Option>
        );
    }

    const teamMember = props.data as IBusinessTeamMember;
    return (
        <components.Option
            {...props}
            getStyles={() => ({})}
            className={classNames(
                props.isFocused ? 'bg-gray-700' : 'bg-gray-800',
                'flex items-center p-3 group select-none hover:bg-gray-700 cursor-pointer'
            )}
        >
            <Avatar src={teamMember.avatar || undefined} />
            <div className="truncate flex-1 ml-2">
                <div className="truncate text-base">{NameFormatter.getLastNameFirst(teamMember)}</div>
                <div className="text-gray-400 truncate text-sm">{teamMember.emailAddress || 'No email'}</div>
            </div>
            <div
                className={classNames(
                    props.isFocused ? 'bg-primary-500' : 'bg-primary-600',
                    'flex flex-shrink-0 justify-center items-center rounded-full h-8 w-8 group-hover:bg-primary-500'
                )}
            >
                <FontAwesomeIcon icon={faPlus} aria-hidden />
            </div>
        </components.Option>
    );
};

interface IProps {
    onChange: (option: OptionTypeBase | null | undefined) => void;
    onClickNew?: () => void;
    potentialMembers: IBusinessTeamMember[];
    teamMembers: IBusinessTeamMember[];
}

const PostAssessmentContactSearch = ({ onChange, onClickNew, potentialMembers, teamMembers }: IProps): JSX.Element => {
    const membersNotOnTeam = potentialMembers.filter(e => !teamMembers.some(t => t.id === e.id));
    const options: ITeamMemberOption[] = [
        ...membersNotOnTeam.map(a => ({
            ...a,
            label: NameFormatter.getLastNameFirst(a),
            value: a.id,
        })),
    ];

    return (
        <Select
            isCreatable={!!onClickNew}
            showSearchIcon
            onChange={onChange}
            optionComponent={OptionComponent}
            options={options}
            placeholder="Search"
            showCreateWhenEmpty
        />
    );
};

export default PostAssessmentContactSearch;
