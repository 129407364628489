import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft, faRedo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { Button } from './Button';

interface IProps {
    fullScreen?: boolean;
    hideActions?: boolean;
    title?: string;
}

const ErrorPage = (props: IProps): JSX.Element => {
    const { fullScreen = false, title = defaultErrorMessage, hideActions = false } = props;
    return (
        <div className={`flex flex-col justify-center mx-auto ${fullScreen ? 'h-screen' : 'h-full'}`}>
            <div className="text-center">
                <FontAwesomeIcon className="text-gray-700 mb-3" icon={faExclamationTriangle} size="3x" />
                <p>{title}</p>
                {!hideActions && (
                    <div className="mt-6">
                        {window.history.length > 1 && (
                            <Button
                                className="text-primary hover:text-primary-300 focus:ring-0"
                                color="link"
                                size="lg"
                                onClick={() => window.history.back()}
                            >
                                <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                                Go Back
                            </Button>
                        )}
                        <Button
                            className="text-primary hover:text-primary-300 focus:ring-0 ml-6"
                            color="link"
                            size="lg"
                            onClick={() => window.location.reload()}
                        >
                            <FontAwesomeIcon className="mr-2" icon={faRedo} />
                            Reload
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export { ErrorPage };
