import { IDocumentFolderCreateRequest } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, IModalProps, Modal, TextInput } from '~/components';
import { useAddFolder } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { defaultErrorMessage } from '~/utils/errorUtils';

interface IProps extends Pick<IModalProps, 'open'> {
    onClose: () => void;
    parentFolderId: string;
}

type IDocumentFolderCreateForm = Pick<IDocumentFolderCreateRequest, 'name'>;

const AddFolderModal = ({ onClose, open, parentFolderId }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const createFolder = useAddFolder(businessId);
    const [formData, setFormData] = useState<IDocumentFolderCreateForm>({ name: '' });
    const [saveFailed, setSaveFailed] = useState(false);

    const handleOnSubmit = async () => {
        try {
            setSaveFailed(false);
            await createFolder.mutateAsync({ ...formData, businessId, parentFolderId });
            onClose();
        } catch (e) {
            setSaveFailed(true);
            throw e;
        }
    };

    return (
        <Modal maxWidth="xl" open={open} setOpen={onClose} title="Add Folder">
            <div className="p-6">
                <div className="my-5">
                    <TextInput label="Name" onChange={name => setFormData({ ...formData, name })} />
                    {saveFailed && (
                        <p className="text-danger mt-3">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" aria-hidden />
                            {defaultErrorMessage}
                        </p>
                    )}
                </div>
                <div className="space-x-3 flex justify-end">
                    <Button color="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleOnSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default AddFolderModal;
