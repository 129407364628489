import { ITodoSystemActivityType } from '@api';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Button, ErrorPage, LoadingIndicator } from '~/components';
import { useBusiness, useGetMyTodos, useUpdateTodo } from '~/hooks';
import DelegateUserPageHeader from '../DelegateUserPageHeader';
import EmployeeListPage from './EmployeeListPage';
import EmployeesUploadPage from './EmployeesUploadPage';

interface BusinessParams {
    businessId: string;
}

const EmployeeUploadDelegateApp = (): JSX.Element => {
    const { push } = useHistory();
    const todos = useGetMyTodos();
    const { businessId } = useParams<BusinessParams>();
    const business = useBusiness(businessId);
    const [showConfirmationFooter, setShowConfirmationFooter] = useState(false);
    const [updatedEmployees, setUpdatedEmployees] = useState<Record<string, boolean>>({});
    const relatedTodo = todos.data?.find(t => t.systemActivity === ITodoSystemActivityType.EmployeeUpload);
    const isTaskCompleted = relatedTodo?.completed ?? false;
    const updateTodo = useUpdateTodo(businessId, relatedTodo?.id ?? '');

    if (todos.isLoading || business.isLoading) {
        return <LoadingIndicator />;
    }
    if (todos.error || business.error || !business.data) {
        return <ErrorPage />;
    }

    const onClickConfirm = async () => {
        if (relatedTodo && !relatedTodo.completed) {
            await updateTodo.mutateAsync({ ...relatedTodo, completed: true });
        }
        push(`/${businessId}/ThankYou`);
    };

    const onSuccessfulEmployeeChange = (id: string) => {
        setShowConfirmationFooter(true);
        setUpdatedEmployees({ ...updatedEmployees, [id]: true });
    };

    const confirmationFooter = (
        <Transition
            show={showConfirmationFooter || isTaskCompleted}
            className="fixed bottom-0 inset-x-0 transition ease-in-out duration-300 transform"
            enterFrom="translate-y-full"
            enterTo="translate-x-0"
            leaveFrom="translate-x-0"
            leaveTo="translate-y-full"
        >
            <div className="border-success-500 border-t bg-success-600 flex items-center justify-center flex-wrap py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center text-white mr-4">
                    <FontAwesomeIcon aria-hidden="true" icon={faCheckCircle} />
                    <p className="ml-3 font-medium">
                        Successfully updated {Object.keys(updatedEmployees).length} employees
                    </p>
                </div>
                <Button onClick={onClickConfirm} color="tertiary">
                    {isTaskCompleted ? 'Continue' : 'Mark Task Completed'}
                </Button>
            </div>
        </Transition>
    );

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <DelegateUserPageHeader business={business.data} />
            <main className="flex h-full max-h-full overflow-y-auto scrollbar-themed focus:outline-none" tabIndex={0}>
                <div className="w-full max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <Switch>
                        <Route path={`/:businessId/Employees`}>
                            <EmployeeListPage business={business.data} onSuccess={onSuccessfulEmployeeChange} />
                            {confirmationFooter}
                        </Route>
                        <Route path={`/:businessId/EmployeesUpload`}>
                            <EmployeesUploadPage business={business.data} onSuccess={onSuccessfulEmployeeChange} />
                            {confirmationFooter}
                        </Route>
                        <Route path={`/:businessId/ThankYou`}>
                            <div className="w-full h-full">
                                <div className="flex flex-col h-full items-center justify-center text-center">
                                    <FontAwesomeIcon className="text-success-500" icon={faCheckCircle} size="5x" />
                                    <h1 className="text-5xl my-5">Thank you!</h1>
                                    <p className="text-lg">You may now close this window.</p>
                                </div>
                            </div>
                        </Route>
                        <Redirect to={`/${businessId}/Employees`} />
                    </Switch>
                </div>
            </main>
        </div>
    );
};

export default EmployeeUploadDelegateApp;
