import { useCallback, useEffect, useState } from 'react';

export const useBoundingClientRect = (): [DOMRect | undefined, (node: HTMLDivElement | null) => void] => {
    const [rect, setRect] = useState<DOMRect | undefined>();
    const [ref, setRef] = useState<HTMLElement>();

    const updateRect = useCallback(
        node => {
            if (node !== null) {
                if (!ref) setRef(node);
            }
            if (ref) {
                setRect(ref.getBoundingClientRect());
            }
        },
        [ref]
    );

    useEffect(() => {
        const eventListener = () => updateRect(null);
        window.addEventListener('resize', eventListener);
        return () => {
            window.removeEventListener('resize', eventListener);
        };
    }, [updateRect]);

    return [rect, updateRect];
};
