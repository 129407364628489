import { IBusiness } from '@api';
import { Modal } from '~/components';
import { useAdvisorFirm } from '~/hooks';
import ServicesSection from './ServicesSection';

interface IProps {
    business: IBusiness;
    isConnected: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ServicesModal = ({ business, isConnected, open, setOpen }: IProps): JSX.Element | null => {
    const { isError, isLoading, data: advisorFirm } = useAdvisorFirm(business.advisorFirmId);
    if (isError || isLoading || !advisorFirm) return null;

    return (
        <Modal maxWidth="5xl" open={open} setOpen={setOpen} title="VisionLink's Services">
            <div>
                <ServicesSection advisorFirm={advisorFirm} businessId={business.id} isConnected={isConnected} />
            </div>
        </Modal>
    );
};

export default ServicesModal;
