import { IBusiness, IBusinessEvaluationResponse } from '@api';
import { HorizontalTabs } from '~/components';
import Metrics from '~/components/evaluations/metrics/Metrics';
import Overview from '~/components/evaluations/overview/Overview';
import utils from './metricUtils';

const marketTabId = 'market';
const operationalTabId = 'operational';
const overviewTabId = 'overview';

interface IProps {
    business: IBusiness;
    data: IBusinessEvaluationResponse;
}

const Results = ({ business, data }: IProps): JSX.Element => {
    const businessDetail = {
        businessKey: business.id,
        businessName: business.name,
        metrics: data.metrics.map(m => ({ gap: 0, ...m })),
        potentialValue: data.revenue * 2,
        profit: data.ebitda,
        revenue: data.revenue,
        gap: data.revenue * 0.5,
        value: data.revenue * 1.5,
    };

    return (
        <HorizontalTabs containerClassName="h-full p-10" activeTabId={overviewTabId}>
            <HorizontalTabs.Tab tabId={overviewTabId}>Overview</HorizontalTabs.Tab>
            <HorizontalTabs.Panel tabId={overviewTabId}>
                <div className="mt-7">
                    <Overview business={business} businessDetail={businessDetail} utils={utils} />
                </div>
            </HorizontalTabs.Panel>
            <HorizontalTabs.Tab tabId={marketTabId}>Market</HorizontalTabs.Tab>
            <HorizontalTabs.Panel tabId={marketTabId}>
                <div className="mt-7 h-full">
                    <Metrics
                        metrics={businessDetail.metrics.filter(utils.isSalesDriver)}
                        title="Market Metrics"
                        utils={utils}
                    />
                </div>
            </HorizontalTabs.Panel>
            <HorizontalTabs.Tab tabId={operationalTabId}>Operational</HorizontalTabs.Tab>
            <HorizontalTabs.Panel tabId={operationalTabId}>
                <div className="mt-7 h-full">
                    <Metrics
                        metrics={businessDetail.metrics.filter(utils.isOperationsDriver)}
                        title="Operational Metrics"
                        utils={utils}
                    />
                </div>
            </HorizontalTabs.Panel>
        </HorizontalTabs>
    );
};

export default Results;
