import { faFolders } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoSubfoldersMessage = (): JSX.Element => (
    <div className="text-center p-3 md:p-5">
        <FontAwesomeIcon icon={faFolders} size="4x" className="mb-3 opacity-50" aria-hidden />
        <div>There are no subfolders in this folder.</div>
    </div>
);

export default NoSubfoldersMessage;
