import {
    IProblemDetails,
    IRecapitalization,
    IRecapitalizationCreateRequest,
    IRecapitalizationUpdateRequest,
    RecapitalizationsApi,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const recapitalizationsApi = new RecapitalizationsApi(undefined, config.api.baseUrl);

export const getRecapitalizationQueryKey = (businessId: string): QueryKey =>
    getBusinessQueryKey(businessId, 'recapitalization');

export const useAddRecapitalization = (
    businessId: string
): UseMutationResult<IRecapitalization, IProblemDetails, IRecapitalizationCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IRecapitalization, IProblemDetails, IRecapitalizationCreateRequest>(
        request => recapitalizationsApi.postRecapitalization(undefined, request).then(response => response.data),
        {
            onSuccess: () => invalidateRecapitalization(queryClient, businessId),
        }
    );

    return mutation;
};

export const useDeleteRecapitalization = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        recapitalizationId =>
            recapitalizationsApi.deleteRecapitalization(recapitalizationId, undefined).then(response => response.data),
        {
            onSuccess: () => invalidateRecapitalization(queryClient, businessId),
        }
    );

    return mutation;
};

interface IRecapitalizationUpdateParams {
    recapitalizationId: string;
    request: IRecapitalizationUpdateRequest;
}

export const useUpdateRecapitalization = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IRecapitalizationUpdateParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IRecapitalizationUpdateParams>(
        ({ recapitalizationId, request }) =>
            recapitalizationsApi
                .putRecapitalization(recapitalizationId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => invalidateRecapitalization(queryClient, businessId),
        }
    );

    return mutation;
};

export const useRecapitalizationForBusiness = (
    businessId: string
): UseQueryResult<IRecapitalization, IProblemDetails> =>
    useQuery(
        getRecapitalizationQueryKey(businessId),
        () => recapitalizationsApi.getRecapitalizationForBusiness(businessId).then(response => response.data),
        {
            enabled: !!businessId,
        }
    );

function invalidateRecapitalization(queryClient: QueryClient, businessId: string) {
    // reset so that we can display an empty state with a 404
    queryClient.resetQueries(getRecapitalizationQueryKey(businessId));
}
