import { faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    isSelected: boolean;
}

const SelectionIcon = ({ isSelected }: IProps): JSX.Element => (
    <span className="fa-stack fa-xs">
        {isSelected ? (
            <>
                <FontAwesomeIcon icon={faCircleSolid} className="fa-stack-2x text-primary" />
                <FontAwesomeIcon icon={faCheck} className="fa-stack-1x" />
            </>
        ) : (
            <FontAwesomeIcon icon={faCircleLight} className="fa-stack-2x text-gray-600" />
        )}
    </span>
);

export default SelectionIcon;
