import { IEmployee, IEmployeeCreateRequest, IEmployeeRatingConfig, IEmployeeUpdateRequest } from '@api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
    AvatarInput,
    CurrencyInput,
    DateInput,
    EntitySelect,
    ErrorPage,
    FormActions,
    IEntitySelectOption,
    ISelectOption,
    LoadingIndicator,
    Select,
    SlideOver,
    TextInput,
    useAvatarInput,
} from '~/components';
import { Checkbox } from '~/components/Checkbox';
import { useBusiness, useEmployeeRatingConfig, useEmployees } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import { transformEmptyStringOrZeroToNull, transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';
import SharesInput from './SharesInput';

const mapShares = (shares: string | undefined): number | undefined => {
    const inputValue = shares?.length ? parseFloat(shares) : undefined;
    return inputValue === 0 ? undefined : inputValue;
};

const mapFormDataToApi = (formData: IEmployeeFormData): IEmployeeCreateRequest | IEmployeeUpdateRequest => ({
    ...formData,
    emailAddress: formData.emailAddress?.length ? formData.emailAddress : undefined,
    familyRelativeId: formData.familyRelativeId?.length ? formData.familyRelativeId : undefined,
    rating: formData.rating?.length ? parseFloat(formData.rating) : undefined,
    shares: mapShares(formData.shares),
});

interface IProps {
    businessId: string;
    employee?: Partial<IEmployee>;
    isOpen: boolean;
    onClose: () => void;
    onDelete?: () => Promise<void>;
    onSave: (request: IEmployeeCreateRequest | IEmployeeUpdateRequest, profilePhoto: File | undefined) => Promise<void>;
    title: string;
}

const buildDepartmentOptions = (employees: IEmployee[], newDepartment: string | null): ISelectOption[] => {
    const departments = [...employees.map(e => e.department ?? ''), newDepartment || ''];
    departments.sort((a, b) => a.localeCompare(b));
    const uniqueDepartments = [...new Set(departments.filter(d => !!d))];
    return uniqueDepartments.map(d => ({
        label: d,
        value: d,
    }));
};
const buildFamilyRelativeOptions = (
    employees: IEmployee[],
    currentEmployee?: Partial<IEmployee>
): IEntitySelectOption[] => {
    return [
        {
            label: 'No one related',
            name: 'No one related',
            value: '',
        },
        ...employees
            .filter(e => e.isOwner && currentEmployee?.id !== e.id)
            .map(a => ({
                avatar: a.avatar || undefined,
                label: NameFormatter.getLastNameFirst(a),
                name: NameFormatter.getLastNameFirst(a),
                value: a.id,
            })),
    ];
};
const schemaValidation = (ratingConfig: IEmployeeRatingConfig | undefined) => {
    const maxRating = ratingConfig?.maxRating ?? Number.MAX_SAFE_INTEGER;
    const minRating = ratingConfig?.minRating ?? 0;
    const ratingBoundsMessage = `Rating must be between ${minRating} and ${maxRating}`;

    return Yup.object().shape({
        dateOfBirth: Yup.date().max(new Date(), 'Birthday must be in the past.').nullable(),
        emailAddress: Yup.string().nullable().email().label('Email'),
        firstName: Yup.string().required().label('First Name'),
        lastName: Yup.string().required().label('Last Name'),
        rating: Yup.number()
            .max(maxRating, ratingBoundsMessage)
            .min(minRating, ratingBoundsMessage)
            .transform(transformEmptyStringToNull)
            .nullable()
            .label('Rating'),
        salary: Yup.number().positive().transform(transformEmptyStringOrZeroToNull).nullable().label('Salary'),
        shares: Yup.number()
            .positive()
            .transform(transformEmptyStringOrZeroToNull)
            .nullable()
            .label('Shares Outstanding'),
    });
};

type IEmployeeFormData = Omit<IEmployeeCreateRequest, 'businessId' | 'rating' | 'shares'> &
    Strings<Pick<IEmployee, 'rating' | 'shares'>>;

const getInitialValues = (employee?: Partial<IEmployee>): IEmployeeFormData => {
    return {
        dateOfBirth: employee?.dateOfBirth,
        department: employee?.department || '',
        emailAddress: employee?.emailAddress,
        familyRelativeId: employee?.familyRelativeId || '',
        firstName: employee?.firstName || '',
        isOwner: employee?.isOwner || false,
        lastName: employee?.lastName || '',
        rating: employee?.rating?.toString() || '',
        salary: employee?.salary,
        shares: employee?.shares?.toString() || '',
        title: employee?.title || '',
    };
};

const EmployeeForm = ({ businessId, employee, isOpen, onClose, onDelete, onSave, title }: IProps): JSX.Element => {
    const { error, data: employees = [], isLoading } = useEmployees(businessId);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<IEmployeeFormData>(getInitialValues(employee));
    const [newAvatar, setNewAvatar] = useAvatarInput(isOpen);
    const [newDepartment, setNewDepartment] = useState<string | null>(null);
    const { data: business } = useBusiness(businessId);
    const { data: ratingConfig } = useEmployeeRatingConfig(businessId);
    const familyRelativeOptions = buildFamilyRelativeOptions(employees, employee);
    const departmentOptions = buildDepartmentOptions(employees, newDepartment);

    const handleSave = () => {
        try {
            schemaValidation(ratingConfig).validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        const request = mapFormDataToApi(formData);
        return onSave(request, newAvatar?.file);
    };
    const resetForm = (employee: Partial<IEmployee> | undefined) => {
        setErrors({});
        setFormData(getInitialValues(employee));
        setNewDepartment(null);
    };

    useEffect(() => {
        if (isOpen) {
            resetForm(employee);
        }
    }, [employee, isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onDelete={onDelete} onSave={handleSave} />}
            title={title}
        >
            {error && <ErrorPage />}
            {isLoading && !error && <LoadingIndicator />}
            {!isLoading && !error && (
                <>
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <TextInput
                                error={errors.firstName}
                                label="First Name"
                                value={formData.firstName}
                                onChange={firstName => setFormData({ ...formData, firstName })}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <TextInput
                                error={errors.lastName}
                                label="Last Name"
                                value={formData.lastName}
                                onChange={lastName => setFormData({ ...formData, lastName })}
                            />
                        </div>
                    </div>
                    <div>
                        <TextInput
                            error={errors.emailAddress}
                            label="Email"
                            value={formData.emailAddress || ''}
                            onChange={emailAddress => setFormData({ ...formData, emailAddress })}
                        />
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <DateInput
                                error={errors.dateOfBirth}
                                label="Birthday"
                                max={moment().format('yyyy-MM-DD')}
                                value={formData.dateOfBirth || ''}
                                onChange={dateOfBirth => setFormData({ ...formData, dateOfBirth })}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label className="block text-sm font-medium">Photo</label>
                            <AvatarInput
                                error={errors.avatar}
                                src={newAvatar?.url || employee?.avatar}
                                onChange={setNewAvatar}
                            />
                        </div>
                    </div>
                    <div className="relative">
                        <TextInput
                            error={errors.title}
                            label="Title"
                            value={formData.title || ''}
                            onChange={title => setFormData({ ...formData, title })}
                        />
                        <Checkbox
                            checked={formData.isOwner}
                            className="absolute right-0 top-0 text-sm"
                            error={errors.isOwner}
                            label="Owner"
                            name="isOwner"
                            onChange={() => setFormData({ ...formData, isOwner: !formData.isOwner })}
                        />
                    </div>
                    <div>
                        <Select
                            isCreatable
                            showSearchIcon
                            error={errors.department}
                            label="Department"
                            value={formData.department || ''}
                            onChange={e => setFormData({ ...formData, department: e?.value })}
                            onCreateOption={newValue => {
                                setNewDepartment(newValue);
                                setFormData({ ...formData, department: newValue });
                            }}
                            options={departmentOptions}
                        />
                    </div>
                    <div>
                        <CurrencyInput
                            error={errors.salary}
                            label="Salary"
                            value={formData.salary || ''}
                            onChange={salary => setFormData({ ...formData, salary })}
                        />
                    </div>
                    <div>
                        <SharesInput
                            business={business}
                            employees={employees}
                            selectedEmployee={employee}
                            error={errors.shares}
                            onChange={shares => setFormData({ ...formData, shares })}
                            value={formData.shares || ''}
                        />
                    </div>
                    <div>
                        <TextInput
                            error={errors.rating}
                            label={getRatingLabel(ratingConfig)}
                            min={ratingConfig?.minRating}
                            max={ratingConfig?.maxRating}
                            type="number"
                            value={formData.rating || ''}
                            onChange={rating => setFormData({ ...formData, rating })}
                        />
                    </div>
                    <div>
                        <EntitySelect
                            error={errors.familyRelativeId}
                            label="Related to an owner?"
                            onEntityOptionSelected={e => setFormData({ ...formData, familyRelativeId: e?.value })}
                            options={familyRelativeOptions}
                            placeholder="No one related"
                            value={formData.familyRelativeId || ''}
                        />
                    </div>
                </>
            )}
        </SlideOver>
    );
};

const getRatingLabel = (ratingConfig: IEmployeeRatingConfig | undefined) =>
    ratingConfig ? `Rating (${ratingConfig.minRating}-${ratingConfig.maxRating})` : 'Rating';

export default EmployeeForm;
