import { IAdvisorFirm, IBusinessSizeType } from '@api';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { Avatar } from '~/components';
import { getAddress } from '~/utils/advisorFirmUtils';
import { businessSizeDisplay } from '~/utils/enumUtils';

interface IProps {
    advisorFirm: IAdvisorFirm;
}

interface IInfoRow<T> {
    label: string;
    value: T | null | undefined;
    func?: (v: T) => JSX.Element | T | string;
}

type IInfo = number | string | IBusinessSizeType;

const InfoRow = <T extends IInfo>({ label, value, func = v => v }: IInfoRow<T>): JSX.Element | null => {
    return (
        <>
            <div>{label}</div>
            <div className="col-span-2">{value ? func(value) : '--'}</div>
        </>
    );
};

const AdvisorFirmInfoSection = ({ advisorFirm }: IProps): JSX.Element | null => {
    const address = getAddress(advisorFirm);

    return (
        <div className="grid lg:grid-cols-2 gap-16 my-8">
            <div>
                <div className="flex">
                    <div>
                        <Avatar
                            className="mr-7"
                            placeholderIcon={faBuilding}
                            size={20}
                            src={advisorFirm.avatar || ''}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <h4 className="text-xl font-bold">{advisorFirm.name}</h4>
                        <h5 className="leading-6">
                            Financial Services
                            {address && ` | ${address}`}
                        </h5>
                    </div>
                </div>
                <p className="mt-4 leading-6">{advisorFirm.description && <div>{advisorFirm.description}</div>}</p>
            </div>
            <div>
                <div className="grid grid-cols-3 gap-1">
                    <InfoRow label="Website" value={advisorFirm.website} func={v => <a href={`${v}`}>{v}</a>} />
                    <InfoRow label="Industry" value="Financial Services" />
                    <InfoRow label="Business Size" value={advisorFirm.size} func={v => businessSizeDisplay[v]} />
                    <InfoRow label="Headquarters" value={address} />
                    <InfoRow label="Founded" value={advisorFirm.yearFounded} />
                </div>
            </div>
        </div>
    );
};

export default AdvisorFirmInfoSection;
