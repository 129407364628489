import { IQuestion } from '~/components/surveys/models';

export const questions: IQuestion[] = [
    {
        id: 'Documentation',
        header: 'Documentation',
        content:
            'We have a written compensation philosophy statement that articulates how value will be shared within our organization.',
    },
    {
        id: 'Balance',
        header: 'Balance',
        content:
            'Our pay strategy effectively balances guaranteed compensation (salaries) and value-sharing (performance rewards).',
    },
    {
        id: 'TimeHorizon',
        header: 'Time Horizon',
        content:
            'We effectively balance rewards for short-term performance (12 months or less) and long-term (36 months or more).',
    },
    {
        id: 'CorporateCulture',
        header: 'Corporate Culture',
        content: 'Our pay offering supports our ability to create and sustain a high-performance culture.',
    },
    {
        id: 'Upside',
        header: 'Upside',
        content: 'Our value-sharing plans offer our most productive employees significant upside earnings potential.',
    },
    {
        id: 'Retention',
        header: 'Retention',
        content: 'Our compensation offering helps us recruit and retain top talent.',
    },
    {
        id: 'Understanding',
        header: 'Understanding',
        content:
            'Our employees have a good understanding of the results they and the company need to meet to maximize contributions to their value-sharing plans.',
    },
    {
        id: 'Transparency',
        header: 'Transparency',
        content:
            'We effectively communicate our rewards plans to employees. They understand their pay offering and what they must do to maximize their earnings.',
    },
    {
        id: 'Agility',
        header: 'Agility',
        content:
            'Our pay strategy is flexible enough to adjust to rapid change but structured enough to prevent us from reinventing our compensation offering too frequently.',
    },
    {
        id: 'Communication',
        header: 'Communication',
        content: 'Our company has an effective means of communicating and celebrating our rewards plan.',
    },
    {
        id: 'Partnership',
        header: 'Partnership',
        content:
            'Our compensation offering encourages employees to act as true growth partners in building the business.',
    },
    {
        id: 'Motivation',
        header: 'Motivation',
        content:
            'For our top performers, we offer a strong long-term incentive plan that provides a significant wealth accumulation opportunity.',
    },
];
