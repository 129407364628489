import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import CurrencyTableCell from '../../CurrencyTableCell';

interface IProps {
    years: IBonusRightAggregatePaySummaryYear[];
}

const ProjectedCompensationValues = ({ years }: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold text-lg">Projected Compensation Values</TableCell>
                {years.map(y => (
                    <TableCell key={`placeholder:${y.year}`} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Salaries</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`salaries:${y.year}`} value={y.salaries} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>STIP</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`stip:${y.year}`} value={y.stips} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>LTIP Redemptions</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`ltipRedemptions:${y.year}`} value={y.ltipRedemptions} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Total Cash Compensation</TableCell>
                {years.map(y => (
                    <CurrencyTableCell
                        key={`totalCashComp:${y.year}`}
                        value={y.salaries + y.stips + y.ltipRedemptions}
                    />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default ProjectedCompensationValues;
