import americaWithFlag from './americaWithFlag.svg';
import conferenceTable from './conferenceTable.svg';
import threeBuildings from './threeBuildings.svg';

const PrivatelyHeldCompaniesInfoScreen = (): JSX.Element => {
    return (
        <div className="mx-auto max-w-7xl p-6">
            <h1 className="text-center text-5xl font-extrabold pb-3">Privately Held Company Facts</h1>
            <p className="text-center text-xl mb-3">Facts about privately held companies</p>

            <div className="mt-16 pb-12 lg:mt-20 lg:pb-20">
                <div className="relative z-0">
                    <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="relative lg:grid lg:grid-cols-7">
                            <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                                    <div className="flex-1 flex flex-col">
                                        <div className="bg-gray-700 px-6 py-10 text-center">
                                            <h5 className="xl:text-xl 2xl:text-2xl font-semibold">Creates New Jobs</h5>
                                            <h4 className="text-3xl xl:text-5xl 2xl:text-6xl leading-none font-extrabold tracking-tight">
                                                60%-80%
                                            </h4>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-600 p-6 bg-gray-800 sm:p-10 lg:p-6 xl:p-10">
                                            <img src={conferenceTable} alt="People sitting at conference table" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 max-w-md mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 shadow-2xl">
                                <div className="relative z-10 rounded-lg shadow-xl">
                                    <div className="bg-gray-700 rounded-t-lg px-6 pt-12 pb-10 text-center">
                                        <h5 className="text-lg  xl:text-xl 2xl:text-3xl font-semibold">
                                            Companies in USA
                                        </h5>
                                        <h4 className="text-3xl lg:text-5xl 2xl:text-6xl leading-none font-extrabold tracking-tight">
                                            22.9M
                                        </h4>
                                    </div>
                                    <div className="border-t-2 border-gray-600 rounded-b-lg pt-10 pb-8 px-6 bg-gray-800 sm:px-10 sm:py-10">
                                        <img src={threeBuildings} alt="Group of three office buildings" />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                                <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                                    <div className="flex-1 flex flex-col">
                                        <div className="bg-gray-700 px-6 py-10 text-center">
                                            <h5 className="xl:text-xl 2xl:text-2xl font-semibold">Employees in USA</h5>
                                            <h4 className="text-3xl xl:text-5xl 2xl:text-6xl leading-none font-extrabold tracking-tight">
                                                146M
                                            </h4>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-600 p-6 bg-gray-800 sm:p-10 lg:p-6 xl:p-10">
                                            <img src={americaWithFlag} alt="Map of USA with American flag" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivatelyHeldCompaniesInfoScreen;
