import { IBusiness, IEmployee } from '@api';
import NumberFormatter from '~/utils/numberFormatter';
import { GridView } from '../GridView';
import { IEditState } from '../TeamValuesTabs';

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
    setEditState: (editState: IEditState) => void;
}

const Income = ({ business, employees, setEditState }: IProps): JSX.Element => {
    const employeesSortedByIncome = [...employees];
    employeesSortedByIncome.sort((a, b) => (b.salary ?? 0) - (a.salary ?? 0));

    return (
        <>
            <div className="border-t border-gray-700 pt-3"></div>
            <GridView
                business={business}
                employees={employeesSortedByIncome}
                onClickEmployee={(employee: IEmployee) => setEditState({ isOpen: true, employee })}
                renderValue={(employee: IEmployee) => (
                    <div className="pr-3">
                        {employee.salary ? `$${NumberFormatter.formatNumber(employee.salary)}` : '--'}
                    </div>
                )}
            />
        </>
    );
};

export default Income;
