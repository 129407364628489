import background from './background.svg';

const Facts = () => (
    <>
        <div className="flex lg:justify-end lg:my-28 xl:mr-16 2xl:mr-48">
            <div className="text-5xl xl:text-7xl mr-4 font-bold">43%</div>
            <div className="lg:text-lg xl:text-2xl max-w-sm xl:max-w-md">
                of family firms do not have a succession plan in place, with only 12% making it to 3rd generation.
            </div>
        </div>
        <div className="flex my-8 lg:my-16 xl:ml-8">
            <div className="text-5xl xl:text-7xl mr-4 font-bold">72%</div>
            <div className="lg:text-lg xl:text-2xl max-w-sm xl:max-w-md">
                intend to pass ownership on to the next generation.
            </div>
        </div>
    </>
);

const DidYouKnowScreen = (): JSX.Element => (
    <div className="mx-auto max-w-7xl flex flex-col h-full p-6">
        <h1 className="text-center text-5xl font-extrabold pb-3">Did You Know?</h1>
        <div className="flex-1 flex p-8">
            <div
                className="hidden lg:flex flex-col bg-center bg-contain bg-no-repeat w-full justify-between"
                style={{ backgroundImage: `url(${background}` }}
            >
                <Facts />
            </div>
            <div className="flex lg:hidden flex-col w-full">
                <img className="mb-8" src={background} alt="" />
                <Facts />
            </div>
        </div>
    </div>
);

export default DidYouKnowScreen;
