import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { useParams } from 'react-router-dom';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useDocumentList } from '~/hooks';
import { BusinessParams } from '../..';
import { DocumentCenterContextProvider } from './DocumentCenterContext';
import Folder from './Folder';

export interface FolderParams extends BusinessParams {
    folderId: string;
}

const DocumentCenter = ({ match }: RouteComponentProps): JSX.Element | null => {
    const params = useParams<BusinessParams>();
    const rootDocumentList = useDocumentList(params.businessId);

    if (rootDocumentList.isLoading) return <LoadingIndicator />;
    if (rootDocumentList.error || !rootDocumentList.data?.folderId) return <ErrorPage />;

    const rootFolderId = rootDocumentList.data.folderId;
    return (
        <DocumentCenterContextProvider rootFolder={rootDocumentList.data}>
            <Switch>
                <Route exact path={`${match.path}/Folder/:folderId`} component={Folder} />
                <Redirect to={`${match.url}/Folder/${rootFolderId}`} />
            </Switch>
        </DocumentCenterContextProvider>
    );
};

export default withRouter(DocumentCenter);
