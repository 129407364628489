import { useState } from 'react';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { Button, TextInput } from '~/components';
import { useCurrentUser, useInnerZoneLogin } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    className?: string;
    username?: string;
}

interface ILoginFormData {
    password: string;
    username: string;
}

const schemaValidation = Yup.object().shape({
    password: Yup.string().label('Password').required(),
    username: Yup.string().label('Username').required(),
});

const InnerZoneLoginForm = ({ className, username }: IProps): JSX.Element => {
    const { data: currentUser } = useCurrentUser();
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<ILoginFormData>({ password: '', username: username || '' });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const innerZoneLogin = useInnerZoneLogin();
    const onLogin = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        setIsLoggingIn(true);
        setLoginErrorMessage(undefined);
        setValidationErrors({});
        innerZoneLogin
            .mutateAsync({
                ...formData,
                advisorFirmId: currentUser?.advisorFirmId ?? '',
            })
            .then(() => {
                setIsLoggingIn(false);
                setLoginErrorMessage(undefined);
                setValidationErrors({});
            })
            .catch(e => {
                setLoginErrorMessage(
                    e.response.status === 401
                        ? 'Wrong username or password'
                        : 'Sorry, there was an error logging into InnerZone. Please try again.'
                );
                setIsLoggingIn(false);
            });
    };

    return (
        <form
            className={className}
            onSubmit={e => {
                e.preventDefault();
                onLogin();
            }}
        >
            <fieldset disabled={isLoggingIn}>
                <TextInput
                    className="mb-4"
                    error={validationErrors.username}
                    label="Username"
                    onChange={username => {
                        setFormData({ ...formData, username });
                        setLoginErrorMessage(undefined);
                        setValidationErrors({});
                    }}
                    value={formData.username}
                />
                <TextInput
                    className="mb-6"
                    error={validationErrors.password}
                    label="Password"
                    type="password"
                    onChange={password => {
                        setFormData({ ...formData, password });
                        setLoginErrorMessage(undefined);
                        setValidationErrors({});
                    }}
                />
                {loginErrorMessage && <p className="mb-6 text-sm text-danger-600">{loginErrorMessage}</p>}
                <Button color="primary" size="lg" type="submit">
                    Sign In
                </Button>
            </fieldset>
        </form>
    );
};

export default InnerZoneLoginForm;
