export interface IRadarChartAxis {
    label: string;
}
export const axisLabelMargin = 8;
export const axisLabelWidth = 120;
export const axisLabelHeight = 50;

interface IProps {
    axes: IRadarChartAxis[];
    radians: number;
    radius: number;
}
const RadarChartAxes = ({ axes, radians, radius }: IProps): JSX.Element => {
    return (
        <>
            {axes.map((a, i) => {
                const axisX2 = radius * (1 - Math.sin((i * radians) / axes.length));
                const axisY2 = radius * (1 - Math.cos((i * radians) / axes.length));
                let axisLabelX;
                let axisLabelY;

                if (
                    Math.floor(axisX2) === Math.floor(radius) ||
                    Math.floor(axisX2) === Math.ceil(radius) ||
                    Math.ceil(axisX2) === Math.floor(radius) ||
                    Math.ceil(axisX2) === Math.ceil(radius)
                ) {
                    axisLabelX = axisX2 - axisLabelWidth / 2;
                } else if (axisX2 < radius) {
                    axisLabelX = axisX2 - axisLabelWidth - axisLabelMargin;
                } else {
                    axisLabelX = axisX2 + axisLabelMargin;
                }

                if (
                    Math.floor(axisY2) === Math.floor(radius) ||
                    Math.floor(axisY2) === Math.ceil(radius) ||
                    Math.ceil(axisY2) === Math.floor(radius) ||
                    Math.ceil(axisY2) === Math.ceil(radius)
                ) {
                    axisLabelY = axisY2 - axisLabelHeight / 2;
                } else if (axisY2 < radius) {
                    axisLabelY = axisY2 - axisLabelHeight - axisLabelMargin;
                } else {
                    axisLabelY = axisY2 + axisLabelMargin;
                }

                return (
                    <g key={i}>
                        <line
                            x1={radius}
                            y1={radius}
                            x2={axisX2}
                            y2={axisY2}
                            className="stroke-current text-gray-500"
                            strokeWidth=".5px"
                        ></line>
                        <foreignObject height={axisLabelHeight} width={axisLabelWidth} x={axisLabelX} y={axisLabelY}>
                            <div className="leading-none rounded text-center p-2 bg-gray-800">{a.label}</div>
                        </foreignObject>
                    </g>
                );
            })}
        </>
    );
};

export default RadarChartAxes;
