import { IAdvisor, IAdvisorUpdateRequest } from '@api';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import * as Yup from 'yup';
import { AvatarInput, FileUpload, TextInput } from '~/components';

export type IAdvisorInfoFormData = Strings<IAdvisorUpdateRequest>;

export const getInitialFormData = (advisor?: IAdvisor): IAdvisorInfoFormData => {
    return {
        emailAddress: advisor?.emailAddress || '',
        firstName: advisor?.firstName || '',
        lastName: advisor?.lastName || '',
        qualification: advisor?.qualification || '',
        title: advisor?.title || '',
    };
};
export const mapFormDataToApi = (formData: IAdvisorInfoFormData): IAdvisorUpdateRequest => {
    return {
        ...formData,
        emailAddress: formData.emailAddress?.length ? formData.emailAddress : '',
    };
};
export const schemaValidation = Yup.object().shape({
    emailAddress: Yup.string().label('Email').email().required(),
    firstName: Yup.string().label('First Name').required(),
    lastName: Yup.string().label('Last Name').required(),
});

interface IProps {
    avatar: string | null | undefined;
    disabled?: boolean;
    errors: { [key: string]: string };
    formData: IAdvisorInfoFormData;
    onChange: (formData: IAdvisorInfoFormData) => void;
    onChangeAvatar: React.Dispatch<React.SetStateAction<FileUpload | undefined>>;
}

const AdvisorInfoForm = ({
    avatar,
    disabled = false,
    errors,
    formData,
    onChange,
    onChangeAvatar,
}: IProps): JSX.Element => {
    return (
        <fieldset className="grid grid-cols-6 gap-6" disabled={disabled}>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    autoFocus
                    error={errors.firstName}
                    label="First Name"
                    value={formData.firstName}
                    onChange={firstName => onChange({ ...formData, firstName })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    error={errors.lastName}
                    label="Last Name"
                    value={formData.lastName}
                    onChange={lastName => onChange({ ...formData, lastName })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3 relative">
                <TextInput
                    error={errors.qualification}
                    label="Qualification"
                    value={formData.qualification || ''}
                    onChange={qualification => onChange({ ...formData, qualification })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <TextInput
                    error={errors.emailAddress}
                    label="Email"
                    value={formData.emailAddress || ''}
                    onChange={emailAddress => onChange({ ...formData, emailAddress })}
                />
            </div>
            <div className="col-span-6 sm:col-span-3">
                <label className="block text-sm font-medium">Photo</label>
                <AvatarInput error={errors.avatar} onChange={onChangeAvatar} placeholderIcon={faUser} src={avatar} />
            </div>
            <div className="col-span-6 sm:col-span-3 relative">
                <TextInput
                    error={errors.title}
                    label="Title"
                    value={formData.title || ''}
                    onChange={title => onChange({ ...formData, title })}
                />
            </div>
        </fieldset>
    );
};

export default AdvisorInfoForm;
