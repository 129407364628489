import { IBusiness } from '@api';
import { faCloudUpload, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '~/components';
import Employees from '../business/employees';
import AddEmployeeForm from '../business/employees/AddEmployeeForm';

interface IProps {
    business: IBusiness;
    onSuccess: (id: string) => void;
}

const EmployeeListPage = ({ business, onSuccess }: IProps): JSX.Element => {
    const [addState, setAddState] = useState({ isOpen: false });

    return (
        <>
            <div className="z-10 top-0 sticky flex justify-between mb-12 pt-10 pb-6 bg-gray-900 border-b border-gray-700">
                <h2 className="text-2xl leading-none self-center">{business.name} Employees</h2>
                <div className="self-center space-x-3">
                    <Button color="primary" onClick={() => setAddState({ ...addState, isOpen: true })}>
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add New
                    </Button>
                    <Link to="EmployeesUpload">
                        <Button>
                            <FontAwesomeIcon icon={faCloudUpload} className="mr-2" />
                            Bulk Upload
                        </Button>
                    </Link>
                </div>
            </div>
            <Employees businessId={business.id} onSuccess={onSuccess} />
            <AddEmployeeForm
                businessId={business.id}
                onClose={() => setAddState({ ...addState, isOpen: false })}
                onSuccess={onSuccess}
                {...addState}
            />
        </>
    );
};

export default EmployeeListPage;
