import { IBusinessEvaluationMetricType } from '@api';
import { IQuestion } from '~/components/surveys/models';

export const questions: IQuestion[] = [
    {
        id: IBusinessEvaluationMetricType.Alignment,
        header: 'Management Team',
        content: 'My management team is completely aligned with my goals and vision for the business.',
    },
    {
        id: IBusinessEvaluationMetricType.Finances,
        header: 'Financial Health',
        content: 'My company is currently capable of handling multiple economic downturns.',
    },
    {
        id: IBusinessEvaluationMetricType.Sales,
        header: 'Sales and Marketing',
        content:
            'Our current marketing strategy is providing a healthy pipeline, and we have a robust digital strategy.',
    },
    {
        id: IBusinessEvaluationMetricType.Operations,
        header: 'Operations',
        content: 'My company’s operational structure allows for the most efficient processes.',
    },
    {
        id: IBusinessEvaluationMetricType.Management,
        header: 'Senior Management',
        content:
            'The leaders of my company provide the expertise and direction that is needed to help my company grow.',
    },
    {
        id: IBusinessEvaluationMetricType.Growth,
        header: 'Growth',
        content: 'The growth of my business is consistent and trending upward over the next five to ten years.',
    },
    {
        id: IBusinessEvaluationMetricType.RecurringRevenue,
        header: 'Recurring Revenue',
        content: 'The majority of my business income comes in the form of recurring revenue.',
    },
    {
        id: IBusinessEvaluationMetricType.CustomerSatisfaction,
        header: 'Customer Satisfaction',
        content:
            'My company’s customers tend to stay with my company longer than the industry average, and the amount of business they do with us continues to grow.',
    },
    {
        id: IBusinessEvaluationMetricType.BarriersToEntry,
        header: 'Barriers to Entry',
        content:
            'Due to regulation, high startup costs, or deep expertise, it is very difficult for competitors to break into our industry.',
    },
    {
        id: IBusinessEvaluationMetricType.Brand,
        header: 'Brand',
        content: 'My company’s brand is almost as important to the end user as my customer’s brand.',
    },
    {
        id: IBusinessEvaluationMetricType.CustomerDiversification,
        header: 'Customer Diversification',
        content:
            'The bulk of our revenue is not tied to just a handful of clients. The loss of one or two clients will not meaningfully impact our financial success.',
    },
    {
        id: IBusinessEvaluationMetricType.Innovation,
        header: 'Innovation',
        content:
            'My company is constantly leveraging new technology to modernize our processes and deliver more value our clients.',
    },
];
