interface IProps {
    color: string;
}

const FolderIconGridView = ({ color }: IProps): JSX.Element => {
    return (
        <svg width={96} height={74} viewBox="0 0 96 74" fill="none">
            <g clipPath="url(#prefix__clip0)" className={color}>
                <path
                    opacity={0.5}
                    d="M88.718 8.198H45.026c-1.917 0-4.175-1.375-5.049-3.086l-1.165-2.267C38.012 1.278 35.924 0 34.152 0H7.282C5.558 0 4.15 1.398 4.15 3.11v15.793h4.54V17.65c0-1.832 1.504-3.327 3.35-3.327h71.944c1.845 0 3.35 1.495 3.35 3.327v1.254h4.54v-7.57c-.025-1.737-1.433-3.135-3.156-3.135z"
                />
                <path d="M92.9 20H3.09C1.27 20-.114 21.495.007 23.303l3.666 46.969c.145 1.832 1.772 3.303 3.616 3.303h81.437c1.844 0 3.47-1.495 3.616-3.303l3.665-46.969C96.13 21.495 94.745 20 92.9 20z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0h96v73.756H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FolderIconGridView;
