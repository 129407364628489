import { IDocument } from '@api';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTextColorFromType } from '../utils/colorMap';
import { getIconFromType } from './iconMap';

type IProps = Pick<IDocument, 'color' | 'icon'>;

const FolderIconTableView = ({ color, icon }: IProps): JSX.Element => {
    const folderColor = getTextColorFromType(color);
    const iconSrc = getIconFromType(icon);

    return (
        <span className={'fa-stack mr-2'}>
            <FontAwesomeIcon icon={faFolder} className={`fa-stack-2x ${folderColor}`} />
            <FontAwesomeIcon icon={iconSrc} size="sm" className="fa-stack-1x opacity-60 text-gray-50 mt-2.5" />
        </span>
    );
};

export default FolderIconTableView;
