import { IBusinessTeamMember, IBusinessTeamMemberType } from '@api';
import { OptionTypeBase } from 'react-select';
import { EntitySelect, IEntitySelectOption } from '~/components';
import { useAddAdvisorTeamMember, useAddEmployeeTeamMember } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    businessId: string;
    onClickNew?: () => void;
    potentialMembers: IBusinessTeamMember[];
    teamMembers: IBusinessTeamMember[];
}

interface IBusinessTeamMemberSelectOption extends IEntitySelectOption {
    type: IBusinessTeamMemberType;
}

const TeamMemberSearch = ({ businessId, onClickNew, potentialMembers, teamMembers }: IProps): JSX.Element => {
    const addAdvisorTeamMember = useAddAdvisorTeamMember(businessId);
    const addEmployeeTeamMember = useAddEmployeeTeamMember(businessId);
    const membersNotOnTeam = potentialMembers.filter(e => !teamMembers.some(t => t.id === e.id));
    const options: IBusinessTeamMemberSelectOption[] = [
        ...membersNotOnTeam.map(a => ({
            avatar: a.avatar || undefined,
            description: a.title || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            showAddButton: true,
            type: a.type,
            value: a.id,
        })),
    ];

    const onChange = (option: OptionTypeBase | null | undefined) => {
        if (!option?.value) {
            return;
        }
        const teamMemberOption = option as IBusinessTeamMemberSelectOption;

        if (teamMemberOption.type === IBusinessTeamMemberType.Advisor) {
            addAdvisorTeamMember.mutateAsync({ advisorId: option.value, businessId: businessId });
        }
        if (teamMemberOption.type === IBusinessTeamMemberType.Employee) {
            addEmployeeTeamMember.mutateAsync({ employeeId: option.value });
        }
    };

    return (
        <EntitySelect
            isCreatable={!!onClickNew}
            options={options}
            placeholder="Search and add to team"
            onEntityOptionSelected={onChange}
            onNewSelected={onClickNew}
        />
    );
};

export default TeamMemberSearch;
