import { useState } from 'react';
import { Button, DeleteConfirmationModal, ErrorModal } from '~/components';

interface IProps {
    modelTitle: string;
    onConfirm: () => Promise<void>;
}

const ResetButton = ({ modelTitle, onConfirm }: IProps): JSX.Element => {
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean; isSaving: boolean }>({
        isOpen: false,
        isSaving: false,
    });
    const [showErrorModal, setShowErrorModal] = useState(false);

    const onConfirmDelete = async () => {
        try {
            setDeleteState({ ...deleteState, isSaving: true });
            await onConfirm();
        } catch {
            setShowErrorModal(true);
        } finally {
            setDeleteState({ isOpen: false, isSaving: false });
        }
    };

    return (
        <div className="self-start mt-auto">
            <Button onClick={() => setDeleteState({ ...deleteState, isOpen: true })} color="primary">
                Reset
            </Button>
            <DeleteConfirmationModal
                onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                onConfirm={onConfirmDelete}
                title={`Delete ${modelTitle}`}
                {...deleteState}
            >
                Are you sure you want to delete this {modelTitle}? All of your data will be permanently removed from our
                servers forever. This action cannot be undone.
            </DeleteConfirmationModal>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
        </div>
    );
};

export default ResetButton;
