import { IBusiness, IBusinessValuation, IUserEntitlementType } from '@api';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ValidationError } from 'yup';
import { Button, Can, DeleteConfirmationModal, ErrorModal, useAvatarInput } from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useBusinessAvatar, useDeleteBusiness, useUpdateBusiness } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';
import BusinessInfoForm, { IBusinessInfoFormData, mapFormDataToApi, schemaValidation } from './BusinessInfoForm';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const getInitialFormData = (business: IBusiness, businessValuation: IBusinessValuation): IBusinessInfoFormData => {
    return {
        city: business.city || '',
        description: business.description || '',
        industry: business.industry || '',
        name: business.name || '',
        size: business.size || '',
        sharesOutstanding: business.sharesOutstanding?.toString() || '',
        stateOrProvince: business.stateOrProvince || '',
        value: businessValuation.value,
        website: business.website || '',
        yearFounded: business.yearFounded?.toString() || '',
    };
};

const BusinessInfo = ({ business, businessValuation }: IProps): JSX.Element => {
    const { show: showSuccessNotification, withSuccessNotification } = useContext(SuccessNotificationContext);
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const [formData, setFormData] = useState<IBusinessInfoFormData>(getInitialFormData(business, businessValuation));
    const [isDisabled, setIsDisabled] = useState(false);
    const [newAvatar, setNewAvatar] = useAvatarInput(business);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const deleteBusiness = useDeleteBusiness();
    const history = useHistory();
    const updateBusiness = useUpdateBusiness(business.id);
    const updateBusinessAvatar = useBusinessAvatar();
    const onSave = async () => {
        try {
            try {
                schemaValidation.validateSync(formData, { abortEarly: false });
            } catch (err: unknown) {
                if (ValidationError.isError(err)) {
                    setValidationErrors(yupToFormErrors(err));
                }
                return;
            }
            setIsDisabled(true);
            await updateBusiness.mutateAsync({
                ...business,
                ...mapFormDataToApi(formData),
            });
            if (newAvatar?.file) {
                await updateBusinessAvatar.mutateAsync({ avatar: newAvatar.file, businessId: business.id });
            }
            showSuccessNotification();
        } catch {
            setShowErrorModal(true);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Business Information</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-gray-300">
                Enter general information about the business.
            </p>
            <div className="space-y-3">
                <BusinessInfoForm
                    avatar={newAvatar?.url || business?.avatar}
                    disabled={isDisabled}
                    errors={validationErrors}
                    formData={formData}
                    onChange={setFormData}
                    onChangeAvatar={setNewAvatar}
                />
                <div className="py-3 border-t border-gray-700 flex flex-row-reverse justify-between sm:px-0">
                    <Button color="primary" disabled={isDisabled} onClick={onSave}>
                        Save
                    </Button>
                    <Can hasEntitlement={IUserEntitlementType.BusinessDelete}>
                        <Button
                            className="text-danger"
                            color="tertiary"
                            disabled={isDisabled}
                            onClick={() => setDeleteState({ isOpen: true })}
                        >
                            Delete Business
                        </Button>
                    </Can>
                </div>
            </div>
            <DeleteConfirmationModal
                onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                onConfirm={withSuccessNotification(() => {
                    setIsDisabled(true);
                    return deleteBusiness
                        .mutateAsync(business.id)
                        .then(() => history.push('/'))
                        .finally(() => setIsDisabled(false));
                })}
                title="Delete Business"
                {...deleteState}
            >
                Are you sure you want to delete this business? All of your data will be permanently removed from our
                servers forever. This action cannot be undone.
            </DeleteConfirmationModal>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
        </>
    );
};

export default BusinessInfo;
