import { ErrorPage, LoadingIndicator } from '~/components';
import { useAdvisorFirm, useCurrentUser } from '~/hooks';
import PresentationSettings from './PresentationSettings';

const MyFirmPresentationSettings = (): JSX.Element => {
    const { data } = useCurrentUser();
    const { isLoading, error, data: myFirm } = useAdvisorFirm(data?.advisorFirmId || '');

    if (error) return <ErrorPage />;
    if (isLoading || !myFirm) return <LoadingIndicator />;

    return <PresentationSettings advisorFirm={myFirm} />;
};

export default MyFirmPresentationSettings;
