import { useContext, useRef } from 'react';
import { documentCenterStore } from './DocumentCenterContext';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';

interface IProps {
    folderId: string;
}

const ItemNewUploadGridView = ({ folderId }: IProps): JSX.Element => {
    const fileDialog = useRef<HTMLInputElement>(null);
    const { openAddFilesModal } = useContext(documentCenterStore);

    return (
        <div
            className={`h-60 w-60 rounded-lg mb-4 mr-4 flex flex-col justify-between items-center bg-gray-800 hover:bg-gray-700 hover:bg-opacity-100 bg-opacity-50`}
        >
            <DragAndDropUploadHandler className="flex flex-col justify-center items-center" folderId={folderId}>
                <div className="m-3 p-3 text-center cursor-pointer" onClick={() => fileDialog.current?.click()}>
                    <input
                        onChange={e => openAddFilesModal(e.currentTarget.files || undefined, folderId)}
                        ref={fileDialog}
                        type="file"
                        multiple
                        className="hidden"
                    />
                    Click or drag-and-drop here to add new files
                </div>
            </DragAndDropUploadHandler>
        </div>
    );
};

export default ItemNewUploadGridView;
