import { IEmployee, IEmployeeCreateRequest, IEmployeeUpdateRequest } from '@api';
import { faCircle, faCircleNotch, faCloudUpload, faDatabase } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import { parse as papaParse, ParseResult } from 'papaparse';
import { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { bool, date, number, object, string, ValidationError } from 'yup';
import {
    Button,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { useAddEmployee, useEmployees, useUpdateEmployee } from '~/hooks';
import { nameofFactory } from '~/utils/nameof';
import NumberFormatter from '~/utils/numberFormatter';
import { transformEmptyStringToNull } from '~/utils/yupUtils';
import { BusinessParams } from '..';

interface IProps {
    onSuccess?: (id: string) => void;
}

interface IEmployeeCsvRow {
    dateOfBirth?: string | null;
    department?: string | null;
    emailAddress?: string | null;
    firstName: string;
    id?: string | null;
    isOwner?: string | null;
    lastName: string;
    rating?: string | null;
    salary?: string | null;
    shares?: string | null;
    title?: string | null;
}

const employeeSchemaValidation = object({
    dateOfBirth: date().typeError('must be a valid date').nullable().transform(transformEmptyStringToNull),
    department: string().nullable(),
    emailAddress: string().nullable().email(),
    firstName: string().required(),
    id: string().nullable(),
    isOwner: bool()
        .nullable()
        .transform(curr => stringToBoolean(curr)),
    lastName: string().required(),
    rating: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    salary: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    shares: number()
        .nullable()
        .transform((_curr, orig: string) => stringToNumber(orig)),
    title: string().nullable(),
});
const nameof = nameofFactory<IEmployeeCsvRow>();
const supportedHeaders = [
    nameof('firstName'),
    nameof('dateOfBirth'),
    nameof('department'),
    nameof('emailAddress'),
    nameof('id'),
    nameof('isOwner'),
    nameof('lastName'),
    nameof('rating'),
    nameof('salary'),
    nameof('shares'),
    nameof('title'),
];

interface IEmployeeDataRow {
    data: IEmployeeCsvRow;
    errors: { [field: string]: string[] };
    rowNumber: number;
}

const isCsvFileType = (file: File) =>
    file.type === 'application/vnd.ms-excel' || file.type === 'text/csv' || file.name.endsWith('.csv');
const formatDateString = (dateString: string | null | undefined): string | undefined => {
    const date = moment(dateString, false);
    if (!date.isValid()) return undefined;
    return date.format('YYYY-MM-DD');
};
const stringToBoolean = (value: string | null | undefined): boolean | undefined =>
    !!JSON.parse(String(value || 'false').toLowerCase());
const stringToNumber = (value: string | null | undefined) => NumberFormatter.unformatNumber(value ?? '');
const mapCsvToApi = (employee: IEmployeeCsvRow): IEmployeeCreateRequest | IEmployeeUpdateRequest => ({
    dateOfBirth: formatDateString(employee.dateOfBirth),
    department: employee.department,
    emailAddress: employee.emailAddress ? employee.emailAddress : undefined,
    firstName: employee.firstName,
    isOwner: stringToBoolean(employee.isOwner),
    lastName: employee.lastName,
    rating: stringToNumber(employee.rating),
    salary: stringToNumber(employee.salary),
    shares: stringToNumber(employee.shares),
    title: employee.title,
});
const findEmployee = (employee: IEmployeeCsvRow, employees: IEmployee[]) => {
    if (employee.id) {
        return employees.find(e => e.id === employee.id);
    }
    if (employee.emailAddress) {
        return employees.find(e => e.emailAddress === employee.emailAddress);
    }
    return employees.find(
        e =>
            e.firstName?.toUpperCase() === employee.firstName?.toUpperCase() &&
            e.lastName?.toUpperCase() === employee.lastName?.toUpperCase()
    );
};

interface IUploadPreviewProps {
    onReset: () => void;
    onSuccess?: (id: string) => void;
    parseResult: ParseResult<IEmployeeCsvRow>;
}

const validateRow = (row: IEmployeeCsvRow): { [field: string]: string[] } => {
    const errors: { [field: string]: string[] } = {};
    try {
        employeeSchemaValidation.validateSync(row, { abortEarly: false });
    } catch (error: unknown) {
        if (ValidationError.isError(error)) {
            error.inner.forEach(err => (errors[err.path ?? ''] = err.errors));
        }
    }
    return errors;
};

const UploadPreview = ({ onReset, onSuccess, parseResult }: IUploadPreviewProps) => {
    const { businessId } = useParams<BusinessParams>();
    const [isUploading, setIsUploading] = useState(false);
    const [pendingUploads] = useState<IEmployeeDataRow[]>(
        parseResult.data.map((data, index) => {
            return {
                data,
                errors: validateRow(data),
                rowNumber: index + 1,
            };
        }) ?? []
    );
    const history = useHistory();
    const { error, data: employees, isLoading } = useEmployees(businessId, !isUploading);
    const addEmployee = useAddEmployee(businessId);
    const updateEmployee = useUpdateEmployee(businessId);
    const invalidHeaders = parseResult?.meta.fields?.filter(h => !supportedHeaders.includes(h));
    if (invalidHeaders && invalidHeaders?.length > 0) {
        return (
            <>
                <span className="fa-layers fa-fw fa-10x">
                    <FontAwesomeIcon className="text-yellow-300" icon={faCircle} />
                    <FontAwesomeIcon className="text-gray-900" icon={faDatabase} transform="shrink-8" />
                </span>
                <h3 className="text-lg">We've detected one or more unknown headers.</h3>
                <ul className="list-disc list-inside">
                    {invalidHeaders.map((h, i) => (
                        <li key={`header_${i}`}>"{h}"</li>
                    ))}
                </ul>
                <div>
                    For a list of supported headers download our{' '}
                    <a href={`${process.env.PUBLIC_URL}/employees.csv`} className="text-primary-500">
                        Employee CSV Template
                    </a>
                    .
                </div>
                <div className="flex items-center">
                    <Button color="primary" onClick={onReset}>
                        Reset
                    </Button>
                </div>
            </>
        );
    }
    if (error) return <ErrorPage />;
    if (isLoading || !employees) return <LoadingIndicator />;

    const handleUpload = async () => {
        setIsUploading(true);
        for (let index = 0; index < pendingUploads.length; index++) {
            const { data, errors, rowNumber } = pendingUploads[index];
            if (Object.keys(errors)?.length > 0) {
                continue;
            }
            const existingEmployee = findEmployee(data, employees);
            try {
                let employee = existingEmployee;
                if (employee) {
                    await updateEmployee.mutateAsync({
                        ...employee,
                        employeeId: employee.id,
                        ...mapCsvToApi(data),
                    });
                } else {
                    employee = await addEmployee.mutateAsync({
                        businessId,
                        ...mapCsvToApi(data),
                    });
                }
                onSuccess?.(employee.id);
            } catch (error) {
                console.error(`Error uploading employee row ${rowNumber}`, error);
            }
        }
        setIsUploading(false);
        history.push(`/${businessId}/Edit/Employees`);
    };

    if (pendingUploads.length === 0) {
        return (
            <>
                <h3 className="text-lg">No employees found to upload.</h3>
                <Button onClick={onReset}>Reset</Button>
            </>
        );
    }

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>#</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('firstName')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('lastName')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('dateOfBirth')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('department')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('emailAddress')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('isOwner')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('rating')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('salary')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('shares')}</TableHeaderCell>
                        <TableHeaderCell className="normal-case">{nameof('title')}</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pendingUploads.map(({ data, errors, rowNumber }) => {
                        const hasError = (field: string): boolean => errors[field]?.length > 0;
                        const getErrorMessage = (field: string): string => errors[field]?.join('&#10;');

                        return (
                            <TableRow key={`BodyRow_${rowNumber}`}>
                                <TableCell className="text-xs">{rowNumber}</TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('firstName')),
                                    })}
                                    title={getErrorMessage(nameof('firstName'))}
                                >
                                    {data.firstName}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('lastName')),
                                    })}
                                    title={getErrorMessage(nameof('lastName'))}
                                >
                                    {data.lastName}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('dateOfBirth')),
                                    })}
                                    title={getErrorMessage(nameof('dateOfBirth'))}
                                >
                                    {data.dateOfBirth}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('department')),
                                    })}
                                    title={getErrorMessage(nameof('department'))}
                                >
                                    {data.department}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('emailAddress')),
                                    })}
                                    title={getErrorMessage(nameof('emailAddress'))}
                                >
                                    {data.emailAddress}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('isOwner')),
                                    })}
                                    title={getErrorMessage(nameof('isOwner'))}
                                >
                                    {data.isOwner}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('rating')),
                                    })}
                                    title={getErrorMessage(nameof('rating'))}
                                >
                                    {data.rating}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('salary')),
                                    })}
                                    title={getErrorMessage(nameof('salary'))}
                                >
                                    {data.salary}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('shares')),
                                    })}
                                    title={getErrorMessage(nameof('shares'))}
                                >
                                    {data.shares}
                                </TableCell>
                                <TableCell
                                    className={classNames('text-xs', {
                                        'bg-danger cursor-not-allowed': hasError(nameof('title')),
                                    })}
                                    title={getErrorMessage(nameof('title'))}
                                >
                                    {data.title}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div className="w-full text-right space-x-3">
                <Button onClick={onReset}>Reset</Button>
                <Button color="primary" disabled={isUploading || pendingUploads.length === 0} onClick={handleUpload}>
                    Upload
                    {isUploading && <FontAwesomeIcon className="ml-3" icon={faCircleNotch} spin />}
                </Button>
            </div>
        </>
    );
};

const EmployeesUpload = ({ onSuccess }: IProps): JSX.Element => {
    // Need to keep a count because of how the event bubbles to child elements.
    // Technique described at https://stackoverflow.com/a/21002544
    const [dragCounter, setDragCounter] = useState(0);
    const fileDialog = useRef<HTMLInputElement>(null);
    const [isCsvLoading, setIsCsvLoading] = useState(false);
    const [parseResult, setParseResult] = useState<ParseResult<IEmployeeCsvRow> | undefined>(undefined);

    const handleDrop = (fileList: FileList | null) => {
        const files = Array.from(fileList || []);
        if (files.length !== 1) {
            return;
        }

        const file = files[0];
        if (!isCsvFileType(file)) {
            return;
        }
        setIsCsvLoading(true);
        papaParse<IEmployeeCsvRow>(file, {
            complete: results => {
                setParseResult(results);
                setIsCsvLoading(false);
            },
            header: true,
            skipEmptyLines: true,
            transformHeader: (header, _index) => header.trim(),
        });
    };
    const handleReset = () => {
        if (fileDialog.current != null) {
            fileDialog.current.value = '';
        }
        setIsCsvLoading(false);
        setParseResult(undefined);
    };

    return (
        <div className="space-y-3">
            {parseResult ? (
                <UploadPreview onReset={handleReset} onSuccess={onSuccess} parseResult={parseResult} />
            ) : (
                <>
                    {!isCsvLoading && (
                        <div
                            className={classNames(
                                'group w-full sm:w-1/2 p-8 rounded-lg border-4 border-dashed border-gray-700 flex items-center cursor-pointer hover:bg-gray-700',
                                {
                                    'bg-gray-700': dragCounter > 0,
                                }
                            )}
                            onClick={() => fileDialog.current?.click()}
                            onDragEnter={() => setDragCounter(dragCounter + 1)}
                            onDragLeave={() => {
                                setDragCounter(dragCounter - 1);
                            }}
                            onDragOver={e => e.preventDefault()}
                            onDrop={event => {
                                event.preventDefault();
                                setDragCounter(dragCounter - 1);
                                handleDrop(event.dataTransfer?.files);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faCloudUpload}
                                size="4x"
                                className={classNames('mr-5 group-hover:text-gray-400', {
                                    'text-gray-400': dragCounter > 0,
                                    'text-gray-700': dragCounter === 0,
                                })}
                            />
                            <span>
                                Drag and drop to upload an Employee CSV file, or{' '}
                                <Button color="link" size="lg" className="text-primary-500">
                                    select a file on disk
                                </Button>
                                .
                            </span>
                            <input
                                accept=".csv"
                                className="hidden"
                                onChange={e => handleDrop(e.currentTarget.files)}
                                ref={fileDialog}
                                type="file"
                            />
                        </div>
                    )}
                    {isCsvLoading && (
                        <div className="flex items-center">
                            <h3 className="text-lg">
                                Loading Employees from CSV file...
                                <FontAwesomeIcon className="ml-3" icon={faCircleNotch} spin />
                            </h3>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default EmployeesUpload;
