import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook,
    faArrowLeft,
    faBuilding,
    faKey,
    faPresentation,
    faUserTie,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link, NavLink, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useBusiness, useBusinessValuation } from '~/hooks';
import { BusinessParams } from '..';
import { ILocationState } from '../../ILocationState';
import BusinessPageHeader from '../header/BusinessPageHeader';
import BusinessInfo from './businessInfo/BusinessInfo';
import EmployeeList from './employeeList/EmployeeList';
import PortalAccess from './portalAccess/PortalAccess';
import PresentationSettings from './presentationSettings/PresentationSettings';
import PrimaryEmployee from './primaryContact/PrimaryEmployee';

export enum IEditPageType {
    Employees = 'Employees',
    Info = 'Info',
    PresentationSettings = 'PresentationSettings',
    PrimaryContact = 'PrimaryContact',
    PortalAccess = 'PortalAccess',
}

const pageDisplay: Record<IEditPageType, string> = {
    Employees: 'Employee List',
    Info: 'Business Info',
    PresentationSettings: 'Presentation Settings',
    PrimaryContact: 'Primary Contact',
    PortalAccess: 'Portal Access',
};

const pageIcon: Record<IEditPageType, IconProp> = {
    Employees: faAddressBook,
    Info: faBuilding,
    PresentationSettings: faPresentation,
    PrimaryContact: faUserTie,
    PortalAccess: faKey,
};

const pages = [
    IEditPageType.Info,
    IEditPageType.Employees,
    IEditPageType.PrimaryContact,
    IEditPageType.PresentationSettings,
    IEditPageType.PortalAccess,
];

const BusinessEdit = (): JSX.Element | null => {
    const { businessId } = useParams<BusinessParams>();
    const businessQuery = useBusiness(businessId);
    const businessValuationQuery = useBusinessValuation(businessId);
    const [businessSettingsLocationState, setBusinessSettingsLocationState] = useState<ILocationState>();
    const { state: locationState } = useLocation<ILocationState>();
    const isError = isTrueForAnyQuery('isError', businessQuery, businessValuationQuery);
    const isLoading = isTrueForAnyQuery('isLoading', businessQuery, businessValuationQuery);

    useEffect(() => {
        if (locationState) {
            setBusinessSettingsLocationState(locationState);
        }
    }, [locationState]);

    if (isLoading) return <LoadingIndicator />;
    if (isError || !businessQuery.data || !businessValuationQuery.data) return <ErrorPage />;

    const { data: business } = businessQuery;
    const { data: businessValuation } = businessValuationQuery;

    return (
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <BusinessPageHeader business={business} />
            <main className="flex h-full max-h-full overflow-y-auto scrollbar-themed focus:outline-none" tabIndex={0}>
                <div className="max-w-7xl w-full mx-auto sm:px-6 lg:px-8">
                    <div className="mb-12 pt-10 pb-6 bg-gray-900 border-b border-gray-700">
                        <Link
                            className="text-sm text-primary hover:text-primary-300"
                            to={businessSettingsLocationState?.backUrl ?? '/'}
                        >
                            <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                            Back {businessSettingsLocationState?.label && `to ${businessSettingsLocationState.label}`}
                        </Link>
                        <h2 className="text-2xl leading-none self-center mt-2">{business.name} Settings</h2>
                    </div>
                    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                            <nav className="space-y-1">
                                {pages.map(p => (
                                    <NavLink
                                        key={p}
                                        activeClassName="bg-gray-800 text-white"
                                        className="group flex items-center px-2 py-2 rounded-md hover:bg-gray-800"
                                        to={`/${businessId}/Edit/${p}`}
                                    >
                                        <FontAwesomeIcon fixedWidth className="mr-4" icon={pageIcon[p]} />
                                        {pageDisplay[p]}
                                    </NavLink>
                                ))}
                            </nav>
                        </aside>
                        <div className="sm:px-6 lg:px-0 sm:pb-6 lg:pb-8 lg:col-span-9">
                            <Switch>
                                <Route path={`/:businessId/Edit/${IEditPageType.Employees}`}>
                                    <EmployeeList businessId={businessId} />
                                </Route>
                                <Route path={`/:businessId/Edit/${IEditPageType.Info}`}>
                                    <BusinessInfo business={business} businessValuation={businessValuation} />
                                </Route>
                                <Route path={`/:businessId/Edit/${IEditPageType.PrimaryContact}`}>
                                    <PrimaryEmployee business={business} businessValuation={businessValuation} />
                                </Route>
                                <Route path={`/:businessId/Edit/${IEditPageType.PresentationSettings}`}>
                                    <PresentationSettings business={business} businessValuation={businessValuation} />
                                </Route>
                                <Route path={`/:businessId/Edit/${IEditPageType.PortalAccess}`}>
                                    <PortalAccess business={business} />
                                </Route>
                                <Redirect to={`/:businessId/Edit/${IEditPageType.Info}`} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default BusinessEdit;
