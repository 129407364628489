import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import useOutsideClickDetector from '~/hooks/useOutsideClickDetector';
import { defaultTransitionClassNames, defaultTransitionDuration } from '~/utils/transitions';
import { Button } from '.';

interface ISuccessNotificationContext {
    hide: () => void;
    show: () => void;
    triggered: boolean;
    withSuccessNotification: (onSave: () => Promise<void>) => () => Promise<void>;
}

export const SuccessNotificationContext = createContext<ISuccessNotificationContext>({
    hide: () => undefined,
    show: () => undefined,
    triggered: false,
    withSuccessNotification: onSave => () => onSave(),
});

const ConfirmationBar = (): JSX.Element => {
    const { hide, triggered } = useContext(SuccessNotificationContext);
    const confirmationBarRef = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        setShow(triggered);
    }, [triggered]);

    const delayedHide = () => {
        setShow(false);
        setTimeout(() => {
            hide();
        }, defaultTransitionDuration);
    };

    useOutsideClickDetector({ ref: confirmationBarRef, handler: hide });

    return (
        <Transition
            show={triggered && show}
            className={classNames(defaultTransitionClassNames, 'fixed bottom-0 inset-x-0 transition transform')}
            enterFrom="translate-y-full"
            enterTo="translate-x-0"
            leaveFrom="translate-x-0"
            leaveTo="translate-y-full"
        >
            <div
                ref={confirmationBarRef}
                className="bg-green-500 text-white flex items-center justify-center flex-wrap py-2 px-2 sm:px-6 lg:px-8 space-x-4"
            >
                <span className="text-xl">
                    <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <p>Changes saved successfully!</p>
                <Button className="text-sm" color="tertiary" onClick={delayedHide}>
                    Dismiss
                </Button>
            </div>
        </Transition>
    );
};

interface IProps {
    children: React.ReactNode;
}

const SuccessNotificationWrapper = ({ children }: IProps): JSX.Element => {
    const [triggered, setTriggered] = useState<boolean>(false);

    const withSuccessNotification = (onSave: () => Promise<void>) => () => onSave().then(() => setTriggered(true));

    return (
        <SuccessNotificationContext.Provider
            value={{
                hide: () => setTriggered(false),
                show: () => setTriggered(true),
                triggered,
                withSuccessNotification,
            }}
        >
            {children}
            <ConfirmationBar />
        </SuccessNotificationContext.Provider>
    );
};

export default SuccessNotificationWrapper;
