import { IBusiness } from '@api';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { Avatar, NavBar } from '~/components';

interface IProps {
    business: IBusiness;
}

const DelegateUserPageHeader = ({ business }: IProps): JSX.Element => {
    return (
        <NavBar>
            <div className="flex justify-center w-full">
                <div className="flex items-center flex-grow-1">
                    <Avatar src={business.avatar || ''} rounded="none" placeholderIcon={faBuilding} />
                    <div className="ml-2">{business.name}</div>
                </div>
            </div>
        </NavBar>
    );
};

export default DelegateUserPageHeader;
