import {
    IProblemDetails,
    ITermsOfServiceAgreementRequest,
    ITermsOfServiceAgreementStatus,
    ITermsOfServiceAgreementUpdate,
    TermsOfServiceAgreementsApi,
} from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getCurrentUserQueryKey } from '.';

const termsOfServiceAgreementsApi = new TermsOfServiceAgreementsApi(undefined, config.api.baseUrl);

const getTosQueryKey = (): QueryKey => getCurrentUserQueryKey('tos');

export const useTermsOfServiceAgreementStatus = (): UseQueryResult<ITermsOfServiceAgreementStatus, IProblemDetails> =>
    useQuery(getTosQueryKey(), () =>
        termsOfServiceAgreementsApi.getTermsOfServiceAgreementStatus().then(response => response.data)
    );

export const useAgreeToTermsOfService = (): UseMutationResult<
    ITermsOfServiceAgreementUpdate,
    IProblemDetails,
    ITermsOfServiceAgreementRequest
> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<ITermsOfServiceAgreementUpdate, IProblemDetails, ITermsOfServiceAgreementRequest>(
        request =>
            termsOfServiceAgreementsApi
                .agreeToTermsOfServiceAgreement(undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getTosQueryKey());
            },
        }
    );

    return mutation;
};
