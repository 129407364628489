import { ITodo } from '@api';
import { useDeleteTodo, useUpdateTodo } from '~/hooks';
import TodoForm, { ITodoFormData, mapFormDataToApi } from './TodoForm';

export interface IProps {
    businessId: string;
    isOpen: boolean;
    onClose: () => void;
    todo: ITodo;
}

const EditTodoForm = ({ businessId, todo, isOpen, onClose }: IProps): JSX.Element => {
    const deleteTodo = useDeleteTodo(businessId);
    const onDelete = () => {
        return deleteTodo.mutateAsync(todo.id).then(onClose);
    };
    const updateTodo = useUpdateTodo(businessId, todo.id);
    const onSave = (formData: ITodoFormData) => {
        return updateTodo
            .mutateAsync({
                ...todo,
                ...mapFormDataToApi(formData),
                completed: false,
            })
            .then(onClose);
    };

    return (
        <>
            <TodoForm
                businessId={businessId}
                isOpen={isOpen}
                onClose={onClose}
                onDelete={onDelete}
                onSave={onSave}
                todo={todo}
                title="Edit Task"
            />
        </>
    );
};

export default EditTodoForm;
