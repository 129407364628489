import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import CurrencyTableCell from '../../CurrencyTableCell';
import PercentageTableCell from '../../PercentageTableCell';

interface IProps {
    baseValuation: number;
    years: IBonusRightAggregatePaySummaryYear[];
}

const CumulativeValueSharingEnterpriseValue = ({ baseValuation, years }: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold">
                    <div className="text-lg">Cumulative Value Sharing</div>
                    <div className="text-sm">All Values Based On Change in Enterprise Value</div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Formula Value</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`formulaValue:${y.year}`} value={y.formulaValue} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Change in Formula Value</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`deltaFormulaValue:${y.year}`} value={y.formulaValue - baseValuation} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>LTIP Redemptions as % of Increase</TableCell>
                {years.map((y, i) => (
                    <PercentageTableCell
                        key={`ltipRedemptions:${y.year}`}
                        value={
                            years.slice(0, i + 1).reduce((a, v) => a + v.ltipRedemptions, 0) /
                            (y.formulaValue - baseValuation)
                        }
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>LTIP Total Value as % of Increase</TableCell>
                {years.map(y => (
                    <PercentageTableCell
                        key={`ltipTotalValue:${y.year}`}
                        value={y.ltipTotalValue / (y.formulaValue - baseValuation)}
                    />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default CumulativeValueSharingEnterpriseValue;
