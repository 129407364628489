import {
    DocumentsApi,
    IDocument,
    IDocumentDownloadLocation,
    IDocumentFolderCreateRequest,
    IDocumentList,
    IDocumentPathSegment,
    IDocumentUpdateRequest,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const documentsApi = new DocumentsApi(undefined, config.api.baseUrl);
export const getDocumentsQueryKey = (businessId: string, ...rest: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'documents', ...(rest || []));

interface IDocumentCreateRequest {
    parentFolderId: string;
    file: File;
}
export const useAddDocument = (
    businessId: string
): UseMutationResult<IDocument, IProblemDetails, IDocumentCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IDocument, IProblemDetails, IDocumentCreateRequest>(
        ({ parentFolderId, file }) =>
            documentsApi.postDocument(businessId, parentFolderId, file).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDocuments(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useAddFolder = (
    businessId: string
): UseMutationResult<IDocument, IProblemDetails, IDocumentFolderCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IDocument, IProblemDetails, IDocumentFolderCreateRequest>(
        request => documentsApi.createFolder(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDocuments(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteDocument = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        documentId => documentsApi.deleteDocument(documentId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDocuments(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDocumentDownload = (): ((id: string) => Promise<IDocumentDownloadLocation>) => {
    const queryClient = useQueryClient();
    return (id: string) =>
        queryClient
            .fetchQuery(['documentDownload', id], () => documentsApi.downloadDocument(id), { staleTime: 0 })
            .then(response => response.data);
};

interface IUpdateDocumentParams {
    documentId: string;
    request: IDocumentUpdateRequest;
}

export const useUpdateDocument = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IUpdateDocumentParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IUpdateDocumentParams>(
        ({ documentId, request }) =>
            documentsApi.putDocument(documentId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateDocuments(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDocumentList = (
    businessId: string,
    folderId?: string
): UseQueryResult<IDocumentList, IProblemDetails> =>
    useQuery(getDocumentsQueryKey(businessId, folderId ?? 'root'), () =>
        documentsApi.getDocuments(businessId, folderId).then(response => response.data)
    );

export const useDocumentPath = (
    businessId: string,
    documentId: string
): UseQueryResult<IDocumentPathSegment[], IProblemDetails> =>
    useQuery(getDocumentsQueryKey(businessId, documentId, 'Path'), () =>
        documentsApi.getDocumentPath(documentId).then(response => response.data)
    );

function invalidateDocuments(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getDocumentsQueryKey(businessId));
}
