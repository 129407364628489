import { IDocumentPathSegment } from '@api';
import { faFolder } from '@fortawesome/pro-light-svg-icons';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useContext } from 'react';
import { Button } from '~/components';
import { documentCenterStore } from '../../DocumentCenterContext';
import { getRootFolderName } from '../../utils/rootFolder';

interface IProps {
    folderSegments: IDocumentPathSegment[];
    onChangeActiveFolder: (segmentId: string) => void;
}

const ModalBreadcrumbs = ({ folderSegments, onChangeActiveFolder }: IProps): JSX.Element => {
    const { rootFolder } = useContext(documentCenterStore);
    const immediateAncestorId = folderSegments.length > 1 ? folderSegments[folderSegments.length - 2].id : null;
    const [activeFolder] = folderSegments.slice(-1);
    const parentSegments = folderSegments.filter(s => s.id !== activeFolder.id);
    const rootFolderName = getRootFolderName(rootFolder);

    return (
        <div className="flex items-center mb-2">
            <Button
                className="mr-2"
                color="secondary"
                disabled={!immediateAncestorId}
                onClick={() => immediateAncestorId && onChangeActiveFolder(immediateAncestorId)}
                size="sm"
                title="View previous folder"
            >
                <FontAwesomeIcon icon={faCaretLeft} size="lg" aria-hidden />
            </Button>
            <Button
                className="mr-2"
                color="link"
                onClick={() => folderSegments.length > 0 && onChangeActiveFolder(folderSegments[0].id)}
                size="lg"
                title="View root folder"
            >
                <FontAwesomeIcon icon={faFolder} aria-hidden />
            </Button>
            {parentSegments.map(s => (
                <Fragment key={s.id}>
                    <Button
                        className="mr-2 text-primary"
                        color="link"
                        onClick={() => onChangeActiveFolder(s.id)}
                        size="lg"
                    >
                        {rootFolder.folderId === s.id ? rootFolderName : s.name}
                    </Button>
                    <div className="mr-2">/</div>
                </Fragment>
            ))}
            <div>{rootFolder.folderId === activeFolder.id ? rootFolderName : activeFolder.name}</div>
        </div>
    );
};

export default ModalBreadcrumbs;
