import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

interface IProps {
    className?: string;
    invisible: boolean;
    type: 'next' | 'previous';
}

const CarouselNavButton = ({ className, invisible, type }: IProps): JSX.Element => (
    <div className="border-t border-gray-700">
        <div
            className={classnames(
                'p-4 font-medium text-sm cursor-pointer border-t-2 border-transparent hover:text-gray-300 hover:border-gray-300',
                { invisible: invisible },
                className
            )}
            style={{ marginTop: '-2px' }}
        >
            {type === 'next' ? (
                <>
                    Next
                    <FontAwesomeIcon className="ml-4" icon={faArrowRight} />
                </>
            ) : (
                <>
                    <FontAwesomeIcon className="mr-4" icon={faArrowLeft} />
                    Previous
                </>
            )}
        </div>
    </div>
);

export { CarouselNavButton };
