import { IAdvisor, IUserEntitlementType } from '@api';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { Button, Can, ErrorModal } from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useResetPasswordAdvisor } from '~/hooks';

interface IProps {
    advisor: IAdvisor;
}

const PortalAccess = ({ advisor }: IProps): JSX.Element => {
    const { withSuccessNotification } = useContext(SuccessNotificationContext);
    const resetPasswordAdvisor = useResetPasswordAdvisor();
    const [showErrorModal, setShowErrorModal] = useState(false);

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Portal Access</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-gray-300">
                Manage access to the Business Portal.
            </p>
            <Can hasEntitlement={IUserEntitlementType.AdvisorResetPassword}>
                <Button
                    color="secondary"
                    disabled={resetPasswordAdvisor.isLoading}
                    onClick={withSuccessNotification(() => resetPasswordAdvisor.mutateAsync(advisor.id))}
                    size="md"
                >
                    <FontAwesomeIcon className="mr-2" icon={faPaperPlane} />
                    Reset Password
                </Button>
            </Can>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
        </>
    );
};

export default PortalAccess;
