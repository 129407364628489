interface IProps {
    answers: Record<string, number>;
}

const scoreDescription = (score: number): string => {
    if (score >= 100) return 'Your compensation plan is on solid footing and likely needs only minor adjustments.';
    if (score >= 80)
        return 'You have some gaps and/or weaknesses in your compensation plan that need attention or they could become problematic.';
    if (score >= 60)
        return 'You have some significant problems and/or weaknesses in your compensation plan that need urgent attention.';
    return 'You are likely in need of an overhaul of most parts of your pay offering.';
};

const ResultSummary = ({ answers }: IProps): JSX.Element => {
    const totalScore = Object.values(answers).reduce((a, v): number => a + v, 0);
    const maximumScore = Object.keys(answers).length * 10;

    return (
        <div>
            <p className="mb-2 sm:mb-5 text-xl">Thank You for Completing your Assessment</p>
            <p className="mb-2 sm:mb-3">Your Total Score</p>
            <div className="flex items-baseline mb-2 sm:mb-4">
                <div className="text-4xl sm:text-7xl">{totalScore}</div>
                <div className="flex-1 text-left text-xl">
                    <div>&nbsp;/ {maximumScore}</div>
                </div>
            </div>
            <p>{scoreDescription(totalScore)}</p>
        </div>
    );
};

export default ResultSummary;
