import { IExternalTeamMember, IExternalTeamMemberCreateRequest, IExternalTeamMemberUpdateRequest } from '@api';
import { useExternalTeamMemberAvatar, useUpdateExternalTeamMember } from '~/hooks';
import ExternalTeamMemberForm from './ExternalTeamMemberForm';

export interface IProps {
    businessId: string;
    isOpen: boolean;
    onClose: () => void;
    teamMember?: IExternalTeamMember;
}

const UpdateExternalTeamMemberForm = ({ businessId, teamMember, isOpen, onClose }: IProps): JSX.Element => {
    const updateTeamMember = useUpdateExternalTeamMember(businessId, teamMember?.id ?? '');
    const teamMemberAvatar = useExternalTeamMemberAvatar(businessId);
    const onSave = async (
        request: IExternalTeamMemberCreateRequest | IExternalTeamMemberUpdateRequest,
        avatar?: File
    ) => {
        await updateTeamMember.mutateAsync({
            ...(teamMember ?? {}),
            ...request,
        });
        if (avatar) {
            await teamMemberAvatar.mutateAsync({
                avatar,
                teamMemberId: teamMember?.id ?? '',
            });
        }
        onClose();
    };

    return (
        <ExternalTeamMemberForm
            isOpen={isOpen}
            onClose={onClose}
            onSave={onSave}
            teamMember={teamMember}
            title="Edit Team Member"
        />
    );
};

export default UpdateExternalTeamMemberForm;
