import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { useCallback, useContext } from 'react';
import { MagicLinkContext } from '~/auth/MagicLinkWrapper';
import { useMagicLink } from './magicLink';

type AuthContextInterface = Pick<
    Auth0ContextInterface<User>,
    'error' | 'getAccessTokenSilently' | 'isAuthenticated' | 'isLoading' | 'loginWithRedirect' | 'logout'
>;

export const useAuth = (): AuthContextInterface => {
    const { authenticated, magicLinkToken, setMagicLinkToken, setAccessToken, setAuthenticated } =
        useContext(MagicLinkContext);
    const auth0 = useAuth0();
    const magicLink = useMagicLink(magicLinkToken ?? '');

    const magicLinkGetAccessTokenSilently = useCallback(async () => magicLink.data?.accessToken ?? '', [magicLink]);

    const magicLinkIsAuthenticated = authenticated;

    const magicLinkLoginWithRedirect = useCallback(async () => {
        setAuthenticated(true);
    }, [setAuthenticated]);

    const magicLinkLogout = useCallback(() => {
        setAccessToken(undefined);
        setAuthenticated(false);
        setMagicLinkToken(undefined);
    }, [setAccessToken, setAuthenticated, setMagicLinkToken]);

    const magicLinkFunctions = {
        error: magicLink.error ? new Error(magicLink.error.response?.data?.reason || '') : undefined,
        getAccessTokenSilently: magicLinkGetAccessTokenSilently,
        isAuthenticated: magicLinkIsAuthenticated,
        isLoading: magicLink.isLoading,
        loginWithRedirect: magicLinkLoginWithRedirect,
        logout: magicLinkLogout,
    };

    return magicLinkToken ? magicLinkFunctions : auth0;
};
