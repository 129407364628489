import { IBusinessTeamMember } from '@api';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    onClickDelete: (id: string) => void;
    onClickEdit: (id: string) => void;
    teamMember: IBusinessTeamMember;
}

const TeamMember = ({ onClickDelete, onClickEdit, teamMember }: IProps): JSX.Element => {
    const { avatar, emailAddress, id } = teamMember;

    return (
        <div className="flex items-center py-2 border-b border-gray-700">
            <Avatar src={avatar || undefined} />
            <div className="truncate flex-1 ml-2">
                <div className="truncate text-base">{NameFormatter.getLastNameFirst(teamMember)}</div>
                {emailAddress ? (
                    <div className="text-gray-400 truncate text-sm">{emailAddress}</div>
                ) : (
                    <div className="text-danger truncate text-sm">Email address missing. Invite will be skipped.</div>
                )}
            </div>
            {emailAddress ? (
                <Button onClick={() => onClickDelete(id)} color="link" className="p-2">
                    <FontAwesomeIcon icon={faTrash} className="text-danger" />
                </Button>
            ) : (
                <Button onClick={() => onClickEdit(id)} color="secondary">
                    <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add Email
                </Button>
            )}
        </div>
    );
};

export default TeamMember;
