import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import CurrencyTableCell from '../../CurrencyTableCell';

interface IProps {
    years: IBonusRightAggregatePaySummaryYear[];
}

const CompanyPerformance = ({ years }: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold text-lg">Company Performance</TableCell>
                {years.map(y => (
                    <TableCell key={`placeholder:${y.year}`} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Forecasted Gross Sales</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`grossSales:${y.year}`} value={y.grossSales} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Forecasted EBITDA</TableCell>
                {years.map(y => (
                    <CurrencyTableCell key={`ebitda:${y.year}`} value={y.ebitda} />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default CompanyPerformance;
