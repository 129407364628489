import { IBusiness, IEmployee } from '@api';
import { faAngleDown, faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, DropdownMenu, ErrorPage, LoadingIndicator } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { useEmployeeRatingConfig } from '~/hooks/employeeRatingConfig';
import { ILocationState } from '~/pages/ILocationState';
import { IEditPageType } from '../../edit';
import EditEmployeeForm from '../../employees/EditEmployeeForm';
import EmployeesNoData from '../../employees/EmployeesNoData';
import CustomAgePreviewModal from './CustomAgePreviewModal';
import TeamValuesTabs from './TeamValuesTabs';

interface IProps {
    business: IBusiness;
}

const getAgePreviewTitle = (previewYears: number): string => {
    if (previewYears <= 0) {
        return 'Today';
    }

    return `In ${previewYears} Years`;
};

type DateOfBirth = string | null | undefined;

const adjustDateOfBirth = (dateOfBirth: DateOfBirth, agePreview: number): DateOfBirth => {
    if (!dateOfBirth) return dateOfBirth;

    return moment(dateOfBirth).subtract(agePreview, 'years').toISOString();
};

const TeamValues = ({ business }: IProps): JSX.Element | null => {
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Team Values',
    };
    const employeeRatingConfigQuery = useEmployeeRatingConfig(business.id);
    const employeesQuery = useEmployees(business.id);
    const [agePreview, setAgePreview] = useState(0);
    const [showCustomAgeModal, setShowCustomAgeModal] = useState(false);
    const [editState, setEditState] = useState<{ isOpen: boolean; employee?: IEmployee }>({
        isOpen: false,
    });
    const isError = isTrueForAnyQuery('isError', employeeRatingConfigQuery, employeesQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeeRatingConfigQuery, employeesQuery);
    if (isLoading) return <LoadingIndicator />;
    if (isError || !employeeRatingConfigQuery.data) return <ErrorPage />;

    const { data: employees = [] } = employeesQuery;
    const employeesWithAdjustedDateOfBirth: IEmployee[] =
        employees.map(e => ({ ...e, dateOfBirth: adjustDateOfBirth(e.dateOfBirth, agePreview) })) ?? [];

    if (employees.length === 0) {
        return (
            <div className="p-10">
                <h2 className="mb-5 text-xl">Team Values</h2>
                <EmployeesNoData businessId={business.id} businessSettingsState={businessSettingsState} />
            </div>
        );
    }

    return (
        <div className="p-10">
            <div className="flex justify-between content-center items-center">
                <h2 className="text-xl">Team Values</h2>
                <div className="flex items-center">
                    <Link
                        to={{
                            pathname: `/${business.id}/Edit/${IEditPageType.Employees}`,
                            state: businessSettingsState,
                        }}
                    >
                        <Button className="mr-3">
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            Edit
                        </Button>
                    </Link>
                    <DropdownMenu>
                        <DropdownMenu.Trigger as={Button}>
                            {getAgePreviewTitle(agePreview)}&nbsp;
                            <FontAwesomeIcon icon={faAngleDown} size="lg" className="ml-2" />
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Item onClick={() => setAgePreview(0)}>{getAgePreviewTitle(0)}</DropdownMenu.Item>
                        <DropdownMenu.Item onClick={() => setAgePreview(3)}>{getAgePreviewTitle(3)}</DropdownMenu.Item>
                        <DropdownMenu.Item onClick={() => setAgePreview(5)}>{getAgePreviewTitle(5)}</DropdownMenu.Item>
                        <hr className="border-t border-gray-700 my-1" />
                        <DropdownMenu.Item onClick={() => setShowCustomAgeModal(true)}>Custom</DropdownMenu.Item>
                    </DropdownMenu>
                    <CustomAgePreviewModal
                        onClose={() => setShowCustomAgeModal(false)}
                        onSubmit={years => setAgePreview(years)}
                        open={showCustomAgeModal}
                    />
                </div>
            </div>
            <TeamValuesTabs
                business={business}
                employeeRatingConfig={employeeRatingConfigQuery.data}
                employees={employeesWithAdjustedDateOfBirth}
                setEditState={setEditState}
            />
            <EditEmployeeForm
                businessId={business.id}
                onClose={() => setEditState({ ...editState, isOpen: false })}
                onSuccess={() => undefined}
                {...editState}
            />
        </div>
    );
};

export default TeamValues;
