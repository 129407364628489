import { useBoundingClientRect } from '~/hooks';
import { lineStrokeWidth } from './constants';

interface IProps {
    ownerIds: string[];
}

const svgHeight = 50;
const horizontalLineY = 30;

const UpdatedOwnersAndLines = ({ children, ownerIds }: React.PropsWithChildren<IProps>): JSX.Element => {
    const [updateOwnersGridRect, updateOwnersGridRef] = useBoundingClientRect();
    const [containerGridRect, containerGridRef] = useBoundingClientRect();
    const containerSvgWidth = containerGridRect?.width || 0;
    const updatedOwnerLinesSvgWidth = updateOwnersGridRect?.width || 0;
    const updatedOwnerEntityCardWidth = updatedOwnerLinesSvgWidth / ownerIds.length;

    return (
        <>
            <svg className="mx-auto" height={horizontalLineY} width={containerSvgWidth}>
                <line
                    className="stroke-success-400"
                    strokeWidth={lineStrokeWidth}
                    x1={containerSvgWidth / 2}
                    y1={0}
                    x2={containerSvgWidth / 2}
                    y2={horizontalLineY}
                />
            </svg>
            <svg className="mx-auto" height={svgHeight} width={updatedOwnerLinesSvgWidth}>
                {ownerIds.map((id, i) => (
                    <line
                        key={id}
                        className="stroke-success-400"
                        strokeWidth={lineStrokeWidth}
                        x1={updatedOwnerEntityCardWidth * (i + 1) - updatedOwnerEntityCardWidth / 2}
                        y1={0}
                        x2={updatedOwnerEntityCardWidth * (i + 1) - updatedOwnerEntityCardWidth / 2}
                        y2={svgHeight}
                    />
                ))}
                <line
                    className="stroke-success-400"
                    strokeWidth={lineStrokeWidth}
                    x1={updatedOwnerEntityCardWidth / 2}
                    y1={lineStrokeWidth / 2}
                    x2={updatedOwnerLinesSvgWidth - updatedOwnerEntityCardWidth / 2}
                    y2={lineStrokeWidth / 2}
                />
            </svg>
            <div className="w-full" ref={containerGridRef}></div>
            <div className="flex justify-center mx-auto">
                <div
                    className={`grid gap-3`}
                    ref={updateOwnersGridRef}
                    style={{ gridTemplateColumns: `repeat(${ownerIds.length}, 1fr)` }}
                >
                    {children}
                </div>
            </div>
        </>
    );
};

export default UpdatedOwnersAndLines;
