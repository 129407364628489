import { IDocumentColorType } from '@api';
import { IconName } from '@fortawesome/fontawesome-common-types';

export const getFillColorFromType = (colorType?: IDocumentColorType): string => {
    const result = fillMap[colorType || IDocumentColorType.Gray];
    return result ?? fillMap[IDocumentColorType.Gray];
};

export const getTextColorFromType = (colorType?: IDocumentColorType): string => {
    const result = colorMap[colorType || IDocumentColorType.Gray];
    return result ?? colorMap[IDocumentColorType.Gray];
};

export const fillMap: Record<IDocumentColorType, string> = {
    Blue: 'fill-blue-500',
    Gray: 'fill-gray-500',
    Orange: 'fill-orange-500',
    Purple: 'fill-purple-500',
    Red: 'fill-red-500',
    Teal: 'fill-teal-500',
    Yellow: 'fill-yellow-500',
};

export const colorMap: Record<IDocumentColorType, string> = {
    Blue: 'text-blue-500',
    Gray: 'text-gray-500',
    Orange: 'text-orange-500',
    Purple: 'text-purple-500',
    Red: 'text-red-500',
    Teal: 'text-teal-500',
    Yellow: 'text-yellow-500',
};

export const getColorFromIcon = (iconName: IconName): string => {
    switch (iconName) {
        case 'calendar-alt':
        case 'envelope-open-text':
        case 'file-word':
            return 'text-blue-500';
        case 'file-archive':
        case 'file-code':
            return 'text-orange-500';
        case 'file-audio':
            return 'text-yellow-500';
        case 'file-csv':
        case 'file-excel':
            return 'text-green-500';
        case 'file-image':
        case 'file-pdf':
        case 'file-powerpoint':
        case 'file-video':
            return 'text-red-500';
        default:
            return 'text-gray-400';
    }
};
