import {
    BusinessTeamMembersApi,
    IBusinessTeamMember,
    IBusinessTeamMemberAdvisorCreateRequest,
    IBusinessTeamMemberEmployeeCreateRequest,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const teamMembersApi = new BusinessTeamMembersApi(undefined, config.api.baseUrl);

export const getBusinessTeamMembersQueryKey = (businessId: string): QueryKey =>
    getBusinessQueryKey(businessId, 'teamMembers');

export const useBusinessTeamMembers = (
    businessId: string,
    enabled = true
): UseQueryResult<IBusinessTeamMember[], IProblemDetails> =>
    useQuery(
        getBusinessTeamMembersQueryKey(businessId),
        () => teamMembersApi.getBusinessTeamMembers(businessId).then(response => response.data),
        { enabled: !!businessId && enabled }
    );

export const useAddAdvisorTeamMember = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBusinessTeamMemberAdvisorCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBusinessTeamMemberAdvisorCreateRequest>(
        request => teamMembersApi.createAdvisorTeamMember(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusinessTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useAddEmployeeTeamMember = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBusinessTeamMemberEmployeeCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBusinessTeamMemberEmployeeCreateRequest>(
        request => teamMembersApi.createEmployeeTeamMember(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusinessTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteAdvisorTeamMember = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        advisorId => teamMembersApi.deleteAdvisorTeamMember(businessId, advisorId).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusinessTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteEmployeeTeamMember = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        employeeId => teamMembersApi.deleteEmployeeTeamMember(employeeId).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusinessTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

function invalidateBusinessTeamMembers(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getBusinessTeamMembersQueryKey(businessId));
}
