import {
    ExternalTeamMembersApi,
    IAvatar,
    IExternalTeamMember,
    IExternalTeamMemberCreateRequest,
    IExternalTeamMemberUpdateRequest,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const teamMembersApi = new ExternalTeamMembersApi(undefined, config.api.baseUrl);

const getExternalTeamMembersQueryKey = (businessId: string, ...rest: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'externalTeamMembers', ...(rest || []));

export const useAddExternalTeamMember = (
    businessId: string
): UseMutationResult<IExternalTeamMember, IProblemDetails, IExternalTeamMemberCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IExternalTeamMember, IProblemDetails, IExternalTeamMemberCreateRequest>(
        request => teamMembersApi.postExternalTeamMember(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteExternalTeamMember = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        teamMemberId =>
            teamMembersApi.deleteExternalTeamMember(teamMemberId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                invalidateTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useExternalTeamMembers = (
    businessId: string,
    enabled = true
): UseQueryResult<IExternalTeamMember[], IProblemDetails> =>
    useQuery(
        getExternalTeamMembersQueryKey(businessId),
        () => teamMembersApi.getExternalTeamMembersForBusiness(businessId).then(response => response.data),
        { enabled: !!businessId && enabled }
    );

export const useUpdateExternalTeamMember = (
    businessId: string,
    teamMemberId: string
): UseMutationResult<void, IProblemDetails, IExternalTeamMemberUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IExternalTeamMemberUpdateRequest>(
        request =>
            teamMembersApi.putExternalTeamMember(teamMemberId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

interface IExternalTeamMemberAvatarParams {
    avatar: File;
    teamMemberId: string;
}

export const useExternalTeamMemberAvatar = (
    businessId: string
): UseMutationResult<IAvatar, IProblemDetails, IExternalTeamMemberAvatarParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAvatar, IProblemDetails, IExternalTeamMemberAvatarParams>(
        ({ avatar, teamMemberId }: IExternalTeamMemberAvatarParams) =>
            teamMembersApi.setTeamMemberAvatar(teamMemberId, avatar).then(response => response.data),
        {
            onSuccess: () => {
                invalidateTeamMembers(queryClient, businessId);
            },
        }
    );

    return mutation;
};

function invalidateTeamMembers(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getExternalTeamMembersQueryKey(businessId));
}
