import { IInnerZoneEmployeeUser } from '@api';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Avatar, Button, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import { useInnerZoneSurveyBusinessInvitation, useInnerZoneSurveyEmployeeInvitation } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import NameFormatter from '~/utils/nameFormatter';

interface ISurveyStatusTableProps {
    users: IInnerZoneEmployeeUser[];
}

interface ITableCellProps {
    user: IInnerZoneEmployeeUser;
}

const AvatarTableCell = ({ user }: ITableCellProps) => {
    return (
        <TableCell>
            <div className="flex items-center">
                <Avatar className="mr-2" src={user.avatar || undefined} />
                <div className="truncate">{NameFormatter.getName(user)}</div>
            </div>
        </TableCell>
    );
};

export const SurveyStatusNewTable = ({ users }: ISurveyStatusTableProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const sendBusinessInvitation = useInnerZoneSurveyBusinessInvitation();
    const sendEmployeeInvitation = useInnerZoneSurveyEmployeeInvitation(businessId);

    if (users.length === 0) {
        return <div className="pt-6">No new employees to send a survey.</div>;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap">
                        <Button
                            color="primary"
                            disabled={sendBusinessInvitation.isLoading}
                            onClick={() => sendBusinessInvitation.mutate({ businessId, resendOnly: false })}
                            size="sm"
                        >
                            Send All
                        </Button>
                    </TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map(u => (
                    <TableRow key={`employee_${u.employeeId}`}>
                        <AvatarTableCell user={u} />
                        <TableCell className="text-right">
                            {u.emailAddress ? (
                                <Button
                                    color="secondary"
                                    disabled={sendEmployeeInvitation.isLoading}
                                    onClick={() => sendEmployeeInvitation.mutate({ employeeId: u.employeeId })}
                                    size="sm"
                                >
                                    Send Survey
                                </Button>
                            ) : (
                                <em>Missing Email</em>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const SurveyDateTableCell = ({ user }: ITableCellProps) => {
    return <TableCell className="text-right">{moment(user.surveyDate).format('l')}</TableCell>;
};

export const SurveyStatusIsPendingTable = ({ users }: ISurveyStatusTableProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const sendBusinessInvitation = useInnerZoneSurveyBusinessInvitation();
    const sendEmployeeInvitation = useInnerZoneSurveyEmployeeInvitation(businessId);

    if (users.length === 0) {
        return <div className="pt-6">No employees have the survey in progress yet.</div>;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap">Sent</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap pl-0">
                        <Button
                            color="primary"
                            disabled={sendBusinessInvitation.isLoading}
                            onClick={() => sendBusinessInvitation.mutate({ businessId, resendOnly: true })}
                            size="sm"
                        >
                            Resend All
                        </Button>
                    </TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map(u => (
                    <TableRow key={`employee_${u.employeeId}`}>
                        <AvatarTableCell user={u} />
                        <SurveyDateTableCell user={u} />
                        <TableCell className="text-right pl-0">
                            <Button
                                className="ml-3"
                                color="secondary"
                                disabled={sendEmployeeInvitation.isLoading}
                                onClick={() => sendEmployeeInvitation.mutate({ employeeId: u.employeeId })}
                                size="sm"
                            >
                                Resend
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export const SurveyStatusCompletedTable = ({ users }: ISurveyStatusTableProps): JSX.Element => {
    if (users.length === 0) {
        return <div className="pt-6">No employees have the survey completed yet.</div>;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell className="text-right whitespace-nowrap">Completed</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {users.map(u => (
                    <TableRow key={`employee_${u.employeeId}`}>
                        <AvatarTableCell user={u} />
                        <SurveyDateTableCell user={u} />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
