import { IDocument, IDocumentUpdateRequest } from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, IModalProps, Modal, TextInput } from '~/components';
import { useUpdateDocument } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { getNameParts } from './getNameParts';

interface IProps extends Pick<IModalProps, 'open'> {
    item: IDocument;
    onClose: () => void;
}

type IDocumentUpdateForm = Pick<IDocumentUpdateRequest, 'name'>;

const RenameItemModal = ({ item, onClose, open }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const updateDocument = useUpdateDocument(businessId);
    const itemNameParts = getNameParts(item.name);
    const [formData, setFormData] = useState<IDocumentUpdateForm>({ name: itemNameParts.name });
    const [saveFailed, setSaveFailed] = useState(false);

    const handleOnSubmit = async () => {
        if (!formData.name) {
            return;
        }
        try {
            setSaveFailed(false);
            const newName = formData?.name?.concat(itemNameParts.extension);
            await updateDocument.mutateAsync({
                documentId: item.id,
                request: { ...item, name: newName },
            });
            onClose();
        } catch (e) {
            setSaveFailed(true);
            throw e;
        }
    };

    return (
        <Modal maxWidth="xl" open={open} setOpen={onClose} title={`Rename ${item.isFolder ? 'Folder' : 'File'}`}>
            <div className="p-6">
                <div className="my-5">
                    <TextInput
                        label="Name"
                        onChange={name => setFormData({ ...formData, name })}
                        value={formData.name}
                    />
                    {saveFailed && (
                        <p className="text-danger mt-3">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" aria-hidden />
                            {defaultErrorMessage}
                        </p>
                    )}
                </div>
                <div className="space-x-3 flex justify-end">
                    <Button color="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleOnSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default RenameItemModal;
