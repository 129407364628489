import { IEducation, IUserEntitlementType } from '@api';
import { faFileAlt, faPlusCircle, faPresentation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BackLink, Button, Can, EmptyStateIcon, ErrorModal, ErrorPage, LoadingIndicator } from '~/components';
import { useEducationForAdvisor } from '~/hooks';
import { sort } from '~/utils/educationUtils';
import AddEducationForm from './AddEducationForm';
import EditEducationForm from './EditEducationForm';
import { EducationItem } from './EducationItem';

export interface IBusinessEducationLocationState {
    backUrl: string;
}

const Education = (): JSX.Element => {
    const { data: educationList, error } = useEducationForAdvisor();
    const { push } = useHistory();
    const { state } = useLocation<IBusinessEducationLocationState>();
    const [addState, setAddState] = useState({ isOpen: false });
    const [editState, setEditState] = useState<{ isOpen: boolean; education?: IEducation }>({
        isOpen: false,
    });
    const [errorState, setErrorState] = useState<{ open: boolean; message?: string }>({
        open: false,
    });

    if (error) return <ErrorPage />;
    if (!educationList) return <LoadingIndicator />;

    return (
        <div className="py-4">
            <div className="flex justify-between mb-4 py-6 border-b border-gray-700">
                <h2 className="text-2xl self-center relative">
                    Tools Settings / Education
                    <BackLink className="-top-6" relative />
                </h2>
                <div className="self-center space-x-3">
                    <Button color="primary" onClick={() => setAddState({ isOpen: true })}>
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add New
                    </Button>
                    <Can hasEntitlement={IUserEntitlementType.BusinessPresentation} condition={!!state?.backUrl}>
                        <Button onClick={() => push(state?.backUrl)}>
                            <FontAwesomeIcon icon={faPresentation} className="mr-2" />
                            Present
                        </Button>
                    </Can>
                </div>
            </div>
            {educationList.length ? (
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {sort(educationList).map(education => (
                        <EducationItem
                            {...education}
                            key={education.id}
                            onEdit={() => setEditState({ isOpen: true, education })}
                            onError={message => setErrorState({ open: true, message })}
                        />
                    ))}
                </div>
            ) : (
                <div className="p-3 p-md-5 flex flex-col items-center text-center">
                    <EmptyStateIcon icon={faFileAlt} />
                    <p className="my-4 text-lg">No education resources added yet.</p>
                    <Button onClick={() => setAddState({ isOpen: true })} color="primary">
                        Add Education Resource
                    </Button>
                </div>
            )}
            <ErrorModal {...errorState} setIsOpen={() => setErrorState({ ...errorState, open: false })} />
            <AddEducationForm {...addState} onClose={() => setAddState({ ...addState, isOpen: false })} />
            <EditEducationForm {...editState} onClose={() => setEditState({ ...editState, isOpen: false })} />
        </div>
    );
};

export default Education;
