import {
    EmployeeRatingConfigApi,
    IEmployeeRatingConfig,
    IEmployeeRatingConfigUpdateRequest,
    IProblemDetails,
} from '@api';
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const employeeRatingConfigApi = new EmployeeRatingConfigApi(undefined, config.api.baseUrl);

export const getEmployeeRatingConfigQueryKey = (businessId: string): QueryKey =>
    getBusinessQueryKey(businessId, 'employeeRatingConfig');

export const useEmployeeRatingConfig = (businessId: string): UseQueryResult<IEmployeeRatingConfig, IProblemDetails> =>
    useQuery(getEmployeeRatingConfigQueryKey(businessId), () =>
        employeeRatingConfigApi.getEmployeeRatingConfig(businessId).then(response => response.data)
    );

export const useUpdateEmployeeRatingConfig = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IEmployeeRatingConfigUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IEmployeeRatingConfigUpdateRequest>(
        request =>
            employeeRatingConfigApi
                .putEmployeeRatingConfig(businessId, undefined, request)
                .then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getEmployeeRatingConfigQueryKey(businessId));
            },
        }
    );

    return mutation;
};
