import { useInnerZoneLoginStatus } from '~/hooks';
import innerZoneLogo from '../../images/innerzone-logo.png';
import InnerZoneSettingError from './InnerZoneSettingError';
import InnerZoneSettingLoginStatus from './InnerZoneSettingLoginStatus';

const InnerZoneSetting = (): JSX.Element => {
    const { data: loginStatus, error } = useInnerZoneLoginStatus();

    return (
        <>
            <img alt="InnerZone Logo" src={innerZoneLogo} />
            {error && <InnerZoneSettingError error={error} />}
            {loginStatus && <InnerZoneSettingLoginStatus loginStatus={loginStatus} />}
        </>
    );
};

export default InnerZoneSetting;
