import { IContactVisionLinkRequest, IProblemDetails, IVisionLinkContactRecord, VisionLinkContactApi } from '@api';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const visionLinkContactApi = new VisionLinkContactApi(undefined, config.api.baseUrl);

export const useVisionLinkContactRecord = (
    businessId: string
): UseQueryResult<IVisionLinkContactRecord, IProblemDetails> =>
    useQuery(getBusinessQueryKey(businessId, 'visionLinkContactStatus'), () =>
        visionLinkContactApi.getVisionLinkContactRecord(businessId).then(response => response.data)
    );

export const useContactVisionLink = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IContactVisionLinkRequest> => {
    const queryClient = useQueryClient();
    return useMutation<void, IProblemDetails, IContactVisionLinkRequest>(
        request => visionLinkContactApi.postContactVisionLink(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getBusinessQueryKey(businessId, 'visionLinkContactStatus'));
            },
        }
    );
};
