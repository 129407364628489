import { BarChart } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { useBoundingClientRect } from '~/hooks';
import { IBusinessValuationMetric, IBusinessValuationUtils } from './Overview';

const colors = [
    'emerald-500',
    'green-500',
    'teal-500',
    'cyan-500',
    'sky-500',
    'blue-500',
    'indigo-500',
    'pink-500',
    'purple-500',
];

interface IProps {
    metrics: IBusinessValuationMetric[];
    title: string;
    utils: IBusinessValuationUtils;
}

const MetricScoresCard = ({ metrics, title, utils }: IProps): JSX.Element => {
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    const chartWidth = chartContainerRect?.width || 0;
    const totalScore = metrics.reduce((total, d) => {
        total += d.score;
        return total;
    }, 0);

    return (
        <div className="flex flex-col bg-gray-800 rounded-lg px-4 py-5">
            <div className="flex justify-between w-full">
                <div className=" text-xl leading-7">{title}</div>
                <div className="text-2xl leading-8 font-bold">{totalScore}</div>
            </div>
            <div ref={chartContainerRef}>
                <BarChart
                    height={200}
                    points={
                        metrics.map<IChartPoint>((d, i) => ({
                            color: utils.colorMap[d.type] ?? colors[i],
                            name: utils.textMap[d.type] ?? d.type,
                            value: d.score,
                        })) || []
                    }
                    settings={{
                        xAxis: {
                            hideLabels: true,
                            max: 10,
                        },
                    }}
                    width={chartWidth}
                />
            </div>
        </div>
    );
};

export default MetricScoresCard;
