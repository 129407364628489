import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from './Button';
import { Modal } from './Modal';

interface IProps {
    message?: string;
    open: boolean;
    setIsOpen: (open: boolean) => void;
}

const ErrorModal = ({ message, open, setIsOpen }: IProps): JSX.Element => (
    <Modal hideHeader maxWidth="md" open={open} setOpen={setIsOpen}>
        <div className="px-4 pt-5 pb-4 sm:p-6">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-danger">
                <FontAwesomeIcon aria-hidden="true" icon={faExclamationTriangle} size="lg" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium">{message ? 'Error' : 'Unexpected Error'}</h3>
                <div className="mt-2">
                    <p className="text-sm">{message || 'Sorry, there was an unexpected error. Please try again.'}</p>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <Button color="primary" fullWidth onClick={() => setIsOpen(false)}>
                    Ok
                </Button>
            </div>
        </div>
    </Modal>
);

export { ErrorModal };
