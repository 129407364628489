import { IBusinessPageType } from '@api';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DocumentUploadDelegateApp from './documentUploadDelegate/DocumentUploadDelegateApp';

const DocumentUploadDelegateLayout = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={`/:businessId/${IBusinessPageType.DocumentCenter}`}>
                    <DocumentUploadDelegateApp />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default DocumentUploadDelegateLayout;
