import { ITodo, ITodoCreateRequest, ITodoPriorityType, ITodoUpdateRequest } from '@api';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { DateInput, FormActions, RadioGroup, SlideOver, TextArea } from '~/components';
import { useBusinessTeamMembers, usePrevious } from '~/hooks';
import { getEnumFromString } from '~/utils/enumUtils';
import { yupToFormErrors } from '~/utils/yupUtils';
import AddTeamMemberForm from '../team/AddTeamMemberForm';
import { priorityColorMap } from './priorityColorMap';
import TeamMemberSearch from './TeamMemberSearch';

interface IProps {
    businessId: string;
    todo?: ITodo;
    isOpen: boolean;
    onClose: () => void;
    onDelete?: () => Promise<void>;
    onSave: (formData: ITodoFormData) => Promise<void>;
    title: string;
}

export const mapFormDataToApi = (formData: ITodoFormData): ITodoCreateRequest | ITodoUpdateRequest => {
    return {
        ...formData,
        completed: false,
        priority: getEnumFromString(ITodoPriorityType, formData.priority),
    };
};

export interface ITodoFormData extends Pick<ITodo, 'assigneeId' | 'description' | 'dueDate'> {
    priority: string;
}

const getInitialValues = (todo?: ITodo): ITodoFormData => {
    return {
        assigneeId: todo?.assigneeId,
        description: todo?.description || '',
        dueDate: todo?.dueDate || '',
        priority: todo?.priority || ITodoPriorityType.High.toString(),
    };
};
const schemaValidation = Yup.object().shape({
    description: Yup.string().label('Task').required(),
    dueDate: Yup.string().label('Due Date').required(),
    priority: Yup.string().label('Priority').required(),
});

const TodoForm = ({ businessId, todo, isOpen, onClose, onDelete, onSave, title }: IProps): JSX.Element => {
    const [addTeamMemberIsOpen, setAddTeamMemberIsOpen] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<ITodoFormData>(getInitialValues(todo));
    const previousIsOpen = usePrevious(isOpen);
    const teamMembers = useBusinessTeamMembers(businessId, isOpen);
    const handleSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        return onSave(formData);
    };
    const resetForm = (todo: ITodo | undefined) => {
        setErrors({});
        setFormData(getInitialValues(todo));
    };

    useEffect(() => {
        if (isOpen && !previousIsOpen) {
            resetForm(todo);
        }
    }, [isOpen, previousIsOpen, todo]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onDelete={onDelete} onSave={handleSave} />}
            title={title}
        >
            <div className="col">
                <TeamMemberSearch
                    teamMembers={teamMembers.data || []}
                    onChange={assigneeId => setFormData({ ...formData, assigneeId })}
                    onClickNew={() => setAddTeamMemberIsOpen(true)}
                    value={formData.assigneeId}
                />
            </div>
            <div className="col">
                <label className="block text-sm font-medium">Priority</label>
                <RadioGroup
                    ariaLabel="Priority"
                    error={errors.priority}
                    options={[ITodoPriorityType.High, ITodoPriorityType.Medium, ITodoPriorityType.Low].map(s => ({
                        checkedColor: priorityColorMap[s],
                        label: s,
                        value: s,
                    }))}
                    value={formData.priority}
                    onChange={priority => setFormData({ ...formData, priority })}
                />
            </div>
            <div className="col">
                <DateInput
                    error={errors.dueDate}
                    label="Due Date"
                    value={formData.dueDate}
                    onChange={dueDate => setFormData({ ...formData, dueDate: dueDate ?? '' })}
                />
            </div>
            <div className="col">
                <TextArea
                    error={errors.description}
                    label="Task"
                    rows={6}
                    value={formData.description}
                    onChange={description => setFormData({ ...formData, description })}
                />
            </div>
            <AddTeamMemberForm
                businessId={businessId}
                isOpen={addTeamMemberIsOpen}
                onClose={() => setAddTeamMemberIsOpen(false)}
                onSave={assigneeId => setFormData({ ...formData, assigneeId })}
            />
        </SlideOver>
    );
};

export default TodoForm;
