import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import CurrencyTableCell from '../../CurrencyTableCell';
import PercentageTableCell from '../../PercentageTableCell';

interface IProps {
    years: IBonusRightAggregatePaySummaryYear[];
}

const cumulativeEbitda = (years: IBonusRightAggregatePaySummaryYear[], i: number) =>
    years.slice(0, i + 1).reduce((a, v) => a + v.ebitda, 0);

const cumulativeVariablePay = (years: IBonusRightAggregatePaySummaryYear[], i: number) =>
    years.slice(0, i + 1).reduce((a, v) => a + v.stips + v.ltipRedemptions, 0);

const CumulativeValueSharingEbitda = ({ years }: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold">
                    <div className="text-lg">Cumulative Value Sharing</div>
                    <div className="text-sm">All Values % EBITDA</div>
                </TableCell>
                {years.map(y => (
                    <TableCell key={`placeholder:${y.year}`} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Cumulative EBITDA</TableCell>
                {years.map((y, i) => (
                    <CurrencyTableCell key={`ebitda:${y.year}`} value={cumulativeEbitda(years, i)} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Cumulative Variable Pay</TableCell>
                {years.map((y, i) => (
                    <CurrencyTableCell key={`variablepay:${y.year}`} value={cumulativeVariablePay(years, i)} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Cumulative Variable Pay as % EBITDA</TableCell>
                {years.map((y, i) => (
                    <PercentageTableCell
                        key={`variablepayoverebitda:${y.year}`}
                        value={cumulativeVariablePay(years, i) / cumulativeEbitda(years, i)}
                    />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default CumulativeValueSharingEbitda;
