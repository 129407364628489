import React from 'react';

interface IProps {
    children: React.ReactNode;
    title: string;
}

const MetricCard = ({ children, title }: IProps): JSX.Element => (
    <div className="flex flex-col bg-gray-800 rounded-lg px-4 py-5">
        <div className="mb-1 text-sm leading-5 font-medium">{title}</div>
        <div className="text-2xl 3xl:text-3xl leading-9 font-semibold">{children}</div>
    </div>
);

export default MetricCard;
