import { IInnerZoneEmployeeLink, IInnerZoneEmployeeUser } from '@api';
import { SlideOver, SlideOverSizeType } from '~/components';
import LinkSummary from './LinkSummary';

interface IProps {
    employeeUsers: IInnerZoneEmployeeUser[];
    link?: IInnerZoneEmployeeLink;
    onClose: () => void;
}

const LinkSlideOver = ({ employeeUsers, link, onClose }: IProps): JSX.Element => (
    <SlideOver isOpen={!!link} size={SlideOverSizeType.lg} title="Communication" onClose={onClose}>
        {link && <LinkSummary employeeUsers={employeeUsers} link={link} />}
    </SlideOver>
);

export default LinkSlideOver;
