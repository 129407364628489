import { IDocument } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { SyntheticEvent, useContext } from 'react';
import { Button } from '~/components';
import { useDocumentDownload } from '~/hooks';
import { defaultErrorMessage } from '~/utils/errorUtils';
import { documentCenterStore } from './DocumentCenterContext';
import { getIconFromExtension } from './icons/iconMap';
import SelectionIcon from './icons/SelectionIcon';
import ItemDropdown from './ItemDropdown';
import { getColorFromIcon } from './utils/colorMap';

interface IProps {
    item: IDocument;
}

const ItemFileTableView = ({ item }: IProps): JSX.Element => {
    const icon = getIconFromExtension(item.name);
    const color = getColorFromIcon(icon.iconName);
    const getDocumentDownload = useDocumentDownload();
    const { openErrorModal, selectedItems, toggleSelectItem } = useContext(documentCenterStore);
    const isSelected = selectedItems.has(item.id);

    const handleDownload = async (e: SyntheticEvent) => {
        try {
            e.stopPropagation();
            const documentDownload = await getDocumentDownload(item.id);
            saveAs(documentDownload.url, item.name);
        } catch (err: unknown) {
            openErrorModal(`Failed to download ${item.name}. ${defaultErrorMessage}`);
        }
    };

    return (
        <tr
            className={classnames(
                'hover:bg-gray-700 hover:bg-opacity-100',
                { 'bg-gray-700': isSelected },
                { 'odd:bg-gray-800 odd:bg-opacity-50': !isSelected }
            )}
            onClick={() => toggleSelectItem(item)}
        >
            <td className="text-center">
                <SelectionIcon isSelected={isSelected} />
            </td>
            <td className="flex items-center py-4 px-6 max-w-screen-md">
                <Button className="flex items-center justify-center px-2" color="link" onClick={handleDownload}>
                    <FontAwesomeIcon icon={icon} fixedWidth className={`${color} text-xl`} />
                </Button>
                <Button className="text-base truncate ml-2" color="link" onClick={handleDownload} title={item.name}>
                    <span className="max-w-full truncate">{item.name}</span>
                </Button>
            </td>
            <td />
            <td className="align-middle py-2 px-6">{moment(item.hierarchyUpdatedAt).fromNow()}</td>
            <td className="text-right p-2">
                <ItemDropdown item={item} />
            </td>
        </tr>
    );
};

export default ItemFileTableView;
