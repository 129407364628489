import classNames from 'classnames';
import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { allByType } from 'react-children-by-type';

interface ITabProps {
    activeClassName: string;
    children: ReactNode;
    className?: string;
    inactiveClassName: string;
    tabId: string;
}

const Tab: React.FC<ITabProps> = (props: ITabProps) => {
    const { activeTab, setActiveTab } = useTabs();
    const isActiveTab = activeTab === props.tabId;
    return (
        <span
            onClick={() => setActiveTab(props.tabId)}
            className={classNames(
                isActiveTab ? props.activeClassName : props.inactiveClassName,
                props.className,
                'cursor-pointer'
            )}
            aria-current={isActiveTab ? 'page' : undefined}
        >
            {props.children}
        </span>
    );
};

interface ITabFlowElementProps {
    children: ReactNode;
    className?: string;
}

const TabFlowElement: React.FC<ITabFlowElementProps> = ({ children, className }: ITabFlowElementProps) => (
    <div className={className}>{children}</div>
);

export interface IPanelProps {
    children: ReactNode;
    tabId: string;
}

const Panel: React.FC<IPanelProps> = (props: IPanelProps) => {
    const { activeTab } = useTabs();
    return activeTab === props.tabId ? <>{props.children}</> : null;
};

interface ITabsContext {
    activeTab: string;
    setActiveTab: (label: string) => void;
}

interface ITabsComposition {
    Tab: React.FC<ITabProps>;
    TabFlowElement: React.FC<ITabFlowElementProps>;
    Panel: React.FC<IPanelProps>;
}

const TabsContext = React.createContext<ITabsContext | undefined>(undefined);

export interface ITabsProps {
    activeTabId: string;
    children: ReactNode;
    containerClassName?: string;
    tabContainerClassName?: string;
}

interface IDynamicTabType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tabType: React.FC<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tabFlowElementType?: React.FC<any>;
}

const Tabs: React.FC<ITabsProps & IDynamicTabType> & ITabsComposition = ({
    activeTabId,
    children,
    containerClassName = '',
    tabContainerClassName = '',
    tabFlowElementType = undefined,
    tabType,
}: ITabsProps & IDynamicTabType) => {
    const [activeTab, setActiveTab] = useState(activeTabId);
    const memoizedContextValue = useMemo(
        () => ({
            activeTab,
            setActiveTab,
        }),
        [activeTab, setActiveTab]
    );
    const tabs = allByType(children, tabType);
    const tabFlowElements = tabFlowElementType ? allByType(children, tabFlowElementType) : [];
    const panels = allByType(children, Tabs.Panel);

    return (
        <TabsContext.Provider value={memoizedContextValue}>
            <div className={containerClassName}>
                <nav aria-label="Tabs" className={tabContainerClassName}>
                    {tabs}
                    {tabFlowElements}
                </nav>
                {panels}
            </div>
        </TabsContext.Provider>
    );
};

const useTabs = (): ITabsContext => {
    const context = useContext(TabsContext);
    if (!context) {
        throw new Error('This component must be used within a <Tabs> component.');
    }
    return context;
};

Tabs.Tab = Tab;
Tabs.TabFlowElement = TabFlowElement;
Tabs.Panel = Panel;

export { Tabs };
