import {
    BonusRightEmployeeRewardsApi,
    IBonusRightAggregatePaySummary,
    IBonusRightEmployeeRewards,
    IBonusRightEmployeeValueStatement,
    IBonusRightExecutiveBenefitAnalysis,
    IBusiness,
    IEmployee,
    IProblemDetails,
} from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';
import {
    getBonusRightSampleAggregatePaySummary,
    getBonusRightSampleEmployeeRewards,
    getBonusRightSampleEmployeeValueStatement,
    getBonusRightSampleExecutiveBenefitAnalysis,
} from '~/sampleData';
import { getBusinessQueryKey } from './businesses';

const bonusRightEmployeeRewardsApi = new BonusRightEmployeeRewardsApi(undefined, config.api.baseUrl);

export const getBonusRightQueryKey = (businessId: string, ...keyPieces: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'bonusRight', ...keyPieces);

export const getBonusRightAggregatePaySummaryQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'aggregatePaySummary');

export const getBonusRightEmployeeValueStatementQueryKey = (businessId: string, employeeId: number): QueryKey =>
    getBonusRightQueryKey(businessId, `${employeeId}`, 'employeeValueStatement');

export const getBonusRightExecutiveBenefitAnalysisQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'executiveBenefitAnalysis');

export const getBonusRightTotalRewardsAssessmentQueryKey = (businessId: string): QueryKey =>
    getBonusRightQueryKey(businessId, 'totalRewardsAssessment');

export const useBonusRightAggregatePaySummary = (
    business: IBusiness,
    employees: IEmployee[]
): UseQueryResult<IBonusRightAggregatePaySummary, IProblemDetails> =>
    useQuery(getBonusRightAggregatePaySummaryQueryKey(business.id), () => {
        if (business.isSampleCase) {
            return getBonusRightSampleAggregatePaySummary(employees);
        }
        return bonusRightEmployeeRewardsApi
            .getBonusRightAggregatePaySummary(business.id)
            .then(response => response.data);
    });

export const useBonusRightEmployeeValueStatement = (
    business: IBusiness,
    bonusRightEmployeeId: number,
    employees: IEmployee[]
): UseQueryResult<IBonusRightEmployeeValueStatement, IProblemDetails> =>
    useQuery(getBonusRightEmployeeValueStatementQueryKey(business.id, bonusRightEmployeeId), () => {
        if (business.isSampleCase) {
            return getBonusRightSampleEmployeeValueStatement(employees, bonusRightEmployeeId);
        }
        return bonusRightEmployeeRewardsApi
            .getBonusRightEmployeeValueStatement(business.id, bonusRightEmployeeId)
            .then(response => response.data);
    });

export const useBonusRightExecutiveBenefitAnalysis = (
    business: IBusiness,
    employees: IEmployee[]
): UseQueryResult<IBonusRightExecutiveBenefitAnalysis, IProblemDetails> =>
    useQuery(getBonusRightExecutiveBenefitAnalysisQueryKey(business.id), () => {
        if (business.isSampleCase) {
            return getBonusRightSampleExecutiveBenefitAnalysis(employees);
        }
        return bonusRightEmployeeRewardsApi
            .getBonusRightExecutiveBenefitAnalysis(business.id)
            .then(response => response.data);
    });

export const useBonusRightTotalRewardsAssessment = (
    business: IBusiness,
    employees: IEmployee[]
): UseQueryResult<IBonusRightEmployeeRewards[], IProblemDetails> =>
    useQuery(getBonusRightTotalRewardsAssessmentQueryKey(business.id), () => {
        if (business.isSampleCase) {
            return getBonusRightSampleEmployeeRewards(employees);
        }
        return bonusRightEmployeeRewardsApi
            .getBonusRightTotalRewardsAssessment(business.id)
            .then(response => response.data);
    });
