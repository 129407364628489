import classNames from 'classnames';
import moment from 'moment';
import { ComponentPropsWithoutRef } from 'react';

interface IProps {
    error?: string;
    label: string;
    labelClassName?: string;
    onChange: (value: string | null) => void;
}

const DateInput = ({
    className,
    error,
    label,
    labelClassName,
    onChange,
    style,
    value,
    ...rest
}: IProps & Omit<ComponentPropsWithoutRef<'input'>, 'onChange'>): JSX.Element => {
    const date = moment(value as string).utc();
    const formattedValue = date.isValid() ? date.format('YYYY-MM-DD') : value;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === '') {
            onChange(null);
        } else {
            onChange(value);
        }
    };

    return (
        <div>
            <label className={classNames(labelClassName, 'block text-sm font-medium')}>{label}</label>
            <input
                autoComplete="off"
                className={classNames(
                    {
                        'border-danger-300 placeholder-danger-300 focus:ring-danger-500 focus:border-danger-500':
                            !!error,
                    },
                    { 'focus:ring-primary-500 focus:border-primary-500': !error },
                    `
                        mt-1
                        block
                        w-full
                        bg-input-bg
                        sm:text-sm
                        shadow-sm
                        rounded-md
                        focus:outline-none
                        disabled:cursor-not-allowed
                        disabled:opacity-50
                    `,
                    className
                )}
                onChange={handleChange}
                style={{
                    border: !error ? '1px solid var(--color-input-border)' : undefined,
                    ...style,
                }}
                type="date"
                value={formattedValue}
                {...rest}
            />
            {error && <p className="mt-2 text-sm text-danger-600">{error}</p>}
        </div>
    );
};

export { DateInput };
