import { createContext, useEffect, useState } from 'react';
import { useStorageState } from 'react-storage-hooks';
import { getStorageKey } from '~/utils/storageKeys';

interface IProps {
    children: React.ReactNode;
}

interface IMagicLinkContext {
    accessToken: string | undefined;
    authenticated: boolean;
    magicLinkToken: string | undefined;
    setAccessToken: (token: string | undefined) => void;
    setAuthenticated: (authenticated: boolean) => void;
    setMagicLinkToken: (token: string | undefined) => void;
}

export const MagicLinkContext = createContext<IMagicLinkContext>({
    accessToken: undefined,
    authenticated: false,
    magicLinkToken: undefined,
    setAccessToken: () => {
        /* Need a comment to trick prettier. */
    },
    setAuthenticated: () => {
        /* Need a comment to trick prettier. */
    },
    setMagicLinkToken: () => {
        /* Need a comment to trick prettier. */
    },
});

const guidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';
const STORAGE_KEY = getStorageKey('magicLinkToken');

const MagicLinkWrapper = ({ children }: IProps): JSX.Element => {
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [magicLinkToken, setMagicLinkToken] = useStorageState<string | undefined>(
        sessionStorage,
        STORAGE_KEY,
        undefined
    );

    useEffect(() => {
        if (!magicLinkToken) {
            const tokenId = window.location.search.match(`__t=(${guidRegex})`)?.[1];
            if (tokenId) {
                setMagicLinkToken(tokenId);
            }
        }
    }, [magicLinkToken, setMagicLinkToken]);

    return (
        <MagicLinkContext.Provider
            value={{
                accessToken,
                authenticated,
                magicLinkToken,
                setAccessToken,
                setAuthenticated,
                setMagicLinkToken,
            }}
        >
            {children}
        </MagicLinkContext.Provider>
    );
};

export default MagicLinkWrapper;
