import {
    IBusinessTeamMember,
    IBusinessTeamMemberType,
    IDocument,
    IDocumentPermission,
    IEmployee,
    IExternalTeamMember,
    IProblemDetails,
} from '@api';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, ErrorPage, IModalProps, LoadingIndicator, Modal } from '~/components';
import {
    isTrueForAnyQuery,
    useBusinessTeamMembers,
    useDeleteDocumentPermission,
    useDocumentPermissions,
    useEmployees,
    useShareDocumentWithEmployee,
    useShareDocumentWithExternalTeamMember,
} from '~/hooks';
import { BusinessParams } from '~/pages/business';
import EditEmployeeForm from '~/pages/business/employees/EditEmployeeForm';
import AddTeamMemberForm from '~/pages/business/header/team/AddTeamMemberForm';
import UpdateExternalTeamMemberForm from '~/pages/business/header/team/UpdateExternalTeamMemberForm';
import { defaultErrorMessage } from '~/utils/errorUtils';
import NameFormatter from '~/utils/nameFormatter';
import TeamMember from './TeamMember';
import TeamMemberSearch from './TeamMemberSearch';

enum Status {
    None,
    DidSaveSuccessful,
    Error,
    IsSaving,
}

interface IProps extends Pick<IModalProps, 'open'> {
    document: IDocument;
    onClose: () => void;
}

const SharingModal = ({ document, onClose, open }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const [addTeamMemberIsOpen, setAddTeamMemberIsOpen] = useState(false);
    const [editState, setEditState] = useState<{ isOpen: boolean; teamMember?: IBusinessTeamMember }>({
        isOpen: false,
    });
    const [status, setStatus] = useState(Status.None);
    const deletePermission = useDeleteDocumentPermission(businessId);
    const documentPermissionsQuery = useDocumentPermissions(businessId, document.id);
    const employeesQuery = useEmployees(businessId);
    const shareWithEmployee = useShareDocumentWithEmployee(businessId);
    const shareWithExternalTeamMember = useShareDocumentWithExternalTeamMember(businessId);
    const teamMembersQuery = useBusinessTeamMembers(businessId);

    const isLoading = isTrueForAnyQuery('isLoading', documentPermissionsQuery, employeesQuery, teamMembersQuery);
    const isError = isTrueForAnyQuery('isError', documentPermissionsQuery, employeesQuery, teamMembersQuery);
    const potentialMembers = getPotentialMembers(employeesQuery.data, teamMembersQuery.data);
    const currentPermissionsByPerson = getPermissionsByPerson(documentPermissionsQuery);
    const currentMembers = getCurrentMembers(documentPermissionsQuery, potentialMembers, currentPermissionsByPerson);
    const [permissionsToRemove, setPermissionsToRemove] = useState<IDocumentPermission[]>([]);
    const permissionsToRemoveDictionary = getPermissionsToRemoveByPerson(permissionsToRemove);
    const [pendingMemberIds, setPendingMembers] = useState<string[]>([]);
    const pendingMembers = potentialMembers.filter(m => pendingMemberIds.includes(m.id));
    const combinedMembers: Array<IBusinessTeamMember> = [
        ...currentMembers.filter(m => !permissionsToRemoveDictionary[m.id]),
        ...pendingMembers,
    ];

    const handleOnSubmit = async () => {
        try {
            setStatus(Status.IsSaving);
            const employees = pendingMembers.filter(e => e.type === IBusinessTeamMemberType.Employee && e.emailAddress);
            const external = pendingMembers.filter(e => e.type === IBusinessTeamMemberType.External && e.emailAddress);
            await Promise.all([
                ...employees.map(employee =>
                    shareWithEmployee.mutateAsync({
                        documentId: document.id,
                        employeeId: employee.id,
                    })
                ),
                ...external.map(teamMember =>
                    shareWithExternalTeamMember.mutateAsync({
                        documentId: document.id,
                        externalTeamMemberId: teamMember.id,
                    })
                ),
                ...permissionsToRemove.map(permission => deletePermission.mutateAsync(permission.id)),
            ]);
            onClose();
        } catch (e) {
            setStatus(Status.Error);
            throw e;
        }
    };
    const handleAdd = (personId: string) => {
        const currentPermission = currentPermissionsByPerson[personId];
        if (currentPermission) {
            setPermissionsToRemove(permissionsToRemove.filter(p => p.personId !== personId));
        } else {
            setPendingMembers([...pendingMemberIds, personId]);
        }
    };
    const handleDelete = (personId: string) => {
        const currentPermission = currentPermissionsByPerson[personId];
        if (currentPermission) {
            setPermissionsToRemove([...permissionsToRemove, currentPermission]);
        } else {
            setPendingMembers(pendingMemberIds.filter(id => id !== personId));
        }
    };

    return (
        <Modal maxWidth="xl" hideOverflow={false} open={open} setOpen={onClose} title={`Share ${document.name}`}>
            {isError && <ErrorPage />}
            {isLoading && (
                <div className="h-48 relative">
                    <LoadingIndicator />
                </div>
            )}
            {!isError && !isLoading && (
                <>
                    <div className="p-6">
                        <div className="mb-10">
                            <TeamMemberSearch
                                onChange={handleAdd}
                                onClickNew={() => setAddTeamMemberIsOpen(true)}
                                potentialMembers={potentialMembers}
                                teamMembers={combinedMembers}
                            />
                            {combinedMembers.map(teamMember => (
                                <TeamMember
                                    key={teamMember.id}
                                    teamMember={teamMember}
                                    onClickDelete={handleDelete}
                                    onClickEdit={() => {
                                        setEditState({ isOpen: true, teamMember });
                                    }}
                                />
                            ))}

                            {status === Status.Error && (
                                <p className="text-danger">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" aria-hidden />
                                    {defaultErrorMessage}
                                </p>
                            )}
                        </div>
                        <div className="space-x-3 flex justify-end">
                            <Button color="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button color="primary" disabled={status === Status.IsSaving} onClick={handleOnSubmit}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <AddTeamMemberForm
                        businessId={businessId}
                        isOpen={addTeamMemberIsOpen}
                        onClose={() => setAddTeamMemberIsOpen(false)}
                        onSave={handleAdd}
                    />
                    <UpdateExternalTeamMemberForm
                        businessId={businessId}
                        onClose={() => setEditState({ ...editState, isOpen: false })}
                        isOpen={editState.isOpen && editState.teamMember?.type === IBusinessTeamMemberType.External}
                        teamMember={editState.teamMember as IExternalTeamMember}
                    />
                    <EditEmployeeForm
                        businessId={businessId}
                        onClose={() => setEditState({ ...editState, isOpen: false })}
                        isOpen={editState.isOpen && editState.teamMember?.type === IBusinessTeamMemberType.Employee}
                        employee={editState.teamMember as IEmployee}
                        onSuccess={() => setEditState({ ...editState, isOpen: false })}
                    />
                </>
            )}
        </Modal>
    );
};

function getPermissionsToRemoveByPerson(permissionsToRemove: IDocumentPermission[]) {
    return permissionsToRemove.reduce<Record<string, IDocumentPermission>>(
        (dict, p) => ({ ...dict, [p.personId]: p }),
        {}
    );
}

function getCurrentMembers(
    documentPermissions: UseQueryResult<IDocumentPermission[], IProblemDetails>,
    potentialMembers: IBusinessTeamMember[],
    currentPermissionsByPerson: Record<string, IDocumentPermission>
) {
    return !documentPermissions.data ? [] : potentialMembers.filter(m => !!currentPermissionsByPerson[m.id]);
}

function getPermissionsByPerson(documentPermissions: UseQueryResult<IDocumentPermission[], IProblemDetails>) {
    return (documentPermissions.data ?? []).reduce<Record<string, IDocumentPermission>>(
        (dict, p) => ({ ...dict, [p.personId]: p }),
        {}
    );
}

function getPotentialMembers(employees?: IEmployee[], teamMembers?: IBusinessTeamMember[]): IBusinessTeamMember[] {
    return [
        ...(employees || []).map(e => ({ ...e, type: IBusinessTeamMemberType.Employee })),
        ...(teamMembers || []).map(a => ({ ...a, type: IBusinessTeamMemberType.External })),
    ].sort((a, b) => NameFormatter.getLastNameFirst(a).localeCompare(NameFormatter.getLastNameFirst(b)));
}

export default SharingModal;
