import visionLinkLogo from '../../VisionLinkLogo.png';

const AssessmentInstructions = (): JSX.Element => {
    return (
        <>
            <div>
                <img alt="Vision Link Logo" src={visionLinkLogo} style={{ height: '40px', width: 'auto' }} />
            </div>
            <div>
                <p className="text-sm sm:text-base">
                    Rate each statement on a scale of 1 to 10 based on how well it reflects your business currently. A
                    rating of 10 would suggest the statement describes your company perfectly whereas a rating of 1
                    would indicate it isn’t even close.
                </p>
            </div>
            <div />
        </>
    );
};

export default AssessmentInstructions;
