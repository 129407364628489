import { IEducation } from '@api';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    EmptyStateIcon,
    ErrorPage,
    FormActions,
    LoadingIndicator,
    SlideOver,
    SlideOverSizeType,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useBusinessEducation, useCurrentUser, useEducationForAdvisor, useUpdateBusinessEducation } from '~/hooks';
import { sort } from '~/utils/educationUtils';
import { businessPageDisplay } from '~/utils/enumUtils';
import { IPageSettingsSlideOver } from '.';

const getInitialValues = (advisorEducation: IEducation[] = [], businessEducation: IEducation[] = []) => {
    return advisorEducation.reduce((result, educationResource) => {
        result[educationResource.id] = businessEducation.some(b => b.id === educationResource.id);
        return result;
    }, {} as Record<string, boolean>);
};

const EducationSettings = ({ business, isOpen, onClose }: IPageSettingsSlideOver): JSX.Element => {
    const { withSuccessNotification } = useContext(SuccessNotificationContext);
    const {
        data: advisorEducation,
        isError: advisorEducationError,
        isLoading: advisorEducationLoading,
    } = useEducationForAdvisor();
    const {
        data: businessEducation,
        isError: businessEducationError,
        isLoading: businessEducationLoading,
    } = useBusinessEducation(business.id);
    const initialChecked = getInitialValues(advisorEducation, businessEducation);
    const [checked, setChecked] = useState(initialChecked);
    const { data: currentUser } = useCurrentUser();
    const updateBusinessEducation = useUpdateBusinessEducation(business.id);
    let content = null;

    useEffect(() => {
        if (advisorEducation) {
            setChecked(getInitialValues(advisorEducation, businessEducation));
        }
    }, [advisorEducation, businessEducation]);

    const handleSave = () =>
        updateBusinessEducation
            .mutateAsync({
                educationResourceIds: advisorEducation?.filter(e => checked[e.id]).map(e => e.id) ?? [],
            })
            .then(onClose);

    if (business.primaryAdvisorId !== currentUser?.id) {
        content = <ErrorPage hideActions title="You must be the primary advisor to edit these settings." />;
    } else if (advisorEducationError || businessEducationError) {
        content = <ErrorPage />;
    } else if (advisorEducationLoading || businessEducationLoading) {
        content = <LoadingIndicator />;
    } else if (!advisorEducation?.length) {
        content = (
            <div className="p-3 p-md-5 flex flex-col items-center text-center">
                <EmptyStateIcon icon={faFileAlt} />
                <p className="my-4 text-lg">No education resources added yet.</p>
            </div>
        );
    } else {
        content = (
            <div className="grid grid-cols-1 gap-4">
                <p>Please check which resources should be displayed for this business.</p>
                <div>
                    <Button
                        color="link"
                        className="uppercase"
                        onClick={() => {
                            const allEnabled = Object.values(checked).every(c => c);
                            const listToEnable = allEnabled ? [] : advisorEducation;
                            const newChecked = getInitialValues(advisorEducation, listToEnable);
                            setChecked(newChecked);
                        }}
                    >
                        All On / All Off
                    </Button>
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell className="w-9"></TableHeaderCell>
                            <TableHeaderCell>Title</TableHeaderCell>
                            <TableHeaderCell>Author</TableHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sort(advisorEducation ?? []).map((education: IEducation) => (
                            <TableRow
                                className="cursor-pointer group hover:bg-gray-700"
                                onClick={() => setChecked({ ...checked, [education.id]: !checked[education.id] })}
                            >
                                <td>
                                    <Checkbox
                                        checked={checked[education.id]}
                                        className="flex justify-center"
                                        label="Enabled"
                                        labelClassName="sr-only"
                                        name="Enabled"
                                        onChange={(e: React.SyntheticEvent) => {
                                            e.stopPropagation();
                                            setChecked({ ...checked, [education.id]: !checked[education.id] });
                                        }}
                                    />
                                </td>
                                <TableCell className="max-w-xs truncate group-hover:bg-gray-700">
                                    {education.title || education.fileName}
                                </TableCell>
                                <TableCell className="max-w-xs truncate group-hover:bg-gray-700">
                                    {education.author}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }

    return (
        <SlideOver
            size={SlideOverSizeType['3xl']}
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onSave={withSuccessNotification(handleSave)} />}
            title={businessPageDisplay.Education}
        >
            {content}
        </SlideOver>
    );
};

export default EducationSettings;
