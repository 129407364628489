import {
    IBusiness,
    IBusinessValuation,
    IRecapitalization,
    IRecapitalizationEmployee,
    IRecapitalizationUpdateRequest,
} from '@api';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import * as Yup from 'yup';
import {
    Avatar,
    EntitySelect,
    ErrorPage,
    FormActions,
    LoadingIndicator,
    PercentageInput,
    SlideOver,
    SlideOverSizeType,
} from '~/components';
import BusinessValuationNumber from '~/components/BusinessValuationNumber';
import { useEmployees } from '~/hooks';
import { getEmployeesById, getEquityPercentage } from '~/utils/businessUtils';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';
import { employeeToOwnerFormItem, getAdjustedEquityForRecapitalization, IOwner, IOwnerFormItem } from './models';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    isOpen: boolean;
    owners: IOwner[];
    onClose: () => void;
    onSave: (request: IRecapitalizationUpdateRequest) => void;
    recapitalization?: IRecapitalization;
}

interface RecapitalizationFormData {
    employees: IOwnerFormItem[];
}

const schemaValidation = Yup.object().shape({
    employees: Yup.array(
        Yup.object().shape({
            percentage: Yup.number()
                .positive()
                .transform(transformEmptyStringToNull)
                .nullable()
                .required()
                .label('Equity'),
        })
    )
        .min(1)
        .required()
        .label('New Owners')
        .test('sum', 'Equity cannot exceed 100%', (rows = []) => {
            const total = rows.reduce((total, row) => {
                return total + (row.percentage || 0);
            }, 0);
            return total <= 100;
        }),
});

const getInitialPercentage = (employee: IRecapitalizationEmployee): string => {
    if (!employee.percentage) {
        return '';
    }
    return (employee.percentage * 100).toString();
};

const getInitialValues = (recapitalization?: IRecapitalization): RecapitalizationFormData => {
    return {
        employees: (recapitalization?.employees || []).map(e => ({
            ...e,
            percentage: getInitialPercentage(e),
        })),
    };
};

const mapFormDataToApi = (
    formData: RecapitalizationFormData,
    recapitalization?: IRecapitalization
): IRecapitalizationUpdateRequest => ({
    ...(recapitalization ?? {}),
    employees: (formData.employees || []).map(e => ({
        ...e,
        percentage: e.percentage?.length ? parseFloat(e.percentage) / 100 : 0,
    })),
});

const RecapitalizationForm = ({
    business,
    businessValuation,
    isOpen,
    owners,
    onClose,
    onSave,
    recapitalization,
}: IProps): JSX.Element => {
    const [formData, setFormData] = useState<RecapitalizationFormData>(getInitialValues(recapitalization));
    const { isLoading, isError, data: employees = [] } = useEmployees(business.id);
    const ownershipFormItems = formData.employees;
    const employeesById = getEmployeesById(employees);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const formatEquityPercentage = (owner: IOwner) => {
        const newEmployees = mapFormDataToApi(formData).employees;
        const adjustedEquity = getAdjustedEquityForRecapitalization(owner, newEmployees);
        return formatPercentage(Math.max(0, adjustedEquity));
    };
    const onAddOwner = (employeeId: string) => {
        const employee = employeesById[employeeId];
        const equityPercentage = getEquityPercentage(business, employee);

        setFormData({
            ...formData,
            employees: [...formData.employees, employeeToOwnerFormItem(employee, equityPercentage)],
        });
    };
    const onEquityPercentageInputChange = (value: string, employeeId: string) => {
        setFormData({
            ...formData,
            employees: formData.employees.map(o => {
                if (o.employeeId !== employeeId) {
                    return o;
                }

                return {
                    ...o,
                    percentage: value,
                };
            }),
        });
    };
    const onRemoveOwner = (employeeId: string) => {
        setFormData({
            ...formData,
            employees: formData.employees.filter(o => o.employeeId !== employeeId),
        });
    };
    const onSaveInternal = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        const request = mapFormDataToApi(formData);
        onSave(request);
    };

    return (
        <SlideOver
            isOpen={isOpen}
            size={SlideOverSizeType.lg}
            stickyFooter={<FormActions onCancel={onClose} onSave={onSaveInternal} />}
            title="Recapitalize"
            onClose={onClose}
        >
            {isError && <ErrorPage />}
            {isLoading && <LoadingIndicator />}
            {!isError && !isLoading && (
                <>
                    <div>
                        <div className="flex">
                            {business.avatar && <Avatar className="mr-3" size={16} src={business.avatar} />}
                            <div className="flex items-center">
                                <div className="p-2">
                                    <div className="text-xl leading-7 font-semibold">{business.name}</div>
                                    <p className="text-lg leading-6">
                                        <BusinessValuationNumber businessValuation={businessValuation} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="text-left pr-2 py-2 whitespace-nowrap border-b border-gray-600">
                                    Owner
                                </th>
                                <th className="text-right p-2 whitespace-nowrap border-b border-gray-600">Equity %</th>
                                <th className="border-b border-gray-600"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {owners.map(o => {
                                const employee = employeesById[o.employeeId];

                                return (
                                    <tr key={o.employeeId}>
                                        <td className="w-3/4 pr-2 py-2 whitespace-nowrap">
                                            <div>
                                                <Avatar className="mr-3" size={10} src={employee.avatar || undefined} />
                                                <span>{NameFormatter.getName(employee)}</span>
                                            </div>
                                        </td>
                                        <td className="p-2 text-right whitespace-nowrap">
                                            {formatEquityPercentage(o)}
                                        </td>
                                        <td></td>
                                    </tr>
                                );
                            })}
                            {ownershipFormItems.map((ownershipFormItem, index) => {
                                const employee = employeesById[ownershipFormItem.employeeId];

                                return (
                                    <tr key={ownershipFormItem.employeeId}>
                                        <td className="w-3/4 pr-2 py-2 whitespace-nowrap">
                                            <div>
                                                <Avatar className="mr-3" size={10} src={employee.avatar || undefined} />
                                                <span>{NameFormatter.getName(employee)}</span>
                                            </div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                            <PercentageInput
                                                error={errors[`employees[${index}].percentage`]}
                                                label=""
                                                value={ownershipFormItem.percentage}
                                                onChange={e =>
                                                    onEquityPercentageInputChange(e, ownershipFormItem.employeeId)
                                                }
                                            />
                                        </td>
                                        <td>
                                            {!owners.find(o => o.employeeId === ownershipFormItem.employeeId) && (
                                                <button onClick={() => onRemoveOwner(ownershipFormItem.employeeId)}>
                                                    <FontAwesomeIcon className="text-danger" icon={faTrashAlt} />
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <EntitySelect
                        error={errors.employees}
                        options={employees
                            .filter(
                                e =>
                                    !ownershipFormItems.some(o => o.employeeId === e.id) &&
                                    !owners.some(o => o.employeeId === e.id)
                            )
                            .map(e => ({
                                avatar: e.avatar || undefined,
                                label: NameFormatter.getLastNameFirst(e),
                                name: NameFormatter.getLastNameFirst(e),
                                description: e.title || undefined,
                                showAddButton: true,
                                value: e.id,
                            }))}
                        placeholder="Add Owner"
                        onEntityOptionSelected={e => onAddOwner(e.value)}
                    />
                </>
            )}
        </SlideOver>
    );
};

export default RecapitalizationForm;
