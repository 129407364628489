import { IEmployee, IEmployeeCreateRequest, IEmployeeUpdateRequest } from '@api';
import { useContext } from 'react';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useAddEmployee, useEmployeeAvatar } from '~/hooks';
import EmployeeForm from './EmployeeForm';

interface IProps {
    businessId: string;
    initialValues?: Partial<IEmployee>;
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: (id: string) => void;
}

const AddEmployeeForm = (props: IProps): JSX.Element => {
    const { businessId, onClose, onSuccess } = props;
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const addEmployee = useAddEmployee(businessId);
    const employeeAvatar = useEmployeeAvatar(businessId);
    const onSave = async (request: IEmployeeCreateRequest | IEmployeeUpdateRequest, avatar?: File) => {
        const newEmployee = await addEmployee.mutateAsync({
            ...request,
            businessId,
        });
        if (avatar) {
            await employeeAvatar.mutateAsync({
                avatar,
                employeeId: newEmployee.id,
            });
        }
        showSuccessNotification();
        onSuccess?.(newEmployee.id);
        onClose();
    };

    return <EmployeeForm onSave={onSave} title="Add Employee" employee={props.initialValues} {...props} />;
};

export default AddEmployeeForm;
