import { IBusiness, IBusinessValuation, INote, INoteType } from '@api';
import { useState } from 'react';
import { Carousel, ErrorPage, LoadingIndicator } from '~/components';
import { useNotes } from '~/hooks';
import AddNoteForm from './AddNoteForm';
import BusinessInfo from './BusinessInfo';
import EditNoteForm from './EditNoteForm';
import Notes from './Notes';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const slideNavs = ['Your Business', 'Observations', 'Objectives'];

const Observations = (props: IProps): JSX.Element => {
    const { business } = props;
    const { isError, isLoading, data: notes = [] } = useNotes(business.id);
    const [addState, setAddState] = useState<{ isOpen: boolean; type: INoteType }>({
        isOpen: false,
        type: INoteType.Observation,
    });
    const [editState, setEditState] = useState<{ isOpen: boolean; note?: INote }>({ isOpen: false });
    const addNote = (type: INoteType) => setAddState({ isOpen: true, type });
    const editNote = (note: INote) => setEditState({ isOpen: true, note });
    if (isError) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    return (
        <>
            <Carousel historyKey="Observations" slideNavs={slideNavs}>
                <BusinessInfo {...props} />
                <Notes
                    businessName={business.name}
                    notes={notes.filter(n => n.type === INoteType.Observation)}
                    noteType={INoteType.Observation}
                    onAddClick={() => addNote(INoteType.Observation)}
                    onEditClick={editNote}
                />
                <Notes
                    businessName={business.name}
                    notes={notes.filter(n => n.type === INoteType.Objective)}
                    noteType={INoteType.Objective}
                    onAddClick={() => addNote(INoteType.Objective)}
                    onEditClick={editNote}
                />
            </Carousel>
            <AddNoteForm
                businessId={business.id}
                onClose={() => setAddState({ ...addState, isOpen: false })}
                {...addState}
            />
            <EditNoteForm
                businessId={business.id}
                onClose={() => setEditState({ ...editState, isOpen: false })}
                {...editState}
            />
        </>
    );
};

export default Observations;
