import { IDocument } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import moment from 'moment';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { documentCenterStore } from './DocumentCenterContext';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';
import FolderIconGridView from './icons/FolderIconGridView';
import { getIconFromType } from './icons/iconMap';
import SelectionIcon from './icons/SelectionIcon';
import ItemDropdown from './ItemDropdown';
import { getFillColorFromType } from './utils/colorMap';
import { getFolderRoute } from './utils/routes';

interface IProps {
    item: IDocument;
}

const ItemFolderGridView = ({ item }: IProps): JSX.Element => {
    const folderColor = getFillColorFromType(item.color);
    const iconSrc = getIconFromType(item.icon);
    const { selectedItems, toggleSelectItem } = useContext(documentCenterStore);
    const isSelected = selectedItems.has(item.id);

    return (
        <div
            className={classnames(
                'h-60 w-60 hover:bg-gray-700 hover:bg-opacity-100 bg-opacity-50 rounded-lg mb-4 mr-4',
                isSelected ? 'bg-gray-700' : 'bg-gray-800'
            )}
            onClick={() => toggleSelectItem(item)}
        >
            <DragAndDropUploadHandler
                className="flex flex-col justify-between items-center rounded-lg"
                folderId={item.id}
            >
                <div className="w-full pl-3 mt-3 flex flex-row justify-between">
                    <SelectionIcon isSelected={isSelected} />
                    <ItemDropdown item={item} />
                </div>
                <div className="text-center w-full">
                    <Link
                        to={getFolderRoute(item.id)}
                        className="relative w-24 mx-auto flex flex-col justify-center items-center cursor-pointer bg-center bg-no-repeat bg-contain"
                        draggable={false}
                        onClick={e => e.stopPropagation()}
                    >
                        <FolderIconGridView color={folderColor} />
                        <div className="opacity-60 text-gray-50 pt-5 text-center absolute">
                            <FontAwesomeIcon icon={iconSrc} size="2x" />
                        </div>
                    </Link>
                    <div className="mt-3">{item.name}</div>
                </div>
                <div className="text-sm w-full px-3 mb-3 flex flex-row justify-between">
                    <div>
                        {item.fileCount} {item.fileCount === 1 ? 'File' : 'Files'}
                    </div>
                    <div>{moment(item.hierarchyUpdatedAt).fromNow()}</div>
                </div>
            </DragAndDropUploadHandler>
        </div>
    );
};

export default ItemFolderGridView;
