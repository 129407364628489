import { IBusiness, IBusinessEvaluationMetricResponse, IBusinessEvaluationMetricType } from '@api';
import { useState } from 'react';
import { VisibleSection } from '~/components/surveys/models';
import Survey from '~/components/surveys/Survey';
import AssessmentInstructions from './AssessmentInstructions';
import businessEvaluationBackground from './BusinessEvaluationBackground.png';
import BusinessFinancialForm from './BusinessFinancialForm';
import { questions } from './models';

interface IProps {
    business: IBusiness;
}

const BusinessEvaluationWizard = ({ business }: IProps): JSX.Element => {
    const [answers, setAnswers] = useState<Record<string, number>>({});
    const [visibleSection, setVisibleSection] = useState<VisibleSection>(VisibleSection.Questions);

    const metrics: IBusinessEvaluationMetricResponse[] = Object.keys(answers)
        .filter(k => k in IBusinessEvaluationMetricType)
        .map(k => ({
            score: answers[k as IBusinessEvaluationMetricType], // Can cast due to filter above.
            type: k as IBusinessEvaluationMetricType, // Can cast due to filter above.
        }));

    return (
        <div className="h-full sm:h-auto max-h-full max-w-full mx-auto my-auto space-y-5">
            <div className="flex flex-col xl:flex-row h-full sm:h-auto">
                <div className="bg-gray-800 flex flex-col justify-between xl:hidden max-w-full pt-6 px-7 rounded-t-xl space-y-4 w-128">
                    <AssessmentInstructions />
                </div>
                <div
                    className="xl:flex flex-col h-160 hidden justify-between p-12 rounded-bl-xl rounded-tl-xl w-128"
                    style={{
                        backgroundImage: `url("${businessEvaluationBackground}")`,
                        backgroundSize: 'cover',
                    }}
                >
                    <AssessmentInstructions />
                </div>
                <div className="bg-gray-800 flex flex-col flex-grow sm:h-160 justify-between max-w-full xl:px-7 pb-6 xl:pt-6 rounded-bl-xl xl:rounded-bl-none rounded-br-xl xl:rounded-tr-xl w-128">
                    <Survey
                        answers={answers}
                        questions={questions}
                        resultsElementFactory={() => (
                            <BusinessFinancialForm businessId={business.id} metrics={metrics} />
                        )}
                        setAnswers={setAnswers}
                        setVisibleSection={setVisibleSection}
                        submitHandler={async () => {
                            // Intentionally a no-op.
                            // Nothing is submitted until after they complete the BusinessFinancialForm rendered in the ResultElementFactory.
                        }}
                        surveyName="Business Evaluation"
                        visibleSection={visibleSection}
                    />
                </div>
            </div>
        </div>
    );
};

export default BusinessEvaluationWizard;
