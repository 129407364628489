import { IBusiness, IEmployee } from '@api';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from '~/components';
import AddEmployeeForm from '../../employees/AddEmployeeForm';
import { TeamValueCard } from './TeamValueCard';

interface IHeadingProps {
    employeeType: 'Owner' | 'Employee';
    onClickButton: () => void;
}

const Heading = ({ employeeType, onClickButton }: IHeadingProps): JSX.Element => (
    <div className="flex flex-row justify-between mb-3 mt-12">
        <h3 className="font-medium text-lg">{`${employeeType}s`}</h3>
        <div>
            <Button color="tertiary" onClick={onClickButton}>
                <FontAwesomeIcon className="mr-2" icon={faPlusCircle} />
                <span>{`Add ${employeeType}`}</span>
            </Button>
        </div>
    </div>
);

interface IProps {
    business: IBusiness;
    employees: IEmployee[];
    onClickEmployee: (employee: IEmployee) => void;
    renderValue: (employee: IEmployee) => React.ReactNode;
}

interface IAddState {
    isOpen: boolean;
    initialValues?: Partial<IEmployee>;
}

export const GridView = ({ business, employees, onClickEmployee, renderValue }: IProps): JSX.Element => {
    const [addEmployeeState, setAddEmployeeState] = useState<IAddState>({ isOpen: false, initialValues: undefined });
    const owners = employees.filter(e => e.isOwner);
    const otherEmployees = employees.filter(e => !e.isOwner);

    return (
        <>
            {owners.length > 0 && (
                <>
                    <Heading
                        employeeType="Owner"
                        onClickButton={() => setAddEmployeeState({ isOpen: true, initialValues: { isOwner: true } })}
                    />
                    <div className="grid grid-cols-1 gap-3 gap-y-7 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                        {owners.map(employee => (
                            <TeamValueCard
                                key={employee.id}
                                employee={employee}
                                onClick={onClickEmployee}
                                renderValue={renderValue}
                            />
                        ))}
                    </div>
                </>
            )}
            <>
                <Heading
                    employeeType="Employee"
                    onClickButton={() => setAddEmployeeState({ isOpen: true, initialValues: undefined })}
                />
                <div className="grid grid-cols-1 gap-3 gap-y-7 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                    {otherEmployees.length === 0 && <div className="text-lg">No employees added yet.</div>}
                    {otherEmployees.map(employee => (
                        <TeamValueCard
                            key={employee.id}
                            employee={employee}
                            onClick={onClickEmployee}
                            renderValue={renderValue}
                        />
                    ))}
                </div>
            </>
            <AddEmployeeForm
                businessId={business.id}
                initialValues={addEmployeeState.initialValues}
                isOpen={addEmployeeState.isOpen}
                onClose={() => setAddEmployeeState({ isOpen: false, initialValues: undefined })}
            />
        </>
    );
};
