import classNames from 'classnames';
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

type IconButtonProps = ComponentPropsWithRef<'button'>;

const IconButtonComponent = (
    { className, ...buttonProps }: IconButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
): JSX.Element => (
    <button
        className={classNames(
            className,
            'bg-transparent rounded-md p-2 text-white hover:text-gray-300 active:ring-0 focus:outline-none focus:ring-2 focus:ring-primary-500'
        )}
        ref={ref}
        type="button"
        {...buttonProps}
    />
);

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(IconButtonComponent);

export { IconButton };
export type { IconButtonProps };
