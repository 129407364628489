import { IAdvisorFirm } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';
import ProcessInfo from './ProcessInfo';

const TabId = 'EnhancingExecutiveBenefits';

const EnhancingExecutiveBenefits = (advisorFirm: IAdvisorFirm | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Enhancing Executive Benefits</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <h2 className="font-bold">Enhancing Executive Benefits</h2>
                <p>
                    Companies who want to recruit and retain top talent typically make executive benefits a part of
                    their value proposition. These are programs that address specific needs associated with higher paid
                    employees and they also close statutory gaps caused by restrictions imposed within benefit plan
                    laws. These can include benefits such as a supplemental retirement or deferred compensation plans,
                    additional life or disability insurance coverage, and more. The plans should enhance the
                    Company&apos;s value proposition designed to recruit and retain premier talent.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} offers these services directly or through its partnership with
                    The VisionLink Advisory Group. Together, we have access to multiple independent financial services
                    providers to coordinate needs analysis and adoption of appropriate solutions.
                </p>
                <ProcessInfo
                    outcome=""
                    steps={[
                        'Conduct a gap analysis of existing benefit programs and assess the lifestyle, income, and tax environment of the executive group.',
                        'Select the programs that fit.',
                        'Establish your eligible group by identifying the leadership team members who will be invited to participate.',
                        'Research and select ideal third-party providers to assist with plan implementation.',
                        'After reviewing and refining plan specifications, prepare necessary documentation and roll-out materials.',
                        'Present the new program(s) to your executive team. Promote your commitment to their long-term well-being.',
                    ]}
                />
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { EnhancingExecutiveBenefits };
