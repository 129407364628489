import { IAdvisorFirm } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';
import ProcessInfo from './ProcessInfo';

const TabId = 'AdoptingPhantomStockPlan';

const AdoptingPhantomStockPlan = (advisorFirm: IAdvisorFirm | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Adopting a Phantom Stock Plan</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <h2 className="font-bold">Adopting a Phantom Stock Plan</h2>
                <p>
                    Phantom stock is a type of long-term incentive plan (LTIP) that promises participants a future cash
                    benefit that is tied to the value of the business (or the growth in the business). It is like
                    offering stock or stock options without actual shares being exchanged (i.e., no dilution). A phantom
                    stock plan (sometimes called synthetic equity) is perfect for growth-oriented private business
                    owners seeking a tax-friendly way to reward employees and strengthen long-term engagement.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group to provide phantom
                    stock design services to our business clients. VisionLink has designed more phantom stock plans than
                    any other firm in the country. They are phantom stock experts. Since 1996, VisionLink has designed
                    hundreds of plans for businesses of all types. With its proprietary software, it can help you
                    design, implement, communicate and manage a phantom stock plan simply and inexpensively.
                </p>
                <ProcessInfo
                    outcome="A significant wealth-building opportunity for your premier talent that will align them with your view of your company's future, and motivate them to help fulfill it."
                    steps={[
                        'Select from the VisionLink Decision Tree the right type of phantom stock program (or other long-term incentive plan alternative).',
                        'Establish your eligible group by identifying the company employees who will have the greatest impact on your long-term growth objectives.',
                        'Set your "Value-Sharing Budget" (the percentage of growth in company value that might be shared under the plan).',
                        'Arrive at a basic company valuation that can be used to to form individual units of value (phantom shares).',
                        'Determine the terms under which the units can be redeemed (vesting, events, etc.) as well as the number of units for employees (staying within the value-sharing budget).',
                        'Prepare proper documentation and clear communication materials to present the plan to your employees. Promote the plan as part of your "partnership with leadership."',
                    ]}
                />
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { AdoptingPhantomStockPlan };
