import { INote } from '@api';
import { useDeleteNote, useUpdateNote } from '~/hooks';
import NoteForm, { INoteFormData } from './NoteForm';

interface IProps {
    businessId: string;
    isOpen: boolean;
    note?: INote;
    onClose: () => void;
}

const EditNoteForm = ({ businessId, isOpen, note, onClose }: IProps): JSX.Element => {
    const deleteNote = useDeleteNote(businessId);
    const onDelete = async () => {
        return note && deleteNote.mutateAsync(note.id).then(onClose);
    };
    const updateNote = useUpdateNote(businessId, note?.id ?? '');
    const onSave = (formData: INoteFormData) => {
        return updateNote
            .mutateAsync({
                ...(note ?? {}),
                ...formData,
            })
            .then(onClose);
    };

    return (
        <NoteForm
            isOpen={isOpen}
            note={note}
            onClose={onClose}
            onDelete={onDelete}
            onSave={onSave}
            title={`Edit ${note?.type}`}
        />
    );
};

export default EditNoteForm;
