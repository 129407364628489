import { LoadingIndicator } from '~/components';
import { useCurrentUser } from '~/hooks';
import { AdvisorEditInternal } from '../../advisorEdit';

const EditMe = (): JSX.Element => {
    const { data } = useCurrentUser();
    if (!data) return <LoadingIndicator />;
    return <AdvisorEditInternal advisorId={data?.id} />;
};

export default EditMe;
