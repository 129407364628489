import { IMe, IProblemDetails, UsersApi } from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';

const usersApi = new UsersApi(undefined, config.api.baseUrl);

type key = string | number;
export const getCurrentUserQueryKey = (...rest: key[]): QueryKey => ['currentUser', ...(rest || [])];

export const useCurrentUser = (): UseQueryResult<IMe, IProblemDetails> =>
    useQuery(getCurrentUserQueryKey(), () => usersApi.getMe().then(response => response.data));
