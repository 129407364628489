import { EducationApi, IEducation, IEducationDownloadLocation, IEducationUpdateRequest, IProblemDetails } from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { useCurrentUser } from './user';

const educationApi = new EducationApi(undefined, config.api.baseUrl);
export const getEducationQueryKey = (...rest: string[]): QueryKey => ['education', ...(rest || [])];

export interface IEducationCreateRequest extends IEducationUpdateRequest {
    file?: File;
}
export const useAddEducation = (): UseMutationResult<IEducation, IProblemDetails, IEducationCreateRequest> => {
    const { data: currentUser } = useCurrentUser();
    const advisorId = currentUser?.id || '';
    const queryClient = useQueryClient();
    const mutation = useMutation<IEducation, IProblemDetails, IEducationCreateRequest>(
        ({ author, description, file, title }) =>
            educationApi
                .postEducation(
                    advisorId,
                    file,
                    undefined,
                    author || undefined,
                    description || undefined,
                    title || undefined
                )
                .then(response => response.data),
        {
            onSuccess: () => {
                invalidateEducation(queryClient);
            },
        }
    );

    return mutation;
};

export const useDeleteEducation = (): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        educationId => educationApi.deleteEducation(educationId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                invalidateEducation(queryClient);
            },
        }
    );

    return mutation;
};

export const useEducationDownloadLocation = (): ((id: string) => Promise<IEducationDownloadLocation>) => {
    const queryClient = useQueryClient();
    return (id: string) =>
        queryClient
            .fetchQuery(['educationDownload', id], () => educationApi.getDownloadLocation(id), { staleTime: 0 })
            .then(response => response.data);
};

interface IUpdateEducationParams {
    educationId: string;
    request: IEducationUpdateRequest;
}

export const useUpdateEducation = (): UseMutationResult<void, IProblemDetails, IUpdateEducationParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IUpdateEducationParams>(
        ({ educationId, request }) =>
            educationApi.putEducation(educationId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateEducation(queryClient);
            },
        }
    );

    return mutation;
};

export const useEducationForAdvisor = (): UseQueryResult<IEducation[], IProblemDetails> => {
    const { data: currentUser } = useCurrentUser();
    const advisorId = currentUser?.id || '';

    return useQuery(
        getEducationQueryKey(advisorId),
        () => educationApi.getEducationForAdvisor(advisorId).then(response => response.data),
        { enabled: !!advisorId }
    );
};

function invalidateEducation(queryClient: QueryClient) {
    queryClient.invalidateQueries(getEducationQueryKey());
}
