import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import PercentageTableCell from '../../PercentageTableCell';

interface IProps {
    years: IBonusRightAggregatePaySummaryYear[];
}

const AllocationSummary = ({ years }: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold">
                    <div className="text-lg">Allocation Summary</div>
                    <div className="text-sm">All Values % Total Cash Compensation</div>
                </TableCell>
                {years.map(y => (
                    <TableCell key={`placeholder:${y.year}`} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Salaries</TableCell>
                {years.map(y => (
                    <PercentageTableCell
                        key={`salaries:${y.year}`}
                        value={y.salaries / (y.salaries + y.stips + y.ltipRedemptions)}
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>STIP</TableCell>
                {years.map(y => (
                    <PercentageTableCell
                        key={`stip:${y.year}`}
                        value={y.stips / (y.salaries + y.stips + y.ltipRedemptions)}
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>LTIP Redemptions</TableCell>
                {years.map(y => (
                    <PercentageTableCell
                        key={`ltipRedemptions:${y.year}`}
                        value={y.ltipRedemptions / (y.salaries + y.stips + y.ltipRedemptions)}
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Total Variable Compensation</TableCell>
                {years.map(y => (
                    <PercentageTableCell
                        key={`totalVariableCompensation:${y.year}`}
                        value={(y.stips + y.ltipRedemptions) / (y.salaries + y.stips + y.ltipRedemptions)}
                    />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default AllocationSummary;
