import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

interface IProps {
    error?: string;
    label: string;
    onChange: (value: string) => void;
}

const TextArea = ({
    className,
    error,
    label,
    onChange,
    style,
    ...rest
}: IProps & Omit<ComponentPropsWithoutRef<'textarea'>, 'onChange'>): JSX.Element => {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value);
    return (
        <>
            <label className="block text-sm font-medium">{label}</label>
            <textarea
                autoComplete="off"
                className={classNames(
                    {
                        'border-danger-300 placeholder-danger-300 focus:ring-danger-500 focus:border-danger-500':
                            !!error,
                    },
                    { 'focus:ring-primary-500 focus:border-primary-500': !error },
                    `
                        mt-1
                        block
                        w-full
                        bg-input-bg
                        sm:text-sm
                        shadow-sm
                        rounded-md
                        focus:outline-none,
                        disabled:cursor-not-allowed
                        disabled:opacity-50
                    `,
                    className
                )}
                onChange={handleChange}
                style={{
                    border: !error ? '1px solid var(--color-input-border)' : undefined,
                    ...style,
                }}
                {...rest}
            />
            {error && <p className="mt-2 text-sm text-danger-600">{error}</p>}
        </>
    );
};

export { TextArea };
