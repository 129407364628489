import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { ComponentPropsWithoutRef, Fragment, useEffect, useRef, useState } from 'react';
import { Button, ErrorModal, IconButton } from '~/components';
import BusinessInfoForm from '../../business/edit/businessInfo/BusinessInfoForm';
import PrimaryEmployeeForm from '../../business/edit/primaryContact/PrimaryEmployeeForm';
import {
    useSaveBusiness,
    useSaveEmployeeTask,
    useSavePresentationSettings,
    useSavePrimaryContact,
    validateBusiness,
    validateEmployeeTask,
    validatePrimaryContact,
} from './actions';
import EmployeeInputTaskForm from './EmployeeInputTaskForm';
import PresentationSettingsForm from './PresentationSettingsForm';
import { useWizardState } from './useWizardState';
import VerticalNav, { IVerticalNavStep, VerticalNavStepStatus } from './VerticalNav';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const getStepStatus = (activeStepIndex: number, stepIndex: number): VerticalNavStepStatus => {
    if (activeStepIndex > stepIndex) {
        return VerticalNavStepStatus.Complete;
    } else if (activeStepIndex === stepIndex) {
        return VerticalNavStepStatus.Current;
    }
    return VerticalNavStepStatus.Upcoming;
};

const AddBusinessWizardModal = ({ isOpen, onClose }: IProps & ComponentPropsWithoutRef<'dialog'>): JSX.Element => {
    const closeIconButtonRef = useRef<HTMLButtonElement>(null);
    const [activeStep, setActiveStep] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const wizardState = useWizardState(isOpen);
    const onSaveBusiness = useSaveBusiness(wizardState);
    const onSaveEmployeeTask = useSaveEmployeeTask(wizardState);
    const onSavePresentationSettings = useSavePresentationSettings(wizardState);
    const onSavePrimaryContact = useSavePrimaryContact(wizardState);
    const {
        businessAvatar,
        businessFormData,
        delegateEmployeeEmail,
        employeeInputMethod,
        pageSettings,
        primaryEmployeeAvatar,
        primaryEmployeeFormData,
        setBusinessAvatar,
        setPrimaryEmployeeAvatar,
        setWizardState,
        toggleAllPageSettings,
        togglePageSetting,
        validationErrors,
    } = wizardState;

    useEffect(() => {
        if (isOpen) {
            setActiveStep(0);
        }
    }, [isOpen]);

    const steps: IVerticalNavStep[] = [
        {
            name: 'Business Info',
            description: 'Basic info such as name and size.',
            disabled: false,
            onClick: () => setActiveStep(0),
            onSave: onSaveBusiness,
            onValidate: () => validateBusiness(wizardState),
            status: getStepStatus(activeStep, 0),
        },
        {
            name: 'Primary Contact Info',
            description: 'Primary contact info such as name and email.',
            disabled: !wizardState.businessId,
            onClick: () => setActiveStep(1),
            onSave: onSavePrimaryContact,
            onValidate: () => validatePrimaryContact(wizardState),
            status: getStepStatus(activeStep, 1),
        },
        {
            name: 'Employee Info',
            description: 'Choose how to enter employee info.',
            disabled: !wizardState.businessId,
            onClick: () => setActiveStep(2),
            onSave: onSaveEmployeeTask,
            onValidate: () => validateEmployeeTask(wizardState),
            status: getStepStatus(activeStep, 2),
        },
        {
            name: 'Presentation',
            description: 'Settings for your presentation.',
            disabled: !wizardState.businessId,
            onClick: () => setActiveStep(3),
            onSave: onSavePresentationSettings,
            status: getStepStatus(activeStep, 3),
        },
    ];

    const onClickNext = async () => {
        const step = steps[activeStep];

        if (step?.onValidate) {
            if (!step.onValidate()) return;
        }
        try {
            setIsDisabled(true);
            await step?.onSave?.();
        } catch {
            setShowErrorModal(true);
            return;
        } finally {
            setIsDisabled(false);
        }
        setActiveStep(activeStep + 1);
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto scrollbar-themed"
                initialFocus={closeIconButtonRef}
                onClose={onClose}
                open={true}
                static
            >
                <div className="flex items-end justify-center min-h-screen md:pt-4 md:px-4 md:pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="flex flex-col md:inline-flex md:flex-row absolute md:static h-full md:h-auto inset-0 align-bottom bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 sm:align-middle sm:max-w-7xl w-full">
                            <div className="block absolute top-0 right-0 md:pt-4 md:pr-4">
                                <div className="h-7 flex items-center">
                                    <IconButton onClick={onClose} ref={closeIconButtonRef}>
                                        <span className="sr-only">Close</span>
                                        <FontAwesomeIcon icon={faTimes} size="lg" aria-hidden="true" />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="flex flex-col flex-grow overflow-y-auto scrollbar-themed relative">
                                <div className="mt-7 mx-6 px-7">
                                    <Dialog.Title as="h3" className="text-xl leading-6 font-medium text-white">
                                        {steps[activeStep].name}
                                    </Dialog.Title>
                                </div>
                                <div className="flex flex-col md:flex-row">
                                    <div className="flex flex-col p-6 md:flex-grow">
                                        {activeStep === 0 && (
                                            <div className="my-7 px-7">
                                                <BusinessInfoForm
                                                    avatar={businessAvatar?.url}
                                                    disabled={isDisabled}
                                                    errors={validationErrors}
                                                    formData={businessFormData}
                                                    onChange={formData =>
                                                        setWizardState({ businessFormData: formData })
                                                    }
                                                    onChangeAvatar={setBusinessAvatar}
                                                />
                                            </div>
                                        )}
                                        {activeStep === 1 && (
                                            <div className="my-7 px-7">
                                                <PrimaryEmployeeForm
                                                    avatar={primaryEmployeeAvatar?.url}
                                                    disabled={isDisabled}
                                                    errors={validationErrors}
                                                    formData={primaryEmployeeFormData}
                                                    onChange={primaryEmployeeFormData =>
                                                        setWizardState({ primaryEmployeeFormData })
                                                    }
                                                    onChangeAvatar={setPrimaryEmployeeAvatar}
                                                />
                                            </div>
                                        )}
                                        {activeStep === 2 && (
                                            <div className="my-7 px-7">
                                                <EmployeeInputTaskForm
                                                    delegateEmployeeEmail={delegateEmployeeEmail}
                                                    employeeInputMethod={employeeInputMethod}
                                                    errors={validationErrors}
                                                    onChangeEmail={delegateEmployeeEmail =>
                                                        setWizardState({ delegateEmployeeEmail })
                                                    }
                                                    onChangeInputMethod={employeeInputMethod =>
                                                        setWizardState({ employeeInputMethod })
                                                    }
                                                    onClickSend={onClickNext}
                                                />
                                            </div>
                                        )}
                                        {activeStep === 3 && (
                                            <>
                                                <button
                                                    className="hidden md:block absolute right-0 top-0 mr-6 mt-6 px-7 uppercase text-gray-400 hover:text-gray-300"
                                                    onClick={toggleAllPageSettings}
                                                >
                                                    All On / All Off
                                                </button>
                                                <PresentationSettingsForm
                                                    pageSettings={pageSettings}
                                                    onChange={togglePageSetting}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-shrink-0 md:w-96 bg-gray-800 border-l border-gray-700 py-5 pr-5 flex flex-col justify-end space-y-4">
                                <div className="flex md:my-24 md:-ml-4 md:flex-grow md:flex-col justify-center">
                                    <VerticalNav steps={steps} />
                                </div>
                                <div className="flex flex-row-reverse md:flex-col space-x-reverse space-x-4 md:space-y-4 pl-4">
                                    <Button
                                        color="primary"
                                        className="inline-flex justify-center text-sm md:w-full"
                                        onClick={onClickNext}
                                    >
                                        Next
                                    </Button>
                                    <Button
                                        className="inline-flex justify-center text-sm md:w-full"
                                        onClick={activeStep === 0 ? onClose : () => setActiveStep(activeStep - 1)}
                                    >
                                        {activeStep === 0 ? 'Cancel' : 'Back'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
                <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
            </Dialog>
        </Transition>
    );
};

export default AddBusinessWizardModal;
