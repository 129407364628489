import { faCloudDownload, faCloudUpload, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button } from '~/components';
import { useExportEmployeesForBusiness } from '~/hooks';
import Employees from '../../employees';
import AddEmployeeForm from '../../employees/AddEmployeeForm';
import EmployeesUpload from '../../employees/EmployeesUpload';

interface IProps {
    businessId: string;
}

const EmployeeList = ({ businessId }: IProps): JSX.Element => {
    const [addState, setAddState] = useState({ isOpen: false });
    const exportEmployees = useExportEmployeesForBusiness();
    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/Upload`}>
                <h3 className="text-lg leading-6 font-medium mb-4">Employee List</h3>
                <EmployeesUpload />
            </Route>
            <Route>
                <>
                    <div className="flex justify-between mb-4">
                        <h3 className="text-lg leading-6 font-medium">Employee List</h3>
                        <div className="self-center space-x-3">
                            <Button color="primary" onClick={() => setAddState({ ...addState, isOpen: true })}>
                                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                Add New
                            </Button>
                            <Link to={`${match.url}/Upload`}>
                                <Button>
                                    <FontAwesomeIcon icon={faCloudUpload} className="mr-2" />
                                    Bulk Import
                                </Button>
                            </Link>
                            <Button onClick={() => exportEmployees(businessId)}>
                                <FontAwesomeIcon icon={faCloudDownload} className="mr-2" />
                                Export CSV
                            </Button>
                        </div>
                    </div>
                    <Employees businessId={businessId} />
                    <AddEmployeeForm
                        businessId={businessId}
                        onClose={() => setAddState({ ...addState, isOpen: false })}
                        {...addState}
                    />
                </>
            </Route>
        </Switch>
    );
};

export default EmployeeList;
