export default class NameFormatter {
    static getInitials(name: string): string {
        return name.split(/\s/).reduce((a, b) => (a += b.slice(0, 1)), '');
    }

    static getLastNameFirst(person: { firstName: string; lastName: string } | null | undefined): string {
        if (!person) return '';
        return `${person.lastName}, ${person.firstName}`;
    }

    static getName(person: { firstName: string; lastName: string } | null | undefined): string {
        if (!person) return '';
        return `${person.firstName} ${person.lastName}`;
    }
}
