import { IAdvisorFirm } from '@api';
import { VerticalTabs as Tabs } from '~/components';
import IPanel from './IPanel';
import PanelContainer from './PanelContainer';
import ProcessInfo from './ProcessInfo';

const TabId = 'DesigningShortTermIncentivePlan';

const DesigningShortTermIncentivePlan = (advisorFirm: IAdvisorFirm | undefined): IPanel => ({
    Id: TabId,
    Tab: <Tabs.Tab tabId={TabId}>Designing a Short-Term Incentive Plan</Tabs.Tab>,
    Panel: (
        <Tabs.Panel tabId={TabId}>
            <PanelContainer>
                <h2 className="font-bold">Designing a Short-Term Incentive Plan</h2>
                <p>
                    An annual incentive plan is the most common type of variable pay program offered by businesses. Yet,
                    studies show that only 10% of private company leaders think theirs is effective. This is because
                    most plans are over-engineered, uninsipiring or both. A well-designed plan will be simple to
                    understand, have credible performance targets, provide meaningful value to participants, and
                    increase shareholder value. Effective incentive plans promote a partnership mindset and drive higher
                    productivity and innovation.
                </p>
                <h2 className="font-bold">How We Can Help</h2>
                <p>
                    {advisorFirm?.name ?? 'Your advisor'} partners with The VisionLink Advisory Group to offer incentive
                    plan design services to our business clients. Since 1996, VisionLink has designed hundreds of
                    value-sharing plans for companies in nearly every industry. Through its decades of experience, it
                    has virtually perfected incentive plan design. VisionLink uses proprietary technology to ensure
                    effective design, implementation and ongoing management of both short and long-term value-sharing
                    plans.
                </p>
                <ProcessInfo
                    outcome="A compelling value-sharing program that communicates a strong partnership relationship with your employees while assuring a positive ROI for the company."
                    steps={[
                        'Clearly identify the high-level intent and purpose of your annual incentive plan. Keep it simple. E.g., "Achieve 20% profit margin."',
                        'Establish your eligible group by identifying the company employees who will have the greatest impact on your desired result.',
                        'Establish Company performance expectations at minimum (Base), and expected (Target) levels of achievement, as well as conceivable Company overachievement (Superior)',
                        'Budget  incentive opportunities for each role. Set a different level of opportunity correlated to Company performance at Base, Target, & Superior.  Adjust until the plan budget is affordable at all levels.',
                        'Consider and determine if and how to include department and individual performance metrics in the award calculations.',
                        'Prepare clear communication materials to present the plan to your employees. Update possible plan results throughout the year. Communicate values promptly at year-end.',
                    ]}
                />
            </PanelContainer>
        </Tabs.Panel>
    ),
});

export { DesigningShortTermIncentivePlan };
