import { IPlace, IProblemDetails, PlacesApi } from '@api';
import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import config from '~/config';

const placesApi = new PlacesApi(undefined, config.api.baseUrl);

const placesQueryKey = 'places';
const getPlacesNearbyQueryKey = (latitude: number, longitude: number): QueryKey => [
    placesQueryKey,
    'nearby',
    latitude,
    longitude,
];

export const usePlacesNearby = (latitude: number, longitude: number): UseQueryResult<IPlace[], IProblemDetails> =>
    useQuery(getPlacesNearbyQueryKey(latitude, longitude), () =>
        placesApi.getNearbyPlaces(latitude, longitude).then(response => response.data)
    );
