import { IMagicLinkResponse, IProblemDetails, MagicLinkApi } from '@api';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { defaultHeaders } from '~/auth/AxiosConfigurationProvider';
import config from '~/config';
import { getCurrentUserQueryKey } from './user';

const magicLinkApi = new MagicLinkApi(undefined, config.api.baseUrl, axios.create({ headers: defaultHeaders }));

const tokenExpirationInSeconds = 300;
const leewayInSeconds = 5;
const staleTimeInMilliseconds = (tokenExpirationInSeconds - leewayInSeconds) * 1000;

export const useMagicLink = (magicLinkToken: string): UseQueryResult<IMagicLinkResponse, IProblemDetails> =>
    useQuery(
        getCurrentUserQueryKey('magicLink'),
        () => magicLinkApi.getAccessToken(magicLinkToken).then(response => response.data),
        {
            enabled: !!magicLinkToken,
            staleTime: staleTimeInMilliseconds,
        }
    );
