import { RefObject, useEffect } from 'react';

interface IOutsideClickBehavior {
    ref: RefObject<HTMLElement>;
    handler: () => void;
}

export default function useOutsideClickDetector({ ref, handler }: IOutsideClickBehavior): void {
    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (ref?.current && event.target instanceof HTMLElement && !ref.current.contains(event.target)) {
                handler();
            }
        };

        document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [ref, handler]);
}
