import { IEmployeeRatingConfig } from '@api';
import { RatingColor } from './employeeRatingUtils';

const RatingLegend = ({ maxRating, minRating, sortHighToLow }: IEmployeeRatingConfig): JSX.Element => (
    <div className="mt-4 sm:w-96 w-full">
        <div className="flex justify-between text-sm">
            <span>Worst</span>
            <span>Best</span>
        </div>
        <div className="space-x-0.5 h-2 flex flex-nowrap">
            {Object.values(RatingColor).map(color => (
                <div key={color} className={`w-full bg-${color}`}></div>
            ))}
        </div>
        <div className="flex justify-between font-bold">
            <span>{sortHighToLow ? minRating : maxRating}</span>
            <span>{sortHighToLow ? maxRating : minRating}</span>
        </div>
    </div>
);

export default RatingLegend;
