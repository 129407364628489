import { IEmployee } from '@api';
import { OptionTypeBase } from 'react-select';
import { EntitySelect } from '~/components';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    disabled?: boolean;
    employees: IEmployee[];
    onChange: (id: string) => void;
    onClickNew: () => void;
    value: string | null | undefined;
}

const EmployeeSearch = ({ disabled = false, employees, onChange, onClickNew, value }: IProps): JSX.Element => {
    const options = [
        ...employees.map(a => ({
            avatar: a.avatar || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            value: a.id,
        })),
    ];

    const handleChange = (option: OptionTypeBase | null | undefined) => {
        if (!option?.value) {
            return;
        }
        onChange(option.value);
    };

    return (
        <EntitySelect
            isDisabled={disabled}
            label="Primary Contact"
            options={options}
            placeholder="Search for employee"
            value={value || ''}
            onEntityOptionSelected={handleChange}
            onNewSelected={onClickNew}
        />
    );
};

export default EmployeeSearch;
