import { INote } from '@api';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormActions, SlideOver, TextArea } from '~/components';
import { yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    isOpen: boolean;
    note?: INote | null;
    onClose: () => void;
    onDelete?: () => Promise<void>;
    onSave: (formData: INoteFormData) => Promise<void>;
    title: string;
}

export interface INoteFormData {
    text: string;
}

const getInitialValues = (note?: INote | null): INoteFormData => {
    return {
        text: note?.text || '',
    };
};
const schemaValidation = Yup.object().shape({
    text: Yup.string().label('Note').required(),
});

const NoteForm = (props: IProps): JSX.Element => {
    const { isOpen, note, onClose, onDelete, onSave } = props;
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<INoteFormData>(getInitialValues(note));
    const handleSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        return onSave(formData);
    };
    const resetForm = (note: INote | null | undefined) => {
        setErrors({});
        setFormData(getInitialValues(note));
    };

    useEffect(() => resetForm(note), [isOpen, note]);

    return (
        <SlideOver stickyFooter={<FormActions onCancel={onClose} onDelete={onDelete} onSave={handleSave} />} {...props}>
            <div>
                <TextArea
                    error={errors['text']}
                    label="Note"
                    name="text"
                    rows={6}
                    value={formData.text}
                    onChange={text => setFormData({ ...formData, text })}
                />
            </div>
        </SlideOver>
    );
};

export default NoteForm;
