import classNames from 'classnames';
import { ITextInputProps, TextInput } from './TextInput';

const PercentageInput = ({ inputClassName, ...props }: ITextInputProps): JSX.Element => {
    return (
        <div className="relative">
            <TextInput inputClassName={classNames('pr-7', inputClassName)} {...props} />
            <div
                className={`absolute right-0 pr-3 flex items-center pointer-events-none ${
                    props.label ? 'top-8' : 'top-2'
                }`}
            >
                <span className="text-gray-500 sm:text-sm">%</span>
            </div>
        </div>
    );
};

export { PercentageInput };
