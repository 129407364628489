import ArrowHead, { ArrowHeadDirection } from './ArrowHead';

const arrowHeadSize = 4;
const strokeWidth = 6;
const ownerLineInset = 100;

interface IProps {
    estateContainerRect?: DOMRect;
    otherOwnersContainerRect?: DOMRect;
}

const arrowheadId = 'arrowhead';

const PlanIllustrationCrossPurchaseArrow = ({ estateContainerRect, otherOwnersContainerRect }: IProps): JSX.Element => {
    const otherOwnersWidth = otherOwnersContainerRect?.width || 0;
    const containerHeight = (estateContainerRect?.bottom || 0) - (otherOwnersContainerRect?.bottom || 0);
    const containerWidth = (otherOwnersContainerRect?.right || 0) - (estateContainerRect?.right || 0);
    const arrowLineY = containerHeight - (estateContainerRect?.height || 0) / 2;

    return (
        <svg height={containerHeight} width={containerWidth}>
            <defs>
                <ArrowHead direction={ArrowHeadDirection.left} id={arrowheadId} />
            </defs>
            <path
                className="stroke-gray-400"
                d={`
                    M ${(containerWidth - strokeWidth) / 2},${arrowLineY}
                    V${arrowLineY / 2}
                    H${containerWidth - otherOwnersWidth + ownerLineInset}
                    V10
                    V${arrowLineY / 2}
                    H${containerWidth - strokeWidth / 2 - ownerLineInset}
                    V10
                `}
                fill="none"
                strokeWidth={strokeWidth}
            />
            <line
                className="stroke-gray-400"
                x1={arrowHeadSize * strokeWidth}
                y1={arrowLineY}
                x2={containerWidth / 2}
                y2={arrowLineY}
                strokeWidth={strokeWidth}
                markerStart={`url(#${arrowheadId})`}
            />
        </svg>
    );
};

export default PlanIllustrationCrossPurchaseArrow;
