import moment from 'moment';

export const isAboutToRetire = (dateOfBirth: Date | string | null | undefined): boolean => {
    if (!dateOfBirth) return false;
    const age = getAge(dateOfBirth) ?? 0;
    return age >= 65;
};

export const getAge = (dateOfBirth: Date | string | null | undefined): number | null => {
    if (!dateOfBirth) return null;
    return moment().diff(moment(dateOfBirth, 'YYYY-MM-DD'), 'years', false);
};

export const getAgeAsString = (dateOfBirth: Date | string | null | undefined): string => {
    if (!dateOfBirth) return '--';
    return `${getAge(dateOfBirth)}`;
};
