import { IBusinessTeamMember, ITodo } from '@api';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { Button } from '~/components';
import { useUpdateTodo } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import EditTodoForm from './EditTodoForm';
import { priorityColorMap } from './priorityColorMap';

interface ITodoItemProps {
    assigneeName?: string;
    businessId: string;
    canEdit?: boolean;
    todo: ITodo;
}

const getColor = (todo: ITodo): string => {
    if (todo.completed) return 'teal-500';
    const color = priorityColorMap[todo.priority];
    if (color) return color;

    throw new Error('Unmapped item color');
};

const getShortMonth = (dateString: string) =>
    new Date(dateString).toLocaleString('default', { month: 'short', timeZone: 'UTC' });
const getTwoDigitDay = (dateString: string) =>
    new Date(dateString).toLocaleString('default', { day: '2-digit', timeZone: 'UTC' });

export const TodoItem = ({ assigneeName, businessId, canEdit = true, todo }: ITodoItemProps): JSX.Element => {
    const [editIsOpen, setEditIsOpen] = useState(false);
    const updateTodo = useUpdateTodo(businessId, todo.id);
    const onCompleted = () => {
        updateTodo.mutateAsync({
            ...todo,
            completed: !todo.completed,
        });
    };
    const color = getColor(todo);

    return (
        <>
            <div className="flex shadow-sm rounded-md bg-gray-700 mt-4">
                <div
                    className={classNames(
                        `bg-${color}`,
                        'flex-shrink-0 flex items-center justify-center w-1 rounded-l-md'
                    )}
                />
                <div className="px-6 py-4 w-full">
                    <div className="flex">
                        <div className="text-center">
                            <div className="font-medium uppercase tracking-wide">{getShortMonth(todo.dueDate)}</div>
                            <div className="text-3xl font-bold tracking-wide">{getTwoDigitDay(todo.dueDate)}</div>
                        </div>
                        <div className="mx-6 flex-grow truncated">{todo.description}</div>
                        <div>
                            <input
                                checked={todo.completed}
                                type="checkbox"
                                className={classNames(
                                    todo.completed ? 'text-success border-success' : 'border-gray-300',
                                    'h-5 w-5 focus:ring-sky-500 rounded cursor-pointer bg-input-bg'
                                )}
                                onChange={onCompleted}
                            />
                        </div>
                    </div>
                    <div className="flex justify-between mt-3">
                        <div className="truncated">{assigneeName || 'Unassigned'}</div>
                        {!todo.completed && canEdit && (
                            <Button color="secondary" size="sm" onClick={() => setEditIsOpen(true)}>
                                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                Edit
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <EditTodoForm
                businessId={businessId}
                isOpen={editIsOpen}
                onClose={() => setEditIsOpen(false)}
                todo={todo}
            />
        </>
    );
};

interface ITodoItemSetProps {
    businessId: string;
    label: string;
    teamMembers: IBusinessTeamMember[];
    todos: ITodo[];
}

const TodoItemSet = (props: ITodoItemSetProps): JSX.Element | null => {
    const { label, teamMembers, todos } = props;
    if (todos?.length === 0) {
        return null;
    }
    const color = getColor(todos[0]);

    return (
        <>
            {label && <div className={`text-${color} pt-4`}>{label}</div>}
            {todos.map(todo => {
                const assignee = teamMembers.find(t => t.id === todo.assigneeId);
                const assigneeName = assignee ? NameFormatter.getName(assignee) : undefined;

                return <TodoItem key={todo.id} assigneeName={assigneeName} todo={todo} {...props} />;
            })}
        </>
    );
};

export default TodoItemSet;
