import { IInnerZoneEmployeeUser } from '@api';
import { SlideOver, SlideOverSizeType } from '~/components';
import NameFormatter from '~/utils/nameFormatter';
import EmployeeProfileSlideOverContent from './EmployeeProfileSlideOverContent';

interface IProps {
    employeeUser?: IInnerZoneEmployeeUser;
    onClose: () => void;
}

const EmployeeProfileSlideOver = ({ employeeUser, onClose }: IProps): JSX.Element => (
    <SlideOver
        isOpen={!!employeeUser}
        size={SlideOverSizeType.lg}
        title={employeeUser ? NameFormatter.getName(employeeUser) : ''}
        onClose={onClose}
    >
        {employeeUser && <EmployeeProfileSlideOverContent employeeId={employeeUser.employeeId} />}
    </SlideOver>
);

export default EmployeeProfileSlideOver;
