import { IBusinessValuation } from '@api';
import CurrencyFormatter from '~/utils/currencyFormatter';

interface IProps {
    businessValuation: IBusinessValuation;
}

const BusinessValuationNumber = ({ businessValuation }: IProps): JSX.Element => {
    return <>{CurrencyFormatter.format(businessValuation.value ?? 0)}</>;
};

export default BusinessValuationNumber;
