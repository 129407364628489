import { IEmployeeRatingConfig } from '@api';
import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';
import { formatRating, getRatingColor } from './employeeRatingUtils';

interface IProps {
    rating?: number | null;
    ratingConfig: IEmployeeRatingConfig;
}

const EmployeeRating = ({ className, rating, ratingConfig }: IProps & ComponentPropsWithoutRef<'div'>): JSX.Element => {
    const borderColor = rating === null || rating === undefined ? 'transparent' : getRatingColor(ratingConfig, rating);
    return (
        <div
            className={classNames(
                className,
                `border-${borderColor}`,
                'bg-gray-800 border-4 inline-flex flex items-center justify-center font-medium text-white rounded-full'
            )}
        >
            {formatRating(rating)}
        </div>
    );
};

export default EmployeeRating;
