const AssessmentInstructions = (): JSX.Element => (
    <>
        <div />
        <p>
            Rate each statement on a scale of one to 10 based on how well it reflects your business currently. A 10
            rating would suggest the statement describes your company perfectly and a 1 would indicate it isn’t even
            close.
        </p>
        <div />
    </>
);

export default AssessmentInstructions;
