import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import PercentageTableCell from '../../PercentageTableCell';

interface IProps {
    years: IBonusRightAggregatePaySummaryYear[];
}

const TotalValueAnalysis = ({ years }: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold">
                    <div className="text-lg">Total Value Analysis</div>
                    <div className="text-sm">All Values Percentage of EBITDA</div>
                </TableCell>
                {years.map(y => (
                    <TableCell key={`placeholder:${y.year}`} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>STIP</TableCell>
                {years.map(y => (
                    <PercentageTableCell key={`stip:${y.year}`} value={y.stips / y.ebitda} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>LTIP</TableCell>
                {years.map(y => (
                    <PercentageTableCell key={`ltip:${y.year}`} value={y.ltipRedemptions / y.ebitda} />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Total Variable Pay</TableCell>
                {years.map(y => (
                    <PercentageTableCell key={`total:${y.year}`} value={(y.ltipRedemptions + y.stips) / y.ebitda} />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default TotalValueAnalysis;
