import { IUserEntitlementType } from '@api';
import React from 'react';
import { useCurrentUser } from '~/hooks';

interface IProps {
    children: React.ReactNode;
    condition?: boolean | (() => boolean);
    hasEntitlement: IUserEntitlementType;
}

const conditionIsTrue = (condition?: boolean | (() => boolean)): boolean => {
    if (condition === null || condition === undefined) {
        return true;
    } else if (typeof condition === 'function') {
        return condition();
    } else {
        return condition;
    }
};

export const Can = ({ children, condition, hasEntitlement }: IProps): JSX.Element | null => {
    const { isLoading, error, data } = useCurrentUser();

    if (isLoading || error || !hasEntitlement) {
        return null;
    }

    const verifyHasEntitlement = (entitlement: IUserEntitlementType): boolean => {
        return data?.entitlements.includes(entitlement) || false;
    };

    return (verifyHasEntitlement(hasEntitlement) && conditionIsTrue(condition) && <>{children}</>) || null;
};

export default Can;
