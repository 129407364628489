import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { IconButton } from './IconButton';

export enum SlideOverSizeType {
    md = 'md',
    lg = 'lg',
    xl = 'xl',
    '2xl' = '2xl',
    '3xl' = '3xl',
    '4xl' = '4xl',
    '5xl' = '5xl',
    '6xl' = '6xl',
    '7xl' = '7xl',
}

interface IProps {
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    size?: SlideOverSizeType;
    stickyFooter?: ReactNode;
    title: ReactNode;
}

const sizeClassNameMap: Record<SlideOverSizeType, string> = {
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    '7xl': 'max-w-7xl',
};

export const SlideOver = ({
    children,
    isOpen,
    onClose,
    size = SlideOverSizeType.md,
    stickyFooter,
    title,
}: IProps): JSX.Element => {
    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={onClose} open={true} static>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-60 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            appear
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className={`w-screen ${sizeClassNameMap[size]}`}>
                                <div className="h-full flex flex-col bg-gray-900 shadow-xl">
                                    <div className="min-h-0 flex-1 flex flex-col overflow-y-auto scrollbar-themed">
                                        <div className="px-4 sm:px-6 py-4 sm:py-6 bg-gray-800">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium">{title}</Dialog.Title>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <IconButton title="Close panel" onClick={onClose}>
                                                        <FontAwesomeIcon icon={faTimes} size="lg" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between">
                                            <div className="px-4 sm:px-6">
                                                <div className="space-y-6 pt-6 pb-5">{children}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {stickyFooter && (
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end bg-gray-800">
                                            {stickyFooter}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
