import { useState } from 'react';
import { Button, Modal } from '~/components';
import { useDeleteBonusRightConnection } from '~/hooks/bonusRightConnection';

interface IProps {
    businessId: string;
}

const DisconnectButton = ({ businessId }: IProps): JSX.Element => {
    const deleteBonusRightConnectionMutation = useDeleteBonusRightConnection(businessId).mutateAsync;
    const [open, setOpen] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const deleteBonusRightConnection = async () => {
        setIsSubmitting(true);
        try {
            await deleteBonusRightConnectionMutation();
            setOpen(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Button color="link" onClick={() => setOpen(true)}>
                Unlink BonusRight Account
            </Button>
            <Modal maxWidth="sm" open={open} setOpen={setOpen} title="Unlink Accounts">
                <div className="p-6 space-y-8">
                    <div>Are you sure you want to unlink your BonusRight account?</div>
                    <div className="space-x-3 flex justify-end">
                        <Button color="secondary" disabled={isSubmitting} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button color="primary" disabled={isSubmitting} onClick={deleteBonusRightConnection}>
                            Unlink
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DisconnectButton;
