import { IEmployee, IEmployeeCreateRequest, IEmployeeUpdateRequest } from '@api';
import { useContext } from 'react';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useDeleteEmployee, useEmployeeAvatar, useUpdateEmployee } from '~/hooks';
import EmployeeForm from './EmployeeForm';

interface IProps {
    businessId: string;
    employee?: IEmployee;
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: (id: string) => void;
}

const EditEmployeeForm = (props: IProps): JSX.Element => {
    const { businessId, employee, onSuccess, onClose } = props;
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const employeeId = employee?.id ?? '';
    const deleteEmployee = useDeleteEmployee(businessId);
    const updateEmployee = useUpdateEmployee(businessId);
    const employeeAvatar = useEmployeeAvatar(businessId);
    const onDelete = async () => {
        await deleteEmployee.mutateAsync(employeeId);
        showSuccessNotification();
        onSuccess?.(employeeId);
        onClose();
    };
    const onSave = async (request: IEmployeeCreateRequest | IEmployeeUpdateRequest, avatar?: File) => {
        await updateEmployee.mutateAsync({
            ...(employee ?? {}),
            ...request,
            employeeId: employee?.id ?? '',
        });
        if (avatar) {
            await employeeAvatar.mutateAsync({
                avatar,
                employeeId,
            });
        }
        showSuccessNotification();
        onSuccess?.(employeeId);
        onClose();
    };

    return <EmployeeForm onDelete={onDelete} onSave={onSave} title="Edit Employee" {...props} />;
};

export default EditEmployeeForm;
