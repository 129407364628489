import { IExternalTeamMemberCreateRequest, IExternalTeamMemberUpdateRequest } from '@api';
import { useAddExternalTeamMember, useExternalTeamMemberAvatar } from '~/hooks';
import ExternalTeamMemberForm from './ExternalTeamMemberForm';

interface IProps {
    businessId: string;
    isOpen: boolean;
    onClose: () => void;
    onSave?: (id: string) => void;
}

const AddTeamMemberForm = ({ businessId, isOpen, onClose, onSave = () => undefined }: IProps): JSX.Element => {
    const addExternalTeamMember = useAddExternalTeamMember(businessId);
    const teamMemberAvatar = useExternalTeamMemberAvatar(businessId);

    const handleSave = async (
        request: IExternalTeamMemberCreateRequest | IExternalTeamMemberUpdateRequest,
        avatar?: File
    ) => {
        const newTeamMember = await addExternalTeamMember.mutateAsync({
            ...request,
            businessId,
        });
        if (avatar) {
            await teamMemberAvatar.mutateAsync({
                avatar,
                teamMemberId: newTeamMember.id,
            });
        }
        onSave(newTeamMember.id);
        onClose();
    };

    return <ExternalTeamMemberForm isOpen={isOpen} onClose={onClose} onSave={handleSave} title="Add Team Member" />;
};

export default AddTeamMemberForm;
