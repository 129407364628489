import classnames from 'classnames';
import React from 'react';
import { Avatar } from '~/components';

interface IProps {
    avatar?: string;
    className?: string;
    description?: string;
    name: string;
    value?: React.ReactNode;
}

const EntityCard = ({ avatar, className, description, name, value }: IProps): JSX.Element => (
    <div className={classnames('rounded bg-gray-800 overflow-hidden', className)}>
        <div className="flex h-16">
            {avatar && <Avatar className="mr-1" rounded="none" size={16} src={avatar} />}
            <div className="flex-1 flex items-center truncate">
                <div className="flex-1 p-2 truncate">
                    <div className="text-xl leading-7 font-semibold truncate" title={name}>
                        {name}
                    </div>
                    <p className="text-gray-400 text-sm truncate">{description}</p>
                </div>
                {value && <div className="flex-shrink-0 h-full flex flex-wrap content-center px-2">{value}</div>}
            </div>
        </div>
    </div>
);

export default EntityCard;
