import { IBusiness, IBusinessPageType } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBonusRightConnectionStatus } from '~/hooks/bonusRightConnection';
import BusinessForecast from './businessForecast';
import UnconnectedPage from './unconnected';

interface IProps {
    business: IBusiness;
}

const BusinessForecastPage = ({ business }: IProps): JSX.Element => {
    const { data, error, isLoading } = useBonusRightConnectionStatus(business);

    if (isLoading) return <LoadingIndicator />;
    if (error) return <ErrorPage />;

    return data?.isConnected ? (
        <BusinessForecast business={business} />
    ) : (
        <UnconnectedPage business={business} businessPageType={IBusinessPageType.BusinessForecast} />
    );
};

export default BusinessForecastPage;
