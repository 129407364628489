import { IDocument, IDocumentList } from '@api';
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useStorageState } from 'react-storage-hooks';
import { ErrorModal } from '~/components';
import { getStorageKey } from '~/utils/storageKeys';
import { defaultTransitionDuration } from '~/utils/transitions';
import AddFilesModal from './modals/AddFilesModal';
import AddFolderModal from './modals/AddFolderModal';
import BulkDeleteModal from './modals/BulkDeleteModal';
import MoveItemsModal from './modals/moveItems/MoveItemsModal';
import RenameItemModal from './modals/renameItem/RenameItemModal';
import SharingModal from './modals/sharing/SharingModal';
import { getFolderIdFromUrl } from './utils/routes';

export enum DisplayType {
    Grid,
    Table,
}

enum ModalType {
    AddFiles,
    AddFolder,
    Delete,
    Error,
    Move,
    None,
    Rename,
    Share,
}

interface AddFilesConfig {
    files?: FileList;
    folderId: string;
}

interface IProviderProps extends RouteComponentProps {
    rootFolder: IDocumentList;
}

type DocumentCenterContextProps = {
    currentFolderId: string;
    displayType: DisplayType;
    openAddFilesModal: (files?: FileList, folderId?: string) => void;
    openAddFolderModal: () => void;
    openDeleteModal: (item?: IDocument) => void;
    openErrorModal: (message: string) => void;
    openMoveModal: (item?: IDocument) => void;
    openRenameModal: (item?: IDocument) => void;
    openShareModal: (item?: IDocument) => void;
    rootFolder: IDocumentList;
    selectedItems: Map<string, IDocument>;
    setDisplayType: (displayType: DisplayType) => void;
    toggleSelectItem: (item: IDocument) => void;
};

const STORAGE_KEY = getStorageKey('documentCenterDisplayType');
const documentCenterStore = createContext<DocumentCenterContextProps>(
    undefined as unknown as DocumentCenterContextProps
);
const { Provider } = documentCenterStore;

interface ActiveModal {
    isClosing?: boolean;
    saveSuccess?: boolean;
    type: ModalType;
}

const DocumentCenterContextProvider: React.FC<IProviderProps> = ({
    children,
    rootFolder,
}: PropsWithChildren<IProviderProps>) => {
    const [filesToAdd, setFilesToAdd] = useState<Partial<AddFilesConfig>>({});
    const [displayType, setDisplayType] = useStorageState<DisplayType | undefined>(
        localStorage,
        STORAGE_KEY,
        undefined
    );
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [activeModal, setActiveModal] = useState<ActiveModal>({ type: ModalType.None });
    const [selectedItems, setSelectedItems] = useState<Map<string, IDocument>>(new Map());
    const currentFolderId = getFolderIdFromUrl() || '';
    const history = useHistory();

    const openModal = (type: ModalType) => setActiveModal({ type });
    const closeModal = (saveSuccess?: boolean) => setActiveModal({ ...activeModal, isClosing: true, saveSuccess });

    useEffect(() => {
        return history.listen(() => {
            setSelectedItems(new Map());
        });
    }, [history]);

    useEffect(() => {
        if (activeModal.isClosing) {
            setTimeout(() => {
                setActiveModal({ type: ModalType.None });
                switch (activeModal.type) {
                    case ModalType.AddFiles:
                        setFilesToAdd({});
                        break;
                    case ModalType.Delete:
                    case ModalType.Move:
                    case ModalType.Share:
                        if (activeModal.saveSuccess || selectedItems.has(currentFolderId)) {
                            setSelectedItems(new Map());
                        }
                        break;
                    case ModalType.Error:
                        setErrorMessage(undefined);
                        break;
                    default:
                        break;
                }
            }, defaultTransitionDuration);
        }
    }, [currentFolderId, activeModal, selectedItems]);

    const toggleSelectItem = (item: IDocument) => {
        if (selectedItems.has(item.id)) {
            selectedItems.delete(item.id);
        } else {
            selectedItems.set(item.id, item);
        }
        setSelectedItems(new Map(selectedItems));
    };

    return (
        <Provider
            value={{
                currentFolderId,
                displayType: displayType ?? DisplayType.Grid,
                openAddFilesModal: (files?: FileList, folderId?: string) => {
                    if (folderId && !files) {
                        return;
                    }
                    setFilesToAdd({ files, folderId });
                    openModal(ModalType.AddFiles);
                },
                openAddFolderModal: () => openModal(ModalType.AddFolder),
                openDeleteModal: (item?: IDocument) => {
                    if (item) {
                        selectedItems.set(item.id, item);
                        setSelectedItems(new Map(selectedItems));
                    }
                    if (selectedItems.size === 0) {
                        return;
                    }
                    openModal(ModalType.Delete);
                },
                openErrorModal: (message: string) => {
                    setErrorMessage(message);
                    openModal(ModalType.Error);
                },
                openMoveModal: (item?: IDocument) => {
                    if (item) {
                        selectedItems.set(item.id, item);
                        setSelectedItems(new Map(selectedItems));
                    }
                    if (selectedItems.size === 0) {
                        return;
                    }
                    openModal(ModalType.Move);
                },
                openRenameModal: (item?: IDocument) => {
                    if (item) {
                        selectedItems.set(item.id, item);
                        setSelectedItems(new Map(selectedItems));
                    }
                    if (selectedItems.size === 0 || selectedItems.size > 1) {
                        return;
                    }
                    openModal(ModalType.Rename);
                },
                openShareModal: (item?: IDocument) => {
                    let items = selectedItems;
                    if (item) {
                        items = new Map();
                        items.set(item.id, item);
                        setSelectedItems(items);
                    }
                    if (items.size === 0 || items.size > 1) {
                        return;
                    }
                    const selectedItem = item ?? Array.from(selectedItems.values())[0];
                    if (!selectedItem.isFolder) {
                        return;
                    }
                    openModal(ModalType.Share);
                },
                rootFolder,
                selectedItems,
                setDisplayType,
                toggleSelectItem,
            }}
        >
            {children}
            {activeModal.type === ModalType.AddFiles && (
                <AddFilesModal
                    filesToAdd={filesToAdd.files}
                    onClose={closeModal}
                    open={!activeModal.isClosing}
                    targetFolderId={filesToAdd.folderId ?? currentFolderId}
                />
            )}
            {activeModal.type === ModalType.AddFolder && (
                <AddFolderModal onClose={closeModal} open={!activeModal.isClosing} parentFolderId={currentFolderId} />
            )}
            {activeModal.type === ModalType.Delete && (
                <BulkDeleteModal
                    entries={Array.from(selectedItems.values())}
                    onClose={closeModal}
                    onSaveSuccess={() => closeModal(true)}
                    open={!activeModal.isClosing}
                />
            )}
            {activeModal.type === ModalType.Error && (
                <ErrorModal message={errorMessage} open={!activeModal.isClosing} setIsOpen={closeModal} />
            )}
            {activeModal.type === ModalType.Move && (
                <MoveItemsModal
                    onClose={closeModal}
                    onSaveSuccess={() => closeModal(true)}
                    open={!activeModal.isClosing}
                />
            )}
            {activeModal.type === ModalType.Rename && (
                <RenameItemModal
                    item={Array.from(selectedItems.values())[0]}
                    onClose={closeModal}
                    open={!activeModal.isClosing}
                />
            )}
            {activeModal.type === ModalType.Share && (
                <SharingModal
                    document={Array.from(selectedItems.values())[0]}
                    onClose={closeModal}
                    open={!activeModal.isClosing}
                />
            )}
        </Provider>
    );
};

const wrappedProvider = withRouter(DocumentCenterContextProvider);

export { wrappedProvider as DocumentCenterContextProvider, documentCenterStore };
