import { IInnerZoneEmployeeLink, IInnerZoneEmployeeUser, IInnerZoneSurveyStatus } from '@api';
import classnames from 'classnames';
import { Fragment, useState } from 'react';
import EmployeeLabel from './EmployeeLabel';
import { linkTypeColor } from './innerZoneEnumUtils';

interface IProps {
    className?: string;
    employeeLinks: IInnerZoneEmployeeLink[];
    employeeUsers: IInnerZoneEmployeeUser[];
    height: number;
    selectedEmployeeUser?: IInnerZoneEmployeeUser;
    selectedLink?: IInnerZoneEmployeeLink;
    width: number;
    onEmployeeUserClick: (employeeUser: IInnerZoneEmployeeUser) => void;
    onLinkClick: (link: IInnerZoneEmployeeLink) => void;
}

interface IEmployeeUserWithCoordinates {
    employeeUser: IInnerZoneEmployeeUser;
    labelX: number;
    labelY: number;
    pointX: number;
    pointY: number;
}

const labelWidth = 250;
const labelHeight = 72;
const threeHundredSixtyDegreesInRadians = 2 * Math.PI;

const createEmployeeUserWithCoordinates = (
    employeeUser: IInnerZoneEmployeeUser,
    employeeIndex: number,
    totalEmployees: number,
    height: number,
    width: number
): IEmployeeUserWithCoordinates => {
    const pointX = (width / 2) * (1 - Math.sin((employeeIndex * threeHundredSixtyDegreesInRadians) / totalEmployees));
    const pointY = (height / 2) * (1 - Math.cos((employeeIndex * threeHundredSixtyDegreesInRadians) / totalEmployees));
    let labelX;
    let labelY;

    if (
        Math.floor(pointX) === Math.floor(width / 2) ||
        Math.floor(pointX) === Math.ceil(width / 2) ||
        Math.ceil(pointX) === Math.floor(width / 2) ||
        Math.ceil(pointX) === Math.ceil(width / 2)
    ) {
        labelX = pointX - labelWidth / 2;
    } else if (pointX < width / 2) {
        labelX = pointX - labelWidth;
    } else {
        labelX = pointX;
    }

    if (
        Math.floor(pointY) === Math.floor(height / 2) ||
        Math.floor(pointY) === Math.ceil(height / 2) ||
        Math.ceil(pointY) === Math.floor(height / 2) ||
        Math.ceil(pointY) === Math.ceil(height / 2)
    ) {
        labelY = pointY - labelHeight / 2;
    } else if (pointY < height / 2) {
        labelY = pointY - labelHeight;
    } else {
        labelY = pointY;
    }

    return {
        employeeUser,
        labelX,
        labelY,
        pointX,
        pointY,
    };
};

const CommunicationChart = ({
    className,
    employeeLinks,
    employeeUsers,
    height,
    selectedEmployeeUser,
    selectedLink,
    width,
    onLinkClick,
    onEmployeeUserClick,
}: IProps): JSX.Element => {
    const [hoverEmployeeUser, setHoverEmployeeUser] = useState<IInnerZoneEmployeeUser>();
    const [hoverLink, setHoverLink] = useState<IInnerZoneEmployeeLink>();
    const highLightedLink = selectedLink || hoverLink;
    const heightMinusLabel = height - labelHeight * 2;
    const widthMinusLabel = width - labelWidth * 2;
    const totalEmployees = employeeUsers.length;
    const employeeUsersWithCoordinates = employeeUsers.map<IEmployeeUserWithCoordinates>((u, i) =>
        createEmployeeUserWithCoordinates(u, i, totalEmployees, heightMinusLabel, widthMinusLabel)
    );
    const linksByEmployee = employeeLinks.reduce<Record<string, Record<string, IInnerZoneEmployeeLink>>>(
        (map, link) => {
            if (!map[link.employees[0]]) {
                map[link.employees[0]] = {};
            }
            if (!map[link.employees[1]]) {
                map[link.employees[1]] = {};
            }
            map[link.employees[0]][link.employees[1]] = link;
            map[link.employees[1]][link.employees[0]] = link;

            return map;
        },
        {}
    );

    return (
        <svg className={className} height={height} width={width}>
            <g transform={`translate(${labelWidth}, ${labelHeight})`}>
                {employeeUsersWithCoordinates.map((e1, i) => {
                    const employee1LinkIsHighlighted =
                        highLightedLink && highLightedLink.employees.some(id => id === e1.employeeUser.employeeId);
                    const employee1IsHoveredOrSelected =
                        (hoverEmployeeUser && e1.employeeUser === hoverEmployeeUser) ||
                        (selectedEmployeeUser && e1.employeeUser === selectedEmployeeUser);

                    return (
                        <Fragment key={e1.employeeUser.employeeId}>
                            <foreignObject
                                key={e1.employeeUser.employeeId}
                                height={labelHeight}
                                width={labelWidth}
                                x={e1.labelX}
                                y={e1.labelY}
                            >
                                <EmployeeLabel
                                    className={classnames({
                                        [`border-4 border-${highLightedLink && linkTypeColor[highLightedLink.type]}`]:
                                            employee1LinkIsHighlighted,
                                        'border-4 border-primary-500': employee1IsHoveredOrSelected,
                                        'm-1': !employee1LinkIsHighlighted && !employee1IsHoveredOrSelected,
                                        'cursor-pointer':
                                            e1.employeeUser.surveyStatus === IInnerZoneSurveyStatus.SurveyCompleted,
                                        'opacity-50':
                                            e1.employeeUser.surveyStatus !== IInnerZoneSurveyStatus.SurveyCompleted,
                                    })}
                                    employeeUser={e1.employeeUser}
                                    onClick={() => onEmployeeUserClick(e1.employeeUser)}
                                    onMouseOver={() => {
                                        if (e1.employeeUser.surveyStatus === IInnerZoneSurveyStatus.SurveyCompleted) {
                                            setHoverEmployeeUser(e1.employeeUser);
                                        }
                                    }}
                                    onMouseOut={() => {
                                        if (e1.employeeUser.surveyStatus === IInnerZoneSurveyStatus.SurveyCompleted) {
                                            setHoverEmployeeUser(undefined);
                                        }
                                    }}
                                />
                            </foreignObject>
                            {employeeUsersWithCoordinates.slice(i + 1).map(e2 => {
                                const link = linksByEmployee[e1.employeeUser.employeeId]?.[e2.employeeUser.employeeId];

                                return (
                                    <line
                                        key={`${e1.employeeUser.employeeId}-${e2.employeeUser.employeeId}`}
                                        className={classnames(
                                            { 'stroke-gray-700': !link },
                                            { [`cursor-pointer stroke-${linkTypeColor[link?.type]}`]: !!link },
                                            { 'opacity-20': highLightedLink && highLightedLink !== link },
                                            {
                                                'opacity-20':
                                                    (hoverEmployeeUser &&
                                                        hoverEmployeeUser !== e1.employeeUser &&
                                                        hoverEmployeeUser !== e2.employeeUser) ||
                                                    (selectedEmployeeUser &&
                                                        selectedEmployeeUser !== e1.employeeUser &&
                                                        selectedEmployeeUser !== e2.employeeUser),
                                            }
                                        )}
                                        x1={e1.pointX}
                                        y1={e1.pointY}
                                        x2={e2.pointX}
                                        y2={e2.pointY}
                                        strokeWidth={highLightedLink && highLightedLink === link ? 8 : 4}
                                        onClick={() => onLinkClick(link)}
                                        onMouseOver={() => setHoverLink(link)}
                                        onMouseOut={() => setHoverLink(undefined)}
                                    />
                                );
                            })}
                        </Fragment>
                    );
                })}
            </g>
        </svg>
    );
};

export default CommunicationChart;
