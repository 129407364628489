import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button, EmptyStateIcon } from '~/components';
import { ILocationState } from '~/pages/ILocationState';
import { IEditPageType } from '../edit';

interface IProps {
    businessId: string;
    businessSettingsState?: ILocationState;
}

const EmployeesNoData = ({ businessId, businessSettingsState }: IProps): JSX.Element => {
    return (
        <div className="flex flex-col items-center text-center">
            <EmptyStateIcon icon={faUsers} />
            <p className="my-4 text-lg">No employees added yet.</p>
            <Link
                to={{
                    pathname: `/${businessId}/Edit/${IEditPageType.Employees}`,
                    state: businessSettingsState,
                }}
            >
                <Button color="primary">Add Employees</Button>
            </Link>
        </div>
    );
};

export default EmployeesNoData;
