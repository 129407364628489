import { faFolderPlus } from '@fortawesome/pro-solid-svg-icons';
import { useContext, useRef } from 'react';
import { EmptyStateIcon } from '~/components';
import { documentCenterStore } from './DocumentCenterContext';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';

interface IProps {
    folderId: string;
}

const NoDataUpload = ({ folderId }: IProps): JSX.Element => {
    const fileDialog = useRef<HTMLInputElement>(null);
    const { openAddFilesModal } = useContext(documentCenterStore);

    return (
        <DragAndDropUploadHandler folderId={folderId}>
            <div
                className="p-3 p-md-5 flex flex-col items-center cursor-pointer text-center"
                onClick={() => fileDialog.current?.click()}
            >
                <input
                    onChange={e => openAddFilesModal(e.currentTarget.files || undefined, folderId)}
                    ref={fileDialog}
                    type="file"
                    multiple
                    className="hidden"
                />
                <EmptyStateIcon icon={faFolderPlus} />
                <h3 className="mt-4">There are no items in this folder.</h3>
                <p style={{ maxWidth: '30rem' }}>
                    Drag files and folders here to upload, or&nbsp;
                    <button className="text-primary">click here</button>
                    &nbsp;to browse files from your computer.
                </p>
            </div>
        </DragAndDropUploadHandler>
    );
};

export default NoDataUpload;
