import { useEffect } from 'react';
import { LoadingIndicator } from '~/components';
import { useSetBusinessLocation } from '~/hooks';

interface IProps {
    businessId: string;
}

const LocationMissing = ({ businessId }: IProps): JSX.Element => {
    const { mutateAsync } = useSetBusinessLocation(businessId);

    useEffect(() => {
        mutateAsync();
    }, [mutateAsync]);

    return <LoadingIndicator />;
};

export default LocationMissing;
