import classnames from 'classnames';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Button } from './Button';
import { ErrorModal } from './ErrorModal';

interface IFormCancelAndSaveActionsProps {
    disabled: boolean;
    style?: CSSProperties;
    onCancel: () => void;
    onSave: () => void;
}

const FormCancelAndSaveActions = ({
    disabled,
    style,
    onCancel,
    onSave,
}: IFormCancelAndSaveActionsProps): JSX.Element => (
    <div className="space-x-3 flex justify-end" style={style}>
        <Button disabled={disabled} onClick={onCancel}>
            Cancel
        </Button>
        <Button color="primary" disabled={disabled} onClick={onSave}>
            Save
        </Button>
    </div>
);

interface IFormWithDeleteActionsProps {
    disabled: boolean;
    onCancel: () => void;
    onDelete: () => void;
    onSave: () => void;
}

const FormWithDeleteActions = ({ disabled, onCancel, onDelete, onSave }: IFormWithDeleteActionsProps): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const deleteButtonRef = useRef<HTMLButtonElement>(null);
    const deleteButtonsParentRef = useRef<HTMLDivElement>(null);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const [deleteButtonLeftFromParent, setDeleteButtonLeftFromParent] = useState<number>(0);
    const [deleteButtonRightParent, setDeleteButtonRightFromParent] = useState<number>(0);

    useEffect(() => {
        if (containerRef.current && deleteButtonRef.current && deleteButtonsParentRef.current) {
            setContainerHeight(containerRef.current.offsetHeight);
            setDeleteButtonLeftFromParent(
                deleteButtonsParentRef.current.offsetWidth - deleteButtonRef.current.offsetWidth
            );
            setDeleteButtonRightFromParent(
                containerRef.current.offsetWidth - deleteButtonsParentRef.current.offsetWidth
            );
        }
    }, [containerRef, deleteButtonRef, deleteButtonsParentRef]);

    return (
        <>
            <div
                className="flex flex-grow justify-between transition-transform duration-500"
                ref={containerRef}
                style={{
                    transform: isConfirmingDelete
                        ? `translate(${deleteButtonRightParent}px)`
                        : `translate(-${deleteButtonLeftFromParent}px)`,
                }}
            >
                <div
                    className={classnames(
                        { 'opacity-0': !isConfirmingDelete },
                        { 'opacity-100': isConfirmingDelete },
                        'absolute flex items-center transition-transform transition-opacity duration-500'
                    )}
                    style={{
                        height: `${containerHeight}px`,
                        transform: isConfirmingDelete
                            ? `translate(-${deleteButtonRightParent}px)`
                            : `translate(${deleteButtonLeftFromParent}px)`,
                    }}
                >
                    Are you sure?
                </div>
                <div className="z-10 space-x-3 flex justify-start" ref={deleteButtonsParentRef}>
                    <Button
                        className={classnames(
                            { 'opacity-0': !isConfirmingDelete },
                            { 'opacity-100': isConfirmingDelete },
                            'transition-opacity duration-500'
                        )}
                        disabled={disabled}
                        onClick={() => setIsConfirmingDelete(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classnames('transition', { 'text-danger': !isConfirmingDelete })}
                        color={isConfirmingDelete ? 'danger' : 'tertiary'}
                        disabled={disabled}
                        ref={deleteButtonRef}
                        onClick={() => (isConfirmingDelete ? onDelete() : setIsConfirmingDelete(true))}
                    >
                        Delete
                    </Button>
                </div>
                <FormCancelAndSaveActions
                    disabled={disabled}
                    style={{ transform: `translate(${deleteButtonLeftFromParent}px)` }}
                    onCancel={onCancel}
                    onSave={onSave}
                />
            </div>
        </>
    );
};

interface IFormActionsProps {
    onCancel: () => void;
    onDelete?: () => Promise<void>;
    onSave: () => Promise<void>;
}

const FormActions = ({ onCancel, onDelete, onSave }: IFormActionsProps): JSX.Element => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const handleDelete = async () => {
        setIsDisabled(true);
        if (onDelete) {
            await onDelete()
                .then(() => {
                    setIsDisabled(false);
                })
                .catch(() => {
                    setShowErrorModal(true);
                });
        }
    };
    const handleSave = async () => {
        setIsDisabled(true);
        await onSave()
            .then(() => {
                setIsDisabled(false);
            })
            .catch(() => {
                setShowErrorModal(true);
            });
    };

    return (
        <>
            {onDelete ? (
                <FormWithDeleteActions
                    disabled={isDisabled}
                    onCancel={onCancel}
                    onDelete={handleDelete}
                    onSave={handleSave}
                />
            ) : (
                <FormCancelAndSaveActions disabled={isDisabled} onCancel={onCancel} onSave={handleSave} />
            )}
            <ErrorModal
                open={showErrorModal}
                setIsOpen={open => {
                    setShowErrorModal(open);
                    setIsDisabled(false);
                }}
            />
        </>
    );
};

export { FormActions };
