import { IBusinessPageType } from '@api';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle } from '~/components';
import {
    businessPageDescription,
    businessPageDisplay,
    businessPageIcon,
    businessPageIconColor,
} from '~/utils/enumUtils';

export interface IPageSetting {
    enabled: boolean;
    pageType: IBusinessPageType;
}

interface IProps {
    pageSettings: IPageSetting[];
    onChange: (pageType: IBusinessPageType) => void;
}

interface PageSettingProps {
    pageSetting: IPageSetting;
    onChange: (pageType: IBusinessPageType) => void;
}

const isThirdPartyIntegration = (pageType: IBusinessPageType): boolean => {
    switch (pageType) {
        case IBusinessPageType.BonusRight:
        case IBusinessPageType.BusinessForecast:
        case IBusinessPageType.ExecutivePrograms:
        case IBusinessPageType.InnerZone:
            return true;
        default:
            return false;
    }
};

const PageSetting = ({ pageSetting, onChange }: PageSettingProps) => (
    <div className="rounded-lg bg-gray-800 p-4 flex flex-col" key={pageSetting.pageType}>
        <div className="flex items-center">
            <FontAwesomeIcon
                fixedWidth
                size="lg"
                className={`mr-2 text-${businessPageIconColor[pageSetting.pageType]}`}
                icon={businessPageIcon[pageSetting.pageType]}
            />
            <span className="text-xl leading-none flex-grow">{businessPageDisplay[pageSetting.pageType]}</span>
            <Toggle checked={pageSetting.enabled} onChange={() => onChange(pageSetting.pageType)} />
        </div>
        <p className={`mt-3 text-sm ${isThirdPartyIntegration(pageSetting.pageType) && 'mb-3'}`}>
            {businessPageDescription[pageSetting.pageType]}
        </p>
        {isThirdPartyIntegration(pageSetting.pageType) && (
            <div className="text-gray-400 flex items-center mt-auto text-sm leading-none">
                <FontAwesomeIcon icon={faLink} className="mr-2" />
                Third Party Integration
            </div>
        )}
    </div>
);

const PresentationSettingsForm = ({ pageSettings, onChange }: IProps): JSX.Element => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
            {pageSettings.map(pageSetting => (
                <PageSetting key={pageSetting.pageType} pageSetting={pageSetting} onChange={onChange} />
            ))}
        </div>
    );
};

export default PresentationSettingsForm;
