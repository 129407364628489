import { IBusinessEvaluationMetricCreateRequest } from '@api';
import { useState } from 'react';
import { Button, CurrencyInput, Select } from '~/components';
import { useSubmitBusinessEvaluation } from '~/hooks/businessEvaluations';

interface IFormData {
    industry: string;
    revenue: number | null;
    ebitda: number | null;
}

interface IProps {
    businessId: string;
    metrics: IBusinessEvaluationMetricCreateRequest[];
}

const BusinessFinancialForm = ({ businessId, metrics }: IProps): JSX.Element => {
    const submitHandler = useSubmitBusinessEvaluation(businessId).mutateAsync;

    const [formData, setFormData] = useState<IFormData>({
        industry: '',
        revenue: 0,
        ebitda: 0,
    });

    return (
        <div className="h-full">
            {/* eslint-disable-next-line no-script-url */}
            <form action="javascript:void(0);" className="h-full">
                <div className="flex flex-col h-full">
                    <h2 className="text-2xl pb-8 pt-16">Thank you for your responses.</h2>
                    <div className="space-y-4 w-2/3 flex-grow flex-1">
                        <Select label="Industry" />
                        <CurrencyInput
                            label="Revenue"
                            onChange={revenue => setFormData({ ...formData, revenue })}
                            value={formData.revenue ?? undefined}
                        />
                        <CurrencyInput
                            label="EBITDA"
                            onChange={ebitda => setFormData({ ...formData, ebitda })}
                            value={formData.ebitda ?? undefined}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            color="primary"
                            onClick={async () => {
                                await submitHandler({
                                    businessId,
                                    ebitda: formData.ebitda ?? 0,
                                    metrics,
                                    revenue: formData.revenue ?? 0,
                                });
                            }}
                        >
                            See Results
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BusinessFinancialForm;
