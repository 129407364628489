import { IBusiness } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBusinessTeamMembers } from '~/hooks';
import { useBonusRightAssessmentResponse } from '~/hooks/bonusRightOnboardingAssessment';
import visionLinkLogo from '../VisionLinkLogo.png';
import ContactVisionLinkForm from './ContactVisionLinkForm';
import ResultSummary from './ResultSummary';

interface IProps {
    business: IBusiness;
    unsetSubmitRequestAgain: () => void;
}

const TestTakenNoContact = ({ business, unsetSubmitRequestAgain }: IProps): JSX.Element => {
    const {
        data: businessTeamData,
        isLoading: businessTeamIsLoading,
        isError: businessTeamIsError,
    } = useBusinessTeamMembers(business.id, true);

    const {
        data: assessmentResponseData,
        isError: responseIsError,
        isLoading: responseIsLoading,
    } = useBonusRightAssessmentResponse(business.id);

    if (businessTeamIsLoading || responseIsLoading) return <LoadingIndicator />;
    if (businessTeamIsError || responseIsError) return <ErrorPage />;

    return (
        <div className="max-w-md my-auto py-2 px-4 sm:px-0 space-y-4 sm:space-y-8">
            <img className="h-10 sm:h-auto mx-auto" alt="VisionLink Logo" src={visionLinkLogo} />
            <ResultSummary answers={assessmentResponseData?.answerMap ?? {}} />
            <hr className="h-px border-gray-700" />
            <ContactVisionLinkForm
                answers={assessmentResponseData?.answerMap ?? {}}
                business={business}
                businessTeam={businessTeamData ?? []}
                unsetSubmitRequestAgain={unsetSubmitRequestAgain}
            />
        </div>
    );
};

export default TestTakenNoContact;
