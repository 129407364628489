import InnerZoneSetting from '~/pages/advisorSettings/InnerZoneSetting';
import loginBackground from './loginBackground.png';

const LoginPage = (): JSX.Element => (
    <div className="grid xl:grid-cols-2 h-full">
        <div className="flex flex-col items-center justify-center  p-4">
            <div className="max-w-md">
                <InnerZoneSetting />
            </div>
        </div>
        <div
            className="bg-center bg-cover bg-no-repeat hidden xl:block"
            style={{ backgroundImage: `url(${loginBackground})` }}
        ></div>
    </div>
);

export default LoginPage;
