import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import classNames from 'classnames';

export interface IRadioOption {
    checkedColor?: string;
    label: string;
    value: string;
}

interface IProps<T> {
    ariaLabel: string;
    error?: string;
    onChange: (value: string) => void;
    options: T[];
    value: string;
}

const RadioGroup = <T extends IRadioOption>({ ariaLabel, error, onChange, options, value }: IProps<T>): JSX.Element => (
    <HeadlessRadioGroup value={value} onChange={onChange}>
        <HeadlessRadioGroup.Label className="sr-only">{ariaLabel}</HeadlessRadioGroup.Label>
        <div className="flex flex-wrap space-x-2">
            {options.map(option => {
                const checkedColor = `bg-${option.checkedColor || 'primary-600'}`;
                return (
                    <HeadlessRadioGroup.Option
                        key={option.value}
                        value={option.value}
                        className="relative border p-2 flex cursor-pointer focus:outline-none rounded border-gray-600 bg-input-bg hover:bg-gray-700 mb-2"
                    >
                        {({ active, checked }) => (
                            <>
                                <span
                                    className={classNames(
                                        checked ? `${checkedColor} border-transparent` : 'border-gray-600',
                                        active ? 'ring-2 ring-primary-500 ring-offset-2 ring-offset-gray-800' : '',
                                        'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex flex-shrink-0 items-center justify-center'
                                    )}
                                    aria-hidden="true"
                                >
                                    <span
                                        className={classNames(
                                            checked ? 'bg-white' : 'bg-transparent',
                                            'rounded-full w-1.5 h-1.5'
                                        )}
                                    />
                                </span>
                                <HeadlessRadioGroup.Label as="span" className="ml-2 block text-sm text-white">
                                    {option.label}
                                </HeadlessRadioGroup.Label>
                            </>
                        )}
                    </HeadlessRadioGroup.Option>
                );
            })}
        </div>
        {error && <p className="text-sm text-danger-600">{error}</p>}
    </HeadlessRadioGroup>
);

export { RadioGroup };
