import { IBusiness } from '@api';
import { Avatar } from '~/components';
import { getHeadquarters } from '~/utils/businessUtils';
import { businessSizeDisplay } from '~/utils/enumUtils';

interface IProps {
    business: IBusiness;
    lat: number;
    lng: number;
}

const height = 164;
const width = 400;

const BusinessMarker = ({ business }: IProps): JSX.Element => (
    <div className="absolute" style={{ width: `${width}px`, left: `-${width / 2}px`, top: `-${height}px` }}>
        <div className="bg-gray-800 rounded-lg px-7 py-5">
            <div className="flex items-center mb-3">
                <Avatar className="mr-3" rounded="sm" src={business.avatar || undefined} />
                <div className="text-xl font-bold leading-7">{business.name}</div>
            </div>
            <div className="flex text-lg font-semibold">
                <div className="mr-5">
                    <div>Company Size</div>
                    <div>Location</div>
                </div>
                <div>
                    <div>{(business.size && businessSizeDisplay[business.size]) || '--'}</div>
                    <div>{getHeadquarters(business) || '--'}</div>
                </div>
            </div>
        </div>
        <div
            className="w-0 h-0 mx-auto border-gray-800"
            style={{
                borderTopWidth: '1rem',
                borderLeft: '1rem solid transparent',
                borderRight: '1rem solid transparent',
            }}
        ></div>
    </div>
);

export default BusinessMarker;
