import ArrowHead, { ArrowHeadDirection, arrowHeadSize } from './ArrowHead';

const strokeWidth = 6;
const arrowheadId = 'arrowhead';

interface IProps {
    containerHeight: number;
    containerWidth: number;
}

const LeftArrow = ({ containerHeight, containerWidth }: IProps): JSX.Element => (
    <svg height={containerHeight} width={containerWidth}>
        <defs>
            <ArrowHead direction={ArrowHeadDirection.left} id={arrowheadId} />
        </defs>
        <line
            className="stroke-gray-400"
            x1={arrowHeadSize * strokeWidth}
            y1={containerHeight / 2}
            x2={containerWidth}
            y2={containerHeight / 2}
            strokeWidth={strokeWidth}
            markerStart={`url(#${arrowheadId})`}
        />
    </svg>
);

export default LeftArrow;
