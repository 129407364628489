import { IBusiness } from '@api';
import { LoadingIndicator, Table, TableHead, TableRow } from '~/components';
import { isTrueForAnyQuery, useEmployees } from '~/hooks';
import { useBonusRightAggregatePaySummary } from '~/hooks/bonusRightEmployeeRewards';
import BonusRightErrorPage from '../BonusRightErrorPage';
import PoweredBy from '../PoweredBy';
import AllocationSummary from './tableSections/AllocationSummary';
import CompanyPerformance from './tableSections/CompanyPerformance';
import CumulativeValueSharingEbitda from './tableSections/CumulativeValueSharingEbitda';
import CumulativeValueSharingEnterpriseValue from './tableSections/CumulativeValueSharingEnterpriseValue';
import ProjectedCompensationValues from './tableSections/ProjectedCompensationValues';
import ReturnOnTotalRewardsInvestment from './tableSections/ReturnOnTotalRewardsInvestment';
import TotalValueAnalysis from './tableSections/TotalValueAnalysis';

interface IProps {
    business: IBusiness;
}

const BusinessForecast = ({ business }: IProps): JSX.Element => {
    const employees = useEmployees(business.id);
    const aps = useBonusRightAggregatePaySummary(business, employees.data ?? []);
    const isError = isTrueForAnyQuery('isError', employees, aps);
    const isLoading = isTrueForAnyQuery('isLoading', employees, aps);
    if (isError) return <BonusRightErrorPage business={business} reportName="Aggregate Pay Summary" />;
    if (isLoading || !aps.data) return <LoadingIndicator />;
    const { baseValuation, percentProfitToShareholders, returnOnEquity, shareholderEquity, years } = aps.data ?? {
        baseValuation: 0,
        percentProfitToShareholders: 0,
        returnOnEquity: 0,
        shareholderEquity: 0,
        years: [],
    };

    return (
        <div className="space-y-10 p-10">
            <div className="mb-6 flex justify-between">
                <h3 className="text-lg leading-6 font-medium mr-2">Business Forecast</h3>
                <PoweredBy business={business} showServicesOnClick={true} />
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <th />
                        {years.map(y => (
                            <th className="font-extrabold text-4xl" key={`label:${y.year}`}>
                                {y.year}
                            </th>
                        ))}
                    </TableRow>
                </TableHead>
                <CompanyPerformance years={years} />
                <ProjectedCompensationValues years={years} />
                <TotalValueAnalysis years={years} />
                <CumulativeValueSharingEnterpriseValue baseValuation={baseValuation} years={years} />
                <CumulativeValueSharingEbitda years={years} />
                <AllocationSummary years={years} />
                <ReturnOnTotalRewardsInvestment
                    percentProfitToShareholders={percentProfitToShareholders}
                    returnOnEquity={returnOnEquity}
                    shareholderEquity={shareholderEquity}
                    years={years}
                />
            </Table>
        </div>
    );
};

export default BusinessForecast;
