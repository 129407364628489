import classNames from 'classnames';
import * as d3Array from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { useEffect, useRef, useState } from 'react';
import BarChartXAxis from './BarChartXAxis';
import BarChartYAxis from './BarChartYAxis';
import { IBarChartSettings, IChartPoint } from './chartModels';

interface IProps {
    height: number;
    points: IChartPoint[];
    settings?: IBarChartSettings;
    width: number;
}

const marginRight = 15;
const xAxisHeight = 25;

const BarChart = ({ height, points, settings, width }: IProps): JSX.Element => {
    const yAxisRef = useRef<SVGSVGElement>(null);
    const [yAxisWidth, setYAxisWidth] = useState(0);
    const plotHeight = height - (settings?.xAxis?.hideLabels ? 0 : xAxisHeight);
    const plotWidth = Math.max(width - yAxisWidth - marginRight, 0);
    const maxValue = settings?.xAxis?.max || d3Array.max<number>(points.map(p => p.value)) || 0;
    const xScale = scaleLinear().range([0, plotWidth]).domain([0, maxValue]);
    const yScale = scaleBand()
        .rangeRound([plotHeight, 0])
        .padding(0.5)
        .domain(points.map(d => d.name));

    useEffect(() => {
        if (!yAxisRef.current) {
            return;
        }

        setYAxisWidth(yAxisRef.current.getBoundingClientRect().width);
    }, [yAxisRef]);

    return (
        <svg height={height} width={width}>
            <BarChartYAxis ref={yAxisRef} scaleBand={yScale} />
            {yAxisWidth ? (
                <>
                    <BarChartXAxis
                        plotHeight={plotHeight}
                        scaleLinear={xScale}
                        transform={`translate(${yAxisWidth}, ${plotHeight})`}
                    />
                    <g transform={`translate(${yAxisWidth}, 0)`}>
                        {points.map(p => (
                            <g key={p.name}>
                                <foreignObject
                                    height={yScale.bandwidth()}
                                    width={xScale(p.value)}
                                    x={0}
                                    y={yScale(p.name)}
                                >
                                    <div
                                        className={classNames(`w-full h-full rounded-r bg-${p.color}`, {
                                            'cursor-pointer': !!p.onClick,
                                        })}
                                        onClick={p.onClick}
                                    />
                                </foreignObject>
                            </g>
                        ))}
                    </g>
                </>
            ) : null}
        </svg>
    );
};

export { BarChart };
