import { IChartPoint } from './chartModels';

interface IProps {
    point1: IChartPoint;
    point2: IChartPoint;
    valueFormatter: (value: number) => string;
}

const BreakdownChart = ({ point1, point2, valueFormatter }: IProps): JSX.Element => {
    const point1PercentageOfTotal = (point1.value / (point1.value + point2.value)) * 100;

    return (
        <div>
            <div>
                <div className="text-right mb-2 text-2xl 3xl:text-3xl leading-9 font-semibold">
                    {valueFormatter(point2.value)}
                </div>
                <div
                    className={`border-4 border-b-0 border-${point2.color} pr-3 pt-1 mb-3 text-right text-sm leading-5 font-mediu`}
                    style={{ marginLeft: `${point1PercentageOfTotal}%` }}
                >
                    {point2.name}
                </div>
            </div>
            <div className="rounded-3xl overflow-hidden bg-gray-600">
                <div className={`h-6 bg-${point1.color}`} style={{ width: `${point1PercentageOfTotal}%` }}></div>
            </div>
            <div>
                <div
                    className={`border-4 border-t-0 border-${point1.color} pl-3 pb-1 mt-3 text-sm leading-5 font-medium`}
                    style={{ width: `${point1PercentageOfTotal}%` }}
                >
                    {point1.name}
                </div>
                <div className="mt-2 text-2xl 3xl:text-3xl leading-9 font-semibold">{valueFormatter(point1.value)}</div>
            </div>
        </div>
    );
};

export { BreakdownChart };
