interface IProps {
    children: JSX.Element | JSX.Element[];
}

const PanelContainer = ({ children }: IProps): JSX.Element => (
    <div className="relative w-full">
        <div className="absolute inset-0 px-4 py-4 lg:px-8 overflow-y-auto space-y-4 scrollbar-themed">{children}</div>
    </div>
);

export default PanelContainer;
