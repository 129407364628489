import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormActions, SlideOver, useAvatarInput } from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useAddAdvisorFirm, useAdvisorFirmAvatar } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';
import FirmInfoForm, {
    getInitialFormData,
    IFirmInfoFormData,
    mapFormDataToApi,
    schemaValidation,
} from '../advisorFirm/firmInfo/FirmInfoForm';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const AddAdvisorFirm = ({ isOpen, onClose }: IProps): JSX.Element => {
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [formData, setFormData] = useState<IFirmInfoFormData>(getInitialFormData());
    const [newAvatar, setNewAvatar] = useAvatarInput(isOpen);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const addAdvisorFirm = useAddAdvisorFirm();
    const updateAdvisorFirmAvatar = useAdvisorFirmAvatar();
    const onSave = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        const advisorFirm = await addAdvisorFirm.mutateAsync(mapFormDataToApi(formData));
        if (newAvatar?.file) {
            await updateAdvisorFirmAvatar.mutateAsync({ avatar: newAvatar.file, advisorFirmId: advisorFirm.id });
        }
        onClose();
        showSuccessNotification();
    };

    const resetForm = () => {
        setValidationErrors({});
        setFormData(getInitialFormData());
    };

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onSave={onSave} />}
            title="Add Advisor Firm"
        >
            <FirmInfoForm
                avatar={newAvatar?.url}
                errors={validationErrors}
                formData={formData}
                onChange={setFormData}
                onChangeAvatar={setNewAvatar}
            />
        </SlideOver>
    );
};

export default AddAdvisorFirm;
