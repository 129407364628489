interface ItemName {
    extension: string;
    name: string;
}

export const getNameParts = (name: string): ItemName => {
    if (!name) {
        return {
            extension: '',
            name: '',
        };
    }

    let lastDot = name.lastIndexOf('.');
    if (lastDot < 0) {
        lastDot = name.length;
    }

    const namePrefix = name.slice(0, lastDot);
    const extension = name.slice(lastDot);

    return {
        extension,
        name: namePrefix,
    };
};
