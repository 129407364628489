import classNames from 'classnames';

export enum ArrowHeadDirection {
    down = 'down',
    left = 'left',
}

interface IProps {
    className?: string;
    direction: ArrowHeadDirection;
    id: string;
}

export const arrowHeadSize = 4;

const directionPoints: Record<ArrowHeadDirection, string> = {
    down: `0 0, ${arrowHeadSize} ${arrowHeadSize / 2}, 0 ${arrowHeadSize}`,
    left: `${arrowHeadSize} 0, 0 ${arrowHeadSize / 2}, ${arrowHeadSize} ${arrowHeadSize}`,
};
const directionRefX: Record<ArrowHeadDirection, number> = {
    down: 0,
    left: arrowHeadSize,
};

const ArrowHead = ({ className = 'text-gray-400', direction, id }: IProps): JSX.Element => (
    <marker
        id={id}
        markerWidth={arrowHeadSize}
        markerHeight={arrowHeadSize}
        refX={directionRefX[direction]}
        refY={arrowHeadSize / 2}
        orient="auto"
    >
        <polygon className={classNames('fill-current', className)} points={directionPoints[direction]} />
    </marker>
);

export default ArrowHead;
