import {
    BusinessEvaluationsApi,
    IBusinessEvaluationCreateRequest,
    IBusinessEvaluationResponse,
    IProblemDetails,
} from '@api';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const businessEvaluationsApi = new BusinessEvaluationsApi(undefined, config.api.baseUrl);
const getQueryKey = (businessId: string) => getBusinessQueryKey(businessId, 'businessEvaluations');

export const useBusinessEvaluation = (
    businessId: string
): UseQueryResult<IBusinessEvaluationResponse, IProblemDetails> =>
    useQuery(getQueryKey(businessId), () =>
        businessEvaluationsApi.getBusinessEvaluation(businessId).then(response => response.data)
    );

export const useSubmitBusinessEvaluation = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBusinessEvaluationCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBusinessEvaluationCreateRequest>(
        request => businessEvaluationsApi.createBusinessEvaluation(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getQueryKey(businessId));
            },
        }
    );

    return mutation;
};
