import classNames from 'classnames';
import { activeClassName, className, inactiveClassName } from '~/components';

interface INavTabProps {
    children: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
}

const NavTab = ({ children, isActive, onClick }: INavTabProps): JSX.Element => {
    return (
        <span
            className={classNames('cursor-pointer', className, isActive ? activeClassName : inactiveClassName)}
            onClick={onClick}
        >
            {children}
        </span>
    );
};

export default NavTab;
