import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormActions, SlideOver, useAvatarInput } from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useAddAdvisor, useAdvisorAvatar } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';
import FirmInfoForm, {
    getInitialFormData,
    IAdvisorInfoFormData,
    mapFormDataToApi,
    schemaValidation,
} from '../advisorEdit/advisorInfo/AdvisorInfoForm';

interface IProps {
    advisorFirmId: string;
    isOpen: boolean;
    onClose: () => void;
}

const AddAdvisor = ({ advisorFirmId, isOpen, onClose }: IProps): JSX.Element => {
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [formData, setFormData] = useState<IAdvisorInfoFormData>(getInitialFormData());
    const [newAvatar, setNewAvatar] = useAvatarInput(isOpen);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const addAdvisor = useAddAdvisor(advisorFirmId);
    const updateAdvisorAvatar = useAdvisorAvatar(advisorFirmId);
    const onSave = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        const advisor = await addAdvisor.mutateAsync({
            advisorFirmId,
            ...mapFormDataToApi(formData),
        });
        if (newAvatar?.file) {
            await updateAdvisorAvatar.mutateAsync({ avatar: newAvatar.file, advisorId: advisor.id });
        }
        onClose();
        showSuccessNotification();
    };

    const resetForm = () => {
        setValidationErrors({});
        setFormData(getInitialFormData());
    };

    useEffect(() => {
        if (isOpen) {
            resetForm();
        }
    }, [isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onSave={onSave} />}
            title="Add Advisor"
        >
            <FirmInfoForm
                avatar={newAvatar?.url}
                errors={validationErrors}
                formData={formData}
                onChange={setFormData}
                onChangeAvatar={setNewAvatar}
            />
        </SlideOver>
    );
};

export default AddAdvisor;
