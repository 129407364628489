import { IEducation, IEducationUpdateRequest } from '@api';
import { faCloudUpload, faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Button, FormActions, SlideOver, TextArea, TextInput } from '~/components';
import { IEducationCreateRequest } from '~/hooks';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    education?: IEducation;
    isOpen: boolean;
    onClose: () => void;
    onDelete?: () => Promise<void>;
    onSave: (request: IEducationCreateRequest | IEducationUpdateRequest) => Promise<void>;
    title: string;
}

const FILE_SIZE_10_MB = 10 * 1024 * 1024;
const schemaValidation = Yup.object().shape({
    author: Yup.string().transform(transformEmptyStringToNull).nullable().label('Author'),
    description: Yup.string().transform(transformEmptyStringToNull).nullable().label('Description'),
    file: Yup.mixed().when('showFileInput', {
        is: true,
        then: Yup.mixed()
            .required('A file is required')
            .test('type', 'File must be a PDF', value => !value || value.type === 'application/pdf')
            .test('fileSize', 'File is too large. Limit is 10 MB.', value => !value || value.size <= FILE_SIZE_10_MB),
    }),
    showFileInput: Yup.boolean(),
    title: Yup.string().transform(transformEmptyStringToNull).nullable().label('Title'),
});

type IEducationFormData = IEducationCreateRequest & {
    showFileInput: boolean;
};

const getInitialValues = (education?: IEducation): IEducationFormData => {
    return {
        author: education?.author || '',
        description: education?.description || '',
        showFileInput: !education,
        title: education?.title || '',
    };
};

const EducationForm = ({ education, isOpen, onClose, onDelete, onSave, title }: IProps): JSX.Element => {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<IEducationFormData>(getInitialValues(education));
    // Need to keep a count because of how the event bubbles to child elements.
    // Technique described at https://stackoverflow.com/a/21002544
    const [dragCounter, setDragCounter] = useState(0);
    const fileDialog = useRef<HTMLInputElement>(null);

    const handleSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        return onSave(formData);
    };
    const resetForm = (education: IEducation | undefined) => {
        setErrors({});
        setFormData(getInitialValues(education));
    };

    const handleDrop = (fileList: FileList | null) => {
        const file = fileList?.[0];
        if (!file) {
            return;
        }

        setFormData({ ...formData, file, title: file.name });
    };

    useEffect(() => {
        if (isOpen) {
            resetForm(education);
        }
    }, [education, isOpen]);

    return (
        <SlideOver
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onDelete={onDelete} onSave={handleSave} />}
            title={title}
        >
            {education ? (
                <div className="flex items-center">
                    <FontAwesomeIcon icon={faFileAlt} size="lg" className="mr-3" />
                    <div className="break-all">{education.fileName}</div>
                </div>
            ) : (
                <>
                    <div
                        className={classNames(
                            'group w-full p-8 rounded-lg border-4 border-dashed border-gray-700 flex items-center cursor-pointer hover:bg-gray-700',
                            {
                                'bg-gray-700': dragCounter > 0,
                            }
                        )}
                        onClick={() => fileDialog.current?.click()}
                        onDragEnter={() => setDragCounter(dragCounter + 1)}
                        onDragLeave={() => {
                            setDragCounter(dragCounter - 1);
                        }}
                        onDragOver={e => e.preventDefault()}
                        onDrop={event => {
                            event.preventDefault();
                            setDragCounter(dragCounter - 1);
                            handleDrop(event.dataTransfer?.files);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={formData.file ? faFileAlt : faCloudUpload}
                            size="4x"
                            className={classNames('mr-5 group-hover:text-gray-400', {
                                'text-gray-400': dragCounter > 0,
                                'text-gray-700': dragCounter === 0,
                            })}
                        />
                        <span>
                            {formData.file ? (
                                <span className="break-all">{formData.file.name}</span>
                            ) : (
                                <>
                                    Drag and drop to upload a file, or{' '}
                                    <Button color="primaryLink" size="lg">
                                        select a file on disk
                                    </Button>
                                    .
                                </>
                            )}
                        </span>
                        <input
                            accept="application/pdf"
                            className="hidden"
                            onChange={e => handleDrop(e.currentTarget.files)}
                            ref={fileDialog}
                            type="file"
                        />
                    </div>
                    {errors.file && <p className="mt-2 text-sm text-danger-600">{errors.file}</p>}
                </>
            )}
            <div>
                <TextInput
                    error={errors.title}
                    label="Title"
                    value={formData.title || ''}
                    onChange={title => setFormData({ ...formData, title })}
                />
            </div>
            <div>
                <TextInput
                    error={errors.author}
                    label="Author"
                    value={formData.author || ''}
                    onChange={author => setFormData({ ...formData, author })}
                />
            </div>
            <div>
                <TextArea
                    error={errors.description}
                    label="Description"
                    value={formData.description || ''}
                    onChange={description => setFormData({ ...formData, description })}
                />
            </div>
        </SlideOver>
    );
};

export default EducationForm;
