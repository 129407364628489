import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import config from '../config';
import AxiosConfigurationProvider from './AxiosConfigurationProvider';
import LoginRedirect from './LoginRedirect';
import MagicLinkWrapper from './MagicLinkWrapper';

interface IProps {
    children: ReactNode;
}

const AuthWrapper = ({ children }: IProps): JSX.Element => (
    <Auth0Provider
        audience={config.auth0.audience}
        clientId={config.auth0.clientId}
        domain={config.auth0.domain}
        redirectUri={window.location.origin}
    >
        <MagicLinkWrapper>
            <LoginRedirect>
                <AxiosConfigurationProvider>{children}</AxiosConfigurationProvider>
            </LoginRedirect>
        </MagicLinkWrapper>
    </Auth0Provider>
);

export default AuthWrapper;
