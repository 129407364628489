import { IBusiness } from '@api';
import { useHistory } from 'react-router-dom';
import { Button } from '~/components';
import EmployeesUpload from '../business/employees/EmployeesUpload';

interface IProps {
    business: IBusiness;
    onSuccess: (id: string) => void;
}

const EmployeesUploadPage = ({ business, onSuccess }: IProps): JSX.Element => {
    const history = useHistory();

    return (
        <>
            <div className="z-10 top-0 sticky flex justify-between mb-12 pt-10 pb-6 bg-gray-900 border-b border-gray-700">
                <h2 className="text-2xl leading-none self-center">{business.name} Employee Upload</h2>
                <div className="self-center space-x-3">
                    <Button onClick={() => history.goBack()}>Cancel</Button>
                </div>
            </div>
            <EmployeesUpload onSuccess={onSuccess} />
        </>
    );
};

export default EmployeesUploadPage;
