import { IEmployeeRatingConfig, IEmployeeRatingConfigUpdateRequest } from '@api';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
    ErrorModal,
    ErrorPage,
    FormActions,
    LoadingIndicator,
    RadioGroup,
    SlideOver,
    SlideOverSizeType,
    TextInput,
} from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useEmployeeRatingConfig, useUpdateEmployeeRatingConfig } from '~/hooks';
import RatingLegend from '~/pages/business/present/teamValues/RatingLegend';
import { businessPageDisplay } from '~/utils/enumUtils';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';
import { IPageSettingsSlideOver } from '.';

type IRatingConfigFormData = Pick<IEmployeeRatingConfig, 'sortHighToLow'> &
    Strings<Omit<IEmployeeRatingConfig, 'sortHighToLow'>>;

const mapFormDataToApi = (formData: IRatingConfigFormData): IEmployeeRatingConfigUpdateRequest => ({
    ...formData,
    maxRating: formData.maxRating?.length ? parseFloat(formData.maxRating) : 0,
    minRating: formData.minRating?.length ? parseFloat(formData.minRating) : 0,
});

const getInitialValues = (ratingConfig?: IEmployeeRatingConfig): IRatingConfigFormData => ({
    maxRating: ratingConfig?.maxRating?.toString() || '',
    minRating: ratingConfig?.minRating?.toString() || '',
    sortHighToLow: ratingConfig?.sortHighToLow || true,
});

const schemaValidation = Yup.object().shape({
    sortHighToLow: Yup.boolean().nullable().required().label('Sort'),
    maxRating: Yup.number()
        .transform(transformEmptyStringToNull)
        .nullable()
        .required()
        .min(0)
        .moreThan(Yup.ref('minRating'), 'Max must be greater than Min')
        .label('Max'),
    minRating: Yup.number().min(0).transform(transformEmptyStringToNull).nullable().required().label('Min'),
});

const TeamValuesSettings = ({ business, isOpen, onClose }: IPageSettingsSlideOver): JSX.Element => {
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const { data: employeeRatingConfig, error, isLoading } = useEmployeeRatingConfig(business.id);
    const updateRatingConfig = useUpdateEmployeeRatingConfig(business.id);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [apiError, setApiError] = useState(false);
    const [formData, setFormData] = useState(getInitialValues(employeeRatingConfig));
    let content = null;

    useEffect(() => {
        if (employeeRatingConfig) {
            resetForm(employeeRatingConfig);
        }
    }, [employeeRatingConfig]);

    const handleSave = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
            return Promise.resolve();
        }
        try {
            await updateRatingConfig.mutateAsync({
                ...(employeeRatingConfig ?? {}),
                ...mapFormDataToApi(formData),
            });
            showSuccessNotification();
            onClose();
        } catch {
            setApiError(true);
        }
    };
    const resetForm = (ratingConfig: IEmployeeRatingConfig | undefined) => {
        setApiError(false);
        setErrors({});
        setFormData(getInitialValues(ratingConfig));
    };

    if (error) {
        content = <ErrorPage />;
    } else if (isLoading) {
        content = <LoadingIndicator />;
    } else {
        content = (
            <div className="grid grid-cols-1 gap-4">
                <RatingLegend {...mapFormDataToApi(formData)} />
                <div className="grid grid-cols-2 gap-3 sm:gap-6">
                    <div className="col">
                        <TextInput
                            error={errors.minRating}
                            label="Min"
                            value={formData.minRating}
                            onChange={minRating => setFormData({ ...formData, minRating })}
                        />
                    </div>
                    <div className="col">
                        <TextInput
                            error={errors.maxRating}
                            label="Max"
                            value={formData.maxRating}
                            onChange={maxRating => setFormData({ ...formData, maxRating })}
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium mb-1">Sort</label>
                    <RadioGroup
                        error={errors.sortHighToLow}
                        value={`${formData.sortHighToLow}`}
                        ariaLabel="Sort"
                        options={[
                            {
                                label: 'High to Low',
                                value: 'true',
                            },
                            {
                                label: 'Low to High',
                                value: 'false',
                            },
                        ]}
                        onChange={value => setFormData({ ...formData, sortHighToLow: value === 'true' })}
                    />
                </div>
            </div>
        );
    }

    return (
        <SlideOver
            size={SlideOverSizeType.lg}
            isOpen={isOpen}
            onClose={onClose}
            stickyFooter={<FormActions onCancel={onClose} onSave={handleSave} />}
            title={businessPageDisplay.TeamValues}
        >
            {content}
            <ErrorModal open={apiError} setIsOpen={() => setApiError(false)} />
        </SlideOver>
    );
};

export default TeamValuesSettings;
