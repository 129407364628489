import { IUserEntitlementType } from '@api';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { BackLink, Can, CurrentUserMenu, NavBar } from '~/components';
import innerZonePreview from '../../images/innerzone-preview-sm.png';
import Education from './education';
import EducationStatus from './education/Status';
import InnerZoneSetting from './InnerZoneSetting';
import EditMe from './me/EditMe';

const AdvisorSettings = (): JSX.Element => {
    const { path } = useRouteMatch();

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <NavBar>
                <div></div>
                <div className="flex">
                    <CurrentUserMenu />
                </div>
            </NavBar>
            <main className="flex h-full max-h-full overflow-y-auto scrollbar-themed focus:outline-none" tabIndex={0}>
                <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <Switch>
                        <Route path={`${path}/Me`}>
                            <EditMe />
                        </Route>
                        <Route path={`${path}/Education`}>
                            <Education />
                        </Route>
                        <Route path={path}>
                            <h2 className="text-2xl leading-9 self-center mb-12 pt-10 pb-6 border-b border-gray-700 relative">
                                Tools Settings
                                <BackLink relative className="top-5" />
                            </h2>
                            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 pb-4 ">
                                <div className="sm:rounded-2xl bg-gray-800 overflow-hidden">
                                    <img
                                        alt="InnerZone"
                                        className="hidden sm:block opacity-80 h-56 object-cover"
                                        src={innerZonePreview}
                                    />
                                    <div className="px-9 py-6">
                                        <div className="max-w-lg h-full mx-auto flex flex-col justify-center">
                                            <InnerZoneSetting />
                                        </div>
                                    </div>
                                </div>
                                <Can hasEntitlement={IUserEntitlementType.EducationCreate}>
                                    <EducationStatus />
                                </Can>
                            </div>
                        </Route>
                    </Switch>
                </div>
            </main>
        </div>
    );
};

export default AdvisorSettings;
