import { IBusiness, IEmployee, IUserEntitlementType } from '@api';
import { faPaperPlane, faPlusCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useContext, useState } from 'react';
import {
    Avatar,
    Button,
    Can,
    DeleteConfirmationModal,
    DropdownMenu,
    EntitySelect,
    ErrorModal,
    ErrorPage,
    LoadingIndicator,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import {
    useAddBusinessOwner,
    useBusinessOwners,
    useCurrentUser,
    useDeleteBusinessOwner,
    useEmployees,
    useResetPasswordBusinessOwner,
    useUpdateEmployee,
} from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import EmployeesNoData from '../../employees/EmployeesNoData';
import EmployeeEmailModal from './EmployeeEmailModal';

interface IPortalUserActionsProps {
    business: IBusiness;
    userId: string;
}

const PortalUserActions = ({ business, userId }: IPortalUserActionsProps) => {
    const { withSuccessNotification } = useContext(SuccessNotificationContext);
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const deleteBusinessOwner = useDeleteBusinessOwner(business.id);
    const resetPasswordBusinessOwner = useResetPasswordBusinessOwner();
    return (
        <div className="space-x-6">
            <Can hasEntitlement={IUserEntitlementType.BusinessOwnerResetPassword}>
                <Button
                    color="secondary"
                    disabled={resetPasswordBusinessOwner.isLoading}
                    onClick={withSuccessNotification(() => resetPasswordBusinessOwner.mutateAsync(userId))}
                    size="md"
                >
                    <FontAwesomeIcon className="mr-2" icon={faPaperPlane} />
                    Reset Password
                </Button>
            </Can>
            <Can hasEntitlement={IUserEntitlementType.BusinessOwnerDelete}>
                <Button
                    color="secondary"
                    disabled={deleteBusinessOwner.isLoading}
                    onClick={() => setDeleteState({ isOpen: true })}
                    size="md"
                >
                    <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
                    Delete
                </Button>
                <DeleteConfirmationModal
                    onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                    onConfirm={withSuccessNotification(() => deleteBusinessOwner.mutateAsync(userId))}
                    title="Delete Portal User"
                    {...deleteState}
                >
                    Are you sure you want to delete this portal user? All of your data will be permanently removed from
                    our servers forever. This action cannot be undone.
                </DeleteConfirmationModal>
            </Can>
        </div>
    );
};

interface IProps {
    business: IBusiness;
}
const PortalAccess = ({ business }: IProps): JSX.Element => {
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [emailEditEmployee, setEmailEditEmployee] = useState<IEmployee>();
    const [employeesBeingAdded, setEmployeesBeingAdded] = useState<string[]>([]);
    const [isSavingEmployeeEmail, setIsSavingEmployeeEmail] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        isError: isBusinessOwnersError,
        isLoading: isBusinessOwnersLoading,
        data: businessOwners = [],
    } = useBusinessOwners(business.id);
    const addBusinessOwner = useAddBusinessOwner(business.id);
    const { data: currentUser } = useCurrentUser();
    const {
        isError: isEmployeesError,
        isLoading: isEmployeesLoading,
        data: employees = [],
    } = useEmployees(business.id);

    const updateEmployee = useUpdateEmployee(business.id);
    const onAddBusinessOwnerUser = (employeeId: string) => {
        setEmployeesBeingAdded([...employeesBeingAdded, employeeId]);
        addBusinessOwner
            .mutateAsync({ userId: employeeId })
            .then(() => showSuccessNotification())
            .catch((err: unknown) => {
                setErrorMessage(
                    axios.isAxiosError(err) && err.response?.status === 409
                        ? `The email address already has portal access: ${
                              employees.find(e => e.id === employeeId)?.emailAddress
                          }`
                        : ''
                );
                setShowErrorModal(true);
            })
            .finally(() => setEmployeesBeingAdded(employeesBeingAdded.filter(id => id !== employeeId)));
    };
    const onTryAddBusinessOwnerUser = (employeeId: string) => {
        const employee = employees.find(e => e.id === employeeId);

        if (!employee?.emailAddress) {
            setEmailEditEmployee(employee);
            return;
        }
        onAddBusinessOwnerUser(employeeId);
    };
    const onUpdateEmail = async (emailAddress: string) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const employee = emailEditEmployee!;

        setIsSavingEmployeeEmail(true);
        try {
            await updateEmployee.mutateAsync({ ...employee, emailAddress, employeeId: employee.id });
        } catch {
            setIsSavingEmployeeEmail(false);
            setShowErrorModal(true);
        }
        setIsSavingEmployeeEmail(false);
        onAddBusinessOwnerUser(employee.id);
        setEmailEditEmployee(undefined);
    };

    if (isBusinessOwnersError || isEmployeesError) return <ErrorPage />;
    if (isBusinessOwnersLoading || isEmployeesLoading) return <LoadingIndicator />;

    const userOptions = employees
        .filter(e => !businessOwners.some(u => u.id === e.id))
        .sort(a => (a.isOwner ? -1 : 1))
        .map(e => ({
            avatar: e.avatar || undefined,
            description: e.emailAddress || undefined,
            disabled: employeesBeingAdded.some(id => id === e.id),
            label: NameFormatter.getLastNameFirst(e),
            name: NameFormatter.getLastNameFirst(e),
            showAddButton: !employeesBeingAdded.some(id => id === e.id),
            showLoading: employeesBeingAdded.some(id => id === e.id),
            value: e.id,
        }));
    const hasEmployees = employees.length > 0;

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Portal Access</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-gray-300">
                Invite employees to use the Business Portal.
            </p>
            {!hasEmployees && <EmployeesNoData businessId={business.id} />}
            {hasEmployees && (
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>User</TableHeaderCell>
                                <TableHeaderCell className="text-right">
                                    <Can hasEntitlement={IUserEntitlementType.BusinessOwnerCreate}>
                                        <DropdownMenu itemsClassName="w-80" position="right">
                                            <DropdownMenu.Trigger as={Button}>
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    size="lg"
                                                    aria-hidden
                                                    className="mr-2"
                                                />
                                                Add New
                                            </DropdownMenu.Trigger>
                                            <EntitySelect
                                                menuIsOpen
                                                isCreatable={false}
                                                label=""
                                                options={userOptions}
                                                onEntityOptionSelected={e => onTryAddBusinessOwnerUser(e.value)}
                                            />
                                        </DropdownMenu>
                                    </Can>
                                </TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {businessOwners.length === 0 && (
                                <TableRow key="no-data">
                                    <TableCell className="text-lg">No employees with portal access yet.</TableCell>
                                </TableRow>
                            )}
                            {businessOwners.map(u => (
                                <TableRow key={u.id}>
                                    <TableCell className="flex">
                                        <Avatar size={12} src={u.avatar || undefined} />
                                        <div className="ml-3">
                                            <div className="font-medium text-lg">
                                                {NameFormatter.getLastNameFirst(u)}
                                                <p className="text-gray-400 text-sm truncate">{u.emailAddress}</p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {currentUser?.entitlements.some(
                                            e =>
                                                e === IUserEntitlementType.BusinessOwnerDelete ||
                                                e === IUserEntitlementType.BusinessOwnerResetPassword
                                        ) && <PortalUserActions business={business} userId={u.id} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <ErrorModal message={errorMessage} open={showErrorModal} setIsOpen={setShowErrorModal} />
                    <EmployeeEmailModal
                        disabled={isSavingEmployeeEmail}
                        employee={emailEditEmployee}
                        open={!!emailEditEmployee}
                        onSave={onUpdateEmail}
                        setIsOpen={open => {
                            if (!open) {
                                setEmailEditEmployee(undefined);
                            }
                        }}
                    />
                </>
            )}
        </>
    );
};

export default PortalAccess;
