import {
    BusinessesApi,
    IAvatar,
    IBusiness,
    IBusinessCreateRequest,
    IBusinessSummary,
    IBusinessUpdateRequest,
    IBusinessValuation,
    IProblemDetails,
} from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from 'react-query';
import config from '~/config';

const businessesApi = new BusinessesApi(undefined, config.api.baseUrl);
export const businessesQueryKey = 'businesses';
export const businessQueryKey = 'business';

type key = string | number;

export const getBusinessQueryKey = (businessId: string, ...rest: key[]): QueryKey => [
    businessQueryKey,
    businessId,
    ...(rest || []),
];

export const useAddBusiness = (): UseMutationResult<IBusiness, IProblemDetails, IBusinessCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IBusiness, IProblemDetails, IBusinessCreateRequest>(
        request => businessesApi.postBusiness(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(businessesQueryKey);
            },
        }
    );

    return mutation;
};

export const useBusiness = (
    businessId: string,
    queryOptions?: Partial<UseQueryOptions<IBusiness, IProblemDetails>>
): UseQueryResult<IBusiness, IProblemDetails> =>
    useQuery(
        getBusinessQueryKey(businessId),
        () => businessesApi.getBusiness(businessId).then(response => response.data),
        {
            ...queryOptions,
        }
    );

export const useBusinesses = (): UseQueryResult<IBusinessSummary[], IProblemDetails> =>
    useQuery(businessesQueryKey, () => businessesApi.getBusinesses().then(response => response.data));

export const useDeleteBusiness = (): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        businessId => businessesApi.deleteBusiness(businessId, undefined).then(response => response.data),
        {
            onSuccess: (_, businessId) => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useUpdateBusiness = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBusinessUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBusinessUpdateRequest>(
        request => businessesApi.putBusiness(businessId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

interface IBusinessAvatarParams {
    avatar: File;
    businessId: string;
}

export const useBusinessAvatar = (): UseMutationResult<IAvatar, IProblemDetails, IBusinessAvatarParams> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<IAvatar, IProblemDetails, IBusinessAvatarParams>(
        ({ avatar, businessId }: IBusinessAvatarParams) =>
            businessesApi.setBusinessAvatar(businessId, avatar).then(response => response.data),
        {
            onSuccess: (_, { businessId }) => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useAddSampleCase = (): UseMutationResult<void, IProblemDetails, void> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, void>(
        () => businessesApi.createSampleCase().then(response => response.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(businessesQueryKey);
            },
        }
    );

    return mutation;
};

export const getBusinessValuationQueryKey = (businessId: string): QueryKey =>
    getBusinessQueryKey(businessId, 'valuation');

export const useBusinessValuation = (businessId: string): UseQueryResult<IBusinessValuation, IProblemDetails> =>
    useQuery(
        getBusinessValuationQueryKey(businessId),
        () => businessesApi.getBusinessValuation(businessId).then(response => response.data),
        {
            enabled: !!businessId,
        }
    );

export const useSetBusinessLocation = (businessId: string): UseMutationResult<void, IProblemDetails, void> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, void>(
        () => businessesApi.setBusinessLocation(businessId).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

function invalidateBusiness(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(businessesQueryKey);
    queryClient.invalidateQueries(getBusinessQueryKey(businessId));
}
