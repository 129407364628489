import { IInnerZoneEmployeeUser } from '@api';
import { Avatar, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import { Checkbox } from '~/components/Checkbox';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    employeeUsers: IInnerZoneEmployeeUser[];
    selectedEmployeeIds: string[];
    onEmployeeUserClick: (employeeId: string) => void;
}

const EmployeeSelect = ({ employeeUsers, selectedEmployeeIds, onEmployeeUserClick }: IProps): JSX.Element => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeaderCell></TableHeaderCell>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell className="text-right">Title</TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employeeUsers.map(u => (
                    <TableRow
                        className="cursor-pointer"
                        key={u.employeeId}
                        onClick={() => onEmployeeUserClick(u.employeeId)}
                    >
                        <TableCell className="pr-0">
                            <Checkbox
                                checked={selectedEmployeeIds.some(employeeId => u.employeeId === employeeId)}
                                label=""
                                name={`employee-${u.employeeId}`}
                                readOnly
                            />
                        </TableCell>
                        <TableCell className="whitespace-nowrap">
                            <div className="flex items-center">
                                <Avatar className="mr-3" src={u.avatar || undefined} />
                                <div>{NameFormatter.getName(u)}</div>
                            </div>
                        </TableCell>
                        <TableCell className="whitespace-nowrap text-right">{u.title}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default EmployeeSelect;
