import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAddressBook, faArrowLeft, faBuilding, faPresentation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Link, NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useAdvisorFirm } from '~/hooks';
import AdvisorList from '~/pages/advisorList';
import { ILocationState } from '../ILocationState';
import FirmInfo from './firmInfo/FirmInfo';
import PresentationSettings from './presentationSettings/PresentationSettings';

interface AdvisorFirmParams {
    firmId: string;
}

enum EditFirmPageType {
    Advisors = 'Advisors',
    Info = 'Info',
    PresentationSettings = 'PresentationSettings',
}

const pageDisplay: Record<EditFirmPageType, string> = {
    Advisors: 'Advisors',
    Info: 'Firm Info',
    PresentationSettings: 'Presentation Settings',
};

const pageIcon: Record<EditFirmPageType, IconProp> = {
    Advisors: faAddressBook,
    Info: faBuilding,
    PresentationSettings: faPresentation,
};

const pages = [EditFirmPageType.Info, EditFirmPageType.Advisors, EditFirmPageType.PresentationSettings];

const AdvisorFirmEdit = (): JSX.Element | null => {
    const match = useRouteMatch<AdvisorFirmParams>();
    const { firmId } = match.params;
    const { isLoading, error, data: advisorFirm } = useAdvisorFirm(firmId);
    const [firmSettingsLocationState, setFirmSettingsLocationState] = useState<ILocationState>();
    const { state: locationState } = useLocation<ILocationState>();

    useEffect(() => {
        if (locationState) {
            setFirmSettingsLocationState(locationState);
        }
    }, [locationState]);

    if (error) return <ErrorPage />;
    if (isLoading || !advisorFirm) return <LoadingIndicator />;

    return (
        <div className="max-w-7xl w-full mx-auto sm:px-6 lg:px-8">
            <div className="mb-12 pt-10 pb-6 bg-gray-900 border-b border-gray-700">
                <Link
                    className="text-sm text-primary hover:text-primary-300"
                    to={firmSettingsLocationState?.backUrl ?? '/'}
                >
                    <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                    Back {firmSettingsLocationState?.label && `to ${firmSettingsLocationState.label}`}
                </Link>
                <h2 className="text-2xl leading-none self-center mt-2">{advisorFirm.name} Settings</h2>
            </div>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                    <nav className="space-y-1">
                        {pages.map(p => (
                            <NavLink
                                key={p}
                                activeClassName="bg-gray-800 text-white"
                                className="group flex items-center px-2 py-2 rounded-md hover:bg-gray-800"
                                to={`/${firmId}/${p}`}
                            >
                                <FontAwesomeIcon fixedWidth className="mr-4" icon={pageIcon[p]} />
                                {pageDisplay[p]}
                            </NavLink>
                        ))}
                    </nav>
                </aside>
                <div className="sm:px-6 lg:px-0 sm:pb-6 lg:pb-8 lg:col-span-9">
                    <Switch>
                        <Route path={`${match.path}/${EditFirmPageType.Advisors}`}>
                            <AdvisorList firm={advisorFirm} />
                        </Route>
                        <Route path={`${match.path}/${EditFirmPageType.Info}`}>
                            <FirmInfo advisorFirm={advisorFirm} />
                        </Route>
                        <Route path={`${match.path}/${EditFirmPageType.PresentationSettings}`}>
                            <PresentationSettings advisorFirm={advisorFirm} />
                        </Route>
                        <Redirect to={`${match.url}/${EditFirmPageType.Info}`} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default AdvisorFirmEdit;
