export interface IAnswer {
    value: number;
}

export interface IQuestion {
    id: string;
    header: string;
    content: string;
}

export enum VisibleSection {
    None,
    Questions,
    Results,
}
