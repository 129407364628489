import { BusinessEducationApi, IBusinessEducationUpdateRequest, IEducation, IProblemDetails } from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getEducationQueryKey } from './education';

const businessEducationApi = new BusinessEducationApi(undefined, config.api.baseUrl);
export const getBusinessEducationQueryKey = (businessId: string): QueryKey => getEducationQueryKey(businessId);

export const useUpdateBusinessEducation = (
    businessId: string
): UseMutationResult<void, IProblemDetails, IBusinessEducationUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, IBusinessEducationUpdateRequest>(
        request =>
            businessEducationApi.putBusinessEducation(businessId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusinessEducation(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useBusinessEducation = (businessId: string): UseQueryResult<IEducation[], IProblemDetails> => {
    return useQuery(
        getBusinessEducationQueryKey(businessId),
        () => businessEducationApi.getBusinessEducation(businessId).then(response => response.data),
        { enabled: !!businessId }
    );
};

function invalidateBusinessEducation(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getBusinessEducationQueryKey(businessId));
}
