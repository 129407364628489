import { INote, INoteCreateRequest, INoteUpdateRequest, IProblemDetails, NotesApi } from '@api';
import {
    QueryClient,
    QueryKey,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from 'react-query';
import config from '~/config';
import { getBusinessQueryKey } from './businesses';

const notesApi = new NotesApi(undefined, config.api.baseUrl);

const getNotesQueryKey = (businessId: string, ...rest: string[]): QueryKey =>
    getBusinessQueryKey(businessId, 'notes', ...(rest || []));

export const useAddNote = (businessId: string): UseMutationResult<INote, IProblemDetails, INoteCreateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<INote, IProblemDetails, INoteCreateRequest>(
        request => notesApi.postNote(undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useDeleteNote = (businessId: string): UseMutationResult<void, IProblemDetails, string> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, string>(
        noteId => notesApi.deleteNote(noteId, undefined).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useUpdateNote = (
    businessId: string,
    noteId: string
): UseMutationResult<void, IProblemDetails, INoteUpdateRequest> => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, IProblemDetails, INoteUpdateRequest>(
        request => notesApi.putNote(noteId, undefined, request).then(response => response.data),
        {
            onSuccess: () => {
                invalidateBusiness(queryClient, businessId);
            },
        }
    );

    return mutation;
};

export const useNotes = (businessId: string, enabled = true): UseQueryResult<INote[], IProblemDetails> =>
    useQuery(
        getNotesQueryKey(businessId),
        () => notesApi.getNotesForBusiness(businessId).then(response => response.data),
        { enabled: !!businessId && enabled }
    );

function invalidateBusiness(queryClient: QueryClient, businessId: string) {
    queryClient.invalidateQueries(getBusinessQueryKey(businessId));
}
