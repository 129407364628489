import {
    IBusiness,
    IBusinessPageType,
    IInnerZoneBusinessDetail,
    IInnerZoneEmployeeUser,
    IInnerZonePrimaryReport,
} from '@api';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, ErrorPage, ISelectOption, LoadingIndicator, Select } from '~/components';
import {
    isTrueForAnyQuery,
    useBoundingClientRect,
    useEmployees,
    useInnerZonePrimaryReport,
    useInnerZoneSetPrimaryReport,
} from '~/hooks';
import { ILocationState } from '~/pages/ILocationState';
import { missingDepartmentValue } from '~/utils/employeeUtils';
import { businessPageDisplay } from '~/utils/enumUtils';
import EmployeesNoData from '../../employees/EmployeesNoData';
import CommunicationReport from './CommunicationReport';
import CustomizeTeamSlideOver from './slideOvers/CustomizeTeamSlideOver';
import SurveyStatusSlideOver from './slideOvers/SurveyStatusSlideOver';
import UserSurveyProgress from './UserSurveyProgress';

interface IProps {
    business: IBusiness;
    businessDetail: IInnerZoneBusinessDetail;
}

const myTeamOptionValue = 'MyTeam';

const getAllEmployeeUsersExceptPrimaryEmployee = (
    employeeUsers: IInnerZoneEmployeeUser[],
    business: IBusiness
): IInnerZoneEmployeeUser[] =>
    business.primaryEmployeeId ? employeeUsers.filter(u => u.employeeId !== business.primaryEmployeeId) : employeeUsers;

const getDepartments = (employeeUsers: IInnerZoneEmployeeUser[]): string[] => {
    const departments = employeeUsers.map(e => (!e.department ? missingDepartmentValue : e.department));
    const uniqueDepartments = [...new Set(departments)];

    uniqueDepartments.sort((a, b) => a.localeCompare(b));

    return uniqueDepartments;
};

const getPrimaryEmployeeUser = (
    business: IBusiness,
    allEmployeeUsers: IInnerZoneEmployeeUser[]
): IInnerZoneEmployeeUser | undefined =>
    business.primaryEmployeeId ? allEmployeeUsers.find(u => u.employeeId === business.primaryEmployeeId) : undefined;

const getReportEmployeeUsers = (
    selectValue: string,
    allEmployeeUsers: IInnerZoneEmployeeUser[],
    primaryReport: IInnerZonePrimaryReport | undefined,
    business: IBusiness
): IInnerZoneEmployeeUser[] => {
    if (selectValue === myTeamOptionValue) {
        if (!primaryReport) return [];
        const primaryEmployeeUser = getPrimaryEmployeeUser(business, allEmployeeUsers);

        return [
            ...(primaryEmployeeUser ? [primaryEmployeeUser] : []),
            ...allEmployeeUsers.filter(u => primaryReport.employeeIds.some(id => id === u.employeeId)),
        ];
    }
    if (selectValue === missingDepartmentValue) {
        return allEmployeeUsers.filter(
            u => !u.department && (business.primaryEmployeeId ? u.employeeId !== business.primaryEmployeeId : true)
        );
    }
    return allEmployeeUsers.filter(u => u.department === selectValue);
};

const TeamCommunication = ({ business, businessDetail }: IProps): JSX.Element => {
    const [selectValue, setSelectValue] = useState<string>(myTeamOptionValue);
    const [isCustomizeTeamOpen, setIsCustomizeTeamOpen] = useState(false);
    const [isSurveyStatusOpen, setIsSurveyStatusOpen] = useState(false);
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    const employeesQuery = useEmployees(business.id);
    const innerZonePrimaryReportQuery = useInnerZonePrimaryReport(business.id);
    const setInnerZonePrimaryReport = useInnerZoneSetPrimaryReport(business.id);
    const employeeUsersExceptPrimaryEmployee = getAllEmployeeUsersExceptPrimaryEmployee(businessDetail.users, business);
    const departments = getDepartments(employeeUsersExceptPrimaryEmployee);
    const departmentSelectOptions = departments.map(d => ({ label: d, value: d }));
    const businessSettingsState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Team Communication',
    };
    const isError = isTrueForAnyQuery('isError', employeesQuery, innerZonePrimaryReportQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeesQuery, innerZonePrimaryReportQuery);
    const { data: employees = [] } = employeesQuery;
    const { data: primaryReport } = innerZonePrimaryReportQuery;
    useEffect(() => {
        if (!primaryReport) {
            return;
        }
        setSelectedEmployeeIds(primaryReport.employeeIds);
    }, [primaryReport]);

    if (isError) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    const hasEmployees = employees.length > 0;
    const onCancelCustomizeTeam = () => {
        setSelectedEmployeeIds(primaryReport?.employeeIds || []);
        setIsCustomizeTeamOpen(false);
    };
    const onSaveCustomizeTeam = () => {
        setInnerZonePrimaryReport
            .mutateAsync({ employeeIds: selectedEmployeeIds || [] })
            .then(() => setIsCustomizeTeamOpen(false));
    };

    return (
        <div className="flex flex-col h-full p-10">
            <h2 className="mb-5 text-xl">{businessPageDisplay[IBusinessPageType.InnerZone]}</h2>
            {!hasEmployees && (
                <EmployeesNoData businessId={business.id} businessSettingsState={businessSettingsState} />
            )}
            {hasEmployees && (
                <>
                    <div className="flex">
                        <div className="w-40">
                            <Select
                                value={selectValue}
                                options={[{ label: 'My Team', value: myTeamOptionValue }, ...departmentSelectOptions]}
                                onChange={e => setSelectValue((e as ISelectOption).value)}
                            />
                        </div>
                        {selectValue === myTeamOptionValue && (
                            <Button className="ml-auto" onClick={() => setIsCustomizeTeamOpen(true)}>
                                Customize Team
                            </Button>
                        )}
                    </div>
                    <div className="flex flex-grow items-center relative" ref={chartContainerRef}>
                        <CommunicationReport
                            chartHeight={chartContainerRect?.height || 0}
                            chartWidth={chartContainerRect?.width || 0}
                            employeeUsers={getReportEmployeeUsers(
                                selectValue,
                                businessDetail.users,
                                primaryReport,
                                business
                            )}
                            employeeLinks={businessDetail.links}
                        />
                        <UserSurveyProgress
                            className="absolute bottom-0 right-0 cursor-pointer"
                            employeeUsers={businessDetail.users}
                            onClick={() => setIsSurveyStatusOpen(true)}
                        />
                    </div>
                    <CustomizeTeamSlideOver
                        employeeUsers={employeeUsersExceptPrimaryEmployee}
                        isOpen={isCustomizeTeamOpen}
                        selectedEmployeeIds={selectedEmployeeIds}
                        onClose={onCancelCustomizeTeam}
                        onEmployeeUserClick={employeeId => {
                            if (selectedEmployeeIds.indexOf(employeeId) === -1) {
                                setSelectedEmployeeIds([...selectedEmployeeIds, employeeId]);
                            } else {
                                setSelectedEmployeeIds(selectedEmployeeIds.filter(id => id !== employeeId));
                            }
                        }}
                        onSave={onSaveCustomizeTeam}
                    />
                    <SurveyStatusSlideOver
                        employeeUsers={businessDetail.users}
                        isOpen={isSurveyStatusOpen}
                        onClose={() => setIsSurveyStatusOpen(false)}
                    />
                </>
            )}
        </div>
    );
};

export default TeamCommunication;
