import classnames from 'classnames';
import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
    color?: 'danger' | 'link' | 'primary' | 'primaryLink' | 'secondary' | 'tertiary';
    fullWidth?: boolean;
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const classNameByColor = {
    danger: 'bg-danger border-transparent text-white disabled:bg-danger hover:bg-danger-400',
    link: '',
    primary: 'text-white bg-primary-600 hover:bg-primary-500',
    primaryLink: 'text-primary hover:text-primary-300',
    secondary: 'border border-gray-700 bg-transparent disabled:bg-transparent hover:bg-white hover:bg-opacity-20',
    tertiary: 'border border-current bg-transparent disabled:bg-transparent hover:bg-white hover:bg-opacity-20',
};

const classNameBySize = {
    sm: 'text-xs leading-4 font-medium rounded',
    md: 'text-sm leading-5 font-medium rounded-md',
    lg: 'text-base leading-6 font-medium rounded-md',
    xl: 'text-base leading-6 font-medium rounded',
    xxl: 'text-lg leading-6 font-normal rounded',
};

const paddingClassNameBySize = {
    sm: 'px-2 py-1',
    md: 'px-4 py-2',
    lg: 'px-6 py-3',
    xl: 'px-6 py-3',
    xxl: 'px-8 py-4',
};

const ButtonComponent = (
    { className, color = 'secondary', fullWidth, size = 'md', type = 'button', ...rest }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
) => (
    <button
        className={classnames(
            classNameByColor[color],
            classNameBySize[size],
            { [paddingClassNameBySize[size]]: color !== 'link' && color !== 'primaryLink' },
            `
            disabled:cursor-not-allowed
            disabled:opacity-50
            focus:outline-none
            focus:ring-2
            focus:ring-primary-500
            items-center
            shadow-sm
            `,
            { 'w-full block': fullWidth },
            { 'inline-flex': !fullWidth },
            className
        )}
        ref={ref}
        type={type}
        {...rest}
    />
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(ButtonComponent);
