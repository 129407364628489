import { IBusiness } from '@api';
import CommunityMap from './CommunityMap';
import LocationMissing from './LocationMissing';

interface IProps {
    business: IBusiness;
}

const Community = ({ business }: IProps): JSX.Element | null => {
    const { latitude, longitude } = business;

    if (!latitude || !longitude) return <LocationMissing businessId={business.id} />;

    return (
        <div className="h-full">
            <CommunityMap business={business} latitude={latitude} longitude={longitude} />
        </div>
    );
};

export default Community;
