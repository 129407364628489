import { ICreateBonusRightConnectionRequest } from '@api';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button, Modal, TextInput } from '~/components';
import { useCreateBonusRightConnection } from '~/hooks/bonusRightConnection';
import { yupToFormErrors } from '~/utils/yupUtils';
import visionLinkLogo from '../VisionLinkLogo.png';

interface IProps {
    businessId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const schemaValidation = Yup.object().shape({
    email: Yup.string().label('Email').required().email(),
    password: Yup.string().label('Password').required(),
});

const ConnectToBonusRightModal = ({ businessId, open, setOpen }: IProps): JSX.Element => {
    const createBonusRightConnection = useCreateBonusRightConnection(businessId);
    const [formData, setFormData] = useState<ICreateBonusRightConnectionRequest>({ email: '', password: '' });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const onSave = () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setValidationErrors(yupToFormErrors(err));
            }
            return;
        }
        setIsLoggingIn(true);
        setLoginErrorMessage(undefined);
        setValidationErrors({});
        createBonusRightConnection
            .mutateAsync(formData)
            .then(d => {
                setIsLoggingIn(false);
                if (d.isConnected) {
                    setOpen(false);
                } else {
                    setLoginErrorMessage(
                        'Sorry, there was an error logging into VisionLink. Please check your username and password.'
                    );
                }
            })
            .catch(() => {
                setLoginErrorMessage(
                    'Sorry, there was an error logging into VisionLink. Please check your username and password.'
                );
                setIsLoggingIn(false);
            });
    };

    return (
        <Modal maxWidth="sm" open={open} setOpen={setOpen} title="Link Accounts">
            <form
                onSubmit={e => {
                    e.preventDefault();
                    onSave();
                }}
            >
                <fieldset disabled={isLoggingIn}>
                    <div className="p-6 space-y-8">
                        <div className="flex flex-col items-center space-y-2">
                            <img alt="Vision Link Logo" src={visionLinkLogo} />
                        </div>
                        <div className="space-y-4">
                            <TextInput
                                error={validationErrors.email}
                                label="Email"
                                onChange={email => {
                                    setFormData({ ...formData, email });
                                    setLoginErrorMessage(undefined);
                                    setValidationErrors({});
                                }}
                            />
                            <TextInput
                                error={validationErrors.password}
                                onChange={password => {
                                    setFormData({ ...formData, password });
                                    setLoginErrorMessage(undefined);
                                    setValidationErrors({});
                                }}
                                label="Password"
                                type="password"
                            />
                        </div>
                        {loginErrorMessage && <p className="space-y-4 text-sm text-danger-600">{loginErrorMessage}</p>}
                        <div className="space-x-3 flex justify-end">
                            <Button color="secondary" onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </Modal>
    );
};

export default ConnectToBonusRightModal;
