import { IEmployee } from '@api';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, IconButton, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { getAgeAsString } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    employees: IEmployee[];
    onEdit: (employeeId: string) => void;
}

const EmployeeList = ({ employees, onEdit }: IProps): JSX.Element => {
    return (
        <Table className="w-full">
            <TableHead>
                <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Age</TableHeaderCell>
                    <TableHeaderCell>Title</TableHeaderCell>
                    <TableHeaderCell className="text-right">Salary</TableHeaderCell>
                    <TableHeaderCell className="w-28">
                        <span className="sr-only">Edit</span>
                    </TableHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {employees.map(employee => (
                    <TableRow key={employee.id}>
                        <TableCell>
                            <Avatar className="mr-4" src={employee.avatar || ''} />
                            <span>{NameFormatter.getLastNameFirst(employee)}</span>
                        </TableCell>
                        <TableCell>{getAgeAsString(employee.dateOfBirth)}</TableCell>
                        <TableCell>{employee.title}</TableCell>
                        <TableCell className="text-right">{CurrencyFormatter.format(employee.salary)}</TableCell>
                        <TableCell className="flex justify-end w-28">
                            <IconButton
                                className="text-primary hover:text-primary-300"
                                onClick={() => onEdit(employee.id)}
                            >
                                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                Edit
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export { EmployeeList };
