import { IChartPoint, IChartSeries } from './chartModels';
import RadarChartAxes, { axisLabelHeight, axisLabelMargin, axisLabelWidth } from './RadarChartAxes';
import RadarChartLevels from './RadarChartLevels';

interface IProps {
    className?: string;
    data: IChartSeries[];
    dataMax: number;
    dataMin: number;
    height: number;
    levels: number;
    width: number;
}

interface IRadarChartAxis {
    label: string;
}

const threeHundredSixtyDegreesInRadians = 2 * Math.PI;

const getPointsString = (points: IChartPoint[], radians: number, radius: number, range: number): string => {
    const pointCoordinates = points.map((p, i) => [
        radius * (1 - (p.value / range) * Math.sin((i * radians) / points.length)),
        radius * (1 - (p.value / range) * Math.cos((i * radians) / points.length)),
    ]);

    return pointCoordinates.reduce((acc, current) => `${acc} ${current[0]},${current[1]}`, '');
};

const RadarChart = ({ className, data, dataMax, dataMin, height, levels, width }: IProps): JSX.Element => {
    const diameter = Math.min(
        height - 2 * axisLabelHeight - 2 * axisLabelMargin,
        width - 2 * axisLabelWidth - 2 * axisLabelMargin
    );
    const range = dataMax - dataMin;
    const radius = diameter / 2;
    const axes = data[0].points.map<IRadarChartAxis>(d => ({
        label: d.name,
    }));

    return (
        <svg
            className={className}
            height={diameter + 2 * axisLabelHeight + 2 * axisLabelMargin}
            width={diameter + 2 * axisLabelWidth + 2 * axisLabelMargin}
        >
            <defs>
                {data.map((s, seriesIndex) => (
                    <radialGradient key={seriesIndex} id={`gradient-${seriesIndex}`}>
                        {s.gradients.map((g, gradientIndex) => (
                            <stop key={gradientIndex} offset={gradientIndex} stopColor={g} />
                        ))}
                    </radialGradient>
                ))}
            </defs>
            <g transform={`translate(${axisLabelWidth + axisLabelMargin}, ${axisLabelHeight + axisLabelMargin})`}>
                <>
                    <RadarChartLevels
                        levels={levels}
                        numberOfAxes={axes.length}
                        radians={threeHundredSixtyDegreesInRadians}
                        radius={radius}
                    />
                    <RadarChartAxes axes={axes} radians={threeHundredSixtyDegreesInRadians} radius={radius} />
                    {data.map((s, i) => (
                        <polygon
                            key={i}
                            fill={`url(#gradient-${i})`}
                            points={getPointsString(s.points, threeHundredSixtyDegreesInRadians, radius, range)}
                        />
                    ))}
                </>
            </g>
        </svg>
    );
};

export { RadarChart };
