import { IProblemDetails, IUserRoleType } from '@api';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useCurrentUser } from '~/hooks';
import AuthWrapper from './auth/AuthWrapper';
import { ErrorPage, LoadingIndicator } from './components';
import SuccessNotificationWrapper from './components/SuccessNotificationWrapper';
import TermsOfServiceAgreementWrapper from './components/TermsOfServiceAgreementWrapper';
import AdminLayout from './pages/AdminLayout';
import AdvisorLayout from './pages/AdvisorLayout';
import BusinessOwnerLayout from './pages/BusinessOwnerLayout';
import DocumentUploadDelegateLayout from './pages/DocumentUploadDelegateLayout';
import EmployeeUploadDelegateLayout from './pages/EmployeeUploadDelegateLayout';
import InnerZoneSurveyTakerLayout from './pages/InnerZoneSurveyTakerLayout';

const ONE_HOUR_IN_MS = 3_600_000;

const isTransientHttpError = (error?: IProblemDetails): boolean => {
    const statusCode = error?.status ?? 200;
    return statusCode === 408 || statusCode >= 500;
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error) => failureCount < 3 && isTransientHttpError(error as IProblemDetails),
            staleTime: ONE_HOUR_IN_MS,
        },
    },
});

const UserRoleSwitch = () => {
    const { isLoading, isError, data: currentUser } = useCurrentUser();
    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (isError || !currentUser) {
        return <ErrorPage />;
    }

    switch (currentUser.role) {
        case IUserRoleType.Admin:
            return <AdminLayout />;
        case IUserRoleType.Advisor:
            return <AdvisorLayout />;
        case IUserRoleType.BusinessOwner:
            return <BusinessOwnerLayout currentUser={currentUser} />;
        case IUserRoleType.DocumentUploadDelegate:
            return <DocumentUploadDelegateLayout />;
        case IUserRoleType.EmployeeUploadDelegate:
            return <EmployeeUploadDelegateLayout />;
        case IUserRoleType.InnerZoneSurveyTaker:
            return <InnerZoneSurveyTakerLayout />;
        default:
            return <ErrorPage title={`Something went wrong. Your role is not supported (${currentUser.role}).`} />;
    }
};

function App(): JSX.Element {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthWrapper>
                <TermsOfServiceAgreementWrapper>
                    <SuccessNotificationWrapper>
                        <div className="h-screen flex overflow-hidden">
                            <UserRoleSwitch />
                        </div>
                    </SuccessNotificationWrapper>
                </TermsOfServiceAgreementWrapper>
            </AuthWrapper>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default App;
