import { IEmployee } from '@api';
import { useState } from 'react';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useEmployees } from '~/hooks';
import EditEmployeeForm from './EditEmployeeForm';
import { EmployeeList } from './EmployeeList';

interface IProps {
    businessId: string;
    onSuccess?: (id: string) => void;
}

const Employees = ({ businessId, onSuccess }: IProps): JSX.Element => {
    const { error, data: allEmployees = [], isLoading } = useEmployees(businessId);
    const [editState, setEditState] = useState<{ isOpen: boolean; employee?: IEmployee }>({
        isOpen: false,
    });
    const owners = allEmployees.filter(e => e.isOwner) ?? [];
    const employees = allEmployees.filter(e => !e.isOwner) ?? [];
    const onClickEditEmployee = (employeeId: string) => {
        const employee = allEmployees.find(e => e.id === employeeId);
        if (employee === undefined) {
            return;
        }
        setEditState({ isOpen: true, employee: employee });
    };

    if (error) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;
    if (allEmployees.length === 0) {
        return <p className="text-lg">No employees added yet.</p>;
    }

    return (
        <>
            <div className="space-y-12 pb-20">
                {owners.length > 0 && (
                    <div>
                        <h3 className="mb-3">Owners</h3>
                        <EmployeeList employees={owners} onEdit={onClickEditEmployee} />
                    </div>
                )}
                {employees.length > 0 && (
                    <div>
                        <h3 className="mb-3">Employees</h3>
                        <EmployeeList employees={employees} onEdit={onClickEditEmployee} />
                    </div>
                )}
            </div>
            <EditEmployeeForm
                businessId={businessId}
                onClose={() => setEditState({ ...editState, isOpen: false })}
                onSuccess={onSuccess}
                {...editState}
            />
        </>
    );
};

export default Employees;
