import { IAdvisorFirm } from '@api';
import { VerticalTabs as Tabs, VerticalTabs } from '~/components';
import visionLinkLogo from '../../VisionLinkLogo.png';
import DisconnectButton from './DisconnectButton';
import {
    AdoptingPhantomStockPlan,
    AssessingHRReadiness,
    BuildingPayGradeStructure,
    DesigningShortTermIncentivePlan,
    EnhancingExecutiveBenefits,
    ValidatingMarketCompetitiveness,
} from './index';

interface IProps {
    advisorFirm: IAdvisorFirm;
    businessId: string;
    isConnected: boolean;
}

const ServicesSection = ({ advisorFirm, businessId, isConnected }: IProps): JSX.Element => {
    const ValidatingMarketCompetitivenessTab = ValidatingMarketCompetitiveness(advisorFirm);
    const BuildingPayGradeStructureTab = BuildingPayGradeStructure(advisorFirm);
    const DesigningShortTermIncentivePlanTab = DesigningShortTermIncentivePlan(advisorFirm);
    const AdoptingPhantomStockPlanTab = AdoptingPhantomStockPlan(advisorFirm);
    const EnhancingExecutiveBenefitsTab = EnhancingExecutiveBenefits(advisorFirm);
    const AssessingHRReadinessTab = AssessingHRReadiness(advisorFirm);

    return (
        <section className="order-4 lg:col-start-2 lg:col-span-2 h-full max-w-7xl pb-4">
            <div className="bg-gray-800 shadow sm:rounded-2xl">
                <Tabs activeTabId={ValidatingMarketCompetitivenessTab.Id}>
                    {ValidatingMarketCompetitivenessTab.Tab}
                    {ValidatingMarketCompetitivenessTab.Panel}
                    {BuildingPayGradeStructureTab.Tab}
                    {BuildingPayGradeStructureTab.Panel}
                    {DesigningShortTermIncentivePlanTab.Tab}
                    {DesigningShortTermIncentivePlanTab.Panel}
                    {AdoptingPhantomStockPlanTab.Tab}
                    {AdoptingPhantomStockPlanTab.Panel}
                    {EnhancingExecutiveBenefitsTab.Tab}
                    {EnhancingExecutiveBenefitsTab.Panel}
                    {AssessingHRReadinessTab.Tab}
                    {AssessingHRReadinessTab.Panel}
                    <VerticalTabs.TabFlowElement className="flex flex-col items-center pt-16 pb-12 space-y-4">
                        <img alt="Vision Link Logo" className="h-10" src={visionLinkLogo} />
                        {isConnected && (
                            <div>
                                <DisconnectButton businessId={businessId} />
                            </div>
                        )}
                    </VerticalTabs.TabFlowElement>
                </Tabs>
            </div>
        </section>
    );
};

export default ServicesSection;
