import { IAdvisorFirm, IBusinessPageType } from '@api';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Button,
    ErrorModal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
    Toggle,
} from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useUpdateAdvisorFirm } from '~/hooks';
import {
    getInitialBusinessPageSettings,
    IPageSetting,
    reorder,
    togglePage,
} from '~/pages/business/edit/presentationSettings/PresentationSettings';
import { businessPageDisplay, businessPageIcon, businessPageIconColor } from '~/utils/enumUtils';

interface IProps {
    advisorFirm: IAdvisorFirm;
}

const PresentationSettings = ({ advisorFirm }: IProps): JSX.Element => {
    const [isDisabled, setIsDisabled] = useState(false);
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [pageSettings, setPageSettings] = useState<IPageSetting[]>(
        getInitialBusinessPageSettings(advisorFirm.pages || [])
    );
    const [showErrorModal, setShowErrorModal] = useState(false);
    const updateAdvisorFirm = useUpdateAdvisorFirm(advisorFirm.id);
    const onChange = (pageType: IBusinessPageType) => setPageSettings(togglePage(pageSettings, pageType));
    const onReorder = (sourceIndex: number, destinationIndex: number) =>
        setPageSettings(reorder(pageSettings, sourceIndex, destinationIndex));
    const handleSave = async () => {
        try {
            setIsDisabled(true);
            await updateAdvisorFirm.mutateAsync({
                ...advisorFirm,
                pages: pageSettings.filter(ps => ps.enabled).map(ps => ps.pageType),
            });
            showSuccessNotification();
        } catch {
            setShowErrorModal(true);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Firm Presentation Settings</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-gray-300">
                Turn pages on or off to show in presentations. Click and grab to change the order of presentations.
                These will be the default settings for new businesses only.
            </p>
            <DragDropContext
                onDragEnd={result => result.destination && onReorder(result.source.index, result.destination.index)}
            >
                <Droppable droppableId="droppable">
                    {provided => (
                        <Table ref={provided.innerRef}>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell></TableHeaderCell>
                                    <TableHeaderCell>Presentation</TableHeaderCell>
                                    <TableHeaderCell>On/Off</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageSettings.map((pageSetting, index) => (
                                    <Draggable
                                        key={pageSetting.pageType}
                                        draggableId={pageSetting.pageType}
                                        index={index}
                                        isDragDisabled={isDisabled}
                                    >
                                        {provided => (
                                            <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <TableCell>
                                                    <FontAwesomeIcon className="text-gray-500" icon={faGripVertical} />
                                                </TableCell>
                                                <TableCell className="w-full text-sm leading-5">
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        className={`mr-4 text-${
                                                            businessPageIconColor[pageSetting.pageType]
                                                        }`}
                                                        icon={businessPageIcon[pageSetting.pageType]}
                                                    />
                                                    {businessPageDisplay[pageSetting.pageType]}
                                                </TableCell>
                                                <TableCell>
                                                    <Toggle
                                                        checked={pageSetting.enabled}
                                                        disabled={isDisabled}
                                                        onChange={() => onChange(pageSetting.pageType)}
                                                    />
                                                </TableCell>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        </Table>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="py-3 text-right">
                <Button color="primary" disabled={isDisabled} onClick={handleSave}>
                    Save
                </Button>
            </div>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
        </>
    );
};

export default PresentationSettings;
