const { REACT_APP_API_BASE_URL, REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } =
    process.env;

const config = {
    api: {
        baseUrl: REACT_APP_API_BASE_URL || '',
    },
    auth0: {
        audience: REACT_APP_AUTH0_AUDIENCE || '',
        clientId: REACT_APP_AUTH0_CLIENT_ID || '',
        domain: REACT_APP_AUTH0_DOMAIN || '',
    },
    env: { ...process.env },
};

export default config;
