import { IEmployeeRatingConfig } from '@api';

export const formatRating = (n?: number | null): string => {
    if (n === null || n === undefined) {
        return '--';
    } else if (n >= 100) {
        return n.toFixed(0);
    } else if (n >= 10) {
        return `${Math.round(n + Number.EPSILON)}`;
    } else {
        return `${Math.round((n + Number.EPSILON) * 10) / 10}`;
    }
};

const BUCKET_COUNT = 5;

export enum RatingColor {
    Lowest = 'gray-500',
    Low = 'gray-700',
    Average = 'primary-900',
    High = 'primary-600',
    Highest = 'primary-500',
}

const highToLowRatingMap: Record<number, RatingColor> = {
    0: RatingColor.Lowest,
    1: RatingColor.Low,
    2: RatingColor.Average,
    3: RatingColor.High,
    4: RatingColor.Highest,
};

const lowToHighRatingMap: Record<number, RatingColor> = {
    4: RatingColor.Lowest,
    3: RatingColor.Low,
    2: RatingColor.Average,
    1: RatingColor.High,
    0: RatingColor.Highest,
};

export const getRatingColor = (ratingConfig: IEmployeeRatingConfig, rating: number): RatingColor => {
    const { maxRating, minRating, sortHighToLow } = ratingConfig;
    const range = maxRating - minRating;
    const bucketRange = range / BUCKET_COUNT;
    const bucketWithPrecision = (rating - minRating) / bucketRange;
    const roundedBucket = Math.floor(Math.round((bucketWithPrecision + Number.EPSILON) * 100) / 100);
    const cappedBucket = Math.min(BUCKET_COUNT - 1, Math.max(0, roundedBucket));
    const ratingMap = sortHighToLow ? highToLowRatingMap : lowToHighRatingMap;

    return ratingMap[cappedBucket];
};
