import { IProblemDetails } from '@api';
import InnerZoneLoginForm from './InnerZoneLoginForm';

interface IProps {
    error: IProblemDetails;
}

const InnerZoneSettingError = ({ error }: IProps): JSX.Element => {
    const isForbidden = error?.response?.status === 403;

    return isForbidden ? (
        <div className="mt-6 mb-8">
            There is no linked InnerZone account for your business. Please contact your advisor so they can link their
            InnerZone account.
        </div>
    ) : (
        <InnerZoneLoginForm className="mb-6" />
    );
};

export default InnerZoneSettingError;
