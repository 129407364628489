import {
    IBusiness,
    IBusinessValuation,
    IEmployee,
    IEmployeeFamily,
    IFamilyLimitedPartner,
    IFamilyLimitedPartnership,
    IFamilyLimitedPartnershipCreateRequest,
} from '@api';
import { faPlusCircle, faTrash, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
    Avatar,
    Button,
    CurrencyInput,
    DropdownMenu,
    EmptyStateIcon,
    EntitySelect,
    ErrorPage,
    FormActions,
    IEntitySelectOption,
    LoadingIndicator,
    RadioGroup,
    SlideOver,
    SlideOverSizeType,
} from '~/components';
import { isTrueForAnyQuery, useEmployeeFamilies, useEmployees, useUpdateFamilyLimitedPartnership } from '~/hooks';
import AddEmployeeForm from '~/pages/business/employees/AddEmployeeForm';
import { getEmployeesById, getLimitedInterestAmount } from '~/utils/businessUtils';
import { getAge } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';
import { transformEmptyStringToNull, yupToFormErrors } from '~/utils/yupUtils';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    isOpen: boolean;
    onClose: () => void;
    partnership: IFamilyLimitedPartnership;
}

type PartnerFormData = Pick<IFamilyLimitedPartnershipCreateRequest, 'partners'>;

const schemaValidation = Yup.object().shape({
    partners: Yup.array(
        Yup.object().shape({
            dollarAmount: Yup.number()
                .min(0)
                .transform(transformEmptyStringToNull)
                .nullable()
                .required()
                .label('Dollar Amount'),
            isGift: Yup.boolean().required().label('Is Gift'),
        })
    )
        .required()
        .label('Partners'),
});

const getInitialValues = (partnership: IFamilyLimitedPartnership): PartnerFormData => {
    return {
        partners: partnership.partners || [],
    };
};

const mapFormDataToApi = (
    formData: PartnerFormData,
    partnership: IFamilyLimitedPartnership
): IFamilyLimitedPartnershipCreateRequest => ({
    ...partnership,
    partners: formData.partners || [],
});

const isOwner = (e: IEmployee, partnership: IFamilyLimitedPartnership): boolean => {
    return e.id === partnership.employeeId;
};

const isAlreadyAdded = (e: IEmployee, formData: PartnerFormData): boolean => {
    return formData.partners.some(p => e.id === p.familyRelativeId);
};

const isFamily = (employeeId: string, family: IEmployeeFamily | undefined): boolean => {
    if (!family) {
        return false;
    }
    return family.employeeIds.includes(employeeId);
};

const buildPartnerOptions = (
    employees: IEmployee[],
    partnership: IFamilyLimitedPartnership,
    families: IEmployeeFamily[],
    formData: PartnerFormData
): IEntitySelectOption[] => {
    const family = families.find(f => f.employeeIds.includes(partnership.employeeId));

    return employees
        .filter(e => !isAlreadyAdded(e, formData) && !isOwner(e, partnership))
        .map(a => ({
            avatar: a.avatar || undefined,
            label: NameFormatter.getLastNameFirst(a),
            name: NameFormatter.getLastNameFirst(a),
            showAddButton: true,
            value: a.id,
        }))
        .sort((a, b) => {
            const isAFamily = isFamily(a.value, family);
            const isBFamily = isFamily(b.value, family);
            if (isAFamily && !isBFamily) return -1;
            if (!isAFamily && isBFamily) return 1;
            return a.name < b.name ? -1 : 1;
        });
};

const LimitedPartnersForm = ({ business, businessValuation, isOpen, onClose, partnership }: IProps): JSX.Element => {
    const [formData, setFormData] = useState<PartnerFormData>(getInitialValues(partnership));
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const updatePartnership = useUpdateFamilyLimitedPartnership(business.id, partnership.id);
    const employeeFamiliesQuery = useEmployeeFamilies(business.id);
    const employeesQuery = useEmployees(business.id);
    const limitedInterestAmount = getLimitedInterestAmount(business, businessValuation.value ?? 0, partnership);
    const [addNewState, setAddNewState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const triggerRef = useRef<HTMLButtonElement>(null);

    const { data: employees = [] } = employeesQuery;
    const { data: employeeFamilies = [] } = employeeFamiliesQuery;
    const employeesById = getEmployeesById(employees);
    const owner = employeesById[partnership.employeeId];
    const partnerOptions = buildPartnerOptions(employees, partnership, employeeFamilies, formData);
    const isError = isTrueForAnyQuery('isError', employeeFamiliesQuery, employeesQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeeFamiliesQuery, employeesQuery);

    const handleSave = async () => {
        try {
            schemaValidation.validateSync(formData, { abortEarly: false });
            const request = mapFormDataToApi(formData, partnership);
            await updatePartnership.mutateAsync(request);
            onClose();
        } catch (err: unknown) {
            if (Yup.ValidationError.isError(err)) {
                setErrors(yupToFormErrors(err));
            }
        }
    };
    const resetForm = (partnership: IFamilyLimitedPartnership) => {
        setErrors({});
        setFormData(getInitialValues(partnership));
    };
    useEffect(() => {
        if (isOpen) {
            resetForm(partnership);
        }
    }, [partnership, isOpen]);
    const onAddPartner = (id: string) => {
        setErrors({});
        setFormData({
            ...formData,
            partners: [
                ...formData.partners,
                {
                    dollarAmount: 0,
                    familyRelativeId: id,
                    isGift: true,
                },
            ],
        });
    };
    const onChangePartnerForm = (partner: IFamilyLimitedPartner) => {
        const otherPartners = formData.partners.filter(p => p.familyRelativeId !== partner.familyRelativeId);
        setErrors({});
        setFormData({
            ...formData,
            partners: [...otherPartners, partner],
        });
    };
    const onDeletePartner = (partnerId: string) => {
        setErrors({});
        setFormData({
            ...formData,
            partners: formData.partners.filter(p => p.familyRelativeId !== partnerId),
        });
    };
    const onDistributeEvenly = () => {
        if (!formData.partners.length) {
            return;
        }

        setFormData({
            partners: formData.partners.map(p => ({
                ...p,
                dollarAmount: Math.floor(limitedInterestAmount / formData.partners.length),
            })),
        });
    };

    return (
        <SlideOver
            isOpen={isOpen}
            size={SlideOverSizeType.lg}
            stickyFooter={<FormActions onCancel={onClose} onSave={handleSave} />}
            title="Limited Partners"
            onClose={onClose}
        >
            {isError && <ErrorPage />}
            {isLoading && <LoadingIndicator />}
            {!isError && !isLoading && (
                <>
                    <div className="border-b border-gray-600 -mx-4 px-4 -mt-2 sm:-mx-6 sm:px-6 pb-4 flex justify-between">
                        <Button size="sm" onClick={onDistributeEvenly} disabled={formData.partners.length === 0}>
                            Distribute Evenly
                        </Button>
                        <DropdownMenu itemsClassName="w-80" position="right">
                            <DropdownMenu.Trigger>
                                <Button size="sm" ref={triggerRef}>
                                    <FontAwesomeIcon icon={faPlusCircle} aria-hidden className="mr-2" />
                                    Add New
                                </Button>
                            </DropdownMenu.Trigger>
                            <EntitySelect
                                isCreatable
                                label=""
                                menuIsOpen
                                onEntityOptionSelected={e => onAddPartner(e.value)}
                                onNewSelected={() => setAddNewState({ isOpen: true })}
                                options={partnerOptions}
                            />
                        </DropdownMenu>
                    </div>
                    {formData.partners.length === 0 && (
                        <div className="flex flex-col items-center text-center">
                            <EmptyStateIcon icon={faUsers} />
                            <p className="my-4 text-lg">No partners added yet.</p>
                            <Button
                                color="primary"
                                onClick={(e: React.SyntheticEvent) => {
                                    e.stopPropagation();
                                    triggerRef.current?.click();
                                }}
                            >
                                Add Partners
                            </Button>
                        </div>
                    )}
                    {formData.partners
                        .map((a, index) => ({ ...employeesById[a.familyRelativeId], ...a, index }))
                        .sort((a, b) =>
                            NameFormatter.getLastNameFirst(a) < NameFormatter.getLastNameFirst(b) ? -1 : 1
                        )
                        .map(partner => {
                            return (
                                <div className="border-gray-600 border-b space-y-4 pb-6" key={partner.id}>
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <Avatar src={partner.avatar || ''} className="mr-3" />
                                            <span>{NameFormatter.getName(partner)}</span>
                                            {partner.dateOfBirth && <span>&nbsp;({getAge(partner.dateOfBirth)})</span>}
                                        </div>
                                        <Button
                                            color="link"
                                            className="hover:bg-white hover:bg-opacity-10 p-4"
                                            onClick={() => onDeletePartner(partner.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} className="text-danger" />
                                        </Button>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium mb-1">New Created Interests</label>
                                        <RadioGroup
                                            error={errors[`partners[${partner.index}].isGift`]}
                                            value={`${partner.isGift}`}
                                            ariaLabel="New Created Interests"
                                            options={[
                                                {
                                                    label: 'Gifting',
                                                    value: 'true',
                                                },
                                                {
                                                    label: 'Selling',
                                                    value: 'false',
                                                },
                                            ]}
                                            onChange={value =>
                                                onChangePartnerForm({ ...partner, isGift: value === 'true' })
                                            }
                                        />
                                    </div>
                                    <div className="lg:w-40">
                                        <CurrencyInput
                                            className="text-right"
                                            error={errors[`partners[${partner.index}].dollarAmount`]}
                                            label="Amount"
                                            onChange={dollarAmount =>
                                                onChangePartnerForm({ ...partner, dollarAmount: dollarAmount || 0 })
                                            }
                                            value={partner.dollarAmount}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    <AddEmployeeForm
                        businessId={business.id}
                        initialValues={{
                            familyRelativeId: owner.id,
                            lastName: owner.lastName,
                        }}
                        onClose={() => setAddNewState({ ...addNewState, isOpen: false })}
                        onSuccess={onAddPartner}
                        {...addNewState}
                    />
                </>
            )}
        </SlideOver>
    );
};

export default LimitedPartnersForm;
