import { IInnerZoneEmployeeUser, IInnerZoneSurveyStatus } from '@api';
import { HorizontalTabs, SlideOver, SlideOverSizeType } from '~/components';
import { SurveyStatusCompletedTable, SurveyStatusIsPendingTable, SurveyStatusNewTable } from './SurveyStatusTable';

interface IProps {
    employeeUsers: IInnerZoneEmployeeUser[];
    isOpen: boolean;
    onClose: () => void;
}

const newTabId = 'New';
const inProgressTabId = 'InProgress';
const completedTabId = 'Completed';
const SurveyStatusSlideOver = ({ employeeUsers, isOpen, onClose }: IProps): JSX.Element => {
    return (
        <SlideOver isOpen={isOpen} size={SlideOverSizeType.lg} title="Survey Status" onClose={onClose}>
            <HorizontalTabs activeTabId={newTabId}>
                <HorizontalTabs.Tab tabId={newTabId}>New</HorizontalTabs.Tab>
                <HorizontalTabs.Panel tabId={newTabId}>
                    <div className="border-t border-gray-700 pt-3">
                        <SurveyStatusNewTable
                            users={employeeUsers.filter(u => u.surveyStatus === IInnerZoneSurveyStatus.EmailPending)}
                        />
                    </div>
                </HorizontalTabs.Panel>
                <HorizontalTabs.Tab tabId={inProgressTabId}>In Progress</HorizontalTabs.Tab>
                <HorizontalTabs.Panel tabId={inProgressTabId}>
                    <div className="border-t border-gray-700 pt-3">
                        <SurveyStatusIsPendingTable
                            users={employeeUsers.filter(u => u.surveyStatus === IInnerZoneSurveyStatus.EmailSent)}
                        />
                    </div>
                </HorizontalTabs.Panel>
                <HorizontalTabs.Tab tabId={completedTabId}>Completed</HorizontalTabs.Tab>
                <HorizontalTabs.Panel tabId={completedTabId}>
                    <div className="border-t border-gray-700 pt-3">
                        <SurveyStatusCompletedTable
                            users={employeeUsers.filter(u => u.surveyStatus === IInnerZoneSurveyStatus.SurveyCompleted)}
                        />
                    </div>
                </HorizontalTabs.Panel>
            </HorizontalTabs>
        </SlideOver>
    );
};

export default SurveyStatusSlideOver;
