import { faCircleNotch, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Button,
    CurrentUserMenu,
    ErrorModal,
    ErrorPage,
    LoadingIndicator,
    Modal,
    NavBar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableRow,
} from '~/components';
import { useAddSampleCase, useBusinesses } from '~/hooks';
import NameFormatter from '~/utils/nameFormatter';
import { ILocationState } from '../ILocationState';
import AddBusinessWizardModal from './addBusinessWizard/AddBusinessWizardModal';

const BusinessList = (): JSX.Element | null => {
    const [addState, setAddState] = useState({ isOpen: false });
    const [addSampleState, setAddSampleState] = useState({ isOpen: false, hasError: false });
    const { isLoading, error, data } = useBusinesses();
    const locationState: ILocationState = {
        backUrl: useLocation().pathname,
        label: 'Business List',
    };
    const addSampleCase = useAddSampleCase();

    const handleAddSample = async () => {
        try {
            setAddSampleState({ isOpen: true, hasError: false });
            await addSampleCase.mutateAsync();
            setAddSampleState({ isOpen: false, hasError: false });
        } catch {
            setAddSampleState({ isOpen: true, hasError: true });
        }
    };

    return (
        <>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <NavBar>
                    <div></div>
                    <div className="flex">
                        <CurrentUserMenu />
                    </div>
                </NavBar>
                {isLoading && <LoadingIndicator />}
                {error && <ErrorPage />}
                {data && (
                    <main
                        className="flex flex-col h-full max-h-full mx-auto overflow-y-auto pb-4 px-4 sm:px-6 lg:px-8 scrollbar-themed focus:outline-none max-w-7xl w-full"
                        tabIndex={0}
                    >
                        <div className="top-0 flex justify-between mb-12 pt-10 pb-6 bg-gray-900 border-b border-gray-700">
                            <h2 className="text-2xl leading-none self-center">Business List</h2>
                            <div className="self-center space-x-3">
                                <Button onClick={handleAddSample}>
                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                    Add Sample
                                </Button>
                                <Button color="primary" onClick={() => setAddState({ isOpen: true })}>
                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                    Add New
                                </Button>
                            </div>
                        </div>
                        <Table className="w-full">
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell>Business Name</TableHeaderCell>
                                    <TableHeaderCell className="hidden sm:table-cell">Primary Contact</TableHeaderCell>
                                    <TableHeaderCell className="hidden md:table-cell">Email</TableHeaderCell>
                                    <TableHeaderCell>
                                        <span className="sr-only">Actions</span>
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(business => (
                                    <TableRow key={business.id}>
                                        <TableCell>
                                            <Link
                                                className="text-primary hover:text-primary-300"
                                                to={`/${business.id}/Present`}
                                            >
                                                {business.name}
                                            </Link>
                                        </TableCell>
                                        <TableCell className="hidden sm:table-cell">
                                            {NameFormatter.getName(business.primaryEmployee)}
                                        </TableCell>
                                        <TableCell className="hidden md:table-cell">
                                            {business.primaryEmployee?.emailAddress}
                                        </TableCell>
                                        <TableCell className="text-right">
                                            <Link
                                                className="text-primary hover:text-primary-300"
                                                to={{
                                                    pathname: `/${business.id}/Edit`,
                                                    state: locationState,
                                                }}
                                            >
                                                Settings
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </main>
                )}
            </div>
            <AddBusinessWizardModal onClose={() => setAddState({ isOpen: false })} {...addState} />
            <Modal
                maxWidth="md"
                open={addSampleState.isOpen && !addSampleState.hasError}
                setOpen={() => setAddSampleState({ isOpen: false, hasError: false })}
                title="Add Sample Business"
            >
                <div className="p-8 flex flex-col items-center text-center">
                    <FontAwesomeIcon className="text-primary mb-4" size="3x" icon={faCircleNotch} spin />
                    This may take a few seconds. Please wait.
                </div>
            </Modal>
            <ErrorModal
                open={addSampleState.isOpen && addSampleState.hasError}
                setIsOpen={() => setAddSampleState({ isOpen: false, hasError: false })}
            />
        </>
    );
};

export default BusinessList;
