import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren, useContext, useState } from 'react';
import { documentCenterStore } from './DocumentCenterContext';

interface IProps {
    className?: string;
    folderId: string;
    onDrop?: (files: FileList, folderId: string) => Promise<void>;
}

const DragAndDropUploadHandler: FunctionComponent<IProps> = ({
    children,
    className = '',
    folderId,
    onDrop,
}: PropsWithChildren<IProps>) => {
    // Need to keep a count because of how the event bubbles to child elements.
    // Technique described at https://stackoverflow.com/a/21002544
    const [dragCounter, setDragCounter] = useState(0);
    const { openAddFilesModal } = useContext(documentCenterStore);

    const handleDrop = async (files: FileList) => {
        const handler = onDrop ?? openAddFilesModal;
        await handler(files, folderId);
    };

    return (
        <div
            className={classNames(className, 'w-full h-full', { 'bg-gray-700': dragCounter > 0 })}
            onDragEnter={() => setDragCounter(dragCounter + 1)}
            onDragLeave={() => {
                setDragCounter(dragCounter - 1);
            }}
            onDragOver={e => e.preventDefault()}
            onDrop={async event => {
                event.preventDefault();
                setDragCounter(dragCounter - 1);
                await handleDrop(event.dataTransfer?.files);
            }}
        >
            {children}
        </div>
    );
};

export default DragAndDropUploadHandler;
