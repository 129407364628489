import { IInnerZoneEmployeeUser } from '@api';
import { FormActions, SlideOver, SlideOverSizeType } from '~/components';
import EmployeeSelect from './EmployeeSelect';

interface IProps {
    selectedEmployeeIds: string[];
    employeeUsers: IInnerZoneEmployeeUser[];
    isOpen: boolean;
    onClose: () => void;
    onEmployeeUserClick: (employeeId: string) => void;
    onSave: () => void;
}

const CustomizeTeamSlideOver = ({
    employeeUsers,
    isOpen,
    selectedEmployeeIds,
    onClose,
    onEmployeeUserClick,
    onSave,
}: IProps): JSX.Element => (
    <SlideOver
        isOpen={isOpen}
        size={SlideOverSizeType.lg}
        stickyFooter={<FormActions onCancel={onClose} onSave={async () => onSave()} />}
        title="Customize Team"
        onClose={onClose}
    >
        <EmployeeSelect
            employeeUsers={employeeUsers}
            selectedEmployeeIds={selectedEmployeeIds}
            onEmployeeUserClick={onEmployeeUserClick}
        />
    </SlideOver>
);

export default CustomizeTeamSlideOver;
