import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBusinessValuationMetric, IBusinessValuationUtils } from '../overview/Overview';

interface IProps {
    metric: IBusinessValuationMetric;
    utils: IBusinessValuationUtils;
}

const MetricScoreCard = ({ metric, utils }: IProps): JSX.Element => (
    <div>
        <div className="flex rounded p-4 bg-gray-800">
            <div
                className={`h-16 w-16 flex items-center justify-center rounded-md bg-${utils.colorMap[metric.type]}`}
                style={{
                    lineHeight: '4.5rem',
                }}
            >
                <FontAwesomeIcon icon={utils.iconMap[metric.type]} size="2x" />
            </div>
            <div className="ml-5 min-w-0 flex-1">
                <div className="text-sm truncate">{utils.textMap[metric.type]}</div>
                <div className="text-2xl font-semibold mb-1">{metric.score}</div>
                <div className="bg-gray-900 w-full h-1 rounded overflow-hidden">
                    <div
                        className={`h-1 bg-${utils.colorMap[metric.type]}`}
                        style={{ width: `${(metric.score / 10) * 100}%` }}
                    />
                </div>
            </div>
        </div>
    </div>
);

export default MetricScoreCard;
