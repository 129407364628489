import { IBusiness, IBusinessPageType } from '@api';
import { ErrorPage, LoadingIndicator } from '~/components';
import { useBonusRightConnectionStatus } from '~/hooks/bonusRightConnection';
import ExecutivePrograms from './executivePrograms';
import UnconnectedPage from './unconnected';

interface IProps {
    business: IBusiness;
}

const ExecutiveProgramsPage = ({ business }: IProps): JSX.Element => {
    const { data, error, isLoading } = useBonusRightConnectionStatus(business);

    if (isLoading) return <LoadingIndicator />;
    if (error) return <ErrorPage />;

    return data?.isConnected ? (
        <ExecutivePrograms business={business} />
    ) : (
        <UnconnectedPage business={business} businessPageType={IBusinessPageType.ExecutivePrograms} />
    );
};

export default ExecutiveProgramsPage;
