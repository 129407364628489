import { IBusiness } from '@api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { BarChart, SlideOver, SlideOverSizeType } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import { useBoundingClientRect } from '~/hooks';
import {
    metricTypeColor,
    metricTypeDisplay,
    metricTypeIcon,
} from '~/pages/business/present/businessEvaluation/metricUtils';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { IBusinessValuationDetail } from './Overview';

interface IProps {
    business: IBusiness;
    businessDetail: IBusinessValuationDetail;
}

const ValueGapCard = ({ business, businessDetail }: IProps): JSX.Element => {
    const [selectedDriverType, setSelectedDriverType] = useState<string>();
    const [chartContainerRect, chartContainerRef] = useBoundingClientRect();
    const chartWidth = chartContainerRect?.width || 0;
    const selectedValueDriver = businessDetail.metrics.find(d => d.type === selectedDriverType);

    return (
        <>
            <div className="flex flex-col bg-gray-800 rounded-lg px-4 py-5">
                <div className="text-xl leading-7 mb-2">Value Gap</div>
                <div className="leading-6 mb-9">
                    This report displays your total value gap broken down by driver. It is sorted from highest value gap
                    to lowest.
                </div>
                <div className="text-2xl leading-none text-center mb-7">{`Total Value Gap ${CurrencyFormatter.format(
                    businessDetail.gap
                )}`}</div>
                <div ref={chartContainerRef}>
                    <BarChart
                        height={500}
                        points={
                            businessDetail.metrics
                                .filter(d => d.gap > 0)
                                .sort((a, b) => a.gap - b.gap)
                                .map<IChartPoint>(d => ({
                                    color: 'cyan-500',
                                    name: d.type,
                                    onClick: () => {
                                        setSelectedDriverType(d.type);
                                    },
                                    value: d.gap,
                                })) || []
                        }
                        width={chartWidth}
                    />
                </div>
            </div>

            <SlideOver
                isOpen={!!selectedDriverType}
                size={SlideOverSizeType.lg}
                title={
                    <div className="flex items-center">
                        {selectedDriverType && (
                            <>
                                <div
                                    className={`h-12 w-12 flex items-center justify-center rounded-md bg-${metricTypeColor[selectedDriverType]}`}
                                >
                                    <FontAwesomeIcon icon={metricTypeIcon[selectedDriverType]} size="lg" />
                                </div>
                                <div className="ml-4">{metricTypeDisplay[selectedDriverType]}</div>
                            </>
                        )}
                    </div>
                }
                onClose={() => setSelectedDriverType(undefined)}
            >
                {selectedDriverType && (
                    <div>
                        <div className="pb-8 mb-8 border-b border-gray-700">
                            <div className="text-2xl">Value Gap</div>
                            <div className="text-5xl mt-2">{CurrencyFormatter.format(selectedValueDriver?.gap)}</div>
                        </div>
                    </div>
                )}
            </SlideOver>
        </>
    );
};

export default ValueGapCard;
