import { IAdvisor, IBusiness, IEmployee } from '@api';
import { Avatar, ErrorPage, LoadingIndicator } from '~/components';
import { useEmployees } from '~/hooks';

interface IProps {
    advisor: IAdvisor;
    business: IBusiness;
}

interface INamed {
    firstName?: string;
    lastName?: string;
    qualification?: string | null;
}

const formatName = ({ firstName, lastName, qualification }: INamed) => {
    if (qualification) {
        return `${firstName} ${lastName}, ${qualification}`;
    } else {
        return `${firstName} ${lastName}`;
    }
};

const formatGreeting = (primaryEmployee?: IEmployee): string => {
    if (primaryEmployee) {
        return `Hello, ${formatName(primaryEmployee)}`;
    }
    return 'Hello, Business Owner';
};

const AdvisorInfoSection = ({ advisor, business }: IProps): JSX.Element => {
    const { isError, isLoading, data: employees = [] } = useEmployees(business.id, !!business.primaryEmployeeId);
    if (isError) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    const primaryEmployee = business.primaryEmployeeId
        ? employees.find(e => e.id === business.primaryEmployeeId)
        : undefined;

    return (
        <>
            <div className="text-center text-5xl font-extrabold pb-3">{formatGreeting(primaryEmployee)}</div>
            <div className="text-center text-3xl font-extrabold">{formatName(advisor)}</div>
            <div className="text-center text-xl mb-3">{advisor.title}</div>
            <div className="text-center">
                <Avatar size={48} src={advisor.avatar || ''} />
            </div>
        </>
    );
};

export default AdvisorInfoSection;
