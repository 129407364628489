import { IAdvisor, IUserEntitlementType } from '@api';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { ValidationError } from 'yup';
import { Button, Can, DeleteConfirmationModal, ErrorModal, useAvatarInput } from '~/components';
import { SuccessNotificationContext } from '~/components/SuccessNotificationWrapper';
import { useAdvisorAvatar, useDeleteAdvisor, useUpdateAdvisor } from '~/hooks';
import { yupToFormErrors } from '~/utils/yupUtils';
import AdvisorInfoForm, {
    getInitialFormData,
    IAdvisorInfoFormData,
    mapFormDataToApi,
    schemaValidation,
} from './AdvisorInfoForm';

interface IProps {
    advisor: IAdvisor;
}

const AdvisorInfo = ({ advisor }: IProps): JSX.Element => {
    const { show: showSuccessNotification } = useContext(SuccessNotificationContext);
    const [deleteState, setDeleteState] = useState<{ isOpen: boolean }>({ isOpen: false });
    const [formData, setFormData] = useState<IAdvisorInfoFormData>(getInitialFormData(advisor));
    const [isDisabled, setIsDisabled] = useState(false);
    const [newAvatar, setNewAvatar] = useAvatarInput(advisor);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const deleteAdvisor = useDeleteAdvisor(advisor.advisorFirmId);
    const history = useHistory();
    const updateAdvisor = useUpdateAdvisor(advisor.advisorFirmId, advisor.id);
    const updateAdvisorAvatar = useAdvisorAvatar(advisor.advisorFirmId);
    const handleDelete = async () => {
        try {
            await deleteAdvisor.mutateAsync(advisor.id);
            history.push(`/${advisor.advisorFirmId}/Advisors`);
            showSuccessNotification();
        } catch {
            setShowErrorModal(true);
        } finally {
            setIsDisabled(false);
        }
    };
    const handleSave = async () => {
        try {
            try {
                schemaValidation.validateSync(formData, { abortEarly: false });
            } catch (err: unknown) {
                if (ValidationError.isError(err)) {
                    setValidationErrors(yupToFormErrors(err));
                }
                return;
            }
            setIsDisabled(true);
            await updateAdvisor.mutateAsync({
                ...advisor,
                ...mapFormDataToApi(formData),
            });
            if (newAvatar?.file) {
                await updateAdvisorAvatar.mutateAsync({ avatar: newAvatar.file, advisorId: advisor.id });
            }
            showSuccessNotification();
        } catch {
            setShowErrorModal(true);
        } finally {
            setIsDisabled(false);
        }
    };

    return (
        <>
            <h3 className="text-lg leading-6 font-medium">Advisor Information</h3>
            <p className="mt-1 mb-4 text-sm leading-5 font-normal text-gray-300">
                Enter general information about the advisor.
            </p>
            <div className="space-y-3">
                <AdvisorInfoForm
                    avatar={newAvatar?.url || advisor?.avatar}
                    disabled={isDisabled}
                    errors={validationErrors}
                    formData={formData}
                    onChange={setFormData}
                    onChangeAvatar={setNewAvatar}
                />
                <div className="py-3 border-t border-gray-700 flex flex-row-reverse justify-between sm:px-0">
                    <Button color="primary" disabled={isDisabled} onClick={handleSave}>
                        Save
                    </Button>
                    <Can hasEntitlement={IUserEntitlementType.AdvisorDelete}>
                        <Button
                            className="text-danger"
                            color="tertiary"
                            disabled={isDisabled}
                            onClick={() => setDeleteState({ isOpen: true })}
                        >
                            Delete Advisor
                        </Button>
                    </Can>
                </div>
            </div>
            <DeleteConfirmationModal
                onClose={() => setDeleteState({ ...deleteState, isOpen: false })}
                onConfirm={handleDelete}
                title="Delete Advisor"
                {...deleteState}
            >
                Are you sure you want to delete this advisor? All of his/her data will be permanently removed from our
                servers forever. This action cannot be undone.
            </DeleteConfirmationModal>
            <ErrorModal open={showErrorModal} setIsOpen={setShowErrorModal} />
        </>
    );
};

export default AdvisorInfo;
