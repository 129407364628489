import { IEducation, IEducationUpdateRequest } from '@api';
import { useDeleteEducation, useUpdateEducation } from '~/hooks';
import EducationForm from './EducationForm';

interface IProps {
    education?: IEducation;
    isOpen: boolean;
    onClose: () => void;
}

const EditEducationForm = (props: IProps): JSX.Element => {
    const { education, onClose } = props;
    const educationId = education?.id ?? '';
    const deleteEducation = useDeleteEducation();
    const updateEducation = useUpdateEducation();
    const onDelete = async () => {
        await deleteEducation.mutateAsync(educationId);
        onClose();
    };
    const onSave = async (request: IEducationUpdateRequest) => {
        await updateEducation.mutateAsync({
            educationId: education?.id ?? '',
            request: { ...(education ?? {}), ...request },
        });
        onClose();
    };

    return <EducationForm onDelete={onDelete} onSave={onSave} title="Edit Education Resource" {...props} />;
};

export default EditEducationForm;
