import { IInnerZoneEmployeeLink, IInnerZoneEmployeeUser, IInnerZoneSurveyStatus } from '@api';
import { useState } from 'react';
import CommunicationChart from './CommunicationChart';
import EmployeeProfileSlideOver from './slideOvers/EmployeeProfileSlideOver';
import LinkSlideOver from './slideOvers/LinkSlideOver';

interface IProps {
    chartHeight: number;
    chartWidth: number;
    employeeLinks: IInnerZoneEmployeeLink[];
    employeeUsers: IInnerZoneEmployeeUser[];
}

const getSelectedLinkEmployeeUsers = (
    employeeUsers: IInnerZoneEmployeeUser[],
    selectedLink: IInnerZoneEmployeeLink
): IInnerZoneEmployeeUser[] => [
    employeeUsers.find(u => u.employeeId === selectedLink.employees[0]) as IInnerZoneEmployeeUser,
    employeeUsers.find(u => u.employeeId === selectedLink.employees[1]) as IInnerZoneEmployeeUser,
];

const CommunicationReport = ({ chartHeight, chartWidth, employeeLinks, employeeUsers }: IProps): JSX.Element => {
    const [selectedEmployeeUser, setSelectedEmployeeUser] = useState<IInnerZoneEmployeeUser>();
    const [selectedLink, setSelectedLink] = useState<IInnerZoneEmployeeLink>();

    return (
        <>
            <CommunicationChart
                employeeLinks={employeeLinks}
                employeeUsers={employeeUsers}
                height={chartHeight}
                selectedEmployeeUser={selectedEmployeeUser}
                selectedLink={selectedLink}
                width={chartWidth}
                onEmployeeUserClick={employeeUser => {
                    if (employeeUser.surveyStatus === IInnerZoneSurveyStatus.SurveyCompleted) {
                        setSelectedEmployeeUser(employeeUser);
                    }
                }}
                onLinkClick={setSelectedLink}
            />
            <EmployeeProfileSlideOver
                employeeUser={selectedEmployeeUser}
                onClose={() => setSelectedEmployeeUser(undefined)}
            />
            <LinkSlideOver
                employeeUsers={selectedLink ? getSelectedLinkEmployeeUsers(employeeUsers, selectedLink) : []}
                link={selectedLink}
                onClose={() => setSelectedLink(undefined)}
            />
        </>
    );
};

export default CommunicationReport;
