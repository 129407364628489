import { useContext, useRef } from 'react';
import { documentCenterStore } from './DocumentCenterContext';
import DragAndDropUploadHandler from './DragAndDropUploadHandler';

interface IProps {
    folderId: string;
}

const ItemNewUploadTableView = ({ folderId }: IProps): JSX.Element => {
    const fileDialog = useRef<HTMLInputElement>(null);
    const { openAddFilesModal } = useContext(documentCenterStore);

    return (
        <tr>
            <td
                colSpan={5}
                className="text-center cursor-pointer bg-gray-800 hover:bg-gray-700 hover:bg-opacity-100 bg-opacity-50"
                onClick={() => fileDialog.current?.click()}
            >
                <DragAndDropUploadHandler folderId={folderId} className="p-4">
                    <input
                        onChange={e => openAddFilesModal(e.currentTarget?.files || undefined, folderId)}
                        ref={fileDialog}
                        type="file"
                        multiple
                        className="hidden"
                    />
                    Click or drag-and-drop here to add new files
                </DragAndDropUploadHandler>
            </td>
        </tr>
    );
};

export default ItemNewUploadTableView;
