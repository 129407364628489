import { Menu, Portal, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { allByType, withoutTypes } from 'react-children-by-type';
import { usePopper } from 'react-popper';
import { Button, ButtonProps } from '~/components';

interface IDropdownItem extends Omit<ButtonProps, 'children'> {
    children: React.ReactNode | ((props: { active: boolean }) => JSX.Element);
}

const DropdownItem = ({ children, ...rest }: IDropdownItem): JSX.Element => {
    return (
        <Menu.Item>
            {({ active }) => (
                <Button
                    className={classNames('flex items-center px-4 py-2', { 'bg-gray-700': active })}
                    color="link"
                    fullWidth
                    {...rest}
                >
                    {typeof children === 'function' ? children({ active }) : children}
                </Button>
            )}
        </Menu.Item>
    );
};

interface IDropdownMenuProps {
    children: React.ReactNode;
    itemsClassName?: string;
    position?: 'left' | 'right';
}

const DropdownMenu = ({ children, itemsClassName, position = 'left' }: IDropdownMenuProps): JSX.Element => {
    const trigger = allByType(children, Menu.Button);
    const items = withoutTypes(children, Menu.Button);
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: position === 'left' ? 'bottom-start' : 'bottom-end',
    });

    return (
        <Menu as="div" className="inline-block text-left" onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
            {React.Children.map([trigger], child => {
                return React.cloneElement(child, { ref: setReferenceElement });
            })}
            <Portal>
                <Transition
                    as={'div'}
                    enter="transition ease-out duration-100"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Menu.Items
                        className={classNames(
                            'w-44 bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                            itemsClassName
                        )}
                        ref={setPopperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <div className="px-1 py-1">{items}</div>
                    </Menu.Items>
                </Transition>
            </Portal>
        </Menu>
    );
};

DropdownMenu.Trigger = Menu.Button;
DropdownMenu.Item = DropdownItem;

export { DropdownMenu };
