import { Redirect, Route, Switch } from 'react-router-dom';
import BusinessEdit from './edit';
import BusinessPresent from './present';

export interface BusinessParams {
    businessId: string;
}

const BusinessSwitch = (): JSX.Element | null => {
    return (
        <Switch>
            <Route path="/:businessId/Edit">
                <BusinessEdit />
            </Route>
            <Route path="/:businessId/Present">
                <BusinessPresent />
            </Route>
            <Redirect to="/:businessId/Present" />
        </Switch>
    );
};

export default BusinessSwitch;
