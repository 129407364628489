import { IDocument, IDocumentList } from '@api';
import { faLock, faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '~/components';
import { useDocumentPermissions } from '~/hooks';
import { BusinessParams } from '../..';
import { documentCenterStore } from './DocumentCenterContext';

interface IProps {
    folder: IDocumentList;
}

const buildDocument = (folder: IDocumentList): IDocument => ({
    fileCount: 0,
    hierarchyUpdatedAt: '',
    id: folder.folderId,
    isFolder: true,
    name: folder.name,
    parentFolderId: folder.folderId,
});

const ShareCurrentFolderButton = ({ folder }: IProps): JSX.Element | null => {
    const { businessId } = useParams<BusinessParams>();
    const documentPermissions = useDocumentPermissions(businessId, folder.folderId);
    const { openShareModal } = useContext(documentCenterStore);

    if (!documentPermissions.data) {
        return null;
    }

    const isShared = documentPermissions.data.length > 0;
    return (
        <Button color="secondary" className="ml-2" size="sm" onClick={() => openShareModal(buildDocument(folder))}>
            <FontAwesomeIcon icon={isShared ? faUserFriends : faLock} className="mr-2" />
            {isShared ? 'Shared' : 'Private'}
        </Button>
    );
};

export default ShareCurrentFolderButton;
