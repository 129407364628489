import { IEmployee } from '@api';
import classNames from 'classnames';
import { Avatar } from '~/components';
import { getAge, isAboutToRetire } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';

interface IProps {
    employee: IEmployee;
    onClick?: (employee: IEmployee) => void;
    renderValue?: (employee: IEmployee) => React.ReactNode;
}

export const TeamValueCard = ({ employee, onClick, renderValue }: IProps): JSX.Element => {
    const name = NameFormatter.getName(employee);
    const aboutToRetire = isAboutToRetire(employee.dateOfBirth);

    return (
        <div
            className={classNames(
                'rounded-md border-2 bg-gray-800 shadow-sm flex items-center space-x-3',
                aboutToRetire ? 'border-yellow-300' : 'border-gray-900',
                { 'cursor-pointer': !!onClick }
            )}
            onClick={onClick ? () => onClick(employee) : undefined}
        >
            <Avatar className="rounded-l" rounded="none" size={16} src={employee.avatar || ''} />
            <div className="flex-1 flex items-center justify-between truncate">
                <div className="flex-1 py-2 text-sm truncate">
                    <div className="text-white font-medium text-lg flex">
                        <div className="truncate" style={{ maxWidth: '75%' }} title={name}>
                            {name}
                        </div>
                        {employee.dateOfBirth ? (
                            <span
                                className={classNames('ml-1', {
                                    'text-yellow-300': aboutToRetire,
                                })}
                            >
                                ({getAge(employee.dateOfBirth)})
                            </span>
                        ) : null}
                    </div>
                    <p className="text-gray-400 truncate" title={employee.title || undefined}>
                        {employee.title ? employee.title : <em>Title missing</em>}
                    </p>
                </div>
                {renderValue && <div className="flex-shrink-0 pr-2 pl-3">{renderValue(employee)}</div>}
            </div>
        </div>
    );
};
