import { useAddTodo } from '~/hooks';
import TodoForm, { ITodoFormData, mapFormDataToApi } from './TodoForm';

interface IProps {
    businessId: string;
    isOpen: boolean;
    onClose: () => void;
}

const AddTodoForm = ({ businessId, isOpen, onClose }: IProps): JSX.Element => {
    const addTodo = useAddTodo(businessId);
    const onSave = (formData: ITodoFormData) => {
        return addTodo
            .mutateAsync({
                ...mapFormDataToApi(formData),
                businessId,
            })
            .then(onClose);
    };

    return <TodoForm businessId={businessId} isOpen={isOpen} onClose={onClose} onSave={onSave} title="Add Task" />;
};

export default AddTodoForm;
