import classNames from 'classnames';
import { TableCell } from '~/components';
import CurrencyFormatter from '~/utils/currencyFormatter';

interface ICurrencyTableCellProps {
    background?: string;
    className?: string;
    value?: number | null;
}

const CurrencyTableCell = ({ background = 'bg-gray-900', className, value }: ICurrencyTableCellProps): JSX.Element => (
    <TableCell background={background} className={classNames(className, 'text-right')}>
        {CurrencyFormatter.format(value)}
    </TableCell>
);

export default CurrencyTableCell;
