import { IBusiness } from '@api';
import { Carousel } from '~/components';
import AdvisorInfoScreen from './advisorInfo';
import DidYouKnowScreen from './didYouKnow';
import PrivatelyHeldCompaniesInfoScreen from './privatelyHeldCompanies';

interface IProps {
    business: IBusiness;
}

const Purpose = ({ business }: IProps): JSX.Element => {
    return (
        <>
            <Carousel historyKey="Purpose" slideNavs={['1', '2', '3']}>
                <AdvisorInfoScreen business={business} />
                <PrivatelyHeldCompaniesInfoScreen />
                <DidYouKnowScreen />
            </Carousel>
        </>
    );
};

export default Purpose;
