import { useParams } from 'react-router-dom';
import { useInnerZoneEmployeeProfile } from '~/hooks';
import { BusinessParams } from '~/pages/business';
import EmployeeProfile from '../EmployeeProfile';

interface IProps {
    employeeId: string;
}

const EmployeeProfileSlideOverContent = ({ employeeId }: IProps): JSX.Element => {
    const { businessId } = useParams<BusinessParams>();
    const { data: profile } = useInnerZoneEmployeeProfile(businessId, employeeId);

    return <div>{profile && <EmployeeProfile profile={profile} />}</div>;
};

export default EmployeeProfileSlideOverContent;
