import { IBusiness, IBusinessValuation, IEmployee, IFamilyLimitedPartnership } from '@api';
import { useEffect } from 'react';
import { Button } from '~/components';
import { useBoundingClientRect } from '~/hooks';
import { getEquityAmount } from '~/utils/businessUtils';
import CurrencyFormatter from '~/utils/currencyFormatter';
import NameFormatter from '~/utils/nameFormatter';
import { formatPercentage } from '~/utils/percentageFormatter';
import ArrowHead, { ArrowHeadDirection, arrowHeadSize } from '../../successionPlan/ArrowHead';
import EntityCard from '../../successionPlan/EntityCard';
import { entityCardWidth, lineStrokeWidth } from '../constants';
import { limitedPartnerColor } from './constants';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
    employeesById: Record<string, IEmployee>;
    onClick: () => void;
    partnership: IFamilyLimitedPartnership;
}

const svgHeight = 50;
const horizontalLineY = 30;
const arrowheadId = 'limitedPartnerArrowhead';

const LimitedPartnersAndLines = ({
    business,
    businessValuation,
    employeesById,
    onClick,
    partnership,
}: IProps): JSX.Element => {
    const partners = partnership.partners;
    const [containerGridRect, containerGridRef] = useBoundingClientRect();
    const [partnersGridRect, partnersGridRef] = useBoundingClientRect();
    const containerSvgWidth = containerGridRect?.width || 0;
    const partnerLinesSvgWidth = partnersGridRect?.width || 0;
    const partnerColumnWidth = partnerLinesSvgWidth / partners.length;
    const totalPartnershipAmount = getEquityAmount(business, businessValuation.value ?? 0, partnership);

    useEffect(() => {
        partnership.partners && partnersGridRef(null);
    }, [partnersGridRef, partnership.partners]);

    return (
        <>
            <svg className="mx-auto" height={horizontalLineY} width={containerSvgWidth}>
                <line
                    className={`stroke-current ${limitedPartnerColor}`}
                    strokeWidth={lineStrokeWidth}
                    x1={containerSvgWidth / 2}
                    y1={0}
                    x2={containerSvgWidth / 2}
                    y2={horizontalLineY}
                />
            </svg>
            <svg className="mx-auto" height={svgHeight} width={partnerLinesSvgWidth}>
                <defs>
                    <ArrowHead className={limitedPartnerColor} direction={ArrowHeadDirection.down} id={arrowheadId} />
                </defs>
                {partners.map((owner, i) => (
                    <line
                        key={owner.familyRelativeId}
                        className={`stroke-current ${limitedPartnerColor}`}
                        strokeWidth={lineStrokeWidth}
                        x1={partnerColumnWidth * (i + 1) - partnerColumnWidth / 2}
                        y1={0}
                        x2={partnerColumnWidth * (i + 1) - partnerColumnWidth / 2}
                        y2={svgHeight - arrowHeadSize * 4}
                        markerEnd={`url(#${arrowheadId})`}
                    />
                ))}
                <line
                    className={`stroke-current ${limitedPartnerColor}`}
                    strokeWidth={lineStrokeWidth}
                    x1={partnerColumnWidth / 2}
                    y1={lineStrokeWidth / 2}
                    x2={partnerLinesSvgWidth - partnerColumnWidth / 2}
                    y2={lineStrokeWidth / 2}
                />
            </svg>
            <div className="w-full" ref={containerGridRef}></div>
            <div className="flex justify-center mx-auto">
                <div
                    className={`grid gap-3`}
                    ref={partnersGridRef}
                    style={{ gridTemplateColumns: `repeat(${partners.length}, 1fr)` }}
                >
                    {partners
                        .map(a => ({ ...employeesById[a.familyRelativeId], ...a }))
                        .sort((a, b) => {
                            if (a.dollarAmount === b.dollarAmount) {
                                return NameFormatter.getLastNameFirst(a) < NameFormatter.getLastNameFirst(b) ? -1 : 1;
                            }
                            return a.dollarAmount < b.dollarAmount ? 1 : -1;
                        })
                        .map(partner => {
                            const employeeInterest = partner.dollarAmount / totalPartnershipAmount;
                            const formatOptions = employeeInterest > 0.01 ? { maximumFractionDigits: 1 } : undefined;
                            const discountPercentage =
                                (partnership.discountForControl + partnership.discountForMarketability) / 100;
                            const giftValue = partner.dollarAmount * (1 - discountPercentage);

                            return (
                                <div key={partner.familyRelativeId}>
                                    <Button color="link" onClick={onClick} className="text-left">
                                        <EntityCard
                                            avatar={partner.avatar || undefined}
                                            className={`${entityCardWidth} hover:bg-gray-700 cursor-pointer`}
                                            description={partner.title || undefined}
                                            name={NameFormatter.getName(partner)}
                                        />
                                    </Button>
                                    <div className="mt-3">
                                        <div className="mb-2">
                                            <span className={`font-bold ${limitedPartnerColor}`}>
                                                {formatPercentage(employeeInterest, formatOptions)}
                                            </span>
                                            &nbsp;Limited Partner Interest
                                        </div>
                                        <div>
                                            <span className="font-bold">{CurrencyFormatter.format(giftValue)}</span>
                                            &nbsp;{partner.isGift ? 'Gift' : 'Sale'} Value
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default LimitedPartnersAndLines;
