import { IBonusRightAggregatePaySummaryYear } from '@api';
import { TableBody, TableCell, TableRow } from '~/components';
import CurrencyTableCell from '../../CurrencyTableCell';
import PercentageTableCell from '../../PercentageTableCell';

interface IProps {
    percentProfitToShareholders: number;
    returnOnEquity: number;
    shareholderEquity: number;
    years: IBonusRightAggregatePaySummaryYear[];
}

const calculateEquity = (
    shareholderEquity: number,
    percentProfitToShareholders: number,
    years: IBonusRightAggregatePaySummaryYear[],
    i: number
) => {
    if (i === 0) return shareholderEquity;
    const cumulativeEbitda = years.slice(1, i + 1).reduce((a, v) => a + v.ebitda, 0);
    return shareholderEquity + cumulativeEbitda * percentProfitToShareholders;
};

const calculateReturnOnEquity = (
    shareholderEquity: number,
    percentProfitToShareholders: number,
    returnOnEquity: number,
    years: IBonusRightAggregatePaySummaryYear[],
    i: number
) => calculateEquity(shareholderEquity, percentProfitToShareholders, years, i) * returnOnEquity;

const calculateProductivityProfit = (
    shareholderEquity: number,
    percentProfitToShareholders: number,
    returnOnEquity: number,
    years: IBonusRightAggregatePaySummaryYear[],
    i: number
) => years[i].ebitda - calculateEquity(shareholderEquity, percentProfitToShareholders, years, i) * returnOnEquity;

const calculateRotri = (
    shareholderEquity: number,
    percentProfitToShareholders: number,
    returnOnEquity: number,
    years: IBonusRightAggregatePaySummaryYear[],
    i: number
) => {
    return (
        calculateProductivityProfit(shareholderEquity, percentProfitToShareholders, returnOnEquity, years, i) /
        (years[i].salaries + years[i].stips + years[i].ltipRedemptions)
    );
};

const ReturnOnTotalRewardsInvestment = ({
    percentProfitToShareholders,
    returnOnEquity,
    shareholderEquity,
    years,
}: IProps): JSX.Element => {
    return (
        <TableBody>
            <TableRow>
                <TableCell className="font-bold">
                    <div className="text-lg">Return on Total Rewards Investment</div>
                    <div className="text-sm">(ROTRI)</div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Shareholder Equity</TableCell>
                {years.map((y, i) => (
                    <CurrencyTableCell
                        key={`equity:${y.year}`}
                        value={calculateEquity(shareholderEquity, percentProfitToShareholders, years, i)}
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>ROE</TableCell>
                {years.map((y, i) => (
                    <CurrencyTableCell
                        key={`equity:${y.year}`}
                        value={calculateReturnOnEquity(
                            shareholderEquity,
                            percentProfitToShareholders,
                            returnOnEquity,
                            years,
                            i
                        )}
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>Productivity Profit</TableCell>
                {years.map((y, i) => (
                    <CurrencyTableCell
                        key={`equity:${y.year}`}
                        value={calculateProductivityProfit(
                            shareholderEquity,
                            percentProfitToShareholders,
                            returnOnEquity,
                            years,
                            i
                        )}
                    />
                ))}
            </TableRow>
            <TableRow>
                <TableCell>ROTRI</TableCell>
                {years.map((y, i) => (
                    <PercentageTableCell
                        key={`equity:${y.year}`}
                        value={calculateRotri(shareholderEquity, percentProfitToShareholders, returnOnEquity, years, i)}
                    />
                ))}
            </TableRow>
        </TableBody>
    );
};

export default ReturnOnTotalRewardsInvestment;
