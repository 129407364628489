import { IEmployee, IEmployeeRatingConfig } from '@api';
import { useState } from 'react';
import { missingDepartmentValue } from '~/utils/employeeUtils';
import NumberFormatter from '~/utils/numberFormatter';
import DepartmentModal from '../DepartmentModal';
import RatingLegend from '../RatingLegend';
import TeamPanelChart from '../TeamPanelChart';

interface IProps {
    employees: IEmployee[];
    ratingConfig: IEmployeeRatingConfig;
}

export interface IDepartment {
    employees: IEmployee[];
    name: string;
    ratedEmployeeCount: number;
    ratingAverage: number;
    ratingSum: number;
}

const Team = ({ employees, ratingConfig }: IProps): JSX.Element => {
    const [activeDepartment, setActiveDepartment] = useState<string>('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const departmentMap = employees.reduce<Record<string, IDepartment>>((result, employee) => {
        const departmentName = employee.department || missingDepartmentValue;
        const hasRating = employee.rating !== null && employee.rating !== undefined;
        const department = result[departmentName];
        const ratedEmployeeCount = (department?.ratedEmployeeCount ?? 0) + (hasRating ? 1 : 0);
        const ratingSum = (department?.ratingSum ?? 0) + (employee.rating ?? 0);
        result[departmentName] = {
            employees: [...(department?.employees ?? []), employee],
            name: departmentName,
            ratedEmployeeCount,
            ratingAverage: ratingSum / ratedEmployeeCount,
            ratingSum,
        };
        return result;
    }, {});
    const departments = Object.values(departmentMap).sort(
        (a, b) => (b.ratingAverage - a.ratingAverage) * (ratingConfig.sortHighToLow ? 1 : -1)
    );

    return (
        <>
            <div className="border-t border-gray-700 pt-3"></div>

            <div className="grid xl:grid-cols-3 grid-cols-1">
                <div className="col-span-1">
                    <RatingLegend {...ratingConfig} />
                    {departments.map(({ name, ratingAverage }) => (
                        <div
                            key={name}
                            className="px-8 py-4 rounded-md flex items-center justify-between bg-gray-800 my-4 cursor-pointer hover:bg-gray-700"
                            onClick={() => {
                                setActiveDepartment(name);
                                setModalIsOpen(true);
                            }}
                        >
                            <div className="text-lg font-bold">{name}</div>
                            <div className="border-l border-gray-700 pl-7 flex flex-col text-center">
                                <div>Average Rating</div>
                                <div className="font-bold text-5xl">{NumberFormatter.formatNumber(ratingAverage)}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-span-2">
                    <TeamPanelChart departments={departments} {...ratingConfig} />
                </div>
                <DepartmentModal
                    employees={departmentMap[activeDepartment]?.employees ?? []}
                    onClose={() => setModalIsOpen(false)}
                    open={modalIsOpen}
                    title={activeDepartment}
                />
            </div>
        </>
    );
};

export default Team;
