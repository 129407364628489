import { ValidationError } from 'yup';

export const yupToFormErrors = (yupError: ValidationError): { [key: string]: string } => {
    const errors = {} as { [name: string]: string };

    if (yupError.inner) {
        for (const err of yupError.inner) {
            if (err.path) {
                errors[err.path] = err.message;
            }
        }
    }

    return errors;
};

// See https://github.com/jquense/yup#mixedtransformcurrentvalue-any-originalvalue-any--any-schema
// Types are mixed.transform((currentValue: any, originalValue: any) => any): Schema

export const transformEmptyStringToNull = (value: unknown, originalValue: unknown): unknown => {
    if (typeof originalValue === 'string' && originalValue.trim() === '') {
        return null;
    }
    return value;
};

export const transformEmptyStringOrZeroToNull = (value: unknown, originalValue: unknown): unknown => {
    if (typeof originalValue === 'string' && ['', '0'].includes(originalValue.trim())) {
        return null;
    }
    return value;
};
