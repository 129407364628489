import { IDocumentIconType } from '@api';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
    faCalendarAlt as faLightCalendarAlt,
    faEnvelopeOpenText as faLightEnvelopeOpenText,
    faFileAlt as faLightFileAlt,
    faFileArchive as faLightFileArchive,
    faFileAudio as faLightFileAudio,
    faFileCode as faLightFileCode,
    faFileCsv as faLightFileCsv,
    faFileExcel as faLightFileExcel,
    faFileImage as faLightFileImage,
    faFilePdf as faLightFilePdf,
    faFilePowerpoint as faLightFilePowerpoint,
    faFileVideo as faLightFileVideo,
    faFileWord as faLightFileWord,
} from '@fortawesome/pro-light-svg-icons';
import {
    faAward,
    faChartPie,
    faDatabase,
    faFeatherAlt,
    faFileAlt as faSolidFileAlt,
    faFileInvoiceDollar,
    faFileSignature,
    faFlagUsa,
    faHotel,
    faMedal,
    faUmbrellaBeach,
    faUniversity,
} from '@fortawesome/pro-solid-svg-icons';

const iconMap: Record<IDocumentIconType, IconDefinition> = {
    Award: faAward,
    Database: faDatabase,
    Feather: faFeatherAlt,
    FileSignature: faFileSignature,
    FlagUsa: faFlagUsa,
    Hotel: faHotel,
    InvoiceDollar: faFileInvoiceDollar,
    Medal: faMedal,
    Other: faSolidFileAlt,
    PieChart: faChartPie,
    UmbrellaBeach: faUmbrellaBeach,
    University: faUniversity,
};

export const getIconFromType = (iconType?: IDocumentIconType): IconDefinition => {
    const result = iconMap[iconType || IDocumentIconType.Other];
    return result || iconMap[IDocumentIconType.Other];
};

// based on extension list at https://fileinfo.com/filetypes/common
export const getIconFromExtension = (fileName: string): IconDefinition => {
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex <= 0) {
        return faLightFileAlt;
    }

    const extension = fileName.substr(lastIndex + 1).toLowerCase();
    switch (extension) {
        case '7z':
        case 'cbr':
        case 'deb':
        case 'gz':
        case 'pkg':
        case 'rar':
        case 'rpm':
        case 'sitx':
        case 'tar':
        case 'zip':
        case 'zipx':
            return faLightFileArchive;
        case 'aif':
        case 'iff':
        case 'm3u':
        case 'm4a':
        case 'mid':
        case 'mp3':
        case 'mpa':
        case 'wav':
        case 'wma':
            return faLightFileAudio;
        case 'csv':
            return faLightFileCsv;
        case 'ics':
            return faLightCalendarAlt;
        case 'msg':
            return faLightEnvelopeOpenText;
        case 'doc':
        case 'docx':
        case 'odt':
        case 'pages':
        case 'rtf':
        case 'txt':
        case 'wpd':
        case 'wps':
            return faLightFileWord;
        case 'indd':
        case 'pdf':
            return faLightFilePdf;
        case 'htm':
        case 'html':
        case 'xhtml':
            return faLightFileCode;
        case 'xlr':
        case 'xls':
        case 'xlsx':
            return faLightFileExcel;
        case 'key':
        case 'keychain':
        case 'pps':
        case 'ppt':
        case 'pptx':
            return faLightFilePowerpoint;
        case '3dm':
        case '3ds':
        case 'ai':
        case 'bmp':
        case 'dds':
        case 'eps':
        case 'gif':
        case 'heic':
        case 'jpg':
        case 'max':
        case 'png':
        case 'psd':
        case 'pspimage':
        case 'svg':
        case 'tga':
        case 'thm':
        case 'tif':
        case 'tiff':
        case 'yuv':
            return faLightFileImage;
        case '3g2':
        case '3gp':
        case 'asf':
        case 'avi':
        case 'flv':
        case 'm4v':
        case 'mov':
        case 'mp4':
        case 'mpg':
        case 'rm':
        case 'srt':
        case 'swf':
        case 'vob':
        case 'wmv':
            return faLightFileVideo;
        default:
            return faLightFileAlt;
    }
};
