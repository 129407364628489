import { INoteType } from '@api';
import { useAddNote } from '~/hooks';
import NoteForm, { INoteFormData } from './NoteForm';

interface IProps {
    businessId: string;
    isOpen: boolean;
    onClose: () => void;
    type: INoteType;
}

const AddNoteForm = ({ businessId, isOpen, onClose, type }: IProps): JSX.Element => {
    const addNote = useAddNote(businessId);
    const onSave = (formData: INoteFormData) => {
        return addNote
            .mutateAsync({
                ...formData,
                businessId,
                type,
            })
            .then(onClose);
    };

    return <NoteForm isOpen={isOpen} onClose={onClose} onSave={onSave} title={`Add ${type}`} />;
};

export default AddNoteForm;
