import {
    IBonusRightAggregatePaySummary,
    IBonusRightEmployeeRewards,
    IBonusRightEmployeeValueStatement,
    IBonusRightExecutiveBenefitAnalysis,
    IEmployee,
} from '@api';
import { getAge } from '~/utils/dateHelpers';
import NameFormatter from '~/utils/nameFormatter';

const currentYear = new Date().getFullYear();
const ltipYearlyMultiplier = 0.02;
const profitPercentOfValue = 0.25;
const revenuePercentOfValue = 0.667;
const salaryYearlyMultiplier = 0.04;
const stipYearlyMultiplier = 0.02;

const getBonusRightSampleEmployeeRewardsForEmployee = (employee: IEmployee, bonusRightEmployeeId: number) => ({
    age: getAge(employee.dateOfBirth),
    avatar: employee.avatar,
    benefits: (employee.salary || 0) / 15,
    bonusRightEmployeeId,
    equity: null,
    grade: '',
    longTermIncentives: bonusRightEmployeeId <= 3 ? (employee.salary || 0) / 18 : null,
    name: NameFormatter.getName(employee),
    retirement: (employee.salary || 0) / 16.67,
    salary: employee.salary || 0,
    shortTermIncentives: (employee.salary || 0) / 4,
});

export const getBonusRightSampleAggregatePaySummary = (employees: IEmployee[]): IBonusRightAggregatePaySummary => {
    const numberOfYears = 5;
    const enterpriseValue = 20_000_000;
    const ebitda = enterpriseValue * profitPercentOfValue;
    const grossSales = enterpriseValue * revenuePercentOfValue;
    const totals = employees.reduce(
        (total, employee, i) => {
            const employeeRewards = getBonusRightSampleEmployeeRewardsForEmployee(employee, i + 1);

            total.ltip += employeeRewards.longTermIncentives || 0;
            total.salary += employeeRewards.salary;
            total.stip += employeeRewards.shortTermIncentives;

            return total;
        },
        { salary: 0, stip: 0, ltip: 0 }
    );

    return {
        baseValuation: 20_000_000,
        percentProfitToShareholders: 0,
        returnOnEquity: 0,
        shareholderEquity: 0,
        years: [...Array(numberOfYears).keys()].map(yearIndex => {
            const grossSalesForYear = grossSales + grossSales * salaryYearlyMultiplier * yearIndex;
            return {
                ebitda: ebitda + ebitda * salaryYearlyMultiplier * yearIndex,
                formulaValue: grossSalesForYear * 0.94,
                grossSales: grossSalesForYear,
                ltipRedemptions: totals.ltip + totals.salary * ltipYearlyMultiplier * yearIndex,
                ltipTotalValue: totals.ltip + totals.salary * ltipYearlyMultiplier * yearIndex,
                salaries: totals.salary + totals.salary * salaryYearlyMultiplier * yearIndex,
                stips: totals.stip + totals.salary * stipYearlyMultiplier * yearIndex,
                year: currentYear + yearIndex,
            };
        }),
    };
};

export const getBonusRightSampleEmployeeRewards = (employees: IEmployee[]): IBonusRightEmployeeRewards[] =>
    employees.map((e, i) => getBonusRightSampleEmployeeRewardsForEmployee(e, i + 1));

export const getBonusRightSampleEmployeeValueStatement = (
    employees: IEmployee[],
    bonusRightEmployeeId: number
): IBonusRightEmployeeValueStatement => {
    const numberOfYears = 5;
    const employee = employees[bonusRightEmployeeId - 1];
    const employeeRewards = getBonusRightSampleEmployeeRewardsForEmployee(employee, bonusRightEmployeeId);

    return {
        employeeDisplayName: NameFormatter.getName(employee),
        years: [...Array(numberOfYears).keys()].map(yearIndex => {
            const { longTermIncentives, salary, shortTermIncentives } = employeeRewards;

            return {
                ltipRedemption:
                    (longTermIncentives || 0) + (longTermIncentives || 0) * ltipYearlyMultiplier * yearIndex,
                salary: salary + salary * salaryYearlyMultiplier * yearIndex,
                stipPayment: shortTermIncentives + shortTermIncentives * stipYearlyMultiplier * yearIndex,
                unvestedValue:
                    (longTermIncentives || 0) + (longTermIncentives || 0) * ltipYearlyMultiplier * (yearIndex + 2),
                vestedValue:
                    (longTermIncentives || 0) + (longTermIncentives || 0) * ltipYearlyMultiplier * (yearIndex + 1),
                year: (currentYear + yearIndex).toString(),
            };
        }),
    };
};

export const getBonusRightSampleExecutiveBenefitAnalysis = (
    employees: IEmployee[]
): IBonusRightExecutiveBenefitAnalysis => {
    const executives = employees.filter(e => e.title?.startsWith('C'));

    return {
        employees: executives.map(e => {
            const employeeRewards = getBonusRightSampleEmployeeRewardsForEmployee(e, 1);

            return {
                grade: '',
                longTermIncentive: employeeRewards.longTermIncentives,
                name: NameFormatter.getName(e),
                salary: employeeRewards.salary,
                shortTermIncentive: employeeRewards.shortTermIncentives,
                title: e.title,
            };
        }),
    };
};
