import classnames from 'classnames';
import { ProgressChart } from '~/components';
import { IChartPoint } from '~/components/charts/chartModels';
import CurrencyFormatter from '~/utils/currencyFormatter';
import { IEmployeeRewardsSummaryStatViewModel } from './models';

interface IProps {
    stats: IEmployeeRewardsSummaryStatViewModel[];
}

const colors = ['primary-400', 'primary-500', 'cyan-500', 'teal-500', 'yellow-500', 'purple-500'];

const EmployeeRewardsStats = ({ stats }: IProps): JSX.Element => {
    const points = stats.map<IChartPoint>((r, i) => ({
        name: r.shortName || r.name,
        color: colors[i],
        value: r.value,
    }));

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-6 gap-4 mb-4">
                {stats.map((s, i) => (
                    <div key={s.name} className="flex bg-gray-800 rounded-lg px-4 py-5">
                        <div
                            className={classnames(
                                `bg-${colors[i]}`,
                                'h-6 w-6 rounded-full mr-4 flex-shrink-0 self-center'
                            )}
                        />
                        <div title={`${s.name} ${CurrencyFormatter.format(s.value)}`}>
                            <div className="text-sm leading-5 font-medium truncate">{s.name}</div>
                            <div className="text-2xl leading-8 font-semibold truncate">
                                {s.value ? CurrencyFormatter.format(s.value) : '--'}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ProgressChart points={points} />
        </>
    );
};

export default EmployeeRewardsStats;
