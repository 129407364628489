import { ErrorPage, LoadingIndicator } from '~/components';
import { useInnerZoneSurvey } from '~/hooks';
import CurrentUserProfile from './innerZoneSurvey/CurrentUserProfile';
import InnerZoneSurvey from './innerZoneSurvey/InnerZoneSurvey';

const InnerZoneSurveyTakerLayout = (): JSX.Element | null => {
    const { isLoading, isError, data: survey } = useInnerZoneSurvey();
    if (isLoading) {
        return <LoadingIndicator />;
    }
    if (isError || !survey) {
        return <ErrorPage />;
    }
    const { isCompleted } = survey;

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            {isCompleted ? <CurrentUserProfile /> : <InnerZoneSurvey survey={survey} />}
        </div>
    );
};

export default InnerZoneSurveyTakerLayout;
