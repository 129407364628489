import { IBusiness, IBusinessValuation, IEmployee } from '@api';
import { useState } from 'react';
import { useStorageState } from 'react-storage-hooks';
import { EntitySelect, ErrorPage, IEntitySelectOption, LoadingIndicator } from '~/components';
import {
    isTrueForAnyQuery,
    useDeleteFamilyLimitedPartnership,
    useEmployees,
    useFamilyLimitedPartnershipForEmployee,
} from '~/hooks';
import { getEmployeesById } from '~/utils/businessUtils';
import NameFormatter from '~/utils/nameFormatter';
import { getStorageKey } from '~/utils/storageKeys';
import EntityCard from '../../successionPlan/EntityCard';
import ResetButton from '../ResetButton';
import AddLimitedPartners from './AddLimitedPartners';
import AddPartnership from './AddPartnership';
import LimitedPartnersAndLines from './LimitedPartnersAndLines';
import LimitedPartnersForm from './LimitedPartnersForm';
import PartnershipCard from './PartnershipCard';
import RemainingOwnerInterest from './RemainingOwnerInterest';

interface IProps {
    business: IBusiness;
    businessValuation: IBusinessValuation;
}

const buildOwnerOptions = (owners: IEmployee[]): IEntitySelectOption[] =>
    owners.map(employee => ({
        avatar: employee.avatar || undefined,
        label: NameFormatter.getLastNameFirst(employee),
        name: NameFormatter.getLastNameFirst(employee),
        value: employee.id,
    }));

const FamilyLimitedPartnership = (props: IProps): JSX.Element => {
    const { business } = props;
    const [isPartnersFormOpen, setIsPartnersFormOpen] = useState(false);
    const employeesQuery = useEmployees(business.id);
    const { data: employees = [] } = employeesQuery;
    const owners = employees.filter(e => e.isOwner).sort(e => (e.id === business.primaryEmployeeId ? -1 : 1));
    const [selectedOwnerId, setSelectedOwnerId] = useStorageState<string>(
        localStorage,
        getStorageKey(`${business.id}.flpSelectedOwner`),
        owners?.[0].id
    );
    const flpQuery = useFamilyLimitedPartnershipForEmployee(business.id, selectedOwnerId);
    const deletePartnership = useDeleteFamilyLimitedPartnership(business.id);
    const isError = isTrueForAnyQuery('isError', employeesQuery, flpQuery);
    const isLoading = isTrueForAnyQuery('isLoading', employeesQuery, flpQuery);
    if (isError && flpQuery.error?.response?.status !== 404) return <ErrorPage />;
    if (isLoading) return <LoadingIndicator />;

    const { data: partnership } = flpQuery;
    const employeesById = getEmployeesById(employees);
    const selectedOwner = employeesById[selectedOwnerId];
    if (!owners.length) {
        return <div>The business must have owners to use this tool.</div>;
    }
    if (!selectedOwner) {
        return (
            <div className="max-w-sm">
                <EntitySelect
                    label="Which owner?"
                    onEntityOptionSelected={e => setSelectedOwnerId(e.value)}
                    options={buildOwnerOptions(owners)}
                    value={''}
                />
            </div>
        );
    }

    return (
        <div className="h-full flex flex-col">
            <div className="max-w-sm">
                <EntitySelect
                    label="Which owner?"
                    onEntityOptionSelected={e => setSelectedOwnerId(e.value)}
                    options={buildOwnerOptions(owners)}
                    value={selectedOwnerId}
                />
            </div>
            <div className="flex flex-col items-center mt-8">
                {!partnership ? (
                    <AddPartnership owner={employeesById[selectedOwnerId]} />
                ) : (
                    <PartnershipCard owner={employeesById[selectedOwnerId]} partnership={partnership} {...props} />
                )}
                <div style={{ minWidth: '15rem' }}>
                    <EntityCard
                        avatar={selectedOwner.avatar || undefined}
                        className="min-w-full pr-2"
                        description={selectedOwner.title || undefined}
                        name={NameFormatter.getName(selectedOwner)}
                        value={partnership ? <RemainingOwnerInterest partnership={partnership} {...props} /> : null}
                    />
                </div>
                {partnership && (
                    <>
                        {partnership.partners.length === 0 ? (
                            <AddLimitedPartners onClickAdd={() => setIsPartnersFormOpen(true)} />
                        ) : (
                            <LimitedPartnersAndLines
                                employeesById={employeesById}
                                onClick={() => setIsPartnersFormOpen(true)}
                                partnership={partnership}
                                {...props}
                            />
                        )}
                        <LimitedPartnersForm
                            isOpen={isPartnersFormOpen}
                            onClose={() => setIsPartnersFormOpen(false)}
                            partnership={partnership}
                            {...props}
                        />
                    </>
                )}
            </div>
            {partnership && (
                <ResetButton
                    modelTitle="Family Limited Partnership"
                    onConfirm={() => deletePartnership.mutateAsync(partnership.id)}
                />
            )}
        </div>
    );
};

export default FamilyLimitedPartnership;
