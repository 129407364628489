import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button } from '.';

interface IProps {
    className?: string;
    relative?: boolean;
}

const BackLink = ({ relative, className }: IProps): JSX.Element | null => {
    const history = useHistory();
    if (!history.length) {
        return null;
    }

    return (
        <Button
            color="primaryLink"
            className={classNames(className, { 'absolute left-0': relative })}
            onClick={history.goBack}
        >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
            Go Back
        </Button>
    );
};

export { BackLink };
