interface IProps {
    label: string;
    value: string;
}

const BusinessInfoGridItem = ({ label, value }: IProps): JSX.Element => (
    <>
        <div>{label}</div>
        <div className="col-span-2">{value}</div>
    </>
);

export default BusinessInfoGridItem;
