import { IBusiness } from '@api';
import GoogleMapReact, { BootstrapURLKeys, MapOptions } from 'google-map-react';
import { useEffect, useState } from 'react';
import { Button, ErrorPage, LoadingIndicator } from '~/components';
import { usePlacesNearby } from '~/hooks';
import BusinessMarker from './BusinessMarker';
import PlaceMarker from './PlaceMarker';

interface IProps {
    business: IBusiness;
    latitude: number;
    longitude: number;
}

const apiKey = 'AIzaSyBo7Rg-qGlMo_i2ssCxihoJcg5QUVw67zk';
const mapId = 'ccdb9858e62441d0';

const keys: BootstrapURLKeys & { mapIds: string[] } = {
    key: apiKey,
    mapIds: [mapId],
};

const options: MapOptions & { mapId: string } = {
    fullscreenControl: false,
    keyboardShortcuts: false,
    zoomControl: false,
    mapId,
};

const stepText = ['Look at Your Community', 'What happens to Your Community if we fail?'];

const CommunityMap = ({ business, latitude, longitude }: IProps): JSX.Element => {
    const [step, setStep] = useState(0);
    const [visiblePlaceIds, setVisiblePlaceIds] = useState<string[]>([]);
    const { data: places, error } = usePlacesNearby(latitude || 0, longitude || 0);
    const numberOfPlacesToRemoveAtATime = ((places && places.length) || 0) / 4;

    useEffect(() => {
        if (step === 2) {
            if (visiblePlaceIds.length) {
                const timer = setTimeout(() => {
                    setVisiblePlaceIds(
                        visiblePlaceIds.slice(0, visiblePlaceIds.length - numberOfPlacesToRemoveAtATime)
                    );
                }, 1000);

                return () => clearTimeout(timer);
            }
        }
    }, [step, visiblePlaceIds, numberOfPlacesToRemoveAtATime]);

    if (error) return <ErrorPage />;
    if (!places) return <LoadingIndicator />;

    return (
        <div className="flex flex-col h-full">
            <GoogleMapReact
                bootstrapURLKeys={keys}
                defaultCenter={{ lat: latitude, lng: longitude }}
                defaultZoom={17}
                options={options}
            >
                {places.map(p => (
                    <PlaceMarker
                        key={p.id}
                        className={`${
                            visiblePlaceIds.indexOf(p.id) > -1 ? 'opacity-100' : 'opacity-0 pointer-events-none'
                        } duration-1000 transition-opacity`}
                        place={p}
                        lat={p.latitude}
                        lng={p.longitude}
                    />
                ))}
                <BusinessMarker business={business} lat={latitude} lng={longitude} />
            </GoogleMapReact>
            <div className="flex justify-center items-center h-28">
                {step < 2 ? (
                    <Button
                        color="primary"
                        size="lg"
                        onClick={() => {
                            if (step === 0) {
                                setVisiblePlaceIds(places.map(p => p.id));
                            }
                            setStep(step + 1);
                        }}
                    >
                        {stepText[step]}
                    </Button>
                ) : (
                    <div>Look at all that depends on your success</div>
                )}
            </div>
        </div>
    );
};

export default CommunityMap;
