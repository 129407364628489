import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

type TextInputType = 'number' | 'password' | 'text';

export interface ITextInputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
    className?: string;
    error?: string;
    inputClassName?: string;
    label: string;
    labelClassName?: string;
    type?: TextInputType;
    onChange: (value: string) => void;
}

const TextInput = (props: ITextInputProps): JSX.Element => {
    const { className, error, labelClassName, label } = props;

    return (
        <div className={className}>
            <TextInputLabel label={label} labelClassName={labelClassName} />
            <TextInputElement {...props} />
            <TextInputError error={error} />
        </div>
    );
};

export const TextInputElement = ({
    className,
    disabled,
    error,
    inputClassName,
    label,
    labelClassName,
    onChange,
    style,
    type = 'text',
    ...rest
}: ITextInputProps): JSX.Element => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

    return (
        <input
            autoComplete="off"
            className={classNames(
                {
                    'border-danger-300 placeholder-danger-300 focus:ring-danger-500 focus:border-danger-500': !!error,
                },
                { 'focus:ring-primary-500 focus:border-primary-500': !error },
                { 'text-gray-400': disabled },
                `
                        mt-1
                        bg-input-bg
                        block
                        w-full
                        sm:text-sm
                        shadow-sm
                        rounded-md
                        focus:outline-none
                        disabled:cursor-not-allowed
                        disabled:opacity-50
                    `,
                inputClassName
            )}
            disabled={disabled}
            style={{
                border: !error ? '1px solid var(--color-input-border)' : undefined,
                ...style,
            }}
            type={type}
            onChange={handleChange}
            {...rest}
        />
    );
};

interface IErrorProps {
    error?: string;
}

export const TextInputError = ({ error }: IErrorProps): JSX.Element | null =>
    error ? <p className="mt-2 text-sm text-danger-600">{error}</p> : null;

interface ILabelProps {
    label: string;
    labelClassName?: string;
}

export const TextInputLabel = ({ label, labelClassName }: ILabelProps): JSX.Element => (
    <label className={classNames(labelClassName, 'block text-sm font-medium')}>{label}</label>
);

export { TextInput };
