import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';
import NumberFormatter from '~/utils/numberFormatter';

interface IProps {
    error?: string;
    label: string;
    labelClassName?: string;
    onChange: (value: number | null) => void;
}

const CurrencyInput = ({
    className,
    error,
    label,
    labelClassName,
    onChange,
    style,
    value,
    ...rest
}: IProps & Omit<ComponentPropsWithoutRef<'input'>, 'onChange'>): JSX.Element => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === '') {
            onChange(null);
        } else {
            onChange(NumberFormatter.unformatNumber(value));
        }
    };

    return (
        <div>
            <label className={classNames(labelClassName, 'block text-sm font-medium')}>{label}</label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faDollarSign} className="text-gray-500" aria-hidden="true" />
                </div>
                <input
                    autoComplete="off"
                    className={classNames(
                        {
                            'border-danger-300 placeholder-danger-300 focus:ring-danger-500 focus:border-danger-500':
                                !!error,
                        },
                        { 'focus:ring-primary-500 focus:border-primary-500': !error },
                        `
                            pl-7
                            block
                            w-full
                            bg-input-bg
                            sm:text-sm
                            shadow-sm
                            text-right
                            rounded-md
                            focus:outline-none
                            disabled:cursor-not-allowed
                            disabled:opacity-50
                        `,
                        className
                    )}
                    min="0"
                    onBlur={handleChange}
                    onChange={handleChange}
                    style={{
                        border: !error ? '1px solid var(--color-input-border)' : undefined,
                        ...style,
                    }}
                    type="text"
                    value={NumberFormatter.formatNumber(value as string)}
                    {...rest}
                />
            </div>
            {error && <p className="mt-2 text-sm text-danger-600">{error}</p>}
        </div>
    );
};

export { CurrencyInput };
